import namespace from '@/helpers/namespace';

export default namespace('poll', {
  getters: [
    'poll',
    'pollInformation',
    'pollId',
    'pollData',
    'pollDescription'
  ],
  actions: [
    'encuesta',
    'sendAnswer',
    'list',
    'result',
    'generalPollData',
    'filteredPollData',
    'dayRangeFilter',
    'filterDateEnd'
  ],
  mutations: [
    'setPoll',
    'setPollInformation',
    'setPollId',
    'setPollData',
    'setPollDescription'
  ]
});
