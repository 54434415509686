<template>
  <div id="metrics-body" style="overflow: auto;">
    <div v-if="vendor">
      <div class="header-index withMedia" style="width:100%;padding-left:5px;">
        <i class="icon-mks icon_reservation big-reservation white"></i>
        <p class="text bold" v-if="sectionId == 1 || sectionId == 4">Métricas de reservas</p>
        <p class="text bold" v-if="sectionId == 2">Métricas de precompras</p>
        <p class="text bold" v-if="sectionId == 3">Métricas de Walk-in</p>
      </div>
      <div v-if="sectionId != 4">
        <div v-if="!load">
          <div class="flex">
            <Sumary
              :color="colors[0]"
              :colorAvatar="primaryColor"
              :colorIcon="'#fff'"
              :dataReservations="dataReservation"
              :percent="Math.round((dataReservation.reservas * 100) / dataReservation.reservas)"
              :sectionId="sectionId"
              :showOrigen="false"
              :type="type"
              v-if="dataReservation && dateFrom == null && dateTo == null "
            ></Sumary>
            <Sumary
              :color="colors[0]"
              :colorIcon="'#D7D9DB'"
              :dataReservations="dataReservationRange"
              :percent="Math.round((dataReservationRange.reservas * 100) / dataReservation.reservas)"
              :sectionId="sectionId"
              :showOrigen="false"
              :type="type"
              @open2="openMenuM2"
              v-else
            ></Sumary>
            <div
              class="rigth rigth-overflow-mobile"
              v-if="(dataReservation.byMonth && dataReservation.byMonth.length > 0)"
            >
              <div class="v-line">
                <ve-line :colors="colors" :data="byMonthD" :settings="chartSettings2"></ve-line>
              </div>
              <div class="flex metric-cont">
                <div class="header-index flex-1">
                  <p class="text bold padding-left">Cantidad por día</p>
                </div>
                <div class="header-index flex-1">
                  <p class="text bold padding-left" v-if="sectionId !== 3">No efectivas</p>
                  <p
                    class="text bold padding-left"
                    v-else
                  >Cantidad reservas / por grupos de personas asdasdas</p>
                </div>
              </div>
              <div class="flex metric-cont grafica">
                <div class="header-index grafica flex-1">
                  <div class="col-3">
                    <column-chart
                      :colors="getChartColors(byDayD)"
                      :data="byDayD"
                      :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40,symbolPadding: 5}}"
                      class="graph"
                    ></column-chart>
                  </div>
                  <div class="col padding-top flex">
                    <div class="text small bold black">TOTAL asdasd</div>
                    <div class="flex width-height border-top-bot" v-if="sectionId !== 3">
                      <div class="col flex margin-top">
                        <div :key="index" v-for=" (data,index) in byReferrerD">
                          <img
                            :key="index"
                            :src="getUrlImageReferrer(data.referrer)"
                            style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                            v-if="getUrlImageReferrer(data.referrer) !== null"
                            v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                          />
                          <i
                            :class="iconReferrer(data.referrer)"
                            :key="data.referrer"
                            class="icon-mks gray-blue small-grafica"
                            v-else
                            v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                          ></i>
                        </div>
                      </div>
                      <div class="col flex" v-if="typeByDay == 1">
                        asdasdasdsad
                        <p
                          :key="index"
                          class="text black small-grafica"
                          v-for=" (data,index) in byReferrerD"
                        >{{ data.reservations }}</p>
                      </div>
                      <div class="col flex" v-if="typeByDay == 2">
                        <p
                          :key="index"
                          class="text black small-grafica"
                          v-for=" (data,index) in byReferrerD"
                        >{{ data.people }}</p>
                      </div>
                      <div class="col flex" v-if="typeByDay == 1">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica"
                          v-for=" (data,index) in byReferrerD"
                        >{{ ((data.reservations*100) / getTotalsByType(byReferrerD, 'reservations')).toFixed(decimals)}}%</p>
                      </div>
                      <div class="col flex" v-if="typeByDay == 2">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica"
                          v-for=" (data,index) in byReferrerD"
                        >{{ ((data.people*100) / getTotalsByType(byReferrerD, 'people')).toFixed(decimals)}}%</p>
                      </div>
                    </div>
                    <div
                      :class="dataPeopleYate === null ? 'walking':''"
                      class="flex width-height border-top-bot"
                      v-else
                    >
                      <i
                        class="icon_walkin big-walking icon-mks big gray-blue"
                        v-if="dataPeopleYate === null"
                      ></i>
                      <div class="flex margin-width" v-if="dataPeopleYate === null">
                        <div class="col center" v-if="dataPeopleYate === null">
                          <p
                            class="text tittle bold"
                            style="color:#a25bdf"
                            v-if="typeByDay == 1"
                          >{{dataReservationWalkIn}}</p>
                          <p
                            class="text tittle bold"
                            style="color:#a25bdf"
                            v-if="typeByDay == 2"
                          >{{dataPeopleWalkIn}}</p>
                        </div>
                        <div class="col center" v-if="dataPeopleYate === null">
                          <p class="text tittle black bold">100%</p>
                        </div>
                      </div>
                      <div class="col flex margin-top" v-if="dataPeopleYate != null">
                        <i
                          class="icon_walkin icon-mks gray-blue small-grafica"
                          v-if="dataPeopleYate != null"
                        ></i>
                        <i
                          class="icon_yate icon-mks gray-blue small-grafica"
                          v-if="dataPeopleYate != null"
                        ></i>
                      </div>
                      <div class="col flex" v-if="typeByDay == 1 && dataPeopleYate != null">
                        <p
                          class="text tittle bold"
                          style="color:#a25bdf"
                          v-if="typeByDay == 1 && dataPeopleYate != null"
                        >{{dataReservationWalkIn}}</p>
                        <p
                          class="text tittle bold"
                          style="color:#000099"
                          v-if="typeByDay == 1 && dataPeopleYate != null"
                        >{{dataReservationYate}}</p>
                      </div>
                      <div class="col flex" v-if="typeByDay == 2 && dataPeopleYate != null">
                        <p
                          class="text tittle bold"
                          style="color:#a25bdf"
                          v-if="typeByDay == 2 && dataPeopleYate != null"
                        >{{dataPeopleWalkIn}}</p>
                        <p
                          class="text tittle bold"
                          style="color:#000099"
                          v-if="typeByDay == 2 && dataPeopleYate != null"
                        >{{dataPeopleYate}}</p>
                      </div>
                      <div class="col flex" v-if="typeByDay == 1 && dataPeopleYate != null">
                        <p
                          class="text black small-grafica"
                          style="color:#a25bdf"
                          v-if="typeByDay == 1 && dataPeopleYate != null"
                        >{{ ((dataReservationWalkIn*100) / (dataReservationD)).toFixed(decimals)}}%</p>
                        <p
                          class="text black small-grafica"
                          style="color:#000099"
                          v-if="typeByDay == 1 && dataPeopleYate != null"
                        >{{ ((dataReservationYate*100) / (dataReservationD)).toFixed(decimals)}}%</p>
                      </div>
                      <div class="col flex" v-if="typeByDay == 2 && dataPeopleYate != null">
                        <p
                          class="text black small-grafica"
                          style="color:#a25bdf"
                          v-if="typeByDay == 2 && dataPeopleYate != null"
                        >{{ ((dataPeopleWalkIn*100) / (dataPeopleD)).toFixed(decimals)}}%</p>
                        <p
                          class="text black small-grafica"
                          style="color:#000099"
                          v-if="typeByDay == 2 && dataPeopleYate != null"
                        >{{ ((dataPeopleYate*100) / (dataPeopleD)).toFixed(decimals)}}%</p>
                      </div>
                    </div>
                    <div
                      class="text small bold black"
                      v-if="(typeByDay == 1 &&sectionId !== 3)"
                    >Total: {{getTotalsByType(byReferrerD , 'reservations')}}</div>
                    <div
                      class="text small bold black"
                      v-if="(typeByDay == 2 &&sectionId !== 3)"
                    >Total: {{getTotalsByType(byReferrerD , 'people')}}</div>
                    <div class="flex radio-grafica width-height">
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="1"
                          name="reservas"
                          type="is-white"
                          v-model="typeByDay"
                        >
                          <p class="text radio">Reservas</p>
                        </b-radio>
                      </div>
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="2"
                          name="reservas"
                          type="is-white"
                          v-model="typeByDay"
                        >
                          <p class="text radio">Personas</p>
                        </b-radio>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="header-index grafica flex-1" v-if="sectionId !== 3">
                  <div class="col-3" v-if="(notEffectiveD.rows && notEffectiveD.rows.length > 0)">
                    <ve-ring
                      :colors="getChartColors(notEffectiveD)"
                      :data="notEffectiveD"
                      :settings="chartSettings"
                      :extend="chartExtend"
                      class="ringCheck"
                      height="300px"
                      width="380px"
                    ></ve-ring>
                  </div>
                  <div class="col-3 noresults" v-else>
                    <div class="wrapper__noresults">Sin Resultados</div>
                  </div>
                  <div class="col padding-top flex">
                    <div class="text small bold black">TOTAL</div>
                    <div class="flex width-height border-top-bot">
                      <div class="col flex margin-top" v-if="typeNotEffective == 1 ">
                        <div :key="index" v-for=" (data,index) in byIsCancelledD">
                          <img
                            :key="index"
                            :src="getUrlImageReferrer(data.referrer)"
                            style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                            v-if="getUrlImageReferrer(data.referrer) !== null"
                            v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                          />
                          <i
                            :class="iconReferrer(data.referrer)"
                            :key="data.referrer"
                            class="icon-mks gray-blue small-grafica"
                            v-else
                            v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                          ></i>
                        </div>
                      </div>
                      <div class="col flex margin-top" v-if="typeNotEffective == 2 ">
                        <div :key="index" v-for=" (data,index) in byNotShowD">
                          <img
                            :key="index"
                            :src="getUrlImageReferrer(data.referrer)"
                            style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                            v-if="getUrlImageReferrer(data.referrer) !== null"
                            v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                          />
                          <i
                            :class="iconReferrer(data.referrer)"
                            :key="data.referrer"
                            class="icon-mks gray-blue small-grafica"
                            v-else
                            v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                          ></i>
                        </div>
                      </div>
                      <div class="col flex" v-if="typeNotEffective == 1">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byIsCancelledD"
                        >{{ data.reservations}}</p>
                      </div>
                      <div class="col flex" v-if="typeNotEffective == 1">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byIsCancelledD"
                        >{{ data.people}}</p>
                      </div>
                      <div class="col flex" v-if="typeNotEffective == 1">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byIsCancelledD"
                        >{{ ((data.reservations*100) / getTotalsByType(byIsCancelledD,'reservations')).toFixed(decimals)}}%</p>
                      </div>
                      <div class="col flex" v-if="typeNotEffective == 2">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byNotShowD"
                        >{{ data.reservations}}</p>
                      </div>
                      <div class="col flex" v-if="typeNotEffective == 2">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byNotShowD"
                        >{{ data.people}}</p>
                      </div>
                      <div class="col flex" v-if="typeNotEffective == 2">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byNotShowD"
                        >{{ ((data.reservations*100) / getTotalsByType(byNotShowD,'reservations')).toFixed(decimals)}}%</p>
                      </div>
                    </div>
                    <div
                      class="text small bold black"
                      v-if="typeNotEffective == 1"
                    >Total: {{getTotalsByType(byIsCancelledD , 'reservations')}}</div>
                    <div
                      class="text small bold black"
                      v-if="typeNotEffective == 2"
                    >Total: {{getTotalsByType(byNotShowD , 'reservations')}}</div>
                    <div class="flex radio-grafica width-height">
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="1"
                          name="reservasEffective"
                          type="is-white"
                          v-model="typeNotEffective"
                        >
                          <p class="text radio">Canceladas</p>
                        </b-radio>
                      </div>
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="2"
                          name="reservasEffective"
                          type="is-white"
                          v-model="typeNotEffective"
                        >
                          <p class="text radio">No-llegaron</p>
                        </b-radio>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="header-index grafica flex-1" v-else>
                  <div class="col-3">
                    <column-chart
                      :colors="getChartColors(byRangeD)"
                      :data="byRangeD"
                      :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                      class="graph"
                    ></column-chart>
                  </div>
                  <div class="col padding-top flex">
                    <div class="text small bold black">TOTAL</div>
                    <div
                      :class="dataPeopleYate === null ? 'walking':''"
                      class="flex width-height border-top-bot"
                    >
                      <i
                        class="icon_walkin big-walking icon-mks big gray-blue"
                        v-if="dataPeopleYate === null"
                      ></i>
                      <div class="flex margin-width" v-if="dataPeopleYate === null">
                        <div class="col center" v-if="dataPeopleYate === null">
                          <p
                            class="text tittle bold"
                            style="color:#a25bdf"
                            v-if="typeByRange == 1"
                          >{{dataReservationWalkIn}}</p>
                          <p
                            class="text tittle bold"
                            style="color:#a25bdf"
                            v-if="typeByRange == 2"
                          >{{dataPeopleWalkIn}}</p>
                        </div>
                        <div class="col center" v-if="dataPeopleYate === null">
                          <p class="text tittle black bold">100%</p>
                        </div>
                      </div>
                      <div class="col flex margin-top" v-if="dataPeopleYate != null">
                        <i
                          class="icon_walkin icon-mks gray-blue small-grafica"
                          v-if="dataPeopleYate != null"
                        ></i>
                        <i
                          class="icon_yate icon-mks gray-blue small-grafica"
                          v-if="dataPeopleYate != null"
                        ></i>
                      </div>
                      <div class="col flex" v-if="typeByRange == 1 && dataPeopleYate != null">
                        <p
                          class="text tittle bold"
                          style="color:#a25bdf"
                          v-if="typeByRange == 1 && dataPeopleYate != null"
                        >{{dataReservationWalkIn}}</p>
                        <p
                          class="text tittle bold"
                          style="color:#000099"
                          v-if="typeByRange == 1 && dataPeopleYate != null"
                        >{{dataReservationYate}}</p>
                      </div>
                      <div class="col flex" v-if="typeByRange == 2 && dataPeopleYate != null">
                        <p
                          class="text tittle bold"
                          style="color:#a25bdf"
                          v-if="typeByRange == 2 && dataPeopleYate != null"
                        >{{dataPeopleWalkIn}}</p>
                        <p
                          class="text tittle bold"
                          style="color:#000099"
                          v-if="typeByRange == 2 && dataPeopleYate != null"
                        >{{dataPeopleYate}}</p>
                      </div>
                      <div class="col flex" v-if="typeByRange == 1 && dataPeopleYate != null">
                        <p
                          class="text black small-grafica"
                          style="color:#a25bdf"
                          v-if="typeByRange == 1 && dataPeopleYate != null"
                        >{{ ((dataReservationWalkIn*100) / (dataReservationD)).toFixed(decimals)}}%</p>
                        <p
                          class="text black small-grafica"
                          style="color:#000099"
                          v-if="typeByDay == 1 && dataPeopleYate != null"
                        >{{ ((dataReservationYate*100) / (dataReservationD)).toFixed(decimals)}}%</p>
                      </div>
                      <div class="col flex" v-if="typeByRange == 2 && dataPeopleYate != null">
                        <p
                          class="text black small-grafica"
                          style="color:#a25bdf"
                          v-if="typeByRange == 2 && dataPeopleYate != null"
                        >{{ ((dataPeopleWalkIn*100) / (dataPeopleD)).toFixed(decimals)}}%</p>
                        <p
                          class="text black small-grafica"
                          style="color:#000099"
                          v-if="typeByRange == 2 && dataPeopleYate != null"
                        >{{ ((dataPeopleYate*100) / (dataPeopleD)).toFixed(decimals)}}%</p>
                      </div>
                    </div>
                    <div class="flex radio-grafica width-height">
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="1"
                          name="reservasRange"
                          type="is-white"
                          v-model="typeByRange"
                        >
                          <p class="text radio">Reservas</p>
                        </b-radio>
                      </div>
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="2"
                          name="reservasRange"
                          type="is-white"
                          v-model="typeByRange"
                        >
                          <p class="text radio">Personas</p>
                        </b-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex metric-cont" v-if="sectionId === 3">
                <div class="header-index flex-1">
                  <p class="text bold padding-left">Tiempo de estadía por número de personas</p>
                </div>
                <div class="header-index flex-1">
                  <p class="text bold padding-left">Últimos Movimientos</p>
                </div>
              </div>
              <div class="flex metric-cont grafica" v-if="sectionId == 3">
                <div class="header-index grafica flex-1">
                  <div class="col-3 padding">
                    <span class="text-hours">Tiempo horas</span>
                    <span class="text-people">Personas</span>
                    <column-chart
                      :colors="['#9453CB']"
                      :data="timeStateN3"
                      :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                      class="graph"
                    ></column-chart>
                  </div>
                </div>
                <div class="header-index grafica flex-1" v-if="sectionId == 3">
                  <div class="col-3 padding">
                    <span class="text-left">Reservas</span>
                    <span class="text-right">Ultima Hora</span>
                    <div class="col-3">
                      <column-chart
                        :colors="['#9453CB']"
                        :data="byLastHourD"
                        :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                        class="graph"
                      ></column-chart>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex metric-cont">
                <div class="header-index flex-1" v-if="sectionId !== 3">
                  <p class="text bold padding-left">Cantidad reservas / por grupos de personas asdasdsa</p>
                </div>
                <div class="col" v-else></div>
                <div class="header-index flex-1" v-if="sectionId !== 3">
                  <p class="text bold padding-left">Efectivas</p>
                </div>
              </div>
              <div class="flex metric-cont grafica">
                <div class="header-index grafica flex-1" v-if="sectionId !== 3">
                  <div class="col-3">
                    <column-chart
                      :colors="getChartColors(byRangeD)"
                      :data="byRangeD"
                      :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                      class="graph"
                    ></column-chart>
                  </div>
                  <div class="col padding-top flex">
                    <div class="text small bold black">TOTAL</div>
                    <div class="flex width-height border-top-bot">
                      <div class="col flex margin-top">
                        <div :key="index" v-for=" (data,index) in byRange">
                          <img
                            :key="index"
                            :src="getUrlImageReferrer(data.name)"
                            style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                            v-if="getUrlImageReferrer(data.name) !== null"
                            v-tooltip="{content: (data.name === 'whitelabel') ? 'página web' : (data.name === 'dashboard') ? 'telefono' : data.name, placement: 'right'}"
                          />
                          <i
                            :class="iconReferrer(data.name)"
                            :key="data.name"
                            class="icon-mks gray-blue small-grafica"
                            v-else
                            v-tooltip="{content: (data.name === 'whitelabel') ? 'página web' : (data.name === 'dashboard') ? 'telefono' : data.name, placement: 'right'}"
                          ></i>
                        </div>
                      </div>
                      <div class="col flex" v-if="typeByRange == 1">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica"
                          v-for=" (data,index) in getTotalsByRange(byRange)"
                        >{{ data.reservations }}</p>
                      </div>
                      <div class="col flex" v-if="typeByRange == 2">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica"
                          v-for=" (data,index) in getTotalsByRange(byRange)"
                        >{{ data.people }}</p>
                      </div>
                      <div class="col flex" v-if="typeByRange == 1">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica"
                          v-for=" (data,index) in getTotalsByRange(byRange)"
                        >{{((data.reservations*100) / (getTotalsByRange(byRange, 'reservations'))).toFixed(decimals)}}%</p>
                      </div>
                      <div class="col flex" v-if="typeByRange == 2">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica"
                          v-for=" (data,index) in getTotalsByRange(byRange)"
                        >{{((data.people*100) / (getTotalsByRange(byRange, 'people'))).toFixed(decimals)}}%</p>
                      </div>
                    </div>
                    <div
                      class="text small bold black"
                      v-if="typeByRange == 1"
                    >Total: {{getTotalsByRange(byRange, 'reservations')}}</div>
                    <div
                      class="text small bold black"
                      v-if="typeByRange == 2"
                    >Total: {{getTotalsByRange(byRange, 'people')}}</div>
                    <div class="flex radio-grafica width-height">
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="1"
                          name="reservasRange"
                          type="is-white"
                          v-model="typeByRange"
                        >
                          <p class="text radio">Reservas</p>
                        </b-radio>
                      </div>
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="2"
                          name="reservasRange"
                          type="is-white"
                          v-model="typeByRange"
                        >
                          <p class="text radio">Personas</p>
                        </b-radio>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col" v-else></div>
                <div class="col" style="background:white;">
                  <div class="header-index grafica flex-1" v-if="sectionId !== 3">
                    <div
                      class="col-3"
                      v-if="(byAllReservationsD.rows && byAllReservationsD.rows.length > 0)"
                    >
                      <ve-ring
                        :colors="getChartColors(byAllReservationsD)"
                        :data="byAllReservationsD"
                        :settings="chartSettingsPie"
                        class="ringCheck"
                        height="300px"
                        width="380px"
                      ></ve-ring>
                    </div>
                    <div class="col-3 noresults" v-else>
                      <div class="wrapper__noresults">Sin Resultados</div>
                    </div>
                    <div class="col padding-top flex">
                      <div class="text small bold black">TOTAL</div>
                      <div class="flex width-height border-top-bot">
                        <div class="col flex margin-top">
                          <div :key="index" v-for=" (data,index) in byAllReservations">
                            <img
                              :key="index"
                              :src="getUrlImageReferrer(data.referrer)"
                              style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                              v-if="getUrlImageReferrer(data.referrer) !== null"
                              v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                            />
                            <i
                              :class="iconReferrer(data.referrer)"
                              :key="data.referrer"
                              class="icon-mks gray-blue small-grafica"
                              v-else
                              v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                            ></i>
                          </div>
                        </div>
                        <div class="col flex">
                          <p
                            :key="index"
                            :style="{ color: data.color}"
                            class="text black small-grafica reserva"
                            v-for=" (data,index) in byAllReservations"
                          >{{ data.value}}</p>
                        </div>
                        <div class="col flex">
                          <p
                            :key="index"
                            :style="{ color: data.color}"
                            class="text black small-grafica reserva"
                            v-for=" (data,index) in byAllReservations"
                          >{{((data.value*100) / getTotalsByType(byAllReservations,'value')).toFixed(decimals)}}%</p>
                        </div>
                      </div>
                      <div
                        class="text small bold black"
                      >Total: {{getTotalsByType(byAllReservations , 'value')}}</div>
                    </div>
                  </div>
                </div>
                <!-- <div class="header-index grafica flex-1">
                  <geo-chart :data="[['United States', 44], ['Germany', 23], ['Brazil', 22]]"></geo-chart>
                </div>-->
              </div>
              <div class="flex metric-cont" v-if="sectionId === 2">
                <div class="header-index flex-1" v-if="sectionId !== 3">
                  <p class="text bold padding-left">Promedio De Transacción</p>
                </div>
                <div class="header-index flex-1" v-if="sectionId !== 3">
                  <p class="text bold padding-left">Horas de mayor conversión</p>
                </div>
              </div>
              <div class="flex metric-cont" v-if="sectionId === 1">
                <div class="header-index flex-1">
                  <p class="text bold padding-left">Horas de mayor conversión</p>
                </div>
                <div class="header-index flex-1">
                  <p class="text bold padding-left">Tiempo de estadía por número de personas</p>
                </div>
              </div>
              <div class="flex metric-cont grafica" v-if="sectionId === 1">
                <div class="header-index grafica flex-1" style="max-width:900px !important">
                  <div class="col-3 padding">
                    <span class="text-hours" style="left:-66px !important">Cantidad de Reservas</span>
                    <span class="text-people" style="left:40% !important">Hora de creación</span>
                    <div class="col-3" v-if="(byHourD.rows && byHourD.rows.length > 0)">
                      <ve-line :colors="colors" :data="byHourD" :settings="chartSettings3"></ve-line>
                    </div>
                    <div class="col-3 noresults" v-else>
                      <div class="wrapper__noresults">Sin Resultados</div>
                    </div>
                  </div>
                </div>
                <div class="header-index grafica flex-1" v-if="sectionId == 1">
                  <div class="col-3 padding">
                    <span class="text-hours">Tiempo horas</span>
                    <span class="text-people">Personas</span>
                    <div class="col-3">
                      <column-chart
                        :colors="['#00ADC6']"
                        :data="timeStateN2"
                        :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                        class="graph"
                      ></column-chart>
                    </div>
                  </div>
                </div>
                <div class="col" v-else></div>
              </div>
              <div class="flex metric-cont" v-if="sectionId === 1">
                <div class="header-index flex-1">
                  <p class="text bold padding-left">Últimos Movimientos</p>
                </div>
                <div class="header-index flex-1">
                  <p class="text bold padding-left">Total reservas sentadas finalizadas</p>
                </div>
              </div>
              <div class="flex metric-cont grafica" v-if="sectionId === 1">
                <div class="header-index grafica flex-1" v-if="sectionId == 1">
                  <div class="col-3 padding">
                    <span class="text-left">Reservas</span>
                    <span class="text-right">Ultima Hora</span>
                    <div class="col-3">
                      <column-chart
                        :colors="['#00ADC6']"
                        :data="byLastHourD"
                        :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                        class="graph"
                      ></column-chart>
                    </div>
                  </div>
                </div>
                <div class="col" v-else></div>
                <div class="header-index grafica flex-1">
                  <div class="col-3 padding">
                    <span class="text-right">Total reservas sentadas</span>
                    <column-chart
                      :colors="['#00ADC6', '#4A4A4A']"
                      :data="byReservationS"
                      :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                      class="graph"
                    ></column-chart>
                  </div>
                </div>
              </div>
              <div class="flex metric-cont grafica" v-if="sectionId === 2">
                <div class="header-index grafica flex-1" v-if="sectionId === 2">
                  <div class="col-3" v-if="globalD != null && globalD.length > 0">
                    <column-chart
                      :colors="getChartColors(globalD)"
                      :data="globalD"
                      :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                      class="graph"
                    ></column-chart>
                  </div>
                  <div class="col-3 noresults" v-else>
                    <div class="wrapper__noresults">Sin Resultados</div>
                  </div>
                  <div
                    class="col padding-top flex mt-10"
                    v-if="globalD != null && globalD.length > 0"
                  >
                    <div class="flex radio-grafica-transacciones width-height">
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="1"
                          name="reservasRange"
                          type="is-white"
                          v-model="typeByTransaction"
                        >
                          <p class="text radio">Global</p>
                        </b-radio>
                      </div>
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="2"
                          name="reservasRange"
                          type="is-white"
                          v-model="typeByTransaction"
                        >
                          <p class="text radio">Pse</p>
                        </b-radio>
                      </div>
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="3"
                          name="reservasRange"
                          type="is-white"
                          v-model="typeByTransaction"
                        >
                          <p class="text radio">Tarjeta</p>
                        </b-radio>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="header-index grafica flex-1" style="max-width:900px !important">
                  <div class="col-3 padding">
                    <span class="text-hours" style="left:-66px !important">Cantidad de Reservas</span>
                    <span class="text-people" style="left:40% !important">Hora de creación</span>
                    <div class="col-3" v-if="(byHourD.rows && byHourD.rows.length > 0)">
                      <ve-line :colors="['#CAD300']" :data="byHourD" :settings="chartSettings3"></ve-line>
                    </div>
                    <div class="col-3 noresults" v-else>
                      <div class="wrapper__noresults">Sin Resultados</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex metric-cont">
                <div class="header-index flex-1" v-if="validateExperiencieTypeGraph()">
                  <p class="text bold padding-left">Experiencias Más Populares</p>
                </div>
                <div class="header-index flex-1 mw-50">
                  <p class="text bold padding-left test-class">Cantidad de {{ getTypeText() }}</p>
                </div>
              </div>
              <div class="flex metric-cont grafica">
                <div class="header-index grafica flex-1" v-if="validateExperiencieTypeGraph()">
                  <div class="col-3" v-if="(byExperienceD.rows && byExperienceD.rows.length > 0)">
                    <ve-ring
                      :colors="getChartColors(byExperienceD)"
                      :data="byExperienceD"
                      :settings="chartSettings4"
                      class="ringCheck"
                      height="300px"
                      style="height: 19rem!important"
                      width="380px"
                    ></ve-ring>
                  </div>
                  <div class="col-3 noresults" v-else>
                    <div class="wrapper__noresults">Sin Resultados</div>
                  </div>
                  <div class="col padding-top flex">
                    <div class="text small bold black">TOTAL</div>
                    <div class="flex width-height border-top-bot">
                      <div class="col flex margin-top">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva text-small"
                          v-for=" (data,index) in byExperienceD.rows"
                        >{{ data.name}}</p>
                      </div>
                      <div class="col flex" v-if="typeExperience == 1">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in experiencesTotal"
                        >{{ data.value}}</p>
                      </div>
                      <div class="col flex" v-if="typeExperience == 1">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in experiencesTotal"
                        >{{ ((data.value*100) / getTotalsByType(experiencesTotal,'value')).toFixed(decimals)}}%</p>
                      </div>
                      <div class="col flex" v-if="typeExperience == 2">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in experiencesEffective"
                        >{{ data.value}}</p>
                      </div>
                      <div class="col flex" v-if="typeExperience == 2">
                        <p
                          :key="index"
                          :style="{ color: data.color}"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in experiencesEffective"
                        >{{ ((data.value*100) / getTotalsByType(experiencesEffective,'value')).toFixed(decimals)}}%</p>
                      </div>
                    </div>
                    <div
                      class="text small bold black"
                      v-if="typeExperience == 1"
                    >Total: {{getTotalsByType(experiencesTotal , 'value')}}</div>
                    <div
                      class="text small bold black"
                      v-if="typeExperience == 2"
                    >Total: {{getTotalsByType(experiencesEffective , 'value')}}</div>
                    <div class="flex radio-grafica width-height">
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="1"
                          name="reservasEffective"
                          type="is-white"
                          v-model="typeExperience"
                        >
                          <p class="text radio">Totales</p>
                        </b-radio>
                      </div>
                      <div class="flex radio-center">
                        <b-radio
                          :native-value="2"
                          name="reservasEffective"
                          type="is-white"
                          v-model="typeExperience"
                        >
                          <p class="text radio">Efectivas</p>
                        </b-radio>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Grafica de cantidad de reservas -->
                <div class="header-index grafica flex-1 mw-50">
                  <div class="col-3" v-if="(byGuestD.rows && byGuestD.rows.length > 0)">
                    <ve-ring
                      :colors="['#446096', '#fc8452']"
                      :data="byGuestD"
                      :settings="chartSettings"
                      class="ringCheck"
                      height="300px"
                      width="380px"
                    ></ve-ring>
                  </div>
                  <div class="col-3 noresults" v-else>
                    <div class="wrapper__noresults">Sin Resultados</div>
                  </div>
                  <div class="col padding-small flex">
                    <div class="text small bold black">TOTAL</div>
                    <div class="w-100 border-top-grey">
                      <p class="margin-top flex align-center justify-between" v-for="(data, index) in byGuestD.rows" :key="index">
                        <span class="text black small-grafica text-small">{{ data.name }}</span>
                        <span class="text black small-grafica">{{ ((data.value*100) / byGuestD.rows.reduce((sum, objeto) => sum + objeto.value, 0)).toFixed(2) }}%</span>
                      </p>
                    </div>
                    <div class="total-grey">
                      <p>Total {{ getTypeText() }}</p>
                      <p>{{ byGuestD.rows.reduce((sum, objeto) => sum + objeto.value, 0) }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex metric-cont">
                <div class="header-index flex-1" v-if="validateClientTypeGraph()">
                  <p class="text bold padding-left">Reservas con tipo de cliente</p>
                </div>
                <div class="header-index flex-1" v-if="params.activeHowMeetUs == '1'">
                  <p
                    class="text bold padding-left"
                  >{{(params.descriptionHowMeetUs != '') ? params.descriptionHowMeetUs : '¿Como nos conociste?'}}</p>
                </div>
              </div>
              <div class="flex metric-cont grafica">
                <div class="header-index grafica flex-1" v-if="validateClientTypeGraph()">
                  <div class="col-3" v-if="byClientTypeD && byClientTypeD.length > 0">
                    <column-chart
                      :data="byClientTypeD"
                      :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                      class="graph"
                    ></column-chart>
                  </div>
                  <div class="col-3 noresults" v-else>
                    <div class="wrapper__noresults">Sin Resultados</div>
                  </div>
                  <div class="col padding-top flex">
                    <div class="text small bold black">Totales</div>
                    <div class="flex width-height border-top-bot">
                      <div class="col flex">
                        <p
                          :key="index"
                          :style="'color:black'"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byClientTypeD"
                        >{{ data.name}}</p>
                      </div>
                      <div class="col flex">
                        <p
                          :key="index"
                          :style="'color:black'"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byClientTypeD"
                        >{{getTotalClientType(byClientTypeD, data.name)}}</p>
                      </div>
                      <div class="col flex">
                        <p
                          :key="index"
                          :style="'color:black'"
                          class="text black small-grafica reserva"
                          v-for=" (data,index) in byClientTypeD"
                        >{{((getTotalClientType(byClientTypeD, data.name)*100) / getTotalClientType(byClientTypeD)).toFixed(decimals)}}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col" v-if="params.activeHowMeetUs == '1'">
                  <div class="header-index grafica flex-1">
                    <div class="col-3" v-if="byMediaTypeD && byMediaTypeD.length > 0">
                      <column-chart
                        :data="byMediaTypeD"
                        :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                        class="graph"
                      ></column-chart>
                    </div>
                    <div class="col-3 noresults" v-else>
                      <div class="wrapper__noresults">Sin Resultados</div>
                    </div>
                    <div class="col padding-top flex">
                      <div class="text small bold black">TOTAL</div>
                      <div class="flex width-height border-top-bot">
                        <div class="col flex margin-top">
                          <p
                            :key="index"
                            class="text black small-grafica"
                            v-for=" (data,index) in byMediaType"
                            v-tooltip="{content: data.name }"
                          >{{data.name}}</p>
                        </div>
                        <div class="col flex">
                          <p
                            :key="index"
                            class="text black small-grafica"
                            v-for=" (data,index) in getTotalsMediaType(byMediaType, null, null)"
                          >{{ data.totalRes }}</p>
                        </div>
                        <!-- <div class="col flex">
                        <p
                          :key="index"
                          class="text black small-grafica"
                          v-for=" (data,index) in getTotalsMediaType(byMediaType, null, null)"
                        >{{ data.totalPeople }}</p>
                        </div>-->
                      </div>
                      <div
                        class="text small bold black"
                      >Total : {{getTotalsMediaType(byMediaType, 1, "reservations")}}</div>
                      <!-- <div
                  class="text small bold black"
                      >Total People: {{getTotalsMediaType(byMediaType, 1, "people")}}</div>-->
                    </div>
                  </div>
                </div>
              </div>
              <TablesChart
                :sectionId="sectionId"
                v-if="(metricReservations.length > 0 && sectionId !== 4)"
              ></TablesChart>

              <MapChart :sectionId="sectionId" v-if="(byCountry.length > 0 && sectionId !== 4)"></MapChart>
            </div>
            <div v-else>
              <v-alert :value="true" type="warning">
                <b>No existe datos asociados a {{ (sectionId === 1) ? 'Reservas' : (sectionId === 2) ? 'Precompras':'Walk-In' }}</b>
              </v-alert>
            </div>
          </div>
        </div>
      </div>
      <div class="flex" v-else>
        <SumaryGeneral
          :dataReservation="dataReservation"
          :sectionId="sectionId"
          v-if=" dateFrom == null || dateTo == null "
        ></SumaryGeneral>
        <SumaryGeneral :dataReservation="dataReservationRange" :sectionId="sectionId" v-else></SumaryGeneral>
        <div class="rigth rigth-overflow-mobile">
          <div class="v-line">
            <LineTimeGeneral :data="dataReservationRange.byMonth"></LineTimeGeneral>
          </div>
          <div class="flex metric-cont">
            <div class="header-index flex-1">
              <p class="text bold padding-left">Cantidad por día</p>
            </div>
            <div class="header-index flex-1">
              <p class="text bold padding-left">No efectivas</p>
            </div>
          </div>
          <div class="flex metric-cont grafica">
            <div class="header-index grafica flex-1">
              <div class="col-3">
                <column-chart
                  :colors="colors2"
                  :data="byDayD"
                  :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                  class="graph"
                ></column-chart>
              </div>
              <div class="col padding-top flex">
                <div class="text small bold black">TOTAL asdasds</div>
                <div class="flex width-height border-top-bot">
                  {{dataReservation.byDay}}
                  <div class="col flex margin-top">
                    <div :key="index" v-for=" (data,index) in dataReservation.byDay">
                      <img
                        :key="index"
                        :src="getUrlImageReferrer(data.name)"
                        style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                        v-if="getUrlImageReferrer(data.name) !== null"
                        v-tooltip="{content: (data.name === 'whitelabel') ? 'página web' : (data.name === 'dashboard') ? 'telefono' : data.name, placement: 'right'}"
                      />
                      <i
                        :class="iconReferrer(data.name)"
                        :key="data.name"
                        class="icon-mks gray-blue small-grafica"
                        v-else
                        v-tooltip="{content: (data.name === 'whitelabel') ? 'página web' : (data.name === 'dashboard') ? 'telefono' : data.name, placement: 'right'}"
                      ></i>
                    </div>
                  </div>
                  <div class="col flex" v-if="typeByDay == 1">
                    <p
                      class="text black small-grafica reserva"
                    >{{ byAllDataD.resevationReservations}}</p>
                    <p class="text black small-grafica walkin">{{ byAllDataD.walkinReservations}}</p>
                    <p
                      class="text black small-grafica precompra"
                    >{{ byAllDataD.prePurchaseReservations}}</p>
                  </div>
                  <div class="col flex" v-if="typeByDay == 2">
                    <p class="text black small-grafica reserva">{{ byAllDataD.resevationPeople}}</p>
                    <p class="text black small-grafica walkin">{{ byAllDataD.walkinPeople}}</p>
                    <p class="text black small-grafica precompra">{{ byAllDataD.prePurchasePeople}}</p>
                  </div>
                  <div class="col flex" v-if="typeByDay == 1">
                    <p
                      class="text black small-grafica reserva"
                    >{{ ((byAllDataD.resevationReservations*100) / (byAllDataD.reservas)).toFixed(decimals)}}%</p>
                    <p
                      class="text black small-grafica walkin"
                    >{{ ((byAllDataD.walkinReservations*100) / (byAllDataD.reservas)).toFixed(decimals)}}%</p>
                    <p
                      class="text black small-grafica precompra"
                    >{{ ((byAllDataD.prePurchaseReservations*100) / (byAllDataD.reservas)).toFixed(decimals)}}%</p>
                  </div>
                  <div class="col flex" v-if="typeByDay == 2">
                    <p
                      class="text black small-grafica reserva"
                    >{{((byAllDataD.resevationPeople*100) / (byAllDataD.people)).toFixed(decimals)}}%</p>
                    <p
                      class="text black small-grafica walkin"
                    >{{((byAllDataD.walkinPeople*100) / (byAllDataD.people)).toFixed(decimals)}}%</p>
                    <p
                      class="text black small-grafica precompra"
                    >{{((byAllDataD.prePurchasePeople*100) / (byAllDataD.people)).toFixed(decimals)}}%</p>
                  </div>
                </div>
                <div
                  class="text small bold black"
                  v-if="(typeByDay == 1 &&sectionId == 4)"
                >Total: {{getTotalsGenerals(byAllDataD,typeByDay)}}</div>
                <div
                  class="text small bold black"
                  v-if="(typeByDay == 2 &&sectionId == 4)"
                >Total: {{getTotalsGenerals(byAllDataD,typeByDay)}}</div>
                <div class="flex radio-grafica width-height">
                  <div class="flex radio-center">
                    <b-radio :native-value="1" name="reservas" type="is-white" v-model="typeByDay">
                      <p class="text radio">Reservas</p>
                    </b-radio>
                  </div>
                  <div class="flex radio-center">
                    <b-radio :native-value="2" name="reservas" type="is-white" v-model="typeByDay">
                      <p class="text radio">Personas</p>
                    </b-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-index grafica flex-1">
              <div class="col-3" v-if="(notEffectiveD.rows && notEffectiveD.rows.length > 0)">
                <ve-ring
                  :colors="getChartColors(notEffectiveD)"
                  :data="notEffectiveD"
                  :settings="chartSettings"
                  :extend="chartExtend"
                  class="ringCheck"
                  height="300px"
                  width="380px"
                ></ve-ring>
              </div>
              <div class="col-3 noresults" v-else>
                <div class="wrapper__noresults">Sin Resultados</div>
              </div>
              <div class="col padding-top flex">
                <div class="text small bold black">TOTAL</div>
                <div class="flex width-height border-top-bot">
                  <div class="col flex margin-top" v-if="typeNotEffective == 1">
                    <div :key="index" v-for=" (data,index) in byIsCancelledD">
                      <img
                        :key="index"
                        :src="getUrlImageReferrer(data.referrer)"
                        style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                        v-if="getUrlImageReferrer(data.referrer) !== null"
                        v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                      />
                      <i
                        :class="iconReferrer(data.referrer)"
                        :key="data.referrer"
                        class="icon-mks gray-blue small-grafica"
                        v-else
                        v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                      ></i>
                    </div>
                  </div>
                  <div class="col flex margin-top" v-if="typeNotEffective == 2">
                    <div :key="index" v-for=" (data,index) in byNotShowD">
                      <img
                        :key="index"
                        :src="getUrlImageReferrer(data.referrer)"
                        style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                        v-if="getUrlImageReferrer(data.referrer) !== null"
                        v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                      />
                      <i
                        :class="iconReferrer(data.referrer)"
                        :key="data.referrer"
                        class="icon-mks gray-blue small-grafica"
                        v-else
                        v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                      ></i>
                    </div>
                  </div>
                  <div class="col flex" v-if="typeNotEffective == 1">
                    <p
                      :key="index"
                      :style="{ color: data.color}"
                      class="text black small-grafica reserva"
                      v-for=" (data,index) in byIsCancelledD"
                    >{{ data.reservations}}</p>
                  </div>
                  <div class="col flex" v-if="typeNotEffective == 1">
                    <p
                      :key="index"
                      :style="{ color: data.color}"
                      class="text black small-grafica reserva"
                      v-for=" (data,index) in byIsCancelledD"
                    >{{ data.people}}</p>
                  </div>
                  <div class="col flex" v-if="typeNotEffective == 1">
                    <p
                      :key="index"
                      :style="{ color: data.color}"
                      class="text black small-grafica reserva"
                      v-for=" (data,index) in byIsCancelledD"
                    >{{ ((data.reservations*100) / getTotalsByType(byIsCancelledD,'reservations')).toFixed(decimals)}}%</p>
                  </div>
                  <div class="col flex" v-if="typeNotEffective == 2">
                    <p
                      :key="index"
                      :style="{ color: data.color}"
                      class="text black small-grafica reserva"
                      v-for=" (data,index) in byNotShowD"
                    >{{ data.reservations}}</p>
                  </div>
                  <div class="col flex" v-if="typeNotEffective == 2">
                    <p
                      :key="index"
                      :style="{ color: data.color}"
                      class="text black small-grafica reserva"
                      v-for=" (data,index) in byNotShowD"
                    >{{ data.people}}</p>
                  </div>
                  <div class="col flex" v-if="typeNotEffective == 2">
                    <p
                      :key="index"
                      :style="{ color: data.color5}"
                      class="text black small-grafica reserva"
                      v-for=" (data,index) in byNotShowD"
                    >{{ ((data.reservations*100) / getTotalsByType(byNotShowD,'reservations')).toFixed(decimals)}}%</p>
                  </div>
                </div>
                <div
                  class="text small bold black"
                  v-if="(typeNotEffective == 1 &&sectionId == 4)"
                >Total: {{getTotalsByType(byIsCancelledD,'reservations')}}</div>
                <div
                  class="text small bold black"
                  v-if="(typeNotEffective == 2 &&sectionId == 4)"
                >Total: {{getTotalsByType(byNotShowD,'reservations')}}</div>
                <div class="flex radio-grafica width-height">
                  <div class="flex radio-center">
                    <b-radio
                      :native-value="1"
                      name="reservasEffective"
                      type="is-white"
                      v-model="typeNotEffective"
                    >
                      <p class="text radio">Canceladas</p>
                    </b-radio>
                  </div>
                  <div class="flex radio-center">
                    <b-radio
                      :native-value="2"
                      name="reservasEffective"
                      type="is-white"
                      v-model="typeNotEffective"
                    >
                      <p class="text radio">No-llegaron</p>
                    </b-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex metric-cont">
            <div class="header-index flex-1">
              <p class="text bold padding-left">Cantidad reservas / por grupos de personas</p>
            </div>
            <div class="header-index flex-1" v-if="sectionId == 4">
              <p class="text bold padding-left">Tiempo de estadía por número de personas</p>
            </div>
            <div class="col" v-else></div>
          </div>
          <div class="flex metric-cont grafica">
            <div class="header-index grafica flex-1">
              <div class="col-3">
                <column-chart
                  :colors="colors2"
                  :data="byRangeD"
                  :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                  class="graph"
                ></column-chart>
              </div>
              <div class="col padding-top flex">
                <div class="text small bold black">TOTAL</div>
                <div class="flex width-height border-top-bot">
                  <div class="col flex margin-top">
                    <div :key="index"  v-for=" (data,index) in dataReservation.byDay">
                      <img
                        :key="index"
                        :src="getUrlImageReferrer(data.name)"
                        style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                        v-if="getUrlImageReferrer(data.name) !== null"
                        v-tooltip="{content: (data.name === 'whitelabel') ? 'página web' : (data.name === 'dashboard') ? 'telefono' : data.name, placement: 'right'}"
                      />
                      <i
                        :class="iconReferrer(data.name)"
                        :key="data.name"
                        class="icon-mks gray-blue small-grafica"
                        v-else
                        v-tooltip="{content: (data.name === 'whitelabel') ? 'página web' : (data.name === 'dashboard') ? 'telefono' : data.name, placement: 'right'}"
                      ></i>
                    </div>
                  </div>
                  <div class="col flex" v-if="typeByRange == 1">
                    <p
                      class="text black small-grafica reserva"
                    >{{ byAllDataD.resevationReservations}}</p>
                    <p class="text black small-grafica walkin">{{ byAllDataD.walkinReservations}}</p>
                    <p
                      class="text black small-grafica precompra"
                    >{{ byAllDataD.prePurchaseReservations}}</p>
                  </div>
                  <div class="col flex" v-if="typeByRange == 2">
                    <p class="text black small-grafica reserva">{{ byAllDataD.resevationPeople}}</p>
                    <p class="text black small-grafica walkin">{{ byAllDataD.walkinPeople}}</p>
                    <p class="text black small-grafica precompra">{{ byAllDataD.prePurchasePeople}}</p>
                  </div>
                  <div class="col flex" v-if="typeByDay == 1">
                    <p
                      class="text black small-grafica reserva"
                    >{{ ((byAllDataD.resevationReservations*100) / (byAllDataD.reservas)).toFixed(decimals)}}%</p>
                    <p
                      class="text black small-grafica walkin"
                    >{{ ((byAllDataD.walkinReservations*100) / (byAllDataD.reservas)).toFixed(decimals)}}%</p>
                    <p
                      class="text black small-grafica precompra"
                    >{{ ((byAllDataD.prePurchaseReservations*100) / (byAllDataD.reservas)).toFixed(decimals)}}%</p>
                  </div>
                  <div class="col flex" v-if="typeByDay == 2">
                    <p
                      class="text black small-grafica reserva"
                    >{{ ((byAllDataD.resevationPeople*100) / (byAllDataD.people)).toFixed(decimals)}}%</p>
                    <p
                      class="text black small-grafica walkin"
                    >{{ ((byAllDataD.walkinPeople*100) / (byAllDataD.people)).toFixed(decimals)}}%</p>
                    <p
                      class="text black small-grafica precompra"
                    >{{ ((byAllDataD.prePurchasePeople*100) / (byAllDataD.people)).toFixed(decimals)}}%</p>
                  </div>
                </div>
                <div
                  class="text small bold black"
                  v-if="(typeByRange == 1 &&sectionId == 4)"
                >Total: {{getTotalsGenerals(byAllDataD,typeByRange)}}</div>
                <div
                  class="text small bold black"
                  v-if="(typeByRange == 2 &&sectionId == 4)"
                >Total: {{getTotalsGenerals(byAllDataD,typeByRange)}}</div>
                <div class="flex radio-grafica width-height">
                  <div class="flex radio-center">
                    <b-radio
                      :native-value="1"
                      name="reservasRange"
                      type="is-white"
                      v-model="typeByRange"
                    >
                      <p class="text radio">Reservas</p>
                    </b-radio>
                  </div>
                  <div class="flex radio-center">
                    <b-radio
                      :native-value="2"
                      name="reservasRange"
                      type="is-white"
                      v-model="typeByRange"
                    >
                      <p class="text radio">Personas</p>
                    </b-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-index grafica flex-1" v-if="sectionId == 4">
              <div class="col-3 padding">
                <span class="text-hours">Tiempo horas</span>
                <span class="text-people">Personas</span>
                <column-chart
                  :colors="colors2"
                  :data="timeStateN"
                  :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                  class="graph"
                ></column-chart>
              </div>
            </div>
            <div class="col" v-else></div>
          </div>

          <!-- Grafica de cantidad de reservas -->
          <div class="flex metric-cont">
            <div class="header-index flex-1">
              <p class="text bold padding-left">Cantidad de {{ getTypeText() }}</p>
            </div>
            <div class="header-index flex-1 mw-50" style="background-color: transparent;">
              <p class="text bold padding-left test-class"></p>
            </div>
          </div>
          <div class="flex metric-cont grafica">
            <div class="header-index grafica flex-1 mw-50">
              <div class="col-3" v-if="(byGuestD.rows && byGuestD.rows.length > 0)">
                <ve-ring
                  :colors="['#446096', '#fc8452']"
                  :data="byGuestD"
                  :settings="chartSettings"
                  class="ringCheck"
                  height="300px"
                  width="380px"
                ></ve-ring>
              </div>
              <div class="col-3 noresults" v-else>
                <div class="wrapper__noresults">Sin Resultados</div>
              </div>
              <div class="col padding-small flex">
                <div class="text small bold black">TOTAL</div>
                <div class="w-100 border-top-grey">
                  <p class="margin-top flex align-center justify-between" v-for="(data, index) in byGuestD.rows" :key="index">
                    <span class="text black small-grafica text-small">{{ data.name }}</span>
                    <span class="text black small-grafica">{{ ((data.value*100) / byGuestD.rows.reduce((sum, objeto) => sum + objeto.value, 0)).toFixed(2) }}%</span>
                  </p>
                </div>
                <div class="total-grey">
                  <p>Total</p>
                  <p>{{ byGuestD.rows.reduce((sum, objeto) => sum + objeto.value, 0) }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex metric-cont" v-if="sectionId === 4">
            <div class="header-index flex-1">
              <p class="text bold padding-left">Horas de mayor conversión</p>
            </div>
            <div class="header-index flex-1" v-if="params.activeSelectorClient == 1">
              <p class="text bold padding-left">Reservas con tipo de cliente</p>
            </div>
            <div class="header-index flex-1" v-if="params.activeHowMeetUs == 1">
              <p
                class="text bold padding-left"
              >{{(params.descriptionHowMeetUs != '') ? params.descriptionHowMeetUs : '¿Como nos conociste?'}}</p>
            </div>
          </div>
          <div class="flex metric-cont grafica" v-if="sectionId === 4">
            <div class="header-index grafica flex-1" style="min-width: 545px !important">
              <div class="col-3 padding">
                <span class="text-hours" style="left:-66px !important">Cantidad de Reservas</span>
                <span class="text-people" style="left:40% !important">Hora de creación</span>
                <div class="col-3" v-if="(byHourDGeneral.rows && byHourDGeneral.rows.length > 0)">
                  <ve-line
                    :colors="['#00ADC6','#CAD300']"
                    :data="byHourDGeneral"
                    :settings="chartSettings3"
                  ></ve-line>
                </div>
                <div class="col-3 noresults" v-else>
                  <div class="wrapper__noresults">Sin Resultados</div>
                </div>
              </div>
            </div>
            <div class="header-index grafica flex-1" v-if="params.activeSelectorClient == 1">
              <div class="col-3">
                <column-chart
                  :colors="colors2"
                  :data="byClientTypeDGeneral"
                  :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                  class="graph"
                ></column-chart>
              </div>
            </div>
            <div class="col" v-if="params.activeHowMeetUs == 1">
              <div class="header-index grafica flex-1" v-if="params.activeHowMeetUs == 1">
                <div class="col-3">
                  <column-chart
                    :colors="colors2"
                    :data="byMediaTypeDGeneral"
                    :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                    class="graph"
                  ></column-chart>
                </div>
              </div>
            </div>
          </div>
          <MapChartGeneral
            :sectionId="sectionId"
            v-if="(((Array.isArray(this.byCountryReservations) && this.byCountryReservations.length > 0) && this.sectionId === 4))"
          ></MapChartGeneral>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Sumary from '@/components/Metrics/Sumary';
import TablesChart from '@/components/Metrics/TablesChart';
import MapChart from '@/components/Metrics/MapChart';
import MapChartGeneral from '@/components/Metrics/MapChartGenerals';
import SumaryGeneral from '@/components/Metrics/SumaryGeneral';
import metricsTypes from '@/store/types/metrics';
import LineTimeGeneral from '@/components/Metrics/LineTimeGeneral';
import globalTypes from '@/store/types/global';
import _ from 'lodash';

export default {
  name: 'MetricsWrapper',
  props: ['sectionId'],
  components: {
    Sumary,
    SumaryGeneral,
    LineTimeGeneral,
    TablesChart,
    MapChart,
    MapChartGeneral
  },
  computed: {
    ...mapGetters({
      dataReservation: [metricsTypes.getters.dataReservation],
      dataReservationRange: [metricsTypes.getters.dataReservationRange],
      byMonth: [metricsTypes.getters.byMonth],
      byHour: [metricsTypes.getters.byHour],
      byCountry: [metricsTypes.getters.byCountry],
      byCountryPrecompras: [metricsTypes.getters.byCountryPrecompras],
      byCountryReservations: [metricsTypes.getters.byCountryReservations],
      byCountryWalkIn: [metricsTypes.getters.byCountryWalkIn],
      byHourLastHour: [metricsTypes.getters.byHourLastHour],
      byClientType: [metricsTypes.getters.byClientType],
      byExperience: [metricsTypes.getters.byExperience],
      byExperienceEffective: [metricsTypes.getters.byExperienceEffective],
      byMediaType: [metricsTypes.getters.byMediaType],
      byDay: [metricsTypes.getters.byDay],
      byRange: [metricsTypes.getters.byRange],
      dateFrom: [metricsTypes.getters.dateFrom],
      dateTo: [metricsTypes.getters.dateTo],
      year: [metricsTypes.getters.year],
      notEffective: [metricsTypes.getters.notEffective],
      timeState: [metricsTypes.getters.timeState],
      global: [metricsTypes.getters.global],
      pse: [metricsTypes.getters.pse],
      card: [metricsTypes.getters.card],
      metricReservations: [metricsTypes.getters.metricReservations],
      byGuest: [metricsTypes.getters.byGuest]
    })
  },
  watch: {
    dataReservationRange () {
      const context = this;
      if (this.dataReservationRange) {
        if (this.byDay) {
          if (this.sectionId === 3) {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataReservationYate) {
                this.byDayReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataPeopleYate) {
                this.byDayPeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.byRange) {
          if (this.sectionId === 3) {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataReservationYate) {
                this.byRangeReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataPeopleYate) {
                this.byRangePeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        setTimeout(() => {
          if (this.validateExperiencieTypeGraph()) {
            this.experiencesTotal = this.dataReservation.byExperience.map(function (response) {
              const aux = {};
              aux.name = response.name;
              aux.value = response.countExperience;
              aux.color = context.getColorByName(aux.name);
              return aux;
            });
            this.experiencesEffective = this.dataReservation.byExperienceEffective.map(function (response) {
              const aux = {};
              aux.name = response.name;
              aux.value = response.countExperience;
              aux.color = context.getColorByName(aux.name);
              return aux;
            });
            this.byExperienceD.rows = this.experiencesTotal;
          }
        }, 200);
        if (this.notEffective) {
          if (this.sectionId !== 3) {
            if (this.dataReservationRange.notEffective[0].length > 0) {
              this.isCancelled = this.dataReservationRange.notEffective[0].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.isCancelled = null;
            }
            if (this.dataReservationRange.notEffective[1].length > 0) {
              this.notShow = this.dataReservationRange.notEffective[1].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.notShow = [];
            }
            this.notEffectiveD.rows = this.isCancelled;
            if (this.dataReservationRange.notEffective[0] && this.dataReservationRange.notEffective[1]) {
              this.byIsCancelledD = this.dataReservationRange.notEffective[0].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
              this.byNotShowD = this.dataReservationRange.notEffective[1].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
            }
          }
        }
        this.byReferrerWithColors = (this.dataReservation.byReferrer !== null) ? this.dataReservation.byReferrer.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          return {
            ...data,
            name: name,
            color: context.getColorByName(name)
          };
        }) : null;
        this.setDataClientType();
        this.setDataMediaType();
        this.byAllReservations = (this.dataReservation.byReferrer !== null) ? this.byReferrerWithColors.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          const reservations = data.reservations;
          return {
            ...data,
            value: reservations,
            name: name,
            color: context.getColorByName(name)
          };
        }) : [];
        if (this.sectionId === 2) {
          if (this.global != null) {
            this.globalD = context.getNewArrayTransactions(this.global);
          }
        }
        // Se asigna los valores a los objectos de las gráficas
        this.byAllReservationsD.rows = this.byAllReservations;
        // Se asigna los valores a los objectos de las gráficas
        this.byClientTypeD = this.byDayReservationsClient;
        this.byMediaTypeD = this.byDayReservationsMedia;
        this.byMonthD.rows = this.byMonth;
        this.byGuestD.rows = this.byGuest;
        this.byReferrerD = this.byReferrerWithColors;
        this.byDayD = this.byDayReservations;
        this.byRangeD = this.byRangeReservations;
        this.dataReservationD = this.dataReservationRange.reservas;
        this.getDataHours();
        this.getDataHoursLastHour();
        if (this.sectionId === 3) {
          this.dataReservationWalkIn = this.dataReservationRange.walkInReservations;
          this.dataPeopleWalkIn = this.dataReservationRange.walkInPeople;
          const yate = this.byDayD.find(d => d.name === 'yate');
          if (typeof yate !== 'undefined') {
            this.dataReservationYate = this.dataReservationRange.yateRes;
            this.dataPeopleYate = this.dataReservationRange.yatePeople;
          }
        }
        if (this.sectionId !== 2) {
          this.getReservationsCompleted(this.dateFrom, this.dateTo);
          this.getTimeReservations(null, null);
        }
        this.dataPeopleD = this.dataReservationRange.people;
        this.byAllDataD = this.dataReservationRange;
      }
    },
    year () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 5 });
      const context = this;
      if (this.dateFrom != null && this.dateTo != null) {
        if (this.dateFrom <= this.dateTo) {
          this.$store.dispatch({
            type: 'metrics:getMetricReservations',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          });
          this.$store.dispatch({
            type: 'metrics:getDataReservationRange',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          })
            .then(res => {
              this.getReservationsCompleted(this.dateFrom, this.dateTo);
              this.getTimeReservations(this.dateFrom, this.dateTo);
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            })
            .catch(e => {
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
              this.$buefy.notification.open({
                message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
                type: 'is-danger'
              });
            });
        } else {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> La Fecha de inicio no debe ser menor a la fecha tope de busquedada',
            type: 'is-danger'
          });
        }
      } else if (this.dateFrom == null && this.dateTo == null) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 5 });
        this.getReservationsCompleted(null, null);
        this.getTimeReservations(null, null);
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        });
        this.$store
          .dispatch({
            type: 'metrics:getDataReservation',
            data: { vendorId: this.$store.state.idVendor, type: this.sectionId, year: this.year }
          }).then(res => {
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          });
        if (this.byDay) {
          if (this.sectionId === 3) {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataReservationYate) {
                this.byDayReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataPeopleYate) {
                this.byDayPeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.byRange) {
          if (this.sectionId === 3) {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataReservationYate) {
                this.byRangeReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataPeopleYate) {
                this.byRangePeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        setTimeout(() => {
          if (this.validateExperiencieTypeGraph()) {
            this.experiencesTotal = this.dataReservation.byExperience.map(function (response) {
              const aux = {};
              aux.name = response.name;
              aux.value = response.countExperience;
              aux.color = context.getColorByName(aux.name);
              return aux;
            });
            this.experiencesEffective = this.dataReservation.byExperienceEffective.map(function (response) {
              const aux = {};
              aux.name = response.name;
              aux.value = response.countExperience;
              aux.color = context.getColorByName(aux.name);
              return aux;
            });
            this.byExperienceD.rows = this.experiencesTotal;
          }
        }, 200);
        if (this.notEffective) {
          if (this.sectionId !== 3) {
            if (this.dataReservation.notEffective[0].length > 0) {
              this.isCancelled = this.dataReservation.notEffective[0].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.isCancelled = null;
            }
            if (this.dataReservation.notEffective[1].length > 0) {
              this.notShow = this.dataReservation.notEffective[1].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.notShow = [];
            }
            this.notEffectiveD.rows = this.isCancelled;
            if (this.dataReservation.notEffective[0] && this.dataReservation.notEffective[1]) {
              this.byIsCancelledD = this.dataReservation.notEffective[0].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
              this.byNotShowD = this.dataReservation.notEffective[1].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
            }
          }
        }
        this.byReferrerWithColors = this.dataReservation.byReferrer.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          return {
            ...data,
            name: name,
            color: context.getColorByName(name)
          };
        });
        this.setDataClientType();
        this.setDataMediaType();
        this.byAllReservations = this.byReferrerWithColors.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          const reservations = data.reservations;
          return {
            ...data,
            value: reservations,
            name: name,
            color: context.getColorByName(name)
          };
        });
        if (this.sectionId === 2) {
          if (this.global != null) {
            this.globalD = context.getNewArrayTransactions(this.global);
          }
        }
        // Se asigna los valores a los objectos de las gráficas
        this.byClientTypeD = this.byDayReservationsClient;
        this.byMediaTypeD = this.byDayReservationsMedia;
        this.byAllReservationsD.rows = this.byAllReservations || [];
        this.byMonthD.rows = this.byMonth;
        this.byGuestD.rows = this.byGuest;
        this.byReferrerD = this.byReferrerWithColors;
        this.byDayD = this.byDayReservations;
        this.byRangeD = this.byRangeReservations;
        this.dataReservationD = this.dataReservation.reservas;
        if (this.sectionId === 3) {
          this.dataReservationWalkIn = this.dataReservationRange.walkInReservations;
          this.dataPeopleWalkIn = this.dataReservationRange.walkInPeople;
          const yate = this.byDayD.find(d => d.name === 'yate');
          if (typeof yate !== 'undefined') {
            this.dataReservationYate = this.dataReservationRange.yateRes;
            this.dataPeopleYate = this.dataReservationRange.yatePeople;
          }
        }
        this.dataPeopleD = this.dataReservation.people;
        this.byAllDataD = this.dataReservation;
        this.getDataHours();
        this.getDataHoursLastHour();
      }
    },
    dateFrom () {
      const context = this;
      if (this.dateFrom != null && this.dateTo != null) {
        if (this.dateFrom <= this.dateTo) {
          this.$store.dispatch({
            type: 'metrics:getMetricReservations',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          });
          this.$store.dispatch({
            type: 'metrics:getDataReservationRange',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          })
            .then(res => {
              this.getReservationsCompleted(this.dateFrom, this.dateTo);
              this.getTimeReservations(this.dateFrom, this.dateTo);
            })
            .catch(e => {
              this.$buefy.notification.open({
                message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
                type: 'is-danger'
              });
            });
        } else {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> La Fecha de inicio no debe ser menor a la fecha tope de busquedada',
            type: 'is-danger'
          });
        }
      } else if (this.dateFrom == null && this.dateTo == null) {
        this.getReservationsCompleted(null, null);
        this.getTimeReservations(null, null);
        this.$store
          .dispatch({
            type: 'metrics:getDataReservation',
            data: { vendorId: this.$store.state.idVendor, type: this.sectionId, year: this.year }
          });
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        });
        if (this.byDay) {
          if (this.sectionId === 3) {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataReservationYate) {
                this.byDayReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataPeopleYate) {
                this.byDayPeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.byRange) {
          if (this.sectionId === 3) {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataReservationYate) {
                this.byRangeReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataPeopleYate) {
                this.byRangePeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.notEffective) {
          if (this.sectionId !== 3) {
            if (this.dataReservation.notEffective[0].length > 0) {
              this.isCancelled = this.dataReservation.notEffective[0].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.isCancelled = null;
            }
            if (this.dataReservation.notEffective[1].length > 0) {
              this.notShow = this.dataReservation.notEffective[1].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.notShow = [];
            }
            this.notEffectiveD.rows = this.isCancelled;
            if (this.dataReservation.notEffective[0] && this.dataReservation.notEffective[1]) {
              this.byIsCancelledD = this.dataReservation.notEffective[0].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
              this.byNotShowD = this.dataReservation.notEffective[1].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
            }
          }
        }
        this.byReferrerWithColors = this.dataReservation.byReferrer.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          return {
            ...data,
            name: name,
            color: context.getColorByName(name)
          };
        });
        this.setDataClientType();
        this.setDataMediaType();
        this.byAllReservations = this.byReferrerWithColors.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          const reservations = data.reservations;
          return {
            ...data,
            value: reservations,
            name: name,
            color: context.getColorByName(name)
          };
        });
        if (this.sectionId === 2) {
          if (this.global != null) {
            this.globalD = context.getNewArrayTransactions(this.global);
          }
        }
        // Se asigna los valores a los objectos de las gráficas
        this.byClientTypeD = this.byDayReservationsClient;
        this.byMediaTypeD = this.byDayReservationsMedia;
        this.byAllReservationsD.rows = this.byAllReservations || [];
        this.byMonthD.rows = this.byMonth;
        this.byGuestD.rows = this.byGuest;
        this.byReferrerD = this.byReferrerWithColors;
        this.byDayD = this.byDayReservations;
        this.byRangeD = this.byRangeReservations;
        this.dataReservationD = this.dataReservation.reservas;
        if (this.sectionId === 3) {
          this.dataReservationWalkIn = this.dataReservationRange.walkInReservations;
          this.dataPeopleWalkIn = this.dataReservationRange.walkInPeople;
          const yate = this.byDayD.find(d => d.name === 'yate');
          if (typeof yate !== 'undefined') {
            this.dataReservationYate = this.dataReservationRange.yateRes;
            this.dataPeopleYate = this.dataReservationRange.yatePeople;
          }
        }
        this.dataPeopleD = this.dataReservation.people;
        this.byAllDataD = this.dataReservation;
        this.getDataHours();
        this.getDataHoursLastHour();
      }
    },
    dateTo () {
      const context = this;
      if (this.dateFrom !== null && this.dateTo !== null) {
        if (this.dateFrom <= this.dateTo) {
          this.$store.dispatch({
            type: 'metrics:getMetricReservations',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          });
          this.$store.dispatch({
            type: 'metrics:getDataReservationRange',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          })
            .then(res => {
              this.getReservationsCompleted(this.dateFrom, this.dateTo);
              this.getTimeReservations(this.dateFrom, this.dateTo);
            })
            .catch(e => {
              this.$buefy.notification.open({
                message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
                type: 'is-danger'
              });
            });
        } else {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> La Fecha de fin no debe ser menor a la fecha inicio de busquedada',
            type: 'is-danger'
          });
        }
      } else if (this.dateFrom == null && this.dateTo == null) {
        this.getReservationsCompleted(null, null);
        this.getTimeReservations(null, null);
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        });
        this.$store
          .dispatch({
            type: 'metrics:getDataReservation',
            data: { vendorId: this.$store.state.idVendor, type: this.sectionId, year: this.year }
          });
        if (this.byDay) {
          if (this.sectionId === 3) {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataReservationYate) {
                this.byDayReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataPeopleYate) {
                this.byDayPeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.byRange) {
          if (this.sectionId === 3) {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataReservationYate) {
                this.byRangeReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataPeopleYate) {
                this.byRangePeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.notEffective) {
          if (this.sectionId !== 3) {
            if (this.dataReservation.notEffective[0].length > 0) {
              this.isCancelled = this.dataReservation.notEffective[0].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.isCancelled = null;
            }
            if (this.dataReservation.notEffective[1].length > 0) {
              this.notShow = this.dataReservation.notEffective[1].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.notShow = [];
            }
            this.notEffectiveD.rows = this.isCancelled;
            if (this.dataReservation.notEffective[0] && this.dataReservation.notEffective[1]) {
              this.byIsCancelledD = this.dataReservation.notEffective[0].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
              this.byNotShowD = this.dataReservation.notEffective[1].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
            }
          }
        }
        this.byReferrerWithColors = this.dataReservation.byReferrer.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          return {
            ...data,
            name: name,
            color: context.getColorByName(name)
          };
        });
        this.setDataClientType();
        this.setDataMediaType();
        this.byAllReservations = this.byReferrerWithColors.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          const reservations = data.reservations;
          return {
            ...data,
            value: reservations,
            name: name,
            color: context.getColorByName(name)
          };
        });
        if (this.sectionId === 2) {
          if (this.global != null) {
            this.globalD = context.getNewArrayTransactions(this.global);
          }
        }
        // Se asigna los valores a los objectos de las gráficas
        this.byClientTypeD = this.byDayReservationsClient;
        this.byMediaTypeD = this.byDayReservationsMedia;
        this.byAllReservationsD.rows = this.byAllReservations;
        this.byMonthD.rows = this.byMonth;
        this.byGuestD.rows = this.byGuest;
        this.byReferrerD = this.byReferrerWithColors;
        this.byDayD = this.byDayReservations;
        this.byRangeD = this.byRangeReservations;
        this.dataReservationD = this.dataReservation.reservas;
        if (this.sectionId === 3) {
          this.dataReservationWalkIn = this.dataReservationRange.walkInReservations;
          this.dataPeopleWalkIn = this.dataReservationRange.walkInPeople;
          const yate = this.byDayD.find(d => d.name === 'yate');
          if (typeof yate !== 'undefined') {
            this.dataReservationYate = this.dataReservationRange.yateRes;
            this.dataPeopleYate = this.dataReservationRange.yatePeople;
          }
        }
        this.dataPeopleD = this.dataReservation.people;
        this.byAllDataD = this.dataReservation;
        this.getDataHours();
        this.getDataHoursLastHour();
      }
    },
    sectionId () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      if (this.sectionId !== 4) {
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            year: this.year,
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            dateTo: this.dateTo,
            dateFrom: this.dateFrom
          }
        });
      }
      this.$store
        .dispatch({
          type: 'metrics:getDataReservation',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year,
            dateTo: this.dateTo,
            dateFrom: this.dateFrom
          }
        })
        .then(() => {
          // this.$store.commit(metricsTypes.mutations.setDateFrom, null);
          // this.$store.commit(metricsTypes.mutations.setDateTo, null);
          this.typeByDay = 1;
          this.typeByRange = 1;
          this.typeNotEffective = 1;
          // this.byDayD.columns = ["dia", "Web", "Telefono"];
          // this.byRangeD.columns = ["Rangos", "Web", "Telefono"];

          if (this.sectionId === 1) {
            this.colors = ['#00ADC6', '#82888E'];
            this.colors2 = ['#59575D', '#59C4D5', '#CF257D', '#3C5193', '#0AD561', '#3C9368', '#CAD300'];
            this.type = 'reservation';
            this.primaryColor = '#00ADC6';
          }
          if (this.sectionId === 2) {
            this.colors = ['#CAD300', '#E9ED98'];
            this.colors2 = ['#59575D', '#59C4D5', '#CF257D', '#3C5193', '#0AD561', '#3C9368', '#CAD300'];
            this.type = 'prePurchase';
            this.primaryColor = '#CAD300';
          }
          if (this.sectionId === 3) {
            this.byDayD.columns = ['dia', 'WalkIn'];
            this.byRangeD.columns = ['Rangos', 'WalkIn'];
            this.colors = ['#9453CB', '#D3B9EA'];
            this.colors2 = ['#9453CB', '#D3B9EA'];
            this.type = 'Walk-in';
            this.primaryColor = '#9453CB';
          }
          if (this.sectionId === 4) {
            this.byDayD.columns = ['dia', 'WalkIn'];
            this.byRangeD.columns = ['Rangos', 'WalkIn'];
            this.colors = ['#9453CB', '#D3B9EA'];
            this.colors2 = ['#00ADC6', '#9452CB', '#CAD300'];
            this.type = 'General';
            this.primaryColor = '#9453CB';
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      if (this.sectionId !== 2) {
        this.getReservationsCompleted(this.dateFrom, this.dateTo);
        this.getTimeReservations(null, null);
      }
    },
    typeByDay () {
      if (parseInt(this.typeByDay) === 1) {
        this.byDayD = this.byDayReservations;
      }
      if (parseInt(this.typeByDay) === 2) {
        this.byDayD = this.byDayPeople;
      }
    },
    typeByRange () {
      if (parseInt(this.typeByRange) === 1) {
        this.byRangeD = this.byRangeReservations;
      }
      if (parseInt(this.typeByRange) === 2) {
        this.byRangeD = this.byRangePeople;
      }
    },
    typeByTransaction () {
      if (parseInt(this.typeByTransaction) === 1) {
        this.globalD = this.getNewArrayTransactions(this.global);
      }
      if (parseInt(this.typeByTransaction) === 2) {
        this.globalD = this.getNewArrayTransactions(this.pse);
      }
      if (parseInt(this.typeByTransaction) === 3) {
        this.globalD = this.getNewArrayTransactions(this.card);
      }
    },
    typeNotEffective () {
      if (parseInt(this.typeNotEffective) === 1) {
        this.notEffectiveD.rows = this.isCancelled;
      }
      if (parseInt(this.typeNotEffective) === 2) {
        this.notEffectiveD.rows = this.notShow;
      }
    },
    typeExperience () {
      if (parseInt(this.typeExperience) === 1) {
        this.byExperienceD.rows = this.experiencesTotal;
      }
      if (parseInt(this.typeExperience) === 2) {
        this.byExperienceD.rows = this.experiencesEffective;
      }
    }
  },
  methods: {
    getTypeText () {
      switch (this.sectionId) {
        case 1:
          return 'reservas';
        case 2:
          return 'precompras';
        case 3:
          return 'walk-In';
        case 4:
          return 'Reservas - Precompras - Walk-In';
        default:
          break;
      }
    },
    getTotalClientType (byClientTypeD, type = null) {
      if (!Array.isArray(byClientTypeD)) {
        return;
      }
      if (byClientTypeD === null) {
        return 0;
      }
      const clientTypeNew = byClientTypeD.map(client => {
        let totalRes = 0;
        totalRes += (client.data.LUN) !== undefined ? client.data.LUN : 0;
        totalRes += (client.data.MAR) !== undefined ? client.data.MAR : 0;
        totalRes += (client.data['MIÉ']) !== undefined ? client.data['MIÉ'] : 0;
        totalRes += (client.data.JUE) !== undefined ? client.data.JUE : 0;
        totalRes += (client.data.VIE) !== undefined ? client.data.VIE : 0;
        totalRes += (client.data['SÁB']) !== undefined ? client.data['SÁB'] : 0;
        totalRes += (client.data.DOM) !== undefined ? client.data.DOM : 0;
        return {
          name: client.name,
          totalRes: totalRes
        };
      });
      if (type != null) {
        return clientTypeNew.find(ctn => ctn.name === type).totalRes;
      }
      if (clientTypeNew.length === 0) {
        return 0;
      }
      return clientTypeNew.reduce((a, b) => {
        return { totalRes: a.totalRes + b.totalRes };
      }).totalRes;
    },
    getTotalsMediaType (medias, type = null) {
      if (medias === null) {
        return 0;
      }
      const mediasNew = medias.map(media => {
        let totalRes = 0;
        const totalPeople = 0;
        totalRes += (media.dataReservation.LUN) !== undefined ? media.dataReservation.LUN : 0;
        totalRes += (media.dataReservation.MAR) !== undefined ? media.dataReservation.MAR : 0;
        totalRes += (media.dataReservation['MIÉ']) !== undefined ? media.dataReservation['MIÉ'] : 0;
        totalRes += (media.dataReservation.JUE) !== undefined ? media.dataReservation.JUE : 0;
        totalRes += (media.dataReservation.VIE) !== undefined ? media.dataReservation.VIE : 0;
        totalRes += (media.dataReservation['SÁB']) !== undefined ? media.dataReservation['SÁB'] : 0;
        totalRes += (media.dataReservation.DOM) !== undefined ? media.dataReservation.DOM : 0;
        // totalPeople += (media['dataPeople']['LUN']) !== undefined ? parseInt(media['dataPeople']['LUN']) : 0;
        // totalPeople += (media['dataPeople']['MAR']) !== undefined ? parseInt(media['dataPeople']['MAR']) : 0;
        // totalPeople += (media['dataPeople']['MIÉ']) !== undefined ? parseInt(media['dataPeople']['MIÉ']) : 0;
        // totalPeople += (media['dataPeople']['JUE']) !== undefined ? parseInt(media['dataPeople']['JUE']) : 0;
        // totalPeople += (media['dataPeople']['VIE']) !== undefined ? parseInt(media['dataPeople']['VIE']) : 0;
        // totalPeople += (media['dataPeople']['SÁB']) !== undefined ? parseInt(media['dataPeople']['SÁB']) : 0;
        // totalPeople += (media['dataPeople']['DOM']) !== undefined ? parseInt(media['dataPeople']['DOM']) : 0;
        return {
          name: media.name,
          totalRes: totalRes,
          totalPeople: totalPeople
        };
      });
      if (type === null) {
        return mediasNew;
      }
      if (mediasNew.length === 0) {
        return 0;
      }
      return mediasNew.reduce((a, b) => {
        return { totalRes: a.totalRes + b.totalRes };
      }).totalRes;
    },
    getTotalsByRange (ranges, type = null) {
      if (ranges === null) {
        return 0;
      }
      const newRanges = ranges.map((range) => {
        const name = (range.name === 'whitelabel') ? 'página web' : (range.name === 'dashboard') ? 'telefono' : range.name;
        let sum = 0;
        let sumPeople = 0;
        sum += (range.dataReservation) !== undefined ? range.dataReservation['0-5'] : 0;
        sum += (range.dataReservation) !== undefined ? range.dataReservation['6-12'] : 0;
        sum += (range.dataReservation) !== undefined ? range.dataReservation['+12'] : 0;
        sumPeople += (range.dataPeople) !== undefined ? parseInt(range.dataPeople['0-5']) : 0;
        sumPeople += (range.dataPeople) !== undefined ? parseInt(range.dataPeople['6-12']) : 0;
        sumPeople += (range.dataPeople) !== undefined ? parseInt(range.dataPeople['+12']) : 0;
        return {
          name: range.name,
          reservations: sum,
          people: sumPeople,
          color: this.getColorByName(name)
        };
      });
      if (type === null) {
        return newRanges;
      }
      if (newRanges.length === 0) {
        return 0;
      }
      if (type === 'reservations') {
        return newRanges.reduce((a, b) => {
          return { reservations: a.reservations + b.reservations };
        }).reservations;
      }
      return newRanges.reduce((a, b) => {
        return { people: a.people + b.people };
      }).people;
    },
    openMenuM2 () {
      // eslint-disable-next-line
      classie.add(document.getElementById('DownloadXLS'), 'show-menu-messager')
    },
    closeMenuM2 () {
      const els = document.getElementsByClassName('show-menu-messager');
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-messager');
        }
      }
    },
    validateClientTypeGraph () {
      if ((this.sectionId === 1 || this.sectionId === 2) && this.params.activeSelectorClient === 1) {
        return true;
      }
      return false;
    },
    validateExperiencieTypeGraph () {
      if (this.params && (this.sectionId === 1) && this.params.activeExperiencies === 1) {
        return true;
      }
      return false;
    },
    percent (value1, value2) {
      value1 = isNaN(value1) || value1 === null ? 0 : parseInt(value1);
      value2 = isNaN(value2) || value2 === null ? 0 : parseInt(value2);

      return Math.trunc((value1 * 100) / (value1 + value2));
    },
    getTotalsByType (list, type) {
      const total = _.sumBy(list, (data) => {
        if (typeof data[type] === 'string') {
          return parseInt(data[type]);
        }
        return data[type];
      });
      return total;
    },
    getTotalsGenerals (data, type) {
      if (type === 1) {
        return (parseInt(data.resevationReservations) + parseInt(data.walkinReservations) + parseInt(data.prePurchaseReservations));
      }
      return (parseInt(data.resevationPeople) + parseInt(data.walkinPeople) + parseInt(data.prePurchasePeople));
    },
    getTimeReservations (from, to) {
      if (!from && !to) {
        this.$store
          .dispatch({
            type: 'metrics:getDataReservationTime',
            data: { vendorId: this.idVendor, year: this.year }
          })
          .then(() => {
            const timeCont = _.find(this.timeState, { name: 'Reservas' });
            const timeCont2 = _.find(this.timeState, { name: 'Precompras' });
            const timeCont3 = _.find(this.timeState, { name: 'Walk-in' });
            for (var i = 1; i <= 16; i++) {
              if (timeCont.data['' + i] !== undefined) {
                if (i !== 16) {
                  this.timeStateN[0].data['' + i] = timeCont.data['' + i];
                } else {
                  this.timeStateN[0].data['' + i + '+'] = timeCont.data['' + i];
                }
              } else {
                if (i !== 16) {
                  this.timeStateN[0].data['' + i] = 0;
                } else {
                  this.timeStateN[0].data['' + i + '+'] = 0;
                }
              }
              if (timeCont2.data['' + i] !== undefined) {
                if (i !== 16) {
                  this.timeStateN[2].data['' + i] = timeCont2.data['' + i];
                } else {
                  this.timeStateN[2].data['' + i + '+'] = timeCont2.data['' + i];
                }
              } else {
                if (i !== 16) {
                  this.timeStateN[2].data['' + i] = 0;
                } else {
                  this.timeStateN[2].data['' + i + '+'] = 0;
                }
              }
              if (timeCont3.data['' + i] !== undefined) {
                if (i !== 16) {
                  this.timeStateN[1].data['' + i] = timeCont3.data['' + i];
                } else {
                  this.timeStateN[1].data['' + i + '+'] = timeCont3.data['' + i];
                }
              } else {
                if (i !== 16) {
                  this.timeStateN[1].data['' + i] = 0;
                } else {
                  this.timeStateN[1].data['' + i + '+'] = 0;
                }
              }
            }
            if (this.sectionId === 1) {
              this.getDataTimeReservations(this.timeStateN);
            }
            if (this.sectionId === 3) {
              this.getDataTimeReservationsWalkIn(this.timeStateN);
            }
          });
      }
      if (from && to) {
        this.$store
          .dispatch({
            type: 'metrics:getDataReservationTime',
            data: { vendorId: this.idVendor, dateFrom: this.dateFrom, dateTo: this.dateTo, year: this.year }
          })
          .then(() => {
            const timeCont = _.find(this.timeState, { name: 'Reservas' });
            const timeCont2 = _.find(this.timeState, { name: 'Precompras' });
            const timeCont3 = _.find(this.timeState, { name: 'Walk-in' });
            for (var i = 1; i <= 16; i++) {
              if (timeCont.data['' + i] !== undefined) {
                if (i !== 16) {
                  this.timeStateN[0].data['' + i] = timeCont.data['' + i];
                } else {
                  this.timeStateN[0].data['' + i + '+'] = timeCont.data['' + i];
                }
              } else {
                if (i !== 16) {
                  this.timeStateN[0].data['' + i] = 0;
                } else {
                  this.timeStateN[0].data['' + i + '+'] = 0;
                }
              }
              if (timeCont2.data['' + i] !== undefined) {
                if (i !== 16) {
                  this.timeStateN[2].data['' + i] = timeCont2.data['' + i];
                } else {
                  this.timeStateN[2].data['' + i + '+'] = timeCont2.data['' + i];
                }
              } else {
                if (i !== 16) {
                  this.timeStateN[2].data['' + i] = 0;
                } else {
                  this.timeStateN[2].data['' + i + '+'] = 0;
                }
              }
              if (timeCont3.data['' + i] !== undefined) {
                if (i !== 16) {
                  this.timeStateN[1].data['' + i] = timeCont3.data['' + i];
                } else {
                  this.timeStateN[1].data['' + i + '+'] = timeCont3.data['' + i];
                }
              } else {
                if (i !== 16) {
                  this.timeStateN[1].data['' + i] = 0;
                } else {
                  this.timeStateN[1].data['' + i + '+'] = 0;
                }
              }
            }
          });
      }
    },
    getReservationsCompleted (from, to) {
      this.byReservationS[0].data = {};
      this.byReservationS[1].data = {};

      let getDataR = { vendorId: this.idVendor, year: this.year };
      if (from && to) {
        getDataR = { vendorId: this.idVendor, dateFrom: this.dateFrom, dateTo: this.dateTo, year: this.year };
      }
      this.$store
        .dispatch({
          type: 'metrics:getDataReservationCompleted',
          data: getDataR
        })
        .then(res => {
          const data = res.data.metricData;
          for (const [key, value] of Object.entries(data)) {
            this.byReservationS[0].data[key] = value.user;
            this.byReservationS[1].data[key] = value.system;
          }
        });
    },
    getColorByName (name) {
      switch (name) {
        case 'telefono':
          return '#FD7403';
        case 'google':
          return '#FF0000';
        case 'facebook':
          return '#0070C0';
        case 'mail':
          return '#FFFF00';
        case 'precompro':
          return '#00FF00';
        case 'instagram':
          return '#FF3399';
        case 'twitter':
          return '#00acee';
        case 'página web':
          return '#00B0F0';
        case 'whatsapp':
          return '#008000';
        case 'ads':
          return '#000066';
        case 'adsinstagram':
          return '#800080';
        case 'tripadvisor':
          return '#33FF90';
        case 'Web Service':
          return '#000000';
        case 'Walk-In':
          return '#9453CB';
        case 'event':
          return '#9b9b9b';
        case 'yate':
          return '#000099';
        default:
          return '#' + Math.floor(Math.random() * 16777215).toString(16);
      }
    },
    getNewArrayTransactions (array) {
      const grupos = [
        { name: 'Promedio de Transacciones', data: {}, color: '#3CBA9F' },
        { name: 'Transaccion Mas Baja', data: {}, color: '#C45850' },
        { name: 'Transaccion Mas Alta', data: {}, color: '#E8C3B9' }
      ];
      if (array == null || !Array.isArray(array) || array.length <= 0) {
        return [];
      }

      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        grupos[0].data = { ...grupos[0].data, [element.mes]: parseFloat(element['Promedio de Transacciones']) };
        grupos[1].data = { ...grupos[1].data, [element.mes]: element['Transaccion Mas Baja'] };
        grupos[2].data = { ...grupos[2].data, [element.mes]: element['Transaccion Mas Alta'] };
      }
      return grupos;
    },
    getChartColors (data) {
      const newData = (data.rows) ? data.rows : data;
      if (!Array.isArray(newData)) {
        return [];
      }
      const colors = newData.map(data => {
        return data.color;
      });
      this.colors3 = colors;
      return colors;
    },
    getDataHours () {
      if (this.byHour != null) {
        const byHourClone = [...this.byHour];
        byHourClone.forEach(res => {
          if (res.hora.toString().length === 1) {
            res.hour = `0${res.hora}:00`;
            return;
          }
          res.hour = `${res.hora}:00`;
        });
        if (this.sectionId === 4) {
          this.byHourDGeneral.rows = byHourClone;
        }
        if (this.sectionId === 2) {
          this.byHourD.columns = ['hour', 'Precompras'];
          this.byHourD.rows = byHourClone;
          return;
        }
        this.byHourD.columns = ['hour', 'Reservas'];
        this.byHourD.rows = byHourClone;
      }
    },
    getDataHoursLastHour () {
      if ((this.byHourLastHour != null && this.byHourLastHour.length > 0)) {
        const byHourClone = [...this.byHourLastHour];
        byHourClone.forEach(res => {
          if (res.hora.toString().length === 1) {
            res.name = `0${res.hora}:00`;
            res.data = res.Reservas;
            return;
          }
          res.data = res.Reservas;
          res.name = `${res.hora}:00`;
        });
        const lastHour = byHourClone.pop();
        this.byLastHourD = [{
          name: 'Ultimo Movimiento', data: { [lastHour.name]: lastHour.data }
        }];
      }
    },
    getLastFromDay (byHourClone) {
      const finalArray = [];
      const days = [
        { name: 'DOM', pos: 0 },
        { name: 'LUN', pos: 1 },
        { name: 'MAR', pos: 2 },
        { name: 'MIÉ', pos: 3 },
        { name: 'JUE', pos: 4 },
        { name: 'VIE', pos: 5 },
        { name: 'SÁB', pos: 6 }
      ];
      days.forEach((day) => {
        const filtered = byHourClone.filter(hourLast => hourLast.dia === day.name);
        if (filtered.length > 0) {
          const last = filtered.pop();
          finalArray.push({ name: `${last.dia}-${last.name}`, data: { [`${last.dia}-${last.name}`]: last.data } });
        }
      });
      this.byLastHourD = finalArray;
    },
    getDataTimeReservations (data) {
      const newData = [...data];
      newData.splice(2, 1);
      newData.splice(1, 1);
      this.timeStateN2 = [...newData];
    },
    getDataTimeReservationsWalkIn (data) {
      const newData = [...data];
      newData.splice(0, 1);
      newData.splice(1, 1);
      this.timeStateN3 = [...newData];
    },
    setDataClientType () {
      if (this.byClientType) {
        this.byDayReservationsClient = this.dataReservation.byClientType.map(function (response) {
          const aux = {};
          const name = response.name;
          aux.name = name;
          aux.data = response.dataReservation;
          return aux;
        });
        this.byDayPeopleClient = this.dataReservation.byClientType.map(function (response) {
          const aux = {};
          const name = response.name;
          aux.name = name;
          aux.data = response.dataPeople;
          return aux;
        });
        if (this.sectionId === 4) {
          this.byDayReservationsClient.splice(1, 1);
          this.byClientTypeDGeneral = this.byDayReservationsClient;
        }
      }
    },
    setDataMediaType () {
      if (this.byMediaType) {
        this.byDayReservationsMedia = this.dataReservation.byMediaType.map(function (response) {
          const aux = {};
          const name = response.name;
          aux.name = name;
          aux.data = response.dataReservation;
          return aux;
        });
        this.byDayPeopleMedia = this.dataReservation.byMediaType.map(function (response) {
          const aux = {};
          const name = response.name;
          aux.name = name;
          aux.data = response.dataPeople;
          return aux;
        });
        if (this.sectionId === 4) {
          this.byDayReservationsMedia.splice(1, 1);
          this.byMediaTypeDGeneral = this.byDayReservationsMedia;
        }
      }
    }
  },
  data () {
    this.chartSettings2 = {
      area: true
    };
    this.chartSettings3 = {
      area: false,
      curve: false
    };
    this.chartSettings = {
      area: true,
      dimension: 'name',
      metrics: 'value',
      radius: [
        '50', '80'
      ],
      offsetY: 175
    };
    this.chartExtend = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const people = this.notEffectiveD.rows.find((data) => data.name === params.name)?.people;
          return `${params.marker} ${params.name}: ${params.value} reservas, personas ${people} (${params.percent}%)`;
        }
      }
    };
    this.chartSettings4 = {
      area: true,
      dimension: 'name',
      metrics: 'value',
      radius: [
        '50', '80'
      ],
      offsetY: 180
    };
    this.chartSettingsPie = {
      area: true,
      dimension: 'name',
      metrics: 'value',
      radius: [
        '0', '80'
      ],
      offsetY: 175
    };
    this.vRing = {
      radius: [40, 100],
      offsetY: 200
    };
    return {
      load: false,
      colors: ['#00ADC6', '#82888E'],
      colors2: ['rgba(89, 87, 93, 1)', 'rgba(89, 196, 213, 1)', 'rgba(207, 37, 125, 1)', 'rgba(60, 81, 147, 1)', 'rgba(10, 213, 97, 1)', 'rgba(60, 147, 104, 1)', 'rgba(202, 211, 0, 1)', 'rgba(89, 87, 93, 1)', 'rgba(89, 196, 213, 1)', 'rgba(207, 37, 125, 1)'],
      colors3: ['#00B0F0', '#FD7403', '#FF0000', '#0070C0', '#FFFF00', '#00FF00', 'FF3399', '#008000', '#000066', '#000000'],
      purchase: false,
      type: 'reservation',
      decimals: 1,
      byMonthD: {
        columns: ['mes', 'Reservas'],
        rows: []
      },
      byHourD: {
        columns: ['hour', 'Reservas'],
        rows: []
      },
      byHourDGeneral: {
        columns: ['hour', 'Reservas', 'Precompras'],
        rows: []
      },
      byClientTypeDGeneral: {
        columns: ['dia', 'Reservas', 'Precompras'],
        rows: []
      },
      byMediaTypeDGeneral: {
        columns: ['dia', 'Reservas', 'Precompras'],
        rows: []
      },
      byDayD: {
        name: '',
        data: []
      },
      byClientTypeD: {
        name: '',
        data: []
      },
      byLastHourD: {
        name: '',
        data: []
      },
      byMediaTypeD: {
        name: '',
        data: []
      },
      byReferrerD: {},
      notEffectiveD: {
        columns: ['name', 'value'],
        rows: []
      },
      byGuestD: {
        columns: ['name', 'value'],
        rows: []
      },
      byExperienceD: {
        columns: ['name', 'value'],
        rows: []
      },
      byAllReservationsD: {
        columns: ['name', 'value'],
        rows: []
      },
      byRangeD: {
        name: '',
        data: []
      },
      timeStateN: [{
        name: 'Reservas',
        data: {}
      },
      {
        name: 'Walk-in',
        data: {}
      },
      {
        name: 'Precompras',
        data: {}
      }],
      timeStateN2: [{
        name: 'Reservas',
        data: {}
      }],
      timeStateN3: [{
        name: 'Walk-in',
        data: {}
      }],
      byReservationS: [{
        name: 'Total reservas finalizadas por el usuario',
        data: {}
      },
      {
        name: 'Total reservas finalizadas por el sistema',
        data: {}
      }],
      byDayReservations: null,
      byDayReservationsClient: null,
      byDayReservationsMedia: null,
      byDayPeopleClient: null,
      byDayPeopleMedia: null,
      dataReservationD: null,
      dataPeopleD: null,
      byDayPeople: null,
      isCancelled: null,
      experiencesTotal: null,
      experiencesEffective: null,
      notShow: null,
      byIsCancelledD: null,
      byNotShowD: null,
      byAllDataD: null,
      dataReservationWalkIn: null,
      dataPeopleWalkIn: null,
      dataPeopleYate: null,
      dataReservationYate: null,
      byReferrerWithColors: [],
      byAllReservations: [],
      typeByDay: 1,
      typeByRange: 1,
      typeByTransaction: 1,
      typeNotEffective: 1,
      typeExperience: 1,
      primaryColor: '#00ADC6',
      totalReservationPhoneDay: 0,
      totalReservationWebDay: 0,
      totalPeoplePhoneDay: 0,
      totalPeopleWebDay: 0,
      globalD: [],
      pseD: [],
      cardD: [],
      prueba: [
        { name: 'dashboard', data: { '+12': 3, '0-5': 4, '6-12': 4 } },
        { name: 'facebook', data: { '+12': 3, '0-5': 4, '6-12': 4 } },
        { name: 'insta', data: { '+12': 3, '0-5': 4, '6-12': 4 } },
        { name: 'noc', data: { '+12': 3, '0-5': 4, '6-12': 4 } },
        { name: 'prueba', data: { '+12': 3, '0-5': 4, '6-12': 4 } },
        { name: 'Call parents', data: { '+12': 5, '0-5': 3, '6-12': 4 } }
      ],
      prueba2: [
        { name: 'Promedio de Transacciones', data: { '2018-DIC': 240000.0000, '2019-MAR': 200000, '2019-NOV': 232000.0000 } },
        { name: 'Transaccion Mas Baja', data: { '2018-DIC': 240000.0000, '2019-MAR': 160000, '2019-NOV': 200000 } },
        { name: 'Transaccion Mas Alta', data: { '2018-DIC': 240000.0000, '2019-MAR': 290000.0000, '2019-NOV': 300000 } }
      ]
    };
  },
  mounted () {
    if (this.dataReservation.prePurchase) {
      this.purchase = true;
    }
    this.byMonthD.rows = this.byMonth;
    this.byGuestD.rows = this.byGuest;
    this.getDataHours();
    this.getDataHoursLastHour();
    const context = this;
    this.setDataClientType();
    this.setDataMediaType();
    if (this.byDay) {
      this.byDayReservations = this.dataReservation.byDay.map(function (response) {
        const aux = {};
        const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.name = name;
        aux.data = response.dataReservation;
        aux.color = context.getColorByName(name);
        return aux;
      });
      this.byDayPeople = this.dataReservation.byDay.map(function (response) {
        const aux = {};
        const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.data = response.dataPeople;
        aux.color = context.getColorByName(name);
        return aux;
      });
    }
    if (this.byRange) {
      this.byRangeReservations = this.dataReservation.byRange.map(function (response) {
        const aux = {};
        const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.data = response.dataReservation;
        aux.color = context.getColorByName(name);
        return aux;
      });
      this.byRangePeople = this.dataReservation.byRange.map(function (response) {
        const aux = {};
        const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.data = response.dataPeople;
        aux.color = context.getColorByName(name);
        return aux;
      });
    }
    setTimeout(() => {
      if (this.validateExperiencieTypeGraph()) {
        this.experiencesTotal = this.dataReservation.byExperience.map(function (response) {
          const aux = {};
          aux.name = response.name;
          aux.value = response.countExperience;
          aux.color = context.getColorByName(aux.name);
          return aux;
        });
        this.experiencesEffective = this.dataReservation.byExperienceEffective.map(function (response) {
          const aux = {};
          aux.name = response.name;
          aux.value = response.countExperience;
          aux.color = context.getColorByName(aux.name);
          return aux;
        });
        this.byExperienceD.rows = this.experiencesTotal;
      }
    }, 200);
    if (this.sectionId !== 3) {
      if (this.notEffective) {
        this.isCancelled = this.dataReservation.notEffective[0].map(function (response) {
          const aux = {};
          aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
          aux.value = response.reservations;
          aux.people = response.people;
          aux.color = context.getColorByName(aux.name);
          return aux;
        });
        this.notShow = this.dataReservation.notEffective[1].map(function (response) {
          const aux = {};
          aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
          aux.value = response.reservations;
          aux.people = response.people;
          aux.color = context.getColorByName(aux.name);
          return aux;
        });
        this.notEffectiveD.rows = this.isCancelled;
        if (this.dataReservation.notEffective[0] && this.dataReservation.notEffective[1]) {
          this.byIsCancelledD = this.dataReservation.notEffective[0].map((data) => {
            const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
            return {
              ...data,
              color: this.getColorByName(name)
            };
          });
          this.byNotShowD = this.dataReservation.notEffective[1].map((data) => {
            const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
            return {
              ...data,
              color: this.getColorByName(name)
            };
          });
        }
      }
    }
    if (this.sectionId !== 2) {
      this.getReservationsCompleted(this.dateFrom, this.dateTo);
      this.getTimeReservations(null, null);
    }
    this.byReferrerWithColors = this.dataReservation.byReferrer.map((data) => {
      const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
      return {
        ...data,
        name: name,
        color: context.getColorByName(name)
      };
    });
    this.byAllReservations = this.byReferrerWithColors.map((data) => {
      const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
      const reservations = data.reservations;
      return {
        ...data,
        value: reservations,
        name: name,
        color: context.getColorByName(name)
      };
    });
    if (this.global != null) {
      this.globalD = context.getNewArrayTransactions(this.global);
    }
    this.byAllReservationsD.rows = this.byAllReservations;
    this.byDayD = this.byDayReservations;
    this.byClientTypeD = this.byDayReservationsClient;
    this.byMediaTypeD = this.byDayReservationsMedia;
    this.byReferrerD = this.byReferrerWithColors;
    this.byRangeD = this.byRangeReservations;
    this.dataReservationD = this.dataReservation.reservas;
    this.dataPeopleD = this.dataReservation.people;
    this.byAllDataD = this.dataReservation;
    this.getDataHours();
    this.getDataHoursLastHour();
  }
};
</script>
<style lang="scss">
#metrics-body {
  height: calc(100vh - 225px);
  .rigth-overflow-mobile{
    /* overflow: auto;
    height: calc(100dvh - 100px); */
  }
  .total-grey{
    background-color: #ecedee;
    padding: 5px 10px;
    margin-top: 40px;
    border-radius: 8px;
    min-width: 116px;
    p{
      color: #444b57;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
    }
  }
  .border-top-grey{
    border-top: 1px solid #e6e6e6;
  }
  .mw-50{
    max-width: 50%;
  }
  .w-100{
    width: 100%;
  }
  .text-small {
    font-size: 10px !important ;
  }
  .mt-10 {
    margin-top: 30px;
  }
  pt-30 {
    padding-top: 30px;
  }
  padding: 3px 10px 3px 8px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 52px);
  overflow: auto!important;
  /* overflow-y: auto;
  overflow-x: auto; */
  animation: fadeIn 1s ease-in-out;
  .header-index {
    // min-width: 730px;
    min-width: 400px;
    background: rgba(68, 75, 87, 1);
    display: flex;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px 5px 0 0;
    &.flex-1 {
      flex: 1;
      &:first-child {
        margin-right: 5px;
      }
    }
    &.grafica {
      background: white;
    }
  }
  .noresults {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper__noresults {
    width: 150px;
    height: 55px;
    border-radius: 5px;
    display: flex;
    text-align: center;
    justify-content: center;
    color: #707070;
    font-weight: bold;
  }
  .icon-mks {
    height: 25px;
    width: 25px;
    &.big-reservation {
      height: 25px;
      width: 35px;
    }
    &.small-grafica {
      height: 20px;
      width: 20px;
      margin-bottom: 9px;
    }
    &.big-walking {
      height: 50px;
      width: 50px;
    }
  }
  .col {
    flex: 1;
    &-2 {
      flex: 2;
    }
    &-3 {
      position: relative;
      flex: 3;
      &.padding {
        padding: 0 0 20px 20px;
      }
    }
    &.flex {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &.margin-top {
      margin-top: 3px;
    }
    &.padding-top {
      padding: 20px 3% 0 3%;
    }
    &.center {
      text-align: center;
    }
    &.padding-small{
      padding: 20px 3% 0 0;
    }
  }
  .sub-header {
    display: flex;
    background-color: #d9d9d9;
    padding: 10px;
  }
  .text-sub-tittle {
    color: black;
    font-weight: bold;
  }
  .icon-mks {
    &-gra {
      height: 50px !important;
      width: 50px !important;
    }
  }
  .ringCheck {
    padding-top: 30px;
  }
  .flex {
    display: flex;
    &.metric-cont {
      margin: 10px 0 0 0;
      min-width: 1100px;
    }
    &.grafica {
      margin: 0 0 0 0;
    }
    &.width-height {
      height: auto;
      width: 100%;
    }
    &.radio-grafica {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(112, 112, 112, 1);
      border-radius: 5px;
      height: 55px;
      &.options {
        height: 110px;
      }
    }
    &.radio-grafica-transacciones {
      justify-content: center;
      align-items: center;
      background: rgba(112, 112, 112, 1);
      border-radius: 5px;
      height: 55px;
      &.options {
        height: 110px;
      }
    }
    &.radio-center {
      align-items: center;
      margin-bottom: 2px;
      .b-radio.radio input[type="radio"] + .check {
        height: 1rem;
        width: 1rem;
        border: 2px solid white;
        &::before {
          margin-left: calc(-1em / 2);
          margin-bottom: calc(-1em / 2);
          width: 1em;
          height: 1em;
        }
      }
      .checkbox:hover,
      .radio:hover {
        color: white;
      }
      &.options {
        width: 100%;
        padding-left: 20px;
      }
    }
    &.border-top-bot {
      border-bottom: 1px solid #e6e6e6;
      border-top: 1px solid #e6e6e6;
      margin: 6px 0;
      padding: 2px 0px;
    }
    &.walking {
      justify-content: center;
      flex-direction: column;
      padding: 40px 0;
      align-items: center;
    }
    &.margin-width {
      width: 100%;
      margin-top: 15px;
    }
    &.align-center{
      align-items: center;
    }
    &.justify-between{
      justify-content: space-between;
    }
  }
  .container-flex {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    &-color {
      background: #d9d9d9;
    }
  }
  .rigth {
    width: calc(100% - 290px);
    margin: 5px 0 5px 10px;
    animation: fadeIn 1s ease-in-out;
    .v-line {
      background: white;
      //min-width: 1460px;
      min-width: 1100px;
    }
  }
  .prueba {
    display: flex;
    background: white;
    margin: 10px;
    border: 1px solid #ddd;
    padding: 5px;
    height: 400px;
  }
  .stadistic {
    width: 70%;
  }
  .text {
    &.bold {
      font-weight: bold;
    }
    &.tittle {
      font-size: 16px;
    }
    &.small {
      font-size: 10px;
    }
    &.grey {
      color: rgba(68, 75, 87, 1);
    }
    &.black {
      color: black;
    }
    &.small-grafica {
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 7px;
    }
    &.radio {
      font-size: 12px;
      margin-left: 2px;
    }
    &.reserva {
      color: #00adc6;
    }
    &.walkin {
      color: #9452cb;
    }
    &.precompra {
      color: #cad300;
    }
    &.padding-left {
      padding-left: 10px;
    }
    &.center{
      text-align: center;
    }
  }
  .stadistic-sub {
    background: white;
    width: 100%;
  }
  .columns {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    width: 100%;
    text-align: center;
    &-margin {
      margin-bottom: 20px !important;
    }
  }
  .column {
    padding: 0px;
    &-flex-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: -webkit-center;
    }
    &-margin-rigth {
      border-right: 1px solid #d9d9d9;
    }
    &-padding {
      padding: 0 5px;
    }
  }
  .btn-header {
    display: flex;
    width: 100%;
    justify-content: space-around;
    text-align: center;
    .btn {
      width: 100%;
      padding: 5px;
    }

    .active1 {
      color: white;
      background: black;
      border-top: 3px solid rgb(0, 173, 198);
      &:hover {
        background: rgb(0, 173, 198);
        transition: all 0.2s ease-in;
      }
    }
    .active2 {
      color: white;
      background: black;
      border-top: 3px solid rgb(202, 211, 0);
      &:hover {
        background: rgb(202, 211, 0);
        transition: all 0.2s ease-in;
      }
    }
    .active3 {
      color: white;
      background: black;
      border-top: 3px solid rgb(148, 83, 203);
      &:hover {
        background: rgb(148, 83, 203);
        transition: all 0.2s ease-in;
      }
    }
    .active4 {
      color: white;
      background: black;
      border-top: 3px solid rgb(66, 28, 58);
      &:hover {
        background: rgb(66, 28, 58);
        transition: all 0.2s ease-in;
      }
    }
    .opacity1 {
      color: gray;
      background: white;
      border-top: 3px solid rgba(0, 171, 198, 0.5);
      &:hover {
        background: rgba(0, 171, 198, 0.5);
        transition: all 0.2s ease-in;
      }
    }
    .opacity2 {
      color: gray;
      background: white;
      border-top: 3px solid rgba(200, 211, 0, 0.5);
      &:hover {
        background: rgba(200, 211, 0, 0.5);
        transition: all 0.2s ease-in;
      }
    }
    .opacity3 {
      color: gray;
      background: white;
      border-top: 3px solid rgba(149, 83, 203, 0.5);
      &:hover {
        background: rgba(149, 83, 203, 0.5);
        transition: all 0.2s ease-in;
      }
    }
    .opacity4 {
      color: gray;
      background: white;
      border-top: 3px solid rgba(66, 28, 58, 0.5);
      &:hover {
        background: rgba(66, 28, 58, 0.5);
        transition: all 0.2s ease-in;
      }
    }
  }
  .graph {
    .highcharts-legend-item {
      text {
        font-weight: 500 !important;
      }
      .highcharts-point {
        width: 25px;
        height: 13px;
      }
    }
  }
  .text-hours {
    color: #444b57a6;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: -43px;
    transform: rotate(270deg);
  }
  .text-left {
    color: #444b57a6;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: -25px;
    transform: rotate(270deg);
  }
  .text-people {
    color: #444b57a6;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 50%;
  }
  .text-right {
    color: #444b57a6;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 45%;
  }
}
@media (max-width: 720px) {
  .rigth {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
@media screen and (max-height: 1024px) and (min-width: 520px) and (max-width: 768px) {
  #metrics-body{
    height: calc(100vh - 200px)!important;
  }
}
@media (min-width: 1023px) and (max-width: 1025px) {
  .withMedia {
    width: calc(100% + 440px) !important;
  }
}
@media (min-width: 600px) and (max-width: 768px) {
  .withMedia {
    width: calc(100% + 700px) !important;
  }
}
@media (min-width: 500px) and (max-width: 600px) {
  .withMedia {
    width: calc(100% + 880px) !important;
  }
}
#metrics-body .col-3 .ve-ring {
  overflow: visible !important;
}
</style>
