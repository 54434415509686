<template>
  <div id="calendar">
      <div class="content-calendar-blockrange">
        <div class="months-title">
          <div class="" @click.prevent.stop="currentMonth--"><i class="fa fa-chevron-left"></i></div>
          <div class="" >
            <span> {{ monthName }}</span>
            <span> {{ yearName }}</span>
          </div>
          <div class="" @click.prevent.stop="currentMonth++" layout-align="end center"><i class="fa fa-chevron-right"></i></div>
        </div>
        <div class="calendar" >
          <div class="header columns is-mobile" :style="{color: vendorParams.toolbarColor}">
            <div class="cell header column" >
              <div class="">DOM</div>
            </div>
            <div class="cell header column" >
              <div class="">LUN</div>
            </div>
            <div class="cell header column" >
              <div class="">MAR</div>
            </div>
            <div class="cell header column" >
              <div class="">MIE</div>
            </div>
            <div class="cell header column" >
              <div class="">JUE</div>
            </div>
            <div class="cell header column" >
              <div class="">VIE</div>
            </div>
            <div class="cell header column" >
              <div class="">SAB</div>
            </div>
          </div>
          <div class="header-row columns is-mobile" v-for="row in (isLargeMonth ? 6 : 5)" :key="row">
            <div class="column" v-for="cell in 7" :key="cell">
              <div class="cell" v-show="!getDate(row, cell)"></div>
              <div class="cell date available pointer" :id="row+''+cell" v-if="getDate(row, cell)" @click.prevent.stop="setDataRangeSelect(getDate(row, cell).valueOf(), row+''+cell)" @mouseenter="previewDate(getDate(row, cell).valueOf())"  :class="{
                  preview: isPreview(getDate(row, cell).valueOf()),
                  selectedo: isSelected(getDate(row, cell).valueOf()),
                  first: first == row+''+cell ,
                  last: last == row+''+cell,
                  isDisabled: getDate(row, cell).valueOf() < today
                }">
                <div class="day" :class="{opacity5: !isToday(getDate(row, cell))}">{{getDate(row, cell).date()}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['value'],
  data: () => ({
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
    preview: [],
    first: null,
    last: null,
    ciclo: false,
    firt_date: null,
    last_date: null
  }),
  mounted () {
    const month = parseInt(this.$moment(this.dateComplete).format('M'));
    const date = this.monthConvert(month);
    this.currentMonth = date;
    const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
    this.currentYear = year;
    this.year = year;
  },
  computed: {
    monthName () {
      return this.$moment(new Date(this.currentYear, this.currentMonth + 1, 0, 0, 0, 0)).format('MMMM');
    },
    yearName () {
      return this.$moment(new Date(this.currentYear, this.currentMonth + 1, 0, 0, 0, 0)).format('YYYY');
    },
    nextMonth () {
      return this.$moment(new Date(this.currentYear, this.currentMonth + 2, 0, 0, 0, 0)).format('MMMM');
    },
    prevMonth () {
      return this.$moment(new Date(this.currentYear, this.currentMonth, 0, 0, 0, 0)).format('MMMM');
    },
    dayOfWeekOfFirst () {
      const date = new Date(this.currentYear, this.currentMonth);
      date.setDate(1);
      return this.$moment(date).day();
    },
    dates () {
      const dates = _.map(_.range(6), row => _.map(_.range(7), cell => {
        const initialDate = new Date(this.currentYear, this.currentMonth, 0, 0, 0, 0, 0);
        const date = this.$moment(initialDate).add(cell - this.dayOfWeekOfFirst + (7 * row + 1), 'days').startOf('day');
        const mon = this.$moment(new Date(date)).month();
        const mon2 = this.$moment(initialDate).month() + 1;
        const mon3 = (mon2 === 12) ? 0 : mon2;
        return mon === mon3 ? this.$moment(date) : null;
      }));
      return dates;
    },
    isLargeMonth () {
      return _.some(this.dates[5], date => !!date);
    }
  },
  methods: {
    getDate (row, cell) {
      const initialDate = new Date(this.currentYear, this.currentMonth, 0, 0, 0, 0, 0);
      const date = this.$moment(initialDate).add(cell - this.dayOfWeekOfFirst + (7 * (row - 1)), 'days').startOf('day');
      const mon = this.$moment(new Date(date)).month();
      const mon2 = this.$moment(initialDate).month() + 1;
      const mon3 = (mon2 === 12) ? 0 : mon2;
      return mon === mon3 ? this.$moment(date) : null;
    },
    isToday (date) {
      return date.startOf('day').valueOf() === this.$moment().startOf('day').valueOf();
    },
    isSelected (timestamp) {
      if (this.value[1]) {
        return timestamp >= this.value[0] && timestamp <= this.value[1];
      } else if (this.value[0]) {
        return timestamp === this.value[0];
      }
    },
    isPreview (timestamp) {
      const preview = _.sortBy(this.preview);
      if (preview[1]) {
        return timestamp >= preview[0] && timestamp <= preview[1];
      } else if (preview[0]) {
        return timestamp === preview[0];
      }
    },
    previewDate (date) {
      if (this.preview[0] && !this.value[1]) {
        this.preview = [this.preview[0], date];
      }
    },
    setDataRangeSelect (date, id) {
      if (this.first == null) {
        this.first = id;
        this.firt_date = date;
      } else {
        if (!this.ciclo) {
          if (parseInt(this.first) > parseInt(id)) {
            this.last = this.first;
            this.last_date = this.firt_date;
            this.first = id;
            this.firt_date = date;
            this.ciclo = true;
          } else {
            this.last = id;
            this.last_date = date;
            this.$emit('dates', [this.firt_date, this.last_date]);
            this.ciclo = true;
          }
        } else {
          this.first = id;
          this.firt_date = date;
          this.last = null;
          this.ciclo = false;
        }
      }

      if (this.value[1]) {
        this.preview = _.sortBy([date]);
        this.$emit('input', this.preview);
      } else if (this.value[0]) {
        this.preview = _.sortBy([...this.value, date]);
        this.$emit('input', this.preview);
      } else {
        this.preview = _.sortBy([date]);
        this.$emit('input', this.preview);
      }
    }
  },
  watch: {
    day () {
      this.setDataRangeSelect(this.day);
      this.currentMonth = this.$moment(new Date(this.day)).month();
      this.currentYear = this.$moment(new Date(this.day)).year();
    },
    value () {
      this.preview = [...this.value];
    }
  }
};
</script>

<style lang="scss" scoped>

.content-calendar-blockrange{
  width: 297px;
}
.cell.blocked {
  background: #F9A0B1;
  color: #fff !important;
}
.cell.full {
  background: #F44466;
  color: #fff !important;
}
.cell.available {
  transition: all .3s;
}
.cell.available:hover {
  background: rgba(0,0,0, 0.25);
}
.cell.active:hover {
  background: auto;
}
.cell.preview {
  background: transparent;
  &:hover{
    background:transparent;
  }
  &:after{
    background: rgba(244, 67, 54, 0.8);
    position: absolute;

    top: 0px;
    left: 0px;
    height: 100%;
    width: calc(100% + 12.4px);
    content: "";
    z-index:-1;
  }
  color: #fff;
}
.cell.preview.selectedo{
  background: transparent;
  color: #fff;
  border-radius:0 !important;
  border:none !important;
  position:relative;
  &:after{
    background:rgba(244, 67, 54, 0.8) !important;
    position: absolute;

    top: 0px;
    left: 0px;
    height: 100%;
    width: calc(100% + 12.4px);
    content: "";
    z-index:-1;
  }

  &.first{
       background: #F44336!important;
      border-radius:50% !important;
      .opacity5{
       opacity:1;
     }
     &:after{
      border-radius:50% 0 0 50% !important;
     }
  }
  &.last{
     background: #F44336!important;
     border-radius:50% !important;
     &:after{
      width: calc(100%);
      border-radius: 0 50% 50% 0 !important;
     }
     .day {
      color:#fff;
      font-weight: normal;
     }
     .opacity5{
      opacity:1;
     }

  }
}
div.preview.selectedo ~ div.preview.selectedo {
  background: transparent !important;
}
.no-uppercase {
  text-transform: none;
}
.isDisabled {
  pointer-events: none;
}

@media(max-width:550px){
  .content-calendar-blockrange{
     width: 100%;
  }
}
</style>
