<template>
  <div class="flex-center position-ref full-height">
    <div class="error-page">
      <div class="error-content">
        <h2 class="headline text-yellow" style="font-size: 50px;"> 401</h2>
        <h3><i class="fa fa-warning text-yellow"></i> Oops! Página no encontrada.</h3>
        <p>
          Tu usuario no tiene permiso para ver esta página.
        </p>
      </div>
      <br>
      <img src="../../public/images/firma_precompro.png">
    </div>
    <br>
  </div>
</template>
<script>
export default {
  name: 'Errors401Page',
  metaInfo: {
    title: 'Página no encontrada'
  }
};
</script>
<style scoped>
html,
body {
  background-color: #fff;
  color: #636b6f;
  height: 100vh;
  margin: 0;
}

.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

  .top-right {
    position: absolute;
    right: 10px;
    top: 18px;
  }

.content {
  text-align: center;
}

.error-page {
  width: 600px;
  margin-top: -10%;
}

.text-yellow {
  color: #dcd500 !important;
}
</style>
