<template>
  <div
    :class="{'selected': active}"
    @click="selection()"
    class="reservation-item"
    v-if="this.selectedDate == this.dateFilter"
  >
    <!-- <div class="circle-active" v-if="active">
      <i class="icon-mks icon_check_solid white"></i>
    </div> -->
    <div class="cardCheckbox" v-if="multiSelection">
      <input type="checkbox" v-model="active" >
    </div>
    <div
      :class="{'bg-reserva': (itemList.referrer != 'Walk-In'), 'bg-walkin': (itemList.referrer == 'Walk-In')}"
      @click="editItem()"
      class="left-box column"
    >
      <div class="number-top">{{itemList.people}}</div>
      <i class="icon-mks white icon_person"></i>
    </div>
    <div class="center-info column">
      <div @click="editItem()" class="half-height">
        <span class="big-name">{{itemList.name | lowercase}}</span>
        <span class="waitingTime" v-if="waitingTime !== '0m'">{{ waitingTime }}</span>
      </div>
      <div class="half-height">
        <span
          :class="(parseInt(rawCountDown) > parseInt(itemList.waitingTime)) ? 'text-error' : ''"
          class="item-w-icon"
        >
          <i class="icon-masked mk-clock"></i>
          {{ countDown }}
        </span>
        <span class="item-w-icon" v-if="itemList.phone !== null">
          <i class="icon-masked mk-phone"></i>
          {{ itemList.phone }}
        </span>
      </div>
    </div>
    <div class="rigth-action column">
      <span class="cta-btn double-icon" title="Seleccionar mesa" @click="selectTable()">
        <div  class="half-height-nopad" v-if="itemList.referrer == 'Walk-In'">
          <i class="icon-mks black icon_dashboard_seat"></i>
        </div>
        <i

          class="icon-mks black icon_dashboard_metro"
          v-else-if="itemList.referrer == 'reservation' || itemList.referrer == 'whitelabel' || itemList.referrer == 'dashboard'"
        ></i>
        <div
          @click="sendSMSnotification()"
          class="half-height-nopad"
          title="Enviar sms notificiación"
          v-if="itemList.referrer == 'Walk-In' && itemList.typeNotification === 'sms'"
        >
          <i class="icon-mks black icon_msn"></i>
        </div>
      </span>
      <span @click="deleteItem(itemList.id)" class="cta-btn" title="Eliminar item">
        <i class="icon-mks black icon_trash_waiting"></i>
      </span>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['itemList', 'query', 'multiSelection', 'arraySelection'],
  data () {
    return {
      countDown: 0,
      rawCountDown: 0,
      waitingTime: 0,
      dateFilter: null,
      active: false,
      array: []
    };
  },
  mounted () {
    this.waitingTime = this.calculateWaitingTime(this.itemList.waitingTime);
    this.countDownTimer();
    this.dateFilter = this.$moment(this.itemList.date).format('YYYY-MM-DD');
  },
  created () {
    window.setInterval(this.countDownTimer, 1000);
  },
  methods: {
    sendSMSnotification () {
      const data = this.itemList;
      data.vendorId = this.vendorId;
      this.$store
        .dispatch({
          type: 'waitingList:sendSMSnotification',
          data: data
        })
        .then(response => {
          if (parseInt(response.code) === 200) {
            if (response.message === '1' || response.message === 1) {
              this.$buefy.notification.open({
                message: '<i class="icon_alert_success icon-mks green"></i>Se ha enviado el SMS al usuario',
                type: 'is-success'
              });
            }
            if (response.message === '0' || response.message === 0) {
              this.$buefy.notification.open({
                message: '<i class="icon_alert icon-mks orange"></i>Ya has enviado este SMS',
                type: 'is-sucess'
              });
            }
          } else {
            this.$buefy.notification.open({
              message: '<i class="icon_alert_danger icon-mks red"></i>Ocurrió un error enviando el SMS.',
              type: 'is-danger'
            });
          }
        });
    },
    deleteItem (id) {
      if (!this.multiSelection) {
        this.$emit('itemList-delete', this.itemList);
      }
    },
    editItem () {
      if (!this.multiSelection) {
        this.$emit('itemList-edit', this.itemList);
      }
    },
    selectTable () {
      if (!this.multiSelection) {
        this.$emit('itemList-selectTable', this.itemList);
      }
    },
    countDownTimer () {
      const created = this.$moment(this.itemList.created)
        .format('DD/MM/YYYY HH:mm:ss');
      const date = this.$moment()
        .format('DD/MM/YYYY HH:mm:ss');
      var ms = this.$moment(date, 'DD/MM/YYYY HH:mm:ss').diff(
        this.$moment(created, 'DD/MM/YYYY HH:mm:ss')
      );
      var d = this.$moment.duration(ms);
      var s = Math.floor(d.asHours()) + this.$moment.utc(ms).format(':mm:ss');
      this.rawCountDown = Math.floor(d.asHours()) + this.$moment.utc(ms).format('x');
      this.countDown = '' + s;
    },
    calculateWaitingTime (time) {
      const waitingTime = time / 60000;
      if (waitingTime < 60) {
        return waitingTime + 'm';
      }
      if (waitingTime === 60) {
        return '1h';
      }
      if (waitingTime > 60 && waitingTime < 120) {
        const h = '1h ';
        return h + (waitingTime - 60) + 'm';
      }
      if (waitingTime === 120) {
        return '2h';
      }
    },
    selection () {
      if (this.multiSelection) {
        this.$emit('selected', this.itemList);
      }
    }
  },
  watch: {
    itemList () {
      this.waitingTime = this.calculateWaitingTime(this.itemList.waitingTime);
    },
    arraySelection () {
      const data = _.findIndex(this.arraySelection, { id: this.itemList.id });
      if (data !== -1) {
        this.active = true;
      } else {
        this.active = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.center-icon {
  margin-top: 15px;
  height: 2.5rem;
}
.number-top {
  color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.center-info {
  width: 55%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex: 1;
  padding: 0;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
.icon-services {
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
}
.reservation-item {
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  display: flex;
  max-height: 60px;
  flex-direction: row;
  margin: 6px 0px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &.selected {
    border: 1px solid #444b57;
  }
}
.icon-masked {
  display: flex;
  background: #000;
  margin-right: 5px;
  -webkit-mask-repeat: no-repeat;
  height: 10px;
  width: 10px;
  margin-top: 3px;
}
.mk-phone {
  -webkit-mask-image: url(../../../assets/icons/icon_phone.svg);
  mask-image: url(../../../assets/icons/icon_phone.svg);
  margin-top: 4px;
}

.mk-clock {
  -webkit-mask-image: url(../../../assets/icons/time_black.svg);
  mask-image: url(../../../assets/icons/time_black.svg);
}
.text-error i {
  background: red !important;
}
.half-height {
  height: 50%;
  text-align: left;
  display: flex;
  align-items: center;
  &:nth-child(2) {
    background: #f5f5f5;
    margin-left: 1px;
    border-top: 1px solid #d5d5d5;
    font-size: 11px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
  }
  .item-w-icon {
    display: flex;
  }
}
.half-height-nopad {
  height: 50%;
  text-align: left;
  display: flex;
  align-items: center;
  &:nth-child(2) {
    margin-left: 1px;
    border-top: 1px solid #d5d5d5;
    font-size: 11px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
  }
  .item-w-icon {
    display: flex;
  }
}

.rigth-action {
  display: flex;
  align-items: center;
  flex-direction: inherit;
  flex: 1;
  max-width: 70px;
  padding: 0;
}
.divider-v.large {
  height: 5.8rem;
}
.comment-icon {
  font-size: 1.8rem;
}
.cta-btn {
  margin: 0;
  text-transform: none;
  background: #fff;
  width: 35px !important;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #d5d5d5;
  height: 100%;
  cursor: pointer;
  i {
    margin-right: 0;
    width: 16px;
    height: 16px;
    margin-top: 0;
  }
}
.double-icon {
  flex-direction: column !important;
  justify-content: space-around !important;
}
.cta-btn:hover {
  background: #fafafa;
}
.cta-btn:active {
  background: #eee;
}
.icon {
  height: 1.8rem;
}

.icon.small {
  height: 1.6rem;
}

.left-box {
  width: 60px !important;
  display: flex;
  border-radius: 0.25rem 0 0 0.25rem;
  margin: -1px;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  flex: 1;
  padding: 9px;
  min-height: 57px;
  gap: 4px;
}

.big-name {
  text-transform: capitalize;
  font-size: 13px;
  padding: 6px 10px;
  margin-right: 5px;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.waitingTime {
  color: #fff;
  background-color: #555;
  padding: 2px 3px;
  font-size: 11px;
  border-radius: 5px;
  line-height: 14px;
  margin-left: 5px;
  font-weight: 600;
}
.text-error {
  color: red;
}

.itemList {
  &-leftArrow {
    height: 10px;
  }
  &-seat {
    height: 15px;
  }
  &-trashCan {
    height: 15px;
  }
}
.circle-active {
  position: absolute;
  right: 0;
  height: 10px;
  width: 10px;
  background: #0080ff;
  border-radius: 100%;
  .icon-mks {
    height: 6px;
    width: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -1%);
  }
}
.cardCheckbox {
  padding: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  input {
    width: 10px;
    height: 10px;
    appearance: none;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  input:checked {
    background-color: #444b57;
    border-color: #444b57;
  }
}
</style>
