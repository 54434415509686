import marketingTypes from '@/store/types/marketing';
import servicePayment from '@/helpers/apis/servicePayment';
import api from '@/helpers/api';

const state = {
  package: null,
  cardTypeInfo: null,
  integrations: [],
  integrationDefaults: []
};

const getters = {
  [marketingTypes.getters.package]: state => state.package,
  [marketingTypes.getters.cardTypeInfo]: state => state.cardTypeInfo,
  [marketingTypes.getters.integrations]: state => state.integrations,
  [marketingTypes.getters.integrationDefaults]: state => state.integrationDefaults
};

const actions = {
  [marketingTypes.actions.preOrder]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      servicePayment.post('marketing/sale', payload)
        .then(({ data }) => {
          resolve(data);
          window.localStorage.removeItem('marketing_package');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [marketingTypes.actions.getIntegrations]: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      api.get('admin/integrations/' + data.vendorId)
        .then(response => {
          commit(marketingTypes.mutations.setIntegrations, response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [marketingTypes.actions.getIntegrationDefaults]: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      api.get('admin/integrations/null?filter=' + data.filter)
        .then(response => {
          commit(marketingTypes.mutations.setIntegrationDefaults, response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  [marketingTypes.mutations.setPackage]: (state, data) => {
    if (data) {
      state.package = data;
      window.localStorage.setItem('marketing_package', data);
    } else {
      const info = JSON.parse(window.localStorage.getItem('marketing_package'));
      if (info) {
        state.package = info;
      }
    }
  },
  [marketingTypes.mutations.setIntegrations] (state, integrations) {
    state.integrations = integrations;
  },
  [marketingTypes.mutations.setIntegrationDefaults] (state, integrationDefaults) {
    state.integrationDefaults = integrationDefaults;
  },
  [marketingTypes.mutations.setCardTypeInfo] (state, cardTypeInfo) {
    state.cardTypeInfo = cardTypeInfo;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
