<template>
  <div id="calendar">
    <div class="months-title">
      <div @click.prevent.stop="anteriorMes()" v-if="!loadingReservationCalendar">
        <i class="fa fa-chevron-left"></i>
      </div>
      <div>
        <span @click="showMonthChange()">{{monthName}}&nbsp;</span>
        <span @click="showYearChange()">{{yearName}}</span>
      </div>
      <div @click.prevent.stop="siguienteMes()"  v-if="!loadingReservationCalendar">
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
    <span class="today" v-if="selectedDate !== today" @click="setTodayCalendar()">
      Hoy
    </span>
    <div class="calendar" v-if="!loadingReservationCalendar && !showMonthAll && !showYearAll" >
      <div class="header columns is-mobile">
        <div class="cell header column">
          <div>DOM</div>
        </div>
        <div class="cell header column">
          <div>LUN</div>
        </div>
        <div class="cell header column">
          <div>MAR</div>
        </div>
        <div class="cell header column">
          <div>MIE</div>
        </div>
        <div class="cell header column">
          <div>JUE</div>
        </div>
        <div class="cell header column">
          <div>VIE</div>
        </div>
        <div class="cell header column">
          <div>SAB</div>
        </div>
      </div>
      <div class="header-row columns is-mobile" v-for="row in (isLargeMonth ? 6 : 5)" :key="row">
        <div class="column" v-for="cell in 7" :key="cell">
          <div class="cell" v-show="!dates[row - 1][cell - 1]">
          </div>
          <div class="cell date available pointer"
            v-if="dates[row - 1][cell - 1]"
            @click="noSchedule[row - 1][cell - 1] !== 1 ? setDateSelect(timestamps[row - 1][cell - 1]) : ''"
            :class="{selected: timestamps[row - 1][cell - 1] === selectedDate, blocked: blockedDates[row - 1][cell - 1], holiday: isDayHoliday(timestamps[row - 1][cell - 1])  }"
            v-tooltip="{
              content: noSchedule[row - 1][cell - 1] === 1 ? 'Día sin horario <br> de atención' : '',
              trigger:'hover click', html: true, placement: 'bottom',
              classes: cell === 1 ? 'tooltip-calendar-left' : 'tooltip-calendar'
            }"
          >
            <div class="dot-text2" v-show="reservationDay(timestamps[row - 1][cell - 1])" style="pointer-events: none;">
              {{ reservationDay(timestamps[row - 1][cell - 1]) }}
            </div>
            <img v-if="hourblockedDates[row - 1][cell - 1] && !blockedDates[row - 1][cell - 1]"  class="icon-mks" src="@/assets/icons/blockHour.svg">
            <div
              class="day" :class="{ daySelect: timestamps[row - 1][cell - 1] === selectedDate, 'dayEmpty': noSchedule[row - 1][cell - 1] === 1, 'unselectable': noSchedule[row - 1][cell - 1] === 1}"
            >
              {{ days[row - 1][cell - 1] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showMonthAll">
        <div class="box-months">
          <span @click="showMonthAllfunc">Cerrar &nbsp;<i class="fa fa-times-circle"></i></span>
        </div>
        <div class="columns is-mobile">
          <button class="column butn-month" @click="setChangeMonth(0)" :class="{selectedMonthChange: timestampMonth(0)}">Ene - {{ currentYear }}</button>
          <button class="column butn-month" @click="setChangeMonth(1)" :class="{selectedMonthChange: timestampMonth(1)}">Feb - {{ currentYear }}</button>
          <button class="column butn-month" @click="setChangeMonth(2)" :class="{selectedMonthChange: timestampMonth(2)}">Mar - {{ currentYear }}</button>
        </div>
        <div class="columns is-mobile">
          <button class="column butn-month" @click="setChangeMonth(3)" :class="{selectedMonthChange: timestampMonth(3)}">Abr - {{ currentYear }}</button>
          <button class="column butn-month" @click="setChangeMonth(4)" :class="{selectedMonthChange: timestampMonth(4)}">May - {{ currentYear }}</button>
          <button class="column butn-month" @click="setChangeMonth(5)" :class="{selectedMonthChange: timestampMonth(5)}">Jun - {{ currentYear }}</button>
        </div>
        <div class="columns is-mobile">
          <button class="column butn-month" @click="setChangeMonth(6)" :class="{selectedMonthChange: timestampMonth(6)}">Jul - {{ currentYear }}</button>
          <button class="column butn-month" @click="setChangeMonth(7)" :class="{selectedMonthChange: timestampMonth(7)}">Ago - {{ currentYear }}</button>
          <button class="column butn-month" @click="setChangeMonth(8)" :class="{selectedMonthChange: timestampMonth(8)}">Sep - {{ currentYear }}</button>
        </div>
        <div class="columns is-mobile" >
          <button class="column butn-month" @click="setChangeMonth(9)" :class="{selectedMonthChange: timestampMonth(9)}">Oct - {{ currentYear }}</button>
          <button class="column butn-month" @click="setChangeMonth(10)" :class="{selectedMonthChange: timestampMonth(10)}">Nov - {{ currentYear }}</button>
          <button class="column butn-month" @click="setChangeMonth(11)" :class="{selectedMonthChange: timestampMonth(11)}">Dic - {{ currentYear }}</button>
        </div>
    </div>
    <div v-if="showYearAll">
      <div class="box-months">
        <span @click="showYearAllFunc">Cerrar &nbsp;<i class="fa fa-times-circle"></i></span>
      </div>
      <div class="columns is-mobile" >
        <button class="column butn-month" @click="setChangeYear(2019)" :class="{selectedYearChange: timestampYear(2019)}">2019</button>
        <button class="column butn-month" @click="setChangeYear(2020)" :class="{selectedYearChange: timestampYear(2020)}">2020</button>
        <button class="column butn-month" @click="setChangeYear(2021)" :class="{selectedYearChange: timestampYear(2021)}">2021</button>
      </div>
      <div class="columns is-mobile" >
        <button class="column butn-month" @click="setChangeYear(2022)" :class="{selectedYearChange: timestampYear(2022)}">2022</button>
        <button class="column butn-month" @click="setChangeYear(2023)" :class="{selectedYearChange: timestampYear(2023)}">2023</button>
        <button class="column butn-month" @click="setChangeYear(2024)" :class="{selectedYearChange: timestampYear(2024)}">2024</button>
      </div>
      <div class="columns is-mobile" >
        <button class="column butn-month" @click="setChangeYear(2025)" :class="{selectedYearChange: timestampYear(2025)}">2025</button>
        <button class="column butn-month" @click="setChangeYear(2026)" :class="{selectedYearChange: timestampYear(2026)}">2026</button>
        <button class="column butn-month" @click="setChangeYear(2027)" :class="{selectedYearChange: timestampYear(2027)}">2027</button>
      </div>
      <div class="columns is-mobile" >
        <button class="column butn-month" @click="setChangeYear(2028)" :class="{selectedYearChange: timestampYear(2028)}">2028</button>
        <button class="column butn-month" @click="setChangeYear(2029)" :class="{selectedYearChange: timestampYear(2029)}">2029</button>
        <button class="column butn-month" @click="setChangeYear(2030)" :class="{selectedYearChange: timestampYear(2030)}">2030</button>
      </div>
      <div class="columns is-mobile" >
        <button class="column butn-month" @click="setChangeYear(2031)" :class="{selectedYearChange: timestampYear(2031)}">2031</button>
        <button class="column butn-month" @click="setChangeYear(2032)" :class="{selectedYearChange: timestampYear(2032)}">2032</button>
        <button class="column butn-month" @click="setChangeYear(2033)" :class="{selectedYearChange: timestampYear(2033)}">2033</button>
      </div>
    </div>
    <div class="box-loading calendar-bg" v-if="loadingReservationCalendar">
      <i class="icon-mks icon_reservations loading rotating"></i>
      <span>Buscando reservas para {{monthName}} ...</span>
    </div>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';

export default {
  name: 'CalendarVue',
  components: { },
  data: () => ({
    currentMonth: null,
    currentYear: null,
    year: null,
    showMonthAll: false,
    showYearAll: false,
    monthChange: []
  }),
  created () {
    const month = parseInt(this.$moment(this.dateComplete).format('M'));
    const date = this.monthConvert(month);
    this.currentMonth = date;
    const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
    this.currentYear = year;
    this.year = year;
  },
  computed: {
    monthName () {
      return this.$moment([this.currentYear, this.currentMonth, 1]).format('MMMM');
    },
    yearName () {
      return this.$moment([this.currentYear, this.currentMonth, 1]).format('YYYY');
    },
    dates () {
      const listDates = _.map(_.range(6), row => _.map(_.range(7), cell => {
        const initialDate = this.$moment([this.currentYear, this.currentMonth, 1]).startOf('month');
        const date = initialDate.clone().add(cell - initialDate.day() + (7 * row), 'days');
        return date.month() === initialDate.month() ? date : null;
      }));
      return listDates;
    },
    days () {
      return _.map(this.dates, row => _.map(row, cell => cell ? cell.format('D') : null));
    },
    timestamps () {
      return _.map(this.dates, row => _.map(row, cell => cell ? cell.format('YYYY-MM-DD') : null));
    },
    isLargeMonth () {
      return _.some(this.dates[5], date => !!date);
    },
    blockedDates () {
      return _.map(this.dates, row => _.map(row, date => date ? this.getBlocked(date) : null));
    },
    hourblockedDates () {
      return _.map(this.dates, row => _.map(row, date => date ? this.getHoursBlocked(date) : null));
    },
    noSchedule () {
      return _.map(this.dates, row => _.map(row, date => date ? this.getInScheduleDashboard(date) : null));
    }
  },
  methods: {
    setTodayCalendar () {
      this.showYearAll = false;
      this.showMonthAll = false;
      const today = this.$moment().format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, today);
      this.$store.commit(reservationTypes.mutations.setLoadingReservation, true);
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: this.selectedDate } }).finally(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
      }).catch(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
      });
    },
    timestampMonth (month) {
      const date = parseInt(this.$moment(this.selectedDate).format('M'));
      const i = this.monthConvert(date);
      if (month === i) {
        return true;
      } else {
        return false;
      }
    },
    timestampYear (year) {
      const yearIn = parseInt(this.$moment(this.selectedDate).format('YYYY'));
      if (year === yearIn) {
        return true;
      } else {
        return false;
      }
    },
    setChangeMonth (month) {
      const monthNew = this.$moment([this.currentYear, month, 1]).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, monthNew);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, monthNew);
      this.$store.commit(reservationTypes.mutations.setLoadingReservation, true);
      const date = parseInt(this.$moment(this.dateComplete).format('M'));
      this.currentMonth = this.monthConvert(date);
      this.showMonthAll = false;
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: this.selectedDate } }).finally(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
        this.$emit('sibar', true);
      }).catch(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
        this.$emit('sibar', true);
      });
    },
    setChangeYear (year) {
      this.currentYear = parseInt(year);
      const today = this.$moment([this.currentYear, this.currentMonth, 1]).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, today);
      const date = parseInt(this.$moment(this.dateComplete).format('M'));
      this.currentMonth = this.monthConvert(date);
      this.showYearAll = false;
      this.$store.commit(reservationTypes.mutations.setLoadingReservation, true);
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: this.selectedDate } }).finally(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
        this.$emit('sibar', true);
      }).catch(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
        this.$emit('sibar', true);
      });
    },
    showMonthChange () {
      this.showMonthAll = true;
      this.showYearAll = false;
      this.$emit('nobar', true);
    },
    showYearChange () {
      this.showYearAll = true;
      this.showMonthAll = false;
      this.$emit('nobar', true);
    },
    anteriorMes () {
      this.currentMonth--;
      if (this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
      const today = this.$moment([this.currentYear, this.currentMonth, 1, 0, 0, 0]).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, today);
      this.$store.commit(reservationTypes.mutations.setLoadingReservation, true);
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: this.selectedDate } }).finally(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
      }).catch(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
      });
    },
    siguienteMes () {
      this.currentMonth++;
      if (this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      }
      const today = this.$moment([this.currentYear, this.currentMonth, 1, 0, 0, 0]).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, today);
      this.$store.commit(reservationTypes.mutations.setLoadingReservation, true);
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: this.selectedDate } }).finally(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
      }).catch(() => {
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
      });
    },
    getInScheduleDashboard (date) {
      const schedule = this?.schedules?.public?.map(item => item.dayOfWeek);
      const extraFreeDates = this?.schedules?.extraFree?.map(item => this.$moment(parseInt(item.date)).format('YYYY-MM-DD'));
      const formattedDate = this.$moment(date).format('YYYY-MM-DD');
      const dayOfWeek = date.day();

      if (extraFreeDates.includes(formattedDate)) {
        return 0;
      }

      return schedule.includes(dayOfWeek) ? 0 : 1;
    },
    setDateSelect (date) {
      this.$store.commit(reservationTypes.mutations.setType, '');
      this.$store.commit(reservationTypes.mutations.setInfoReservation, null);
      this.$store.commit(reservationTypes.mutations.setViewReservation, false);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
      this.$store.commit(reservationTypes.mutations.setLoadingReservation, true);
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: date } }).finally(() => {
        this.loader = false;
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
      }).catch(() => {
        this.show = true;
        this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
      });
    },
    reservationDay (date) {
      const day = _.find(this.getReservationsDay, { fecha: date });
      return (day !== undefined) ? day.total : day;
    },
    showMonthAllfunc () {
      this.showMonthAll = false;
      this.$emit('sibar', true);
    },
    showYearAllFunc () {
      this.showYearAll = false;
      this.$emit('sibar', true);
    }
  },
  watch: {
    selectedDate () {
      const month = parseInt(this.$moment(this.dateComplete).format('M'));
      const date = this.monthConvert(month);
      this.currentMonth = date;
      const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
      this.currentYear = year;
      this.year = year;
    }
  }
};
</script>
<style lang="scss">
#calendar{
  .selectedMonthChange,
  .selectedYearChange {
    background-color: #411D39 !important;
    color: #ffffff !important;
  }
  .columns{
    margin:0;
    padding:0;
    &:first-child {
      margin:0 !important;
    }
    &:last-child{
      margin: 0 !important;
    }
    .column{
      margin:0;
      padding:0;
    }
  }

  .butn-month{
    background:transparent;
    border:1px solid #fff;
    border-radius:5px;
    padding:5px !important;
    color:#fff;
    margin:5px !important;
    cursor:pointer;
  }
  .box-months{
    color: #fff;
    padding: 5px;
    margin: 10px;
    position: relative;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    cursor:pointer;
    i{
      margin-left: 5px;
      top: 2px !important;
      position: relative;
    }
  }

  .months-title {
    display: flex;
    font-size: 17px;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    text-indent: 0;
    line-height: 0;
    color: #fff;
    span{
      font-size: 17px;
      font-weight: 700;
    }
    i{
      padding:5px;
      font-size: 16px;
      padding: 0px 10px;
      margin-top: 1px;
    }
  }
  .calendar{
    .dayEmpty {
      color: #A8ADB5;
    }
    .header{
      color: #fff;
      margin: 10px 0 3px !important;
      display: flex;
      text-align: center;
      justify-content: center;
    }
    .cell{
      height:30px;
      display:flex;
      align-items: center;
      justify-content:center;
      padding: 5px;
      margin: 5px 6px;
      width: 30px;
      .icon-mks {
        height: 12px;
        width: 12px;
        position: absolute;
        bottom: 10px;
        right: -8px;
        margin-right: 0px;
      }
      &.selected {
        color: white;
        background: #411D39;
        border-radius:50%;
        border:1px solid #fff;
        .dot-text2{
          top: -4px !important;
          right: -4px !important;
          border:inherit !important;
        }
      }
        .dot-text2{
          top: -4px !important;
          right: -4px !important;
          border:inherit !important;
        }
      &.blocked {
        color: white;
        background: red;
        border-radius:50%;
        border: none;
      }
      &.blockedHour{
        color: white;
        background: red;
        border-radius:50%;
        border: 2px dotted white;
      }
      &.holiday {
        &::after{
          content: "";
          height: 3px;
          background: #CAD300;
          border-radius: 10px;
          border: none;
          width: 60%;
          position: absolute;

          bottom: 3px;
          margin: 0 auto;
        }
      }
      .daySelect{
        font-weight: 500;
      }
      .day{
        line-height: 0;
      }
    }
    .cell.date{
      position:relative;
      color:#fff;
      font-weight: normal;
      cursor:pointer;
      .dot-text2 {
        cursor:pointer;
        border: inherit !important;
        border-radius: 50%;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex-direction: column;
        position: absolute;

        padding: 6px 4px 7px 4px;
        line-height: 0;
        font-size: 7px;
        top: -3px;
        right: -3px;
        height: 15px;
        width: 15px;
        background: #411D39;
        transition: 0.1s;
      }
    }
  }

  .today{
    color: #fff;
    border: 1px solid #fff;
    position: absolute;

    top: 23px;
    right: 1rem;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 10px;
    .dot-text2{
      border: inherit;
    }
  }
  .load-reservations{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    width:100%;
    height:340px;
    p{
      color:#fff;
      font-weight:normal;
      font-size:20px;
    }
  }
}
@media screen and (max-width: 550px) {
    .tooltip-calendar {

      &[x-placement^="bottom"] {
        margin-right: 2rem !important;
        ::after, ::before {
          margin-left: 2.5rem !important;
        }
      }

    }
    .tooltip-calendar-left {
      &[x-placement^="bottom"] {
        left: -22px !important;
        ::after, ::before {
          margin-left: 0 !important;
        }
      }
    }
  }
</style>
