<template>
  <div class="container__navbar__options">
    <OptionGiftCard
      title="Nuevas"
      :checked="true"
      filterType="filterTypeNew"
      value="new"
      :status="status.status_new"
      @filterGiftCards="filterGiftCards"
    />
    <OptionGiftCard
      title="Parciales"
      filterType="filterTypePartial"
      value="partial"
      :status="status.status_partial"
      @filterGiftCards="filterGiftCards"
    />
    <OptionGiftCard
      title="Redimidas"
      filterType="filterTypeRedeem"
      value="redeem"
      :status="status.status_redeem"
      @filterGiftCards="filterGiftCards"
    />
    <OptionGiftCard
      title="Vencidas"
      filterType="filterTypeOverdue"
      value="overdue"
      :status="status.status_overdue"
      @filterGiftCards="filterGiftCards"
    />
    <OptionGiftCard
      :label_available="false"
      title="Configuración"
      @openModalGiftCardConfig="$emit('openModalGiftCardConfig')"
    />
  </div>
</template>

<script>
import OptionGiftCard from '@/components/gifCards_v2/atoms/option';

export default {
  name: 'NavBarOptions',
  components: { OptionGiftCard },
  props: ['status', 'filterType'],
  methods: {
    filterGiftCards (e) {
      this.$emit('filterGiftCards', e);
    }
  }
};
</script>

<style lang="scss">
@use "@/assets/styles/giftCards/_navbar_options";
</style>
