// import api from '@/helpers/api';
import serviceActionReservation from '@/helpers/apis/serviceActionReservation';
import serviceAvailability from '@/helpers/apis/serviceAvailability';
import serviceBlocking from '@/helpers/apis/serviceBlocking';
import serviceReport from '@/helpers/apis/serviceReport';
import serviceReservation from '@/helpers/apis/serviceReservation';
import serviceUser from '@/helpers/apis/serviceUser';
import serviceVendor from '@/helpers/apis/serviceVendor';
import reservationTypes from '@/store/types/reservation';
import serviceGiftCard from '@/helpers/apis/serviceGiftCard';
import serviceNotification from '@/helpers/apis/serviceNotification';
import servicewalkin from '@/helpers/apis/servicewalkin';
import serviceAdmin from '@/helpers/apis/serviceAdmin';

const state = {
  reservationDashboard: {
    people: 2,
    date: null,
    address: null,
    legalPersonName: null,
    isLegalPerson: false,
    temperature: null,
    hour: null,
    selectZone: [],
    selectSubzone: [],
    selectTable: [],
    displayName: '',
    typeReservation: 'Normal',
    email: null,
    identityDocument: null,
    typeIdentityDocument: 'CC',
    phone: null,
    indicativo: 57,
    pais: 'Colombia',
    paises: [],
    company: null,
    subscribed: null,
    bandera: 'https://api.precompro.com/banderas/co.png',
    amenities: [],
    comments: null,
    loading: false,
    errorMail: null,
    errorTel: null,
    hostId: null,
    referrer: null,
    originD: null,
    originP: null,
    newOriginP: null,
    newOriginD: null,
    experienceName: null,
    celebrationId: null,
    adjuntFile: null,
    tags: [],
    notes: [],
    sendToClient: 1,
    birthday: '',
    isVip: false,
    isYate: false
  },
  reservationDashboardClean: {
    people: 2,
    date: null,
    address: null,
    legalPersonName: null,
    isLegalPerson: false,
    temperature: null,
    hour: null,
    selectZone: [],
    selectSubzone: [],
    selectTable: [],
    displayName: '',
    typeReservation: 'Normal',
    email: null,
    identityDocument: null,
    typeIdentityDocument: 'CC',
    phone: null,
    indicativo: 57,
    pais: 'Colombia',
    paises: [],
    company: null,
    subscribed: null,
    bandera: 'https://api.precompro.com/banderas/co.png',
    amenities: [],
    comments: null,
    loading: false,
    errorMail: null,
    errorTel: null,
    hostId: null,
    referrer: null,
    originD: null,
    originP: null,
    newOriginP: null,
    newOriginD: null,
    experienceName: null,
    celebrationId: null,
    adjuntFile: null,
    tags: [],
    notes: [],
    sendToClient: 1,
    birthday: '',
    isVip: false,
    isYate: false
  },
  addReservation: false,
  addWalkIn: false,
  viewReservation: false,
  loadingReservation: false,
  editReservation: false,
  infoReservation: '',
  tables: [],
  groupVendor: [],
  groupUrlVendor: null,
  times: [],
  selectTableId: '',
  notifications: [],
  blockedHours: [],
  blocked: [],
  reservations: [],
  reservationsDay: [],
  availability: [],
  availabilityDate: [],
  selectTableInfo: {},
  requirements: [],
  sectionSelected: 0,
  tableSelected: 0,
  tableSelectedId: 0,
  type: '',
  map: true,
  list: false,
  schedules: [],
  apiReportDownload: null,
  typeApiReportDownload: null,
  selectedMonth: null,
  selectedDate: null,
  dateFilterSelect: [],
  sectionFilterSelect: [],
  showBlockedHours: false,
  showBlockedDay: false,
  showBlockedRange: false,
  verticalReservation: false,
  originVendor: [],
  originVendorPeople: [],
  updateSystem: [],
  allEvents: [],
  addOriginPeople: false,
  addOriginDetail: false,
  additionalQuestions: false,
  modules: [],
  rotation: [],
  sidelateral: false,
  sidelateralMove: false,
  sidelateralSearch: false,
  fromMap: false,
  theTurns: null,
  companionsData: null,
  frontModal: false,
  hourFromTimeLine: '',
  fromTimeLine: false,
  blockingDay: [],
  blockingTime: false,
  mealTypeDay: ''
};

const actions = {
  // [reservationTypes.actions.editValueConsumed]: (state, data) => {
  //   return new Promise((resolve, reject) => {
  //     api.post('dashboard/editValueConsumed', data.data)
  //       .then(response => {
  //         resolve(response);
  //       }).catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  [reservationTypes.actions.getModules]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.get('access/modules/' + data.data.userId + '/' + data.data.vendorId)
        .then(response => {
          commit(reservationTypes.mutations.setModules, response.data);
          window.localStorage.setItem('userModules', btoa(JSON.stringify(response.data)));
          resolve(response.data);
        }).catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.allEvents]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('vendors/allEvents', data.data)
        .then(response => {
          commit(reservationTypes.mutations.setAllEvents, response.data.data);
          resolve(response.data);
        }).catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.undoReservationStatusCancelled]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('undoStatus/cancelled', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getGroup]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.get('group/associates/' + data.data.vendorId)
        .then(response => {
          if (response.data.code === 200) {
            commit(reservationTypes.mutations.setGroupVendor, response.data.data);
          }
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.deleteReport]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.delete('deleteReport/' + data.data.url, data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.generateReportUsers]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('generateReportUserVendor', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.generateReportUsersEvent]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('generateReportUserVendorEvent', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getStatusReport]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.get(`/statusReport/${data.data.vendorId}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.generateReport]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('generateReport', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.shiftReport]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('shiftReport', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.generateReportPrevius]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('generateReportPrevius', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getRequirements]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('/requirements', data.data)
        .then(response => {
          commit(reservationTypes.mutations.setRequirements, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.setBlockedSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.setUnBlockedSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unblockedSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.setBlockedTableSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedTableSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.setUnBlockedTableSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unblockedTableSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.updateReservationWalkIn]: (state, data) => {
    return new Promise((resolve, reject) => {
      servicewalkin.put('updateWalkIn', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // [reservationTypes.actions.reservationEliminar]: (state, data) => {
  //   return new Promise((resolve, reject) => {
  //     api.post('dashboard/reservationEliminar', data.data)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  [reservationTypes.actions.getInfoTracking]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/tracking', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getReservationUser]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('/reservationUser', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getVendorGroups]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.get(`/getGroupForReport/${data.data.vendorId}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // [reservationTypes.actions.reservationEmail]: (state, data) => {
  //   return new Promise((resolve, reject) => {
  //     api.post('dashboard/reservationEmail', data.data)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  [reservationTypes.actions.getListUsers]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('getPeople', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // EN DESARROLLO
  [reservationTypes.actions.blockedRange]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedRange', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.notificationIsArch]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceNotification.post('/notificationIsArch', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.notificationIsSeen]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceNotification.post('/notificationIsSeen', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedDayTable]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedDayTable', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.unBlockedDayTable]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unBlockedDayTable', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedHourTable]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedHourTable', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.unBlockedHourTable]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unBlockedHourTable', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedHourSeatingSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedHourSeatingSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedHourSeatingSubSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedHourSeatingSubSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.unBlockedHourSeatingSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unBlockedHourSeatingSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.unBlockedHourSeatingSubSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unBlockedHourSeatingSubSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedDaySeatingSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedDaySeatingSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedDaySeatingSubSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedDaySeatingSubSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.unBlockedDaySeatingSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unBlockedDaySeatingSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.unBlockedDaySeatingSubSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unBlockedDaySeatingSubSection', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.searchHost]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('/searchHost', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.notifications]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceNotification.post('/notifications', data.data)
        .then(response => {
          commit(reservationTypes.mutations.setNotifications, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.notificationId]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceNotification.post('/notificationId', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.validateReservation]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('/validateReservation', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.addReservation]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('reservation/addReservation', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.addReservationLink]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('reservation/payment', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.addWalkIn]: (state, data) => {
    return new Promise((resolve, reject) => {
      servicewalkin.post('addWalkIn', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  },
  [reservationTypes.actions.updateReservation]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.put('reservation/updateReservation', data.data)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservationIsCancelled]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/cancelled', data.data)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservationIsNoShow]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/noShow', data.data)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservationIsConfirmed]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/confirmed', data.data)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservationIsSeated]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/seated', data.data)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservationIsSeatedPartial]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/partialSeated', data.data)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservationIsFinish]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/finish', data.data)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.undoReservationStatus]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/undoStatus', data.data)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedDay]: (state, date) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedDay', date)
        .then(blocked => {
          resolve(blocked);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.unBlockedDay]: (state, date) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('unBlockedDay', date)
        .then(unBlocked => {
          resolve(unBlocked);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedUnBlocked]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedUnBlocked', data.data)
        .then(blockeds => {
          resolve(blockeds);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.sectionStatus]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('section/dashboard', data.data)
        .then(section => {
          resolve(section);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.subSections]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('subSections', data.data).then(section => {
        resolve(section);
      }).catch(err => {
        reject(err);
      });
    });
  },
  [reservationTypes.actions.tablesSections]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('tablesSections', data.data, {
        cancelToken: data.data.source.token
      }).then(section => {
        resolve(section);
      }).catch(err => {
        reject(err);
      });
    });
  },
  [reservationTypes.actions.tables]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('tables', data.data)
        .then(table => {
          commit(reservationTypes.mutations.setTables, table.data);
          resolve(table);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.tableSection]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('tableSection', data.data)
        .then(table => {
          resolve(table);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blocked]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blocked', vendor.data)
        .then(response => {
          if (response.data.code === 200) {
            commit(reservationTypes.mutations.setBlocked, response.data.data);
          }
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.times]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('timeAtention', vendor.data)
        .then(response => {
          if (response.data.code === 200) {
            commit(reservationTypes.mutations.setTimes, response.data.data);
          }
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.blockedHours]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('blockedHours', vendor.data)
        .then(response => {
          if (response.data.code === 200) {
            commit(reservationTypes.mutations.setBlockedHours, response.data.data);
          }
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.schedules]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('/get/schedules', vendor.data)
        .then(response => {
          commit(reservationTypes.mutations.setSchedules, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.availability]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceAvailability.get('/availability/times/' + vendor.data.vendorId + '/' + vendor.data.date + '/' + vendor.data.people + '/1/Normal/null/false', vendor.data)
        .then(response => {
          commit(reservationTypes.mutations.setAvailability, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.availabilityDate]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceAvailability.post('/availability/date', vendor.data)
        .then(response => {
          commit(reservationTypes.mutations.setAvailabilityDate, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservations]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('dashboard/reservations', vendor.data)
        .then(response => {
          commit(reservationTypes.mutations.setReservations, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservationsDay]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('dashboard/reservationsDay', vendor.data)
        .then(response => {
          commit(reservationTypes.mutations.setReservationsDay, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.reservationNew]: (state, vendor) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('/reservationId', vendor.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getOriginVendor]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.get('/vendor/' + data.data.vendorId + '/origin')
        .then(response => {
          commit(reservationTypes.mutations.setOriginVendor, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getReferersVendors]: ({ Commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceAdmin.get('origin/referrers/' + data.data.vendorId + '/1')
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getOriginVendorPeople]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.get('/vendor/' + data.data.vendorId + '/origin/people')
        .then(response => {
          commit(reservationTypes.mutations.setOriginVendorPeople, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.notificationIsReviewed]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceNotification.post('/notificationIsReviewed', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.validateReservationTable]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('/validateReservationTable', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.addOriginPeople]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('/addOriginPeople', data.data)
        .then(response => {
          commit(reservationTypes.mutations.setAddOriginPeople, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.addOriginDetail]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('/addOriginDetail', data.data)
        .then(response => {
          commit(reservationTypes.mutations.setAddOriginDetail, response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // [reservationTypes.actions.reservationIsChefCooking]: (state, data) => {
  //   return new Promise((resolve, reject) => {
  //     api.post('dashboard/reservationIsChefCooking', data.data)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  [reservationTypes.actions.getAdditionalQuestions]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.get('/getAdditionalQuestions/' + data.data.vendorId)
        .then(response => {
          commit(reservationTypes.mutations.setAdditionalQuestions, response.data.data);
          resolve(response.data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.saveAnswersQuestions]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('/reservation/saveAdditionalQuestions', data.data)
        .then(response => { resolve(response.data.data); })
        .catch(err => { reject(err); });
    });
  },
  [reservationTypes.actions.getAnswers]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.get('reservation/getAnswersQuestions/' + data.data.reservationId)
        .then(response => { resolve(response.data.data); })
        .catch(err => { reject(err); });
    });
  },
  [reservationTypes.actions.getGiftCardVendor]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceGiftCard.post('/giftCards', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => { reject(err); });
    });
  },
  [reservationTypes.actions.getGiftCardConfig]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceGiftCard.get('/giftCard/' + data.data.vendorId)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => { reject(err); });
    });
  },
  // Nuevo microservice
  [reservationTypes.actions.updateGiftCardConfig]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceGiftCard.put('/giftCard/update', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => { reject(err); });
    });
  },
  // Nuevo microservice
  [reservationTypes.actions.newGiftCardConfig]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceGiftCard.post('/giftCard', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => { reject(err); });
    });
  },
  // Nuevo microservice
  [reservationTypes.actions.giftCardRedemption]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceGiftCard.post('/giftCard/redemption', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => { reject(err); });
    });
  },
  // No se usa
  // [reservationTypes.actions.validateGiftCard]: (state, data) => {
  //   return new Promise((resolve, reject) => {
  //     api.post('/reservation/validate/giftCard', data.data)
  //       .then(response => {
  //         resolve(response.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  [reservationTypes.actions.DragReservation]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceActionReservation.post('/drag/reservation', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getSearched]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('reservation/searchGeneral', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.theTurns]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.get('turns/' + data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.companions]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('/companions', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.companionUpdate]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.put('/companions/' + data.data.id, data.data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.companionDelete]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.delete('/companions/' + data.data.id)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.companionList]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.get('/companions/' + data.data.id)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [reservationTypes.actions.getRotation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.get('dashboard/rotation/' + data.data.vendorId)
        .then(response => {
          commit(reservationTypes.mutations.setRotation, response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  [reservationTypes.getters.modules]: state => state.modules,
  [reservationTypes.getters.rotation]: state => state.rotation,
  [reservationTypes.getters.allEvents]: state => state.allEvents,
  [reservationTypes.getters.groupUrlVendor]: state => state.groupUrlVendor,
  [reservationTypes.getters.groupVendor]: state => state.groupVendor,
  [reservationTypes.getters.typeApiReportDownload]: state => state.typeApiReportDownload,
  [reservationTypes.getters.apiReportDownload]: state => state.apiReportDownload,
  [reservationTypes.getters.sectionSelected]: state => state.sectionSelected,
  [reservationTypes.getters.tableSelected]: state => state.tableSelected,
  [reservationTypes.getters.tableSelectedId]: state => state.tableSelectedId,
  [reservationTypes.getters.editReservation]: state => state.editReservation,
  [reservationTypes.getters.viewReservation]: state => state.viewReservation,
  [reservationTypes.getters.infoReservation]: state => state.infoReservation,
  [reservationTypes.getters.type]: state => state.type,
  [reservationTypes.getters.addReservation]: state => state.addReservation,
  [reservationTypes.getters.addWalkIn]: state => state.addWalkIn,
  [reservationTypes.getters.tables]: state => state.tables,
  [reservationTypes.getters.notifications]: state => state.notifications,
  [reservationTypes.getters.selectTableInfo]: state => state.selectTableInfo,
  [reservationTypes.getters.selectTableId]: state => state.selectTableId,
  [reservationTypes.getters.blocked]: state => state.blocked,
  [reservationTypes.getters.times]: state => state.times,
  [reservationTypes.getters.blockedHours]: state => state.blockedHours,
  [reservationTypes.getters.availability]: state => state.availability,
  [reservationTypes.getters.reservations]: state => state.reservations,
  [reservationTypes.getters.reservationsDay]: state => state.reservationsDay,
  [reservationTypes.getters.schedules]: state => state.schedules,
  [reservationTypes.getters.loadingReservation]: state => state.loadingReservation,
  [reservationTypes.getters.selectedDate]: state => state.selectedDate,
  [reservationTypes.getters.selectedMonth]: state => state.selectedMonth,
  [reservationTypes.getters.reservation]: state => state.reservation,
  [reservationTypes.getters.map]: state => state.map,
  [reservationTypes.getters.list]: state => state.list,
  [reservationTypes.getters.dateFilterSelect]: state => state.dateFilterSelect,
  [reservationTypes.getters.sectionFilterSelect]: state => state.sectionFilterSelect,
  [reservationTypes.getters.showBlockedHours]: state => state.showBlockedHours,
  [reservationTypes.getters.showBlockedDay]: state => state.showBlockedDay,
  [reservationTypes.getters.showBlockedRange]: state => state.showBlockedRange,
  [reservationTypes.getters.verticalReservation]: state => state.verticalReservation,
  [reservationTypes.getters.requirements]: state => state.requirements,
  [reservationTypes.getters.originVendor]: state => state.originVendor,
  [reservationTypes.getters.originVendorPeople]: state => state.originVendorPeople,
  [reservationTypes.getters.updateSystem]: state => state.updateSystem,
  [reservationTypes.getters.addOriginPeople]: state => state.addOriginPeople,
  [reservationTypes.getters.addOriginDetail]: state => state.addOriginDetail,
  [reservationTypes.getters.additionalQuestions]: state => state.additionalQuestions,
  [reservationTypes.getters.sidelateral]: state => state.sidelateral,
  [reservationTypes.getters.sidelateralMove]: state => state.sidelateralMove,
  [reservationTypes.getters.sidelateralSearch]: state => state.sidelateralSearch,
  [reservationTypes.getters.fromMap]: state => state.fromMap,
  [reservationTypes.getters.theTurns]: state => state.theTurns,
  [reservationTypes.getters.companionsData]: state => state.companionsData,
  [reservationTypes.getters.reservationDashboard]: state => state.reservationDashboard,
  [reservationTypes.getters.reservationDashboardClean]: state => state.reservationDashboardClean,
  [reservationTypes.getters.frontModal]: state => state.frontModal,
  [reservationTypes.getters.getReservationHourTimeLine]: state => state.hourFromTimeLine,
  [reservationTypes.getters.fromTimeLine]: state => state.fromTimeLine,
  [reservationTypes.getters.blockingDay]: state => state.blockingDay,
  [reservationTypes.getters.blockingTime]: state => state.blockingTime,
  [reservationTypes.getters.mealTypeDay]: state => state.mealTypeDay
};

const mutations = {
  [reservationTypes.mutations.setModules] (state, data) {
    state.modules = data;
  },
  [reservationTypes.mutations.setFrontModal] (state, data) {
    state.frontModal = data;
  },
  [reservationTypes.mutations.setAllEvents] (state, data) {
    state.allEvents = data;
  },
  [reservationTypes.mutations.setUpdateSystem]: (state, data) => {
    state.updateSystem = data;
  },
  [reservationTypes.mutations.setGroupUrlVendor]: (state, data) => {
    state.groupUrlVendor = data;
  },
  [reservationTypes.mutations.setGroupVendor]: (state, data) => {
    state.groupVendor = data;
  },
  [reservationTypes.mutations.setTypeApiReportDownload]: (state, data) => {
    state.typeApiReportDownload = data;
  },
  [reservationTypes.mutations.setApiReportDownload]: (state, data) => {
    state.apiReportDownload = data;
  },
  [reservationTypes.mutations.setTableSelected]: (state, data) => {
    state.tableSelected = data;
  },
  [reservationTypes.mutations.setTableSelectedId]: (state, data) => {
    state.tableSelectedId = data;
  },
  [reservationTypes.mutations.setViewReservation]: (state, view) => {
    state.viewReservation = view;
  },
  [reservationTypes.mutations.setEditReservation]: (state, edit) => {
    state.editReservation = edit;
  },
  [reservationTypes.mutations.setInfoReservation]: (state, info) => {
    state.infoReservation = info;
  },
  [reservationTypes.mutations.setType]: (state, type) => {
    state.type = type;
  },
  [reservationTypes.mutations.setAddReservation]: (state, addReservation) => {
    state.addReservation = addReservation;
  },
  [reservationTypes.mutations.setFromMap]: (state, status) => {
    state.fromMap = status;
  },
  [reservationTypes.mutations.setSectionSelected]: (state, data) => {
    state.sectionSelected = data;
  },
  [reservationTypes.mutations.setCompanionsData]: (state, status) => {
    state.companionsData = status;
  },
  [reservationTypes.mutations.setAddWalkIn]: (state, addWalkIn) => {
    state.addWalkIn = addWalkIn;
  },
  [reservationTypes.mutations.setTables]: (state, tables) => {
    state.tables = [];
    state.tables.push(tables);
  },
  [reservationTypes.mutations.setNotifications]: (state, notification) => {
    state.notifications = notification;
  },
  [reservationTypes.mutations.setSelectTableId]: (state, selectTableId) => {
    state.selectTableId = selectTableId;
  },
  [reservationTypes.mutations.setSelectTableInfo]: (state, info) => {
    state.selectTableInfo = info;
  },
  [reservationTypes.mutations.setMap] (state, isMap) {
    state.map = isMap;
  },
  [reservationTypes.mutations.setList] (state, isList) {
    state.list = isList;
  },
  [reservationTypes.mutations.setBlocked] (state, blocked) {
    state.blocked = blocked;
  },
  [reservationTypes.mutations.setBlockedHours] (state, blockedHours) {
    state.blockedHours = blockedHours;
  },
  [reservationTypes.mutations.setReservations] (state, reservations) {
    state.reservations = reservations;
  },
  [reservationTypes.mutations.setSchedules] (state, schedules) {
    state.schedules = schedules;
  },
  [reservationTypes.mutations.setAvailability] (state, availability) {
    state.availability = availability;
  },
  [reservationTypes.mutations.setAvailabilityDate] (state, availability) {
    state.availabilityDate = availability;
  },
  [reservationTypes.mutations.setTimes] (state, times) {
    state.times = times;
  },
  [reservationTypes.mutations.setSelectedDate] (state, date) {
    state.selectedDate = date;
  },
  [reservationTypes.mutations.setSelectedMonth] (state, month) {
    state.selectedMonth = month;
  },
  [reservationTypes.mutations.setLoadingReservation] (state, loading) {
    state.loadingReservation = loading;
  },
  [reservationTypes.mutations.setReservation] (state, reservation) {
    state.reservation = reservation;
  },
  [reservationTypes.mutations.setReservationsDay] (state, reservationsDay) {
    state.reservationsDay = reservationsDay;
  },
  [reservationTypes.mutations.setDateFilterSelect] (state, dateFilterSelect) {
    state.dateFilterSelect.push(dateFilterSelect);
  },
  [reservationTypes.mutations.setResetDateFilterSelect] (state) {
    state.dateFilterSelect = [];
  },
  [reservationTypes.mutations.setSectionFilterSelect] (state, sectionFilterSelect) {
    state.sectionFilterSelect.push(sectionFilterSelect);
  },
  [reservationTypes.mutations.setResetSectionFilterSelect] (state) {
    state.sectionFilterSelect = [];
  },
  [reservationTypes.mutations.setShowBlockedHours] (state, showBlockedHours) {
    state.showBlockedHours = showBlockedHours;
  },
  [reservationTypes.mutations.setShowBlockedDay] (state, showBlockedDay) {
    state.showBlockedDay = showBlockedDay;
  },
  [reservationTypes.mutations.setShowBlockedRange] (state, showBlockedRange) {
    state.showBlockedRange = showBlockedRange;
  },
  [reservationTypes.mutations.setVerticalReservation] (state, verticalReservation) {
    state.verticalReservation = verticalReservation;
  },
  [reservationTypes.mutations.setRequirements] (state, data) {
    state.requirements = data;
  },
  [reservationTypes.mutations.setOriginVendor] (state, data) {
    state.originVendor = data;
  },
  [reservationTypes.mutations.setOriginVendorPeople] (state, data) {
    state.originVendorPeople = data;
  },
  [reservationTypes.mutations.setAddOriginPeople]: (state, addOriginPeople) => {
    state.addOriginPeople = addOriginPeople;
  },
  [reservationTypes.mutations.setAddOriginDetail]: (state, addOriginDetail) => {
    state.addOriginDetail = addOriginDetail;
  },
  [reservationTypes.mutations.setAdditionalQuestions]: (state, data) => {
    state.additionalQuestions = data;
  },
  [reservationTypes.mutations.setSideLateral] (state, SideStatus) {
    state.sidelateral = SideStatus;
  },
  [reservationTypes.mutations.setSideLateralMove] (state, SideMoveStatus) {
    state.sidelateralMove = SideMoveStatus;
  },
  [reservationTypes.mutations.setSideLateralSearch] (state, SideSearchStatus) {
    state.sidelateralSearch = SideSearchStatus;
  },
  [reservationTypes.mutations.setTurns]: (state, status) => {
    state.theTurns = status;
  },
  [reservationTypes.mutations.setRotation]: (state, rotation) => {
    state.rotation = rotation;
  },
  [reservationTypes.mutations.setReservationDashboard]: (state, reservationDashboard) => {
    state.reservationDashboard = reservationDashboard;
  },
  [reservationTypes.mutations.setReservationHourTimeLine]: (state, hour) => {
    state.hourFromTimeLine = hour;
  },
  [reservationTypes.mutations.setFromTimeLine]: (state, hour) => {
    state.fromTimeLine = hour;
  },
  [reservationTypes.mutations.setBlockingDay]: (state, blockingDay) => {
    state.blockingDay = blockingDay;
  },
  [reservationTypes.mutations.setBlockingTime]: (state, blocking) => {
    state.blockingTime = blocking;
  },
  [reservationTypes.mutations.setMealTypeDay]: (state, mealType) => {
    state.mealTypeDay = mealType;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
