<template>
  <div class="component-box">
    <div class="header-component">
      <em class="icon-mks black icon_person_add_reservation i-host-component"></em> Reserva creada por
    </div>
    <div class="host-component" :class="{one:listHost.length === 1,two:listHost.length === 2,three:listHost.length === 3,four:listHost.length === 4,five:listHost.length === 5,six:listHost.length === 6,six:listHost.length >= 7 }">
      <div class="host" v-for="(host,h) in listHost" :key="'Hosts' + h" @click="setHost(host,h)" :id="'host#'+h">
        {{ host.label }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FormReservationHost',
  props: {
    listHost: {
      type: Array
    }
  },
  data () {
    return {
      createdAt: null,
      hostValue: null
    };
  },
  methods: {
    setHost (host, h) {
      this.createdAt = null;
      this.$emit('getHostId', null);
      this.$emit('changeHiddenPasswordHost', false);
      const els = document.getElementsByClassName('active-host');
      if (els.length > 0) {
        for (const element of els) {
          element.classList.remove('active-host');
        }
      }
      const hostId = document.getElementById('host#' + h);
      // eslint-disable-next-line
      classie.add(hostId, 'active-host')
      if (host.pin !== null) {
        this.$emit('showPinHost', false);
        this.$emit('hostData', host);
        if (this.createdAt === host.value) {
          this.createdAt = null;
          this.$emit('getHostId', this.createdAt);
          this.$emit('createdAt', this.createdAt);
          this.$emit('hostValue', this.hostValue);
        } else {
          this.hostValue = host.value;
          this.$emit('showPinHost', true);
          this.$emit('hostData', host);
          this.$emit('createdAt', this.createdAt);
          this.$emit('hostValue', this.hostValue);
        }
      } else {
        this.$emit('showPinHost', false);
        this.$emit('hostData', host);
        this.$emit('createdAt', this.createdAt);
        this.$emit('hostValue', this.hostValue);
        if (this.createdAt === host.value) {
          this.createdAt = null;
        } else {
          this.createdAt = host.value;
        }
        this.$emit('getHostId', this.createdAt);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.host-component {
  display:grid;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  max-height: 150px;
  width: 100%;
  max-width: 100%;
  grid-template-columns:repeat(4, minmax(50px, 1fr));
  gap: 1px;
  background-color: #F0F1F2;
  &.one{
    grid-template-columns:repeat(1, minmax(50px, 1fr));
  }
  &.two{
    grid-template-columns:repeat(2, minmax(50px, 1fr));
  }
  &.three{
    grid-template-columns:repeat(3, minmax(50px, 1fr));
  }
  &.four{
    grid-template-columns:repeat(4, minmax(50px, 1fr));
  }
  @media screen and (min-width:600px) {
    &.five{
      grid-template-columns:repeat(5, minmax(50px, 1fr));
    }
    &.six{
      grid-template-columns:repeat(6, minmax(50px, 1fr));
    }
    &.seven{
      grid-template-columns:repeat(7, minmax(50px, 1fr));
    }
  }
  @media screen and (min-width:1000px) {
    &.five{
      grid-template-columns:repeat(5, minmax(50px, 1fr));
    }
    &.six{
      grid-template-columns:repeat(6, minmax(50px, 1fr));
    }
      &.seven{
      grid-template-columns:repeat(6, minmax(50px, 1fr));
    }
  }
  .host {
    background-color: white;
    font-size: 13px;
    padding: 3px 5px;
    text-align: center;
    width: 100%;
    height: 40px;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;
    &:hover {
      background-color: #444B57;
      color: #fff;
    }
  }
  .active-host {
    background-color: #444B57;
    color: #fff;
  }
}
.hostPin {
  .control {
    margin-top: 5px;
  }
  input {
    border-radius: 4px;
    &:focus{
      border-color: inherit;
    }
    &:active {
      border-color: rgba(155, 160, 165, 1) !important;
    }
  }
  .has-text-primary {
    color: rgba(164, 168, 172, 1) !important;
    height: 100%;
    width: 35px;
  }
}
.i-host-component {
  height: 12px;
  width: 12px;
  margin-top: 1px;
}
</style>
