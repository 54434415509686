<template>
  <div class="alert_request">
    <b-loading :active="loading" :can-cancel="false" :is-full-page="true"></b-loading>
    <div style="width: calc(100% - 30px); margin-top: 30px; margin-bottom: 5px; margin-right: unset;">
      <img src="@/assets/icons/icon-warning-outline-rounded.svg"/>
    </div>
    <div class="alert_request_tittle">Confirmar solicitud de reserva</div>
    <div class="alert_request_description">
      <span>¿Estás seguro que desean confirmar esta solicitud de reserva?</span>
    </div>
    <div class="alert_request_question">*Ten en cuenta que esta acción no se podrá deshacer, y el cliente será notificado al correo electrónico.</div>
    <div class="alert_request_action">
      <button @click="confirmRequest()" class="active">Confirmar</button>
      <button
        @click="$parent.close()">
        Cerrar
      </button>
    </div>
    <div></div>
  </div>
</template>
<script>
import serviceActionReservation from '@/helpers/apis/serviceActionReservation';

export default {
  props: ['id'],
  data () {
    return {
      loading: false
    };
  },
  methods: {
    confirmRequest () {
      this.loading = true;
      serviceActionReservation.post('/confirmed/request', { reservationId: this.id, userId: this.user.id }).then(({ data }) => {
        this.loading = false;
        this.$emit('confirmed', true);
        this.$parent.close();
      }).catch(() => {
        this.loading = false;
        this.$parent.close();
      });
    }
  }
};
</script>
<style lang="scss">
.alert_request {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background: #FFFFFF;
  color: #444B57;
  text-align: center;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.40);
  position: relative;
  max-width: max-content;
  @media screen and (max-width: 600px) {
    max-width: calc(100% - 30px);
  }
  &_tittle{
    color: #000;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 700;
  }
  &_description{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 96px);
    margin: 5px 0px 0px 0px;
    span {
      color: #444B57;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &_question{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 30px);
    color: #444B57;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.7;
    line-height: normal;
  }
  &_action{
    margin-top: 20px;
    margin-bottom: 30px;
    display:flex;
    justify-content: center;
    justify-content: center;
    align-items: center;
    width: calc(100% - 30px);
    gap: 30px;
    button {
      padding: 10px 12px;
      border-radius: 6px;
      border: 1px solid #444B57;
      color: #444B57;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
    .active {
      background: #444B57;
      color: #FFFFFF;
    }
  }
}
</style>
