<template>
  <div class="general-metrics-layer__container__charts-general__card chart-quantity-per-day" v-show="data.length > 0">
    <h2>{{titleChart}}</h2>
    <div class="general-metrics-layer__container__charts-general__card__filters" v-if="type === 'group' && enableFilters && data.length > 0" >
      <b-button
        class="general-metrics-layer__container__charts-general__card__filters__action"
        :class="{'general-metrics-layer__container__charts-general__card__filters__action--active' : filtersBy === 1}"
        @click="$emit('changeFilter', graph, 1)"
      >
        <i class="icon-mks icon_reservation"></i>
        <span>Reservas</span>
      </b-button>
      <b-button
        class="general-metrics-layer__container__charts-general__card__filters__action"
        :class="{'general-metrics-layer__container__charts-general__card__filters__action--active' : filtersBy === 2}"
        @click="$emit('changeFilter', graph, 2)"
      >
        <i class="icon-mks icon_total_users_default_new"></i>
        <span>Personas</span>
      </b-button>
    </div>
    <div class="container-graphs-info">
      <div class="chart-container" ref="chartContainer">
        <div class="tooltip-chart-bar" ref="tooltip"></div>
      </div>
      <Conventions
        v-if="enableConventions"
        :data="data"
        :dataConventions="dataConventions"
        :sectionId="sectionId"
        :generalData="generalData"
        :walkingData="walkingData"
        :filtersBy="filtersBy"
        :graph="graph"
        :colorsByDefault="colorsByDefault"
      />

    </div>
  </div>
</template>

<script>
import Conventions from '@/components/MetricsV2/Charts/Conventions';
import { mapGetters } from 'vuex';
import metricsTypes from '@/store/types/metrics';

export default {
  components: {
    Conventions
  },
  // type = tipo de gráfica, si es barras por grupo o individuales
  // graph = nos indica qué tipo de data en la gráfica viene, si es personas por grupo, por tiempo, etc
  props: ['sectionId', 'titleChart', 'filtersBy', 'data', 'dataConventions', 'graph', 'type', 'colors', 'walkingData', 'enableFilters', 'enableConventions', 'generalData'],
  data () {
    return {
      decimals: 1,
      colorsByDefault: {
        prepurchase: '#BDC30E',
        walkin: '#8a51b4',
        reservations: '#00ADC6'
      },
      chartData: [
      ],
      chartKey: 0
    };
  },
  mounted () {
    if (this.data.length > 0) {
      this.processData();
      this.createChart();
    }
  },
  computed: {
    ...mapGetters({
      year: [metricsTypes.getters.year]
    })
  },
  methods: {
    processData () {
      // si se requiere mover algún día, solo se necesita cambiar la posición del día, si quiero que comience de domingo a sábado, el arreglo sería ['DOM', 'LUN'] etc
      let groupAxisX = [];
      if (this.graph === 'perDay') {
        groupAxisX = ['LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB', 'DOM'];
        if (this.year <= 2022) {
          groupAxisX = ['FRI', 'MON', 'SAT', 'SUN', 'THU', 'TUE', 'WED'];
        }
      }
      if (this.graph === 'perGroup') {
        groupAxisX = ['0-5', '6-12', '+12'];
      }
      if (this.graph === 'perTime') {
        for (let index = 1; index < 16; index++) {
          groupAxisX.push(`${index}`);
        }
        groupAxisX.push('16+');
      }
      // Crear la estructura de chartData
      this.chartData = groupAxisX.map((day) => {
        const values = [];
        this.data.forEach((entry) => {
          if (entry.data && entry.data[day]) {
            values.push({
              value: entry.data[day],
              color: entry.color,
              name: entry.name // Incluye el nombre
            });
          }
        });
        return {
          day,
          values
        };
      });
    },
    defineShadowForBars (elementSvg) {
      // Definir el filtro para la sombra
      const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
      const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
      filter.setAttribute('id', 'dropShadow');
      filter.setAttribute('x', '-20%');
      filter.setAttribute('y', '-20%');
      filter.setAttribute('width', '140%');
      filter.setAttribute('height', '140%');

      const feFlood = document.createElementNS('http://www.w3.org/2000/svg', 'feFlood');
      feFlood.setAttribute('flood-opacity', '0');
      feFlood.setAttribute('result', 'BackgroundImageFix');
      filter.appendChild(feFlood);

      const feColorMatrix = document.createElementNS('http://www.w3.org/2000/svg', 'feColorMatrix');
      feColorMatrix.setAttribute('in', 'SourceAlpha');
      feColorMatrix.setAttribute('type', 'matrix');
      feColorMatrix.setAttribute('values', '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0');
      feColorMatrix.setAttribute('result', 'hardAlpha');
      filter.appendChild(feColorMatrix);

      const feOffset = document.createElementNS('http://www.w3.org/2000/svg', 'feOffset');
      feOffset.setAttribute('dx', '2');
      feOffset.setAttribute('dy', '2');
      filter.appendChild(feOffset);

      const feGaussianBlur = document.createElementNS('http://www.w3.org/2000/svg', 'feGaussianBlur');
      feGaussianBlur.setAttribute('stdDeviation', '2');
      filter.appendChild(feGaussianBlur);

      const feComposite = document.createElementNS('http://www.w3.org/2000/svg', 'feComposite');
      feComposite.setAttribute('in2', 'hardAlpha');
      feComposite.setAttribute('operator', 'out');
      filter.appendChild(feComposite);

      const feColorMatrix2 = document.createElementNS('http://www.w3.org/2000/svg', 'feColorMatrix');
      feColorMatrix2.setAttribute('type', 'matrix');
      feColorMatrix2.setAttribute('values', '0 0 0 0 0.266667 0 0 0 0 0.294118 0 0 0 0 0.341176 0 0 0 0.25 0');
      filter.appendChild(feColorMatrix2);

      const feBlend = document.createElementNS('http://www.w3.org/2000/svg', 'feBlend');
      feBlend.setAttribute('mode', 'multiply');
      feBlend.setAttribute('in2', 'BackgroundImageFix');
      feBlend.setAttribute('result', 'effect1_dropShadow');
      filter.appendChild(feBlend);

      const feBlend2 = document.createElementNS('http://www.w3.org/2000/svg', 'feBlend');
      feBlend2.setAttribute('mode', 'normal');
      feBlend2.setAttribute('in', 'SourceGraphic');
      feBlend2.setAttribute('in2', 'effect1_dropShadow');
      feBlend2.setAttribute('result', 'shape');
      filter.appendChild(feBlend2);

      defs.appendChild(filter);
      elementSvg.appendChild(defs);
    },
    defineGradient (elementSvg, color1, color2) {
      const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
      const gradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
      gradient.setAttribute('id', `grad-${color1}-${color2}`);
      gradient.setAttribute('x1', '0%');
      gradient.setAttribute('y1', '0%');
      gradient.setAttribute('x2', '0%');
      gradient.setAttribute('y2', '100%');

      const stop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
      stop1.setAttribute('offset', '0%');
      stop1.setAttribute('style', `stop-color: ${color1}; stop-opacity: 1`);
      const stop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
      stop2.setAttribute('offset', '100%');
      stop2.setAttribute('style', `stop-color: ${color2}; stop-opacity: 1`);

      gradient.appendChild(stop1);
      gradient.appendChild(stop2);
      defs.appendChild(gradient);
      elementSvg.appendChild(defs);
    },
    getColorByNameDefault (type) {
      const colorMap = {
        Reservas: this.colorsByDefault.reservations,
        reservations: this.colorsByDefault.reservations,
        Precompras: this.colorsByDefault.prepurchase,
        precompra: this.colorsByDefault.prepurchase,
        'Walk-in': this.colorsByDefault.walkin,
        walkIn: this.colorsByDefault.walkin
      };
      return colorMap[type] || '';
    },
    createChart () {
      const existingSvg = this.$refs.chartContainer.querySelector('svg');
      if (existingSvg) {
        existingSvg.remove();
      }
      const windowWidth = window.innerWidth;

      // Ajustar el tamaño del gráfico según el ancho de la ventana
      let width = windowWidth <= 1440 ? 555 : 755; // Si el ancho es menor que 1440, el gráfico será más pequeño
      let height = windowWidth <= 1440 ? 200 : 300; // Ajustar la altura también

      if (this.graph === 'perTime') {
        width = windowWidth <= 1280 ? 800 : 1100;
        height = windowWidth <= 1280 ? 243 : 343;
        if (this.sectionId === 4) {
          width = windowWidth <= 1280 ? 635 : 835;
        }
      }

      let margin = { top: 20, right: 20, bottom: 20, left: 40 };
      if (this.graph === 'perTime') {
        margin = { top: 20, right: 10, bottom: 50, left: 50 };
      }

      // Calcular dimensiones internas del gráfico
      const innerWidth = width - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;
      // Definir el rango máximo y el intervalo de los ticks del eje Y
      const maxDataValue = Math.max(...this.chartData.flatMap((d) => d.values.map(v => v.value)));
      let yInterval = 0;
      if (this.type === 'group') {
        yInterval = maxDataValue > 150 && maxDataValue < 500 ? 150 : (maxDataValue > 500 ? 700 : 30);
      }
      if (this.type === 'single') {
        yInterval = maxDataValue > 30 ? 50 : 5;
      }
      const maxValue = yInterval * Math.ceil(maxDataValue / yInterval);
      // Crear el contenedor SVG
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('width', width);
      svg.setAttribute('height', height);

      // creo las sombras de las barras
      this.defineShadowForBars(svg);

      // Crear grupo para el gráfico
      const chartGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
      chartGroup.setAttribute('transform', `translate(${margin.left},${margin.top})`);
      svg.appendChild(chartGroup);

      // Crear línea vertical del eje Y
      if (this.type === 'group') {
        const yAxisLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        yAxisLine.setAttribute('x1', 0);
        yAxisLine.setAttribute('y1', -21);
        yAxisLine.setAttribute('x2', 0);
        yAxisLine.setAttribute('y2', innerHeight);
        yAxisLine.setAttribute('stroke', '#a8adb580');
        yAxisLine.setAttribute('stroke-width', '1');
        chartGroup.appendChild(yAxisLine);
      }

      // Crear línea horizontal del eje X
      const xAxisLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      xAxisLine.setAttribute('x1', 0);
      xAxisLine.setAttribute('y1', innerHeight);
      xAxisLine.setAttribute('x2', innerWidth + 8);
      xAxisLine.setAttribute('y2', innerHeight);
      xAxisLine.setAttribute('stroke', '#a8adb580');
      xAxisLine.setAttribute('stroke-width', '1');
      chartGroup.appendChild(xAxisLine);

      // Tamaño fuente
      let fontSizeTags = '11';
      if (this.type === 'single' || (this.type === 'group' && this.sectionId === 4)) {
        fontSizeTags = 8;
      }

      // Escala de X y Y
      const xScale = innerWidth / this.chartData.length;
      const yScale = maxValue !== 0 ? innerHeight / maxValue : 0;

      const offSetValues = 11;
      // Espacio deseado entre el eje Y y la primera barra
      const yAxisPadding = 7; // Ajusta este valor según el espacio que quieras

      // Crear las barras
      this.chartData.forEach((dataPoint, dayIndex) => {
        const barGroupWidth = xScale * 0.8; // Espacio total para las barras de un día
        const singleBarWidth = Math.min(barGroupWidth / dataPoint.values.length, 13); // Ancho de cada barra
        const totalBarsWidth = singleBarWidth * dataPoint.values.length; // Ancho total de las barras en el día
        const offsetX = (barGroupWidth - totalBarsWidth) / 2; // Margen para centrar las barras
        dataPoint.values.forEach((data, categoryIndex) => {
          const barHeight = data.value * yScale;
          const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          rect.setAttribute(
            'x',
            dayIndex * xScale + xScale * 0.1 + offsetX + categoryIndex * singleBarWidth + yAxisPadding
          );

          rect.setAttribute('y', innerHeight - barHeight - offSetValues);
          rect.setAttribute('width', singleBarWidth - 2); // Deja un pequeño espacio entre barras
          rect.setAttribute('height', barHeight);

          if (this.graph === 'perTime' && this.sectionId !== 4) {
            // Definir el gradiente de color para cada barra
            const gradientId = `grad-${this.colors[0]}-${this.colors[1]}`;
            this.defineGradient(svg, this.colors[0], this.colors[1]);
            rect.setAttribute('fill', `url(#${gradientId})`); // Usar el gradiente
          } else {
            /* this.colorsByDefault */
            if (this.sectionId === 4) {
              rect.setAttribute('fill', this.getColorByNameDefault(data.name)); // Usar el color proporcionado en los datos
            } else {
              if (this.sectionId === 2) {
                rect.setAttribute('fill', this.colorsByDefault.prepurchase); // Usar el color proporcionado en los datos
              } else {
                rect.setAttribute('fill', data.color); // Usar el color proporcionado en los datos
              }
            }
          }
          // redondeo de esquinas, barras
          rect.setAttribute('rx', 2);
          rect.setAttribute('ry', 2);
          rect.setAttribute('filter', 'url(#dropShadow)');
          rect.setAttribute('style', 'cursor: pointer;');
          // Tooltip events
          rect.addEventListener('mouseenter', (event) =>
            this.showTooltip(event, data)
          );
          rect.addEventListener('mousemove', (event) =>
            this.moveTooltip(event)
          );
          rect.addEventListener('mouseleave', (event) => {
            this.hideTooltip();
          });

          chartGroup.appendChild(rect);
        });
      });

      // Crear el eje X
      this.chartData.forEach((dataPoint, index) => {
        const xPos = index * xScale + xScale / 2 + yAxisPadding; // Ajusta también el texto
        const xPosLines = index * xScale + xScale + yAxisPadding;

        // Etiqueta de día en el eje X
        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', xPos);
        text.setAttribute('y', innerHeight + 20);
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('font-size', fontSizeTags);
        text.setAttribute('fill', 'gray');
        text.textContent = dataPoint.day.charAt(0).toUpperCase() + dataPoint.day.slice(1).toLowerCase();
        chartGroup.appendChild(text);

        if (this.type === 'group') {
          // Línea vertical en el eje X (debajo de cada barra)
          const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
          line.setAttribute('x1', xPosLines);
          line.setAttribute('y1', innerHeight);
          line.setAttribute('x2', xPosLines);
          line.setAttribute('y2', innerHeight + 5);
          line.setAttribute('stroke', 'lightgray');
          line.setAttribute('stroke-width', '1');
          chartGroup.appendChild(line);
        }
      });

      // Crear el eje Y con etiquetas de valor y líneas de cuadrícula
      for (let i = 0; i <= maxValue / yInterval; i++) {
        const safeMaxValue = maxValue === 0 ? 1 : maxValue;
        const safeYInterval = yInterval === 0 ? 1 : yInterval;
        const yPos = innerHeight - (innerHeight / (safeMaxValue / safeYInterval)) * i;
        const value = yInterval * i;

        // Etiqueta de valor en el eje Y
        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', -10);
        text.setAttribute('y', yPos + 5 - offSetValues);
        text.setAttribute('text-anchor', 'end');
        text.setAttribute('font-size', fontSizeTags);
        text.setAttribute('fill', 'gray');
        text.textContent = value;
        chartGroup.appendChild(text);
        if (this.type === 'group') {
          // Línea horizontal en el eje Y
          if (i >= 0) {
            const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
            line.setAttribute('x1', -6);
            line.setAttribute('y1', yPos - offSetValues);
            line.setAttribute('x2', 0);
            line.setAttribute('y2', yPos - offSetValues);
            line.setAttribute('stroke', 'lightgray');
            chartGroup.appendChild(line);
          }
        }
      }

      if (this.graph === 'perTime') {
        // Crear el texto "Personas" debajo del eje X (centrado)
        const xAxisText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        xAxisText.setAttribute('x', innerWidth / 2 + margin.left - 50); // Centrado horizontalmente
        xAxisText.setAttribute('y', innerHeight + 40); // Ajuste vertical
        xAxisText.setAttribute('text-anchor', 'middle');
        xAxisText.setAttribute('font-size', '10');
        xAxisText.setAttribute('fill', '#bcbfc6');
        xAxisText.textContent = 'Personas';
        chartGroup.appendChild(xAxisText);

        // Crear el texto "Tiempo horas" al lado del eje Y (centrado)
        const yAxisText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        yAxisText.setAttribute('x', -innerHeight / 2); // Ajuste horizontal
        yAxisText.setAttribute('y', -40); // Centrado verticalmente
        yAxisText.setAttribute('text-anchor', 'middle');
        yAxisText.setAttribute('font-size', '10');
        yAxisText.setAttribute('fill', '#bcbfc6');
        yAxisText.setAttribute('transform', 'rotate(-90)'); // Rotar el texto
        yAxisText.textContent = 'Tiempo horas';
        chartGroup.appendChild(yAxisText);
      }
      // Añadir el SVG al contenedor
      this.$refs.chartContainer.appendChild(svg);
    },
    showTooltip (event, data) {
      const tooltip = this.$refs.tooltip;
      const chartContainer = this.$refs.chartContainer.getBoundingClientRect();
      const bar = event.target.getBoundingClientRect(); // Obtener las coordenadas de la barra interactuada.
      tooltip.style.display = 'block';
      tooltip.textContent = `${data.name}: ${data.value}`;
      // Calcular posición del tooltip relativo al contenedor de la gráfica.
      tooltip.style.left = `${bar.left - chartContainer.left + bar.width / 2}px`;
      tooltip.style.top = `${bar.top - chartContainer.top - tooltip.offsetHeight - 5}px`;
    },
    moveTooltip (event) {
      const tooltip = this.$refs.tooltip;
      const chartContainer = this.$refs.chartContainer.getBoundingClientRect();
      tooltip.style.left = `${event.clientX - chartContainer.left + 50}px`;
      tooltip.style.top = `${event.clientY - chartContainer.top - tooltip.offsetHeight + 20}px`;
    },
    hideTooltip () {
      const tooltip = this.$refs.tooltip;
      tooltip.style.display = 'none';
    }
  },
  watch: {
    data (value) {
      if (value.length > 0) {
        this.processData();
        this.createChart();
        this.chartKey++;
      } else {
        this.chartData = [];
        const existingSvg = this.$refs.chartContainer.querySelector('svg');
        if (existingSvg) {
          existingSvg.remove();
        }
      }
    },
    filtersBy () {
      this.processData();
      this.createChart();
    },
    sectionId (value) {
      this.processData();
      this.createChart();
      this.chartKey = value;
    }
  }
};
</script>

<style lang="scss">
  @use "@/assets/styles/metrics/charts/_bar_prec";
</style>
