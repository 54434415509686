<template>
  <div class="SectionDivider">
    <div class="SectionDivider-heading">
      <h1 class="SectionDivider-headingTitle">{{title}}</h1>
    </div>
    <div class="SectionDivider-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title']
};
</script>

<style lang="scss" scoped>
.SectionDivider {
  margin-bottom: 2.5rem;
  &-heading {
    border-bottom: 1px solid #f1f1f1;
    margin: 0.5em 0px 1.5em;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #dadada;
      position: absolute;
      bottom: 0;
    }
    &:after {
      content: "";
      display: FLEX;
      width: 50px;
      height: 1px;
      background-color: #05121D;
      position: absolute;
      bottom: 0;
    }
  }
  &-headingTitle {
    font-family: "Open Source Sans Pro" sans-serif !important;
    font-size: 1.5em;
    letter-spacing: 0.08em;
    font-weight: 300;
    color: #05121D;
    margin: 0;
    padding: 0;
    text-align: left;
    text-transform: uppercase;
  }
}
</style>
