const verifyUser = (user) => {
  if (user.isAdmin === 1) {
    return;
  }
  if (!user?.vendors?.length) {
    window.localStorage.clear();
    window.location.href = '/dashboard/login';
    return;
  }
  let vendorId = window.localStorage.getItem('_id');
  if (!vendorId) {
    window.localStorage.clear();
    window.location.href = '/dashboard/login';
    return;
  }
  vendorId = parseInt(vendorId, 10);
  let exists = false;
  user.vendors.forEach((vendor) => {
    if (vendor.vendorId === vendorId) exists = true;
  });

  if (!exists) {
    window.localStorage.clear();
    window.location.href = '/dashboard/login';
  }
};

export default verifyUser;
