<template>
  <div id="host">
    <div class="columns is-desktop styles-columns">
      <b-tabs class="tabMenuCustom" v-model="tabActive">
        <b-tab-item label="Host">
          <div class="column">
            <div class="UserList-toolbar columns is-mobile styles-columns">
              <div class="column is-narrow">
                <b-field>
                  <b-button
                    @click="add"
                    class="is-fullwidth"
                    icon-left="account-plus"
                    type="is-primary"
                  >Agregar Host</b-button>
                </b-field>
              </div>
            </div>
            <div class="column">
              <b-table :data="hosts" :paginated="paginated" :per-page="perPage" ref="hosts">
                <template slot-scope="props">
                  <b-table-column
                    centered
                    field="name"
                    label="Nombres"
                    sortable
                  >{{ props.row.name }}</b-table-column>
                  <b-table-column
                    centered
                    field="pin"
                    label="Pin"
                    sortable
                    width="100"
                  >{{ props.row.pin }}</b-table-column>
                  <b-table-column
                    centered
                    field="userId"
                    label="UserId"
                    sortable
                    width="100"
                  >{{ props.row.userId }}</b-table-column>
                  <b-table-column centered label="Estado">
                    <span
                      class="tag is-success"
                      style="font-weight: bold;"
                      v-if="props.row.isActive"
                    >Activo</span>
                    <span
                      class="tag is-danger"
                      style="font-weight: bold;"
                      v-if="!props.row.isActive"
                    >Inactivo</span>
                  </b-table-column>
                  <b-table-column centered label="Estado">
                    <b-tooltip
                      label="Habilitar"
                      position="is-bottom"
                      type="is-primary "
                      v-if="!props.row.isActive"
                    >
                      <b-button
                        @click="statusUserListHost(props.row)"
                        icon-left="check-circle"
                        label
                        size="is-small"
                        type="is-success"
                        v-if="!props.row.isActive"
                      ></b-button>&nbsp;
                    </b-tooltip>
                    <b-tooltip
                      label="Deshabilitar"
                      position="is-bottom"
                      type="is-primary "
                      v-if="props.row.isActive"
                    >
                      <b-button
                        @click="statusUserListHost(props.row)"
                        icon-left="delete-empty"
                        label
                        size="is-small"
                        type="is-danger"
                        v-if="props.row.isActive"
                      />&nbsp;
                    </b-tooltip>
                    <b-tooltip label="Editar" position="is-bottom" type="is-primary ">
                      <b-button
                        @click="edit(props.row)"
                        icon-left="pencil"
                        size="is-small"
                        type="is-info"
                      ></b-button>
                    </b-tooltip>
                  </b-table-column>
                </template>
              </b-table>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Usuario">
          <SectionDivider title="Listado de Usuarios">
            <div class="column" style="margin-top: 30px;">
              <div class="column">
                <b-table
                  :data="users"
                  :default-sort="['id', 'asc']"
                  :hoverable="hoverable"
                  :loading="isLoading"
                  :paginated="paginated"
                  :per-page="perPage"
                  :show-detail-icon="showDetailIcon"
                  custom-detail-row
                  detail-key="id"
                  detailed
                >
                  <template slot-scope="props">
                    <b-table-column field="id" label="User Id" sortable width="300">
                      <template v-if="showDetailIcon">{{ props.row.id }}</template>
                      <template v-else>
                        <a @click="toggle(props.row)">{{ props.row.id }}</a>
                      </template>
                    </b-table-column>
                    <b-table-column
                      field="name"
                      label="Nombres"
                      sortable
                      width="300"
                    >{{ props.row.name }}</b-table-column>
                    <b-table-column
                      field="email"
                      label="Email"
                      sortable
                      width="300"
                    >{{ props.row.email }}</b-table-column>
                    <b-table-column
                      field="clientIp"
                      label="Ips Conectadas"
                      sortable
                      width="300"
                    >{{ props.row.clientIp }}</b-table-column>
                    <b-table-column field="isConnected" label="Estado" sortable width="300">
                      <b-tag type="is-success" v-if="props.row.isConnected">
                        <b-icon class="mr-5" icon="check-circle" size="is-small"></b-icon>Conectado
                      </b-tag>
                      <b-tag type="is-danger" v-else>
                        <b-icon class="mr-5" icon="close-circle" size="is-small"></b-icon>Desconectado
                      </b-tag>
                    </b-table-column>
                    <b-table-column
                      field="phone"
                      label="Teléfono"
                      sortable
                      width="300"
                    >{{ props.row.phone }}</b-table-column>
                    <b-table-column centered label="Opciones">
                      <b-button
                        @click="addHostFromList(props.row)"
                        class="mr-5 mt-5"
                        icon-left="plus-circle"
                        size="is-small"
                        type="is-primary"
                        v-tooltip="{content: 'Agregar Host', placement: 'bottom'}"
                      ></b-button>
                    </b-table-column>
                  </template>
                  <template slot="detail" slot-scope="props">
                    <tr
                      style="background-color: #000;"
                      v-if="Object.keys(props.row.items).length > 0"
                    >
                      <th
                        class="has-text-centered"
                        colspan="6"
                        style="color: #fff; font-weight: bold;"
                      >Listado de host asignados</th>
                    </tr>
                    <tr v-if="Object.keys(props.row.items).length > 0">
                      <th></th>
                      <th>Id Host</th>
                      <th class="has-text-centered">Nombres</th>
                      <th>Pin</th>
                      <th>Opciones</th>
                    </tr>
                    <tr :key="'items#' + item.id" v-for="item in props.row.items">
                      <td></td>
                      <td>{{ item.id }}</td>
                      <td class="has-text-centered">{{ item.name }}</td>
                      <td>{{ item.password }}</td>
                      <td>
                        <b-tooltip
                          label="Habilitar"
                          position="is-bottom"
                          type="is-primary "
                          v-if="!item.isActive"
                        >
                          <b-button
                            @click="statusUserListHost(item)"
                            icon-left="check-circle"
                            label
                            size="is-small"
                            type="is-success"
                            v-if="!item.isActive"
                          ></b-button>&nbsp;
                        </b-tooltip>
                        <b-tooltip
                          label="Desabilitar"
                          position="is-bottom"
                          type="is-primary "
                          v-if="item.isActive"
                        >
                          <b-button
                            @click="statusUserListHost(item)"
                            icon-left="delete-empty"
                            label
                            size="is-small"
                            type="is-danger"
                            v-if="item.isActive"
                          />&nbsp;
                        </b-tooltip>
                        <b-tooltip label="Editar" position="is-bottom" type="is-primary ">
                          <b-button
                            @click="edit(item)"
                            icon-left="pencil"
                            size="is-small"
                            type="is-info"
                          ></b-button>
                        </b-tooltip>
                      </td>
                    </tr>
                    <tr v-if="Object.keys(props.row.items).length == 0">
                      <td colspan="6" style="font-weight: bold;">No hay información para mostrar.</td>
                    </tr>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                        </p>
                        <p>Nothing here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </div>
          </SectionDivider>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import SectionDivider from '@/components/_shared/SectionDivider.vue';
import UserEdit from '@/components/schedules/host/UserEdit.vue';
import HostList from '@/components/schedules/host/HostList.vue';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import serviceUser from '@/helpers/apis/serviceUser';
import _ from 'lodash';
export default {
  name: 'HostVue',
  props: ['restaurant'],
  components: { SectionDivider },
  data () {
    return {
      userAdd: {},
      users: [],
      hosts: [],
      isLoading: false,
      showDetailIcon: true,
      perPage: 10,
      paginated: true,
      hoverable: true,
      defaultOpenedDetails: [1],
      tabActive: 0
    };
  },
  mounted () {
    this.list(this.restaurant.vendorId);
    this.listHost(this.restaurant.vendorId);
  },
  computed: {
    ...mapGetters({
      restaurantData: [globalTypes.getters.vendor]
    })
  },
  methods: {
    listHost (id) {
      this.$nextTick().then(() => {
        serviceUser.get('/restaurants/' + id + '/dashboard/hosts/').then(response => {
          const hosts = this.userAdd.items;
          const info = response.data.filter(host => {
            if (!_.find(hosts, { name: host.name })) {
              return host;
            }
          });
          this.hosts = info;
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
            type: 'is-danger'
          });
        });
      });
    },
    list (id) {
      this.isLoading = true;
      serviceUser.get('/restaurants/' + id + '/members/').then(response => {
        this.isLoading = false;
        this.users = response.data;
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    },
    add () {
      var instance = this;
      instance.userAdd = {
        name: '',
        password: '',
        email: '',
        phone: null,
        indicativo: '57',
        bandera: null,
        created_at: null,
        type: 'host',
        vendorId: instance.restaurant.vendorId,
        modifyBy: 'Sistema',
        modules: []
      };
      instance.$buefy.modal.open({
        parent: instance,
        props: {
          title: 'new_user',
          userAdd: instance.userAdd,
          restaurant: null
        },
        events: {
          update () {
            instance.list(instance.restaurant.vendorId);
            instance.listHost(instance.restaurant.vendorId);
          },
          updateUserAdd (value) {
            instance.userAdd = value;
          }
        },
        component: UserEdit,
        hasModalCard: true
      });
    },
    edit (user) {
      user.password = '';
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        props: {
          title: 'edit_user',
          userAdd: user,
          restaurant: instance.restaurant.vendorId
        },
        events: {
          update () {
            instance.list(instance.restaurant.vendorId);
            instance.listHost(instance.restaurant.vendorId);
          },
          updateUserAdd (value) {
            instance.userAdd = value;
          }
        },
        component: UserEdit,
        hasModalCard: true
      });
    },
    addHostFromList (user) {
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        props: {
          id: instance.restaurant.vendorId,
          userAdd: user
        },
        events: {
          update () {
            instance.list(instance.restaurant.vendorId);
            instance.listHost(instance.restaurant.vendorId);
          }
        },
        component: HostList,
        hasModalCard: true,
        canCancel: false
      });
    },
    statusUserListHost (user) {
      let status = 1;
      let msg = `Está seguro que desea habilitar el host <br><strong>${user.name}</strong>?<br>`;
      if (parseInt(user.isActive)) {
        status = 0;
        msg = `Está seguro que desea deshabilitar el host <br><strong>${user.name}</strong>?<br>`;
      }
      this.$buefy.dialog.confirm({
        message: msg,
        cancelText: 'No',
        confirmText: (!status) ? 'Si, deshabilitar' : 'Si, habilitar',
        type: (!status) ? 'is-danger' : 'is-success',
        hasIcon: true,
        icon: 'help-circle',
        onConfirm: () => {
          serviceUser.put('/restaurants/members/' + this.restaurant.vendorId + '/' + user.id + '/host/' + status).then(() => {
            this.$buefy.toast.open({
              message: 'Se ha actualizado el host',
              type: 'is-success'
            });
            this.list(this.restaurant.id);
            this.listHost(this.restaurant.vendorId);
          }).catch(() => {
            this.$buefy.toast.open({
              message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
              type: 'is-danger'
            });
          });
        }
      });
    },
    deleteHost (user) {
      const msg = `Está seguro que desea borrar el host <br><strong>${user.name}</strong>?<br>`;
      this.$buefy.dialog.confirm({
        message: msg,
        cancelText: 'No',
        confirmText: 'Si, borrar',
        type: 'is-danger',
        hasIcon: true,
        icon: 'help-circle',
        onConfirm: () => {
          user.vendorId = this.restaurant.vendorId;
          serviceUser.post('/restaurants/members/' + this.restaurant.vendorId + '/delete', user).then(() => {
            this.$buefy.toast.open({
              message: 'Se ha eliminado el host',
              type: 'is-success'
            });
            this.list(this.restaurant.id);
            this.listHost(this.restaurant.vendorId);
          }).catch(() => {
            this.$buefy.toast.open({
              message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
              type: 'is-danger'
            });
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
#host {
  margin-top: 52px;
  overflow: auto;
  height: calc(100vh - 60px);
  .styles-columns {
    margin-top: 60px;
    padding: 10px 10px;
  }
  .UserList {
    &-toolbarTitle {
      border-left: 2px solid #b5b5b5;
      color: #6c757d;
      font-weight: lighter;
      padding: 6px 10px;
    }
  }
  @media (max-width: 1608px) and (min-width: 768px) {
    .button.is-small {
      border-radius: 2px;
      font-size: 0.75rem;
      width: 100%;
      margin-bottom: 1px;
    }
  }
  .mr-5 {
    margin-right: 5px !important;
  }
  .mt-5 {
    margin-top: 5px !important;
  }
  .ml-5 {
    margin-left: 5px;
  }
}
</style>
