<template>
  <div>
    <CardsQualification
      :checkbox="checkQuestions"
      :comments="comments"
      :generalVendorQualify="generalVendorQualify"
      :pullStars="pullStars"
      :pullStarsLength="pullStarsLength"
      :qualify="qualify"
      :users="users"
      icon="general_qualification"
      title="Calificación general"
    />
    <div class="toolbar-poll-v2__container_filters responsive d-flex">
      <button
        @click="openModalDates2"
        class="toolbar-poll-v2__container_filters__button_dates responsive js-modal-trigger"
        type="button"
      >Buscar por fecha</button>
      <div
        @mouseleave="$emit('mouseOut','dateBy')"
        class="toolbar-poll-v2__container_filters__select_date_by"
      >
        <div
          :data-list="selectDateByConfig.options"
          :data-value="selectDateByConfig.value"
          @focusout="localSelectDateByConfig.visible = !localSelectDateByConfig.visible"
          class="aselect"
        >
          <div @click="toggleDateBy()" class="selector">
            <div class="label date_by responsive">
              <span>{{selectDateByConfig.value}}</span>
            </div>
            <div
              :class="selectDateByConfig.visible ? 'expanded' : ''"
              class="arrow_single responsive"
            ></div>
            <div :class="localSelectDateByConfig.visible ? '': 'hidden'">
              <ul class="responsive date_by">
                <li
                  :class="{ current_dateby : item.name === selectDateByConfig.value }"
                  :key="index"
                  @click="selectOrdeBy(item.value, item.name, 'dateby')"
                  class="date_by"
                  v-for="(item, index) in selectDateByConfig.options"
                >{{item.name}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        @mouseleave="$emit('mouseOut','orderBy')"
        class="toolbar-poll-v2__container_filters__select_order_by"
      >
        <div
          :data-list="selectOrdeByConfig.options"
          :data-value="selectOrdeByConfig.value"
          @focusout="localSelectOrdeByConfig.visible = !localSelectOrdeByConfig.visible"
          class="aselect"
        >
          <div @click="$emit('toggle','orderBy')" class="selector">
            <div
              :style="!selectOrdeByConfig.visible ? {'background' : '#767c86', 'color': '#fff'}  : ''"
              class="label responsive"
            >
              <span>{{selectOrdeByConfig.value}}</span>
            </div>
            <div :class="!selectOrdeByConfig.visible ? 'expanded' : ''" class="arrow responsive"></div>
            <div :class="selectOrdeByConfig.visible ? '': 'hidden'">
              <ul class="responsive">
                <li
                  :class="{ current : item.name === selectOrdeByConfig.value }"
                  :key="index"
                  @click="selectOrdeBy(item.value, item.name, 'orderBy')"
                  v-for="(item, index) in selectOrdeByConfig.options"
                >{{item.name}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CardsQualification
      :checkbox="!pollData ? null : pollData.checkQuestions"
      :comments="!pollData ? null : pollData.comments"
      :generalVendorQualify="!pollData ? null : pollData.generalVendorQualify"
      :pullStars="!pollData ? null : pollData.pullStars"
      :pullStarsLength="!pollData ? null : pollData.pullStarsLength"
      :qualify="!pollData ? null : pollData.qualify"
      :users="!pollData ? null : pollData.users"
      icon="avg_qualification"
      title="Calificación promedio"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import pollTypes from '@/store/types/poll';
import CardsQualification from '@/components/polls_v2/molecules/cardsQualification';
import { mapGetters } from 'vuex';
import globalTypes from '@/store/types/global';

export default {
  name: 'PollQualification',
  props: ['title', 'icon', 'group', 'DayPollFilter', 'selectOrdeByConfig', 'selectDateByConfig'],
  computed: {
    ...mapGetters({
      pollData: pollTypes.getters.pollData
    })
  },
  components: {
    CardsQualification
  },
  data () {
    return {
      localSelectDateByConfig: { ...this.selectDateByConfig }, // Copia de `selectDateByConfig`
      localSelectOrdeByConfig: { ...this.selectOrdeByConfig }, // Copia de `selectOrdeByConfig`
      localGroup: this.group, // Copia de `group`
      localDayPollFilter: this.DayPollFilter, // Copia de `DayPollFilter`
      isOpen: false,
      chartOptions: {
        chart: {
          id: 'basic-bar'
        },
        labels: ['Wspp', 'Instagram', 'TripAdvisor', 'Facebook qweqweq', 'Web', 'Otro'],
        dataLabels: {
          enabled: true,
          distributed: false,
          style: {
            fontSize: '10px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: '500',
            colors: ['#fff']
          },
          formatter: function (val, opts) {
            return val.toFixed(0);
          }
        },
        legend: {
          position: 'left',
          width: '110',
          floating: false,
          fontSize: '8px',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 4,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 70
          }
        }
      },
      options: {
      },
      series: [44, 55, 41, 17, 20, 50],
      idPoll: 0,
      polls: [],
      allPolls: [],
      error: null,
      pollDescription: null,
      qualify: 0,
      comments: 0,
      users: 0,
      pollIdEncrypt: null,
      paginatorData: [],
      checkQuestions: {},
      pullStars: null,
      pullStarsLength: null,
      generalVendorQualify: null,
      filterPollBy: null,
      filterDate: null,
      filterDateEnd: null,
      dayPollFilter2: 7,
      group2: ''
    };
  },
  mounted () {
    this.$store
      .dispatch({ type: 'poll:list', data: { vendorId: this.vendor.id } })
      .then(({ data }) => {
        if (data.code === 200) {
          this.polls = data.data;
          if (this.polls.length > 0) {
            this.idPoll = this.polls[0].id;
            this.$store.commit(
              pollTypes.mutations.setPollId,
              this.idPoll
            );
            this.getPollInfo(this.idPoll);
          }
        } else {
          this.error = data.message;
        }
      });
    if (this.pollData == null || this.pollData.generalVendorQualify === 0) {
      this.$store
        .dispatch({ type: 'poll:list', data: { vendorId: this.vendor.id } })
        .then(({ data }) => {
          if (data.code === 200) {
            this.polls = data.data;
            if (this.polls.length > 0) {
              this.idPoll = this.polls[0].id;
              this.getPollInfo(this.idPoll);
            }
          } else {
            this.error = data.message;
          }
        });
    }
  },
  methods: {
    validateNotShowVendorTemporal () {
      const id = this.decryptMx(this.vendorId);
      const index = [143, 144, 145, 226].find(vendor => vendor === id);
      if (index >= 0) {
        return;
      }
      return true;
    },
    getPollInfo (id) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.$store
        .dispatch({
          type: 'poll:generalPollData',
          data: {
            pollId: id
          }
        })
        .then(({ data }) => {
          if (data.data) {
            this.generalVendorQualify = data.data.generalVendorQualify;
            this.checkQuestions = data.data.checkbox;
            this.qualify = data.data.qualify;
            this.comments = data.data.comments;
            this.users = data.data.users;
            this.allPolls = data.data;
            let index = 0;
            const chunkSize = 5;
            const arrayLength = Object.keys(data.data.stars).length;
            const tempArray = [];
            for (index = 0; index < arrayLength; index += chunkSize) {
              const myChunk = Object.entries(data.data.stars).slice(
                index,
                index + chunkSize
              );
              tempArray.push(myChunk);
            }
            this.pullStars = tempArray;
            this.pullStarsLength = Object.keys(data.data.stars).length;
            this.$emit(
              'poll-exist',
              this.polls.length,
              this.paginatorData,
              this.pollIdEncrypt
            );
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
    },
    openModalDates2 () {
      this.$emit('openModalDates');
    },
    selectOrdeBy (value, text, type) {
      this.$emit('selectOrdeBy', value, text, type);
    },
    toggle (type) {
      this.$emit('toggle', type);
    },
    toggleDateBy () {
      this.localSelectDateByConfig.visible = !this.localSelectDateByConfig.visible;
      this.$emit('update-select-date-by-config', this.localSelectDateByConfig);
    },
    toggleOrdeBy () {
      this.localSelectOrdeByConfig.visible = !this.localSelectOrdeByConfig.visible;
      this.$emit('update-select-ord-by-config', this.localSelectOrdeByConfig);
    },
    toggleGroup () {
      this.localGroup = !this.localGroup; // Alternar el valor de localGroup (si es booleano)
      this.$emit('update-group', this.localGroup); // Emitir el cambio de group al componente padre
    },
    toggleDayPollFilter () {
      this.localDayPollFilter = !this.localDayPollFilter; // Alternar el valor de localDayPollFilter (si es booleano)
      this.$emit('update-day-poll-filter', this.localDayPollFilter); // Emitir el cambio de DayPollFilter al componente padre
    }
  },
  watch: {
    idPoll () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });

      if (this.idPoll > 0) {
        const id = parseInt(this.idPoll);
        const info = _.map(this.polls, (item) => {
          if (parseInt(item.id) === id) {
            return item;
          }
        });
        this.pollDescription = info[0].description;
        this.$store.commit(
          pollTypes.mutations.setPollDescription,
          info[0].description
        );
        this.pollIdEncrypt = this.encrypt(id);
        this.$store
          .dispatch({
            type: 'poll:result',
            data: { pollId: this.pollIdEncrypt, dayRangeFilter: 7 }
          })
          .then(({ data }) => {
            if (data.code === 200) {
              this.paginatorData = data.data.reservation;
              this.$store.commit(
                pollTypes.mutations.setPollInformation,
                data.data.reservation.data
              );
              this.getPollInfo(this.idPoll);
            } else {
              this.error = data.message;
            }
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          });
      } else {
        this.error = null;
        this.pollDescription = null;
        this.comments = 0;
        this.qualify = 0;
        this.users = 0;
        this.$store.commit(pollTypes.mutations.setPollInformation, []);
        this.$emit('poll-exist', 0, [], 0);
      }
    },
    group2 () {
      this.localGroup = this.group2;
      this.$emit('update-group', this.localGroup);
    },
    dayPollFilter2 () {
      this.localDayPollFilter = this.dayPollFilter2;
      this.$emit('update-day-poll-filter', this.localDayPollFilter); // Emitir un evento para informar al padre
    },
    selectDateByConfig: {
      deep: true,
      handler (newVal) {
        this.localSelectDateByConfig = { ...newVal };
      }
    },
    selectOrdeByConfig: {
      deep: true,
      handler (newVal) {
        this.localSelectOrdeByConfig = { ...newVal };
      }
    },
    group (newVal) {
      this.localGroup = newVal;
    },
    DayPollFilter (newVal) {
      this.localDayPollFilter = newVal;
    }
  }
};
</script>
