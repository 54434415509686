<template>
  <div id="sidenav-precompro" class="menu-wrap" :class="{isHiddenNav:!showSide}">
    <nav
      class="menu-elastic"
      :style="{ '--backgroundColor': vendorParams.sidenavColor || '#444b57'}"
    >
      <div class="icon-list">
        <div class="client" :class="{opened:isOpen}" style="position: relative;">
          <div class="clientImg" @click="openChooseVendor()">
            <img :src="vendor.thumbnailURL" />
            <div v-if="vendor.sede" style="width: 25px;height: 25px;display: flex;justify-content: center;align-items: center;flex-shrink: 0;background-color: white;border-radius: 50%;position: absolute;z-index: 9;">
              <span style="color: #000;text-align: center;font-family: 'Source Sans Pro';font-size: 15px;font-style: 'normal';font-weight: 600;line-height: 'normal';">{{getInitials(vendor.sede, 1)}}</span>
            </div>
          </div>
          <h2 v-show="isOpen">{{vendorName}}<br>
            <span v-show="vendor.sede">{{ vendor.sede }}</span>
          </h2>
        </div>
        <UserEl v-if="vendor && user" :isOpen="isOpen"></UserEl>
        <div class="cont-menu">
          <button
            class="menu-button"
            id="open-button"
            @click="toggleMenu"
          ><div></div>
          <div></div>
          <div></div>
          </button>
        </div>
        <div class="fatherNavURLContainer">
          <div class="topArrow" v-show="overflowMainBar && !showDown"></div>
          <div class="bottomArrow"  v-show="overflowMainBar && showDown"></div>
          <section class="navURLContainer" :class="{opened:isOpen}" ref="scrollContainerSideNavbar" @scroll="manageScroll">
            <a
              @click="toUrl('/dashboard/reservations')"
              :class="{ active: $route.path.split('/')[2] === 'reservations' }"
              v-if="validateAccessModule('reservations')"
            >
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'reservations',
                }"
              >
                <i class="icon-mks white icon_reservation" :style="{width:isOpen ? '60%' : '88%',transform :isOpen ? 'translateY(2px)' : ''}" ></i>
              </div>
              <span v-show="isOpen">Reservas</span>
            </a>
            <a
              @click="toUrl('/dashboard/users')"
              :class="{ active: $route.path.split('/')[2] === 'users' }"
              v-if="validateAccessModule('users')"
            >
              <div
                :class="{ icon_selected: $route.path.split('/')[2] === 'users' }"
              >
                <i class="icon_menu_user_loading icon_menu_dinamic" v-if="iconMenuDataCombine === 1"></i>
                <i class="icon_menu_user_fail icon_menu_dinamic" v-else-if="iconMenuDataCombine === 2"></i>
                <i class="icon_menu_user icon_menu_dinamic" v-else-if="iconMenuDataCombine === 3"></i>
                <i class="icon-mks white icon_users" v-else></i>
              </div>
              <span v-show="isOpen">Usuarios</span>
            </a>
            <!-- <a
              @click="toUrl('/dashboard/metrics_old')"
              :class="{ active: $route.path.split('/')[2] === 'metrics_old' }"
            >
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'metrics_old',
                }"
              >
                <i class="icon-mks white icon_metrics" style="max-width:37px;"></i>
              </div>
              <span v-show="isOpen">Metricas old</span>
            </a> -->
            <a
              @click="toUrl('/dashboard/metrics')"
              :class="{ active: $route.path.split('/')[2] === 'metrics' }"
              v-if="validateAccessModule('metrics')"
            >
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'metrics',
                }"
              >
                <i class="icon-mks white icon_metrics" style="max-width:37px;"></i>
              </div>
              <span v-show="isOpen">Metricas</span>
            </a>
            <a
              @click="toUrl('/dashboard/polls')"
              :class="{ active: $route.path.split('/')[2] === 'polls' }"
              v-if="validateAccessModule('polls')"
            >
              <div
                :class="{ icon_selected: $route.path.split('/')[2] === 'polls' }"
              >
                <i class="icon-mks white icon_polls"></i>
              </div>
              <span v-show="isOpen">Encuestas</span>
            </a>
            <a
              @click="toUrl('/dashboard/searchs')"
              :class="{ active: $route.path.split('/')[2] === 'searchs' }"
              v-if="validateAccessModule('searchs')"
            >
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'searchs',
                }"
              >
                <i class="icon-mks white icon_consult"></i>
              </div>
              <span v-show="isOpen">Consultas</span>
            </a>
            <a
              @click="toUrl('/dashboard/giftCards')"
              :class="{ active: $route.path.split('/')[2] === 'giftCards' }"
              v-if="validateAccessModule('giftCards')"
            >
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'giftCards',
                }"
              >
                <i class="icon-mks white icon_gift"></i>
              </div>
              <span v-show="isOpen">GiftCards</span>
            </a>
            <a
              @click="toUrl('/dashboard/schedules')"
              :class="{ active: $route.path.split('/')[2] === 'schedules' }"
              v-if="validateAccessModule('schedules')"
              class="icon_config"
            >
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'schedules',
                }"
              >
                <i class="icon-mks white icon_config_schedule"></i>
              </div>
              <span v-show="isOpen">Configuración</span>
            </a>
            <a
              @click="toUrl('/dashboard/contactless')"
              :class="{ active: $route.path.split('/')[2] === 'contactless' }"
              v-if="validateAccessModule('contactless')"
              class="icon_config"
            >
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'contactless',
                }"
              >
                <i class="icon-mks white icon_contactless_payment"></i>
              </div>
              <span v-show="isOpen">Pago sin Contacto</span>
            </a>
            <a
              @click="toUrl('/dashboard/marketing')"
              :class="{ active: $route.path.split('/')[2] === 'marketing' }"
              v-if="validateAccessModule('marketing')"
              class="icon_config"
            >
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'marketing',
                }"
              >
                <i class="icon-mks white icon_config_marketing"></i>
              </div>
              <span v-show="isOpen">Marketing</span>
            </a>
            <a
              @click="loadPDF"
              :class="{ active: activeTermsIcon }"
              v-if="allowTermsConditionsIfExist"
            >
              <div
                :class="{
                  icon_selected: activeTermsIcon,
                }"
              >
                <i class="icon-mks white icon_terms_conditions" :style="{width:isOpen ? '60%' : '88%',transform :isOpen ? 'translateY(2px)' : ''}" ></i>
              </div>
              <span v-show="isOpen">Términos</span>
            </a>
            <a
              @click="changeVendorUrl()"
              v-if="(vendorsForSwitch.length > 1 || user.isAdmin) && isDashboardDomain()"
            >
              <div>
                <i class="icon_shop white icon-mks"></i>
              </div>
              <span v-show="isOpen">Cambiar</span>
            </a>
            <a
              @click="toUrl('/dashboard/onboarding')"
              :class="{ active: $route.path.split('/')[2] === 'onboarding' }"
              v-if="validateAccessModule('onboarding')"
              class="icon-onboarding-sidebar"
            >

              <div class="icon-onboarding-sidebar__activity" v-if="newThingsOnboarding"></div>
              <div
                :class="{
                  icon_selected: $route.path.split('/')[2] === 'onboarding',
                }"
              >
                <i class="icon-mks white icon_onboarding"></i>
              </div>
              <span v-show="isOpen">Onboarding</span>
            </a>
          </section>
        </div>
        <a class="close-button" id="close-button" @click="closeSideBar"></a>
        <section class="lastSection" :class="{opened:isOpen}">
          <section>
            <a class="btn-fullsize" @click="toggleFull"
              ><i class="icon_fullscream icon-mks white"></i
            ></a>
            <a class="close-logout" @click="logOutModal()">
              <span class="material-icons-outlined">
                power_settings_new
              </span>
            </a>
            <span v-if="getVersionApp && isOpen" class="version">{{ getVersionApp }}</span>
          </section>
        </section>
      </div>
    </nav>
  </div>
</template>
<script>
import _ from 'lodash';
import UserEl from '@/components/_shared/User';
import ChooseVendorAuth from '@/components/_shared/ChooseVendorAuth';
import Sesion from '@/components/_shared/Sesion';
import { mapGetters } from 'vuex';
import globalTypes from '@/store/types/global';
import authTypes from '@/store/types/auth';
import ModalPdfViewerVue from '@/components/_shared/ModalPdfViewer';
import serviceAdmin from '@/helpers/apis/serviceAdmin';

export default {
  name: 'SidenavVue',
  components: {
    UserEl
  },

  data () {
    return {
      collapse: true,
      isOpen: false,
      isAnimating: false,
      isOpenMobile: false,
      overflowMainBar: false,
      showDown: true,
      vendorsForSwitch: [],
      setChooseVendor: globalTypes.mutations.setChooseVendor,
      setMenuDataCombine: globalTypes.mutations.setMenuDataCombine,
      activeTermsIcon: false,
      allowTermsConditionsIfExist: false,
      newThingsOnboarding: false,
      iconMenuDataCombine: 0
    };
  },
  mounted () {
    this.getCustomPDF();
    this.getGeneralDataByTypesForOnboarding();
    var bodyEl = document.body;
    // eslint-disable-next-line
    classie.remove(bodyEl, "show-menu");
    if (window.innerWidth <= 600) {
      this.toggleMenu();
    }
    const scrollContainer = this.$refs.scrollContainerSideNavbar;
    const instance = this;
    const observer = new ResizeObserver(function (entries) {
      entries.forEach(function () {
        instance.overflowMainBar = scrollContainer.scrollHeight > scrollContainer.clientHeight;
      });
    });
    observer.observe(scrollContainer);
    const vendors = localStorage.getItem('_vendors');
    this.vendorsForSwitch = JSON.parse(atob(vendors));
  },
  computed: {
    menuDataCombine () {
      return globalTypes.getters.menuDataCombine;
    },
    ...mapGetters({
      showSide: [globalTypes.getters.showSideBar],
      menuDataCombine: [globalTypes.getters.menuDataCombine]
    }),
    vendorName () {
      if (this.vendor) {
        return this.vendor.displayName;
      } else {
        return 'Restaurante';
      }
    }
  },
  methods: {
    manageScroll (e) {
      const element = e.target;
      if (this.hayEspacioParaScroll(element)) {
        this.showDown = true;
      } else {
        this.showDown = false;
      }
    },
    hayEspacioParaScroll (elemento) {
      return elemento.scrollTop + elemento.clientHeight < (elemento.scrollHeight - 5);
    },
    toLink (url) {
      if (url !== this.$route.fullPath) {
        window.location.href = url;
      }
    },
    logOutModal () {
      this.$buefy.modal.open({
        parent: this,
        component: Sesion,
        hasModalCard: true
      });
    },
    getName (nameVendor) {
      if (nameVendor.length <= 5) {
        return nameVendor;
      } else {
        return nameVendor.substr(0, 5) + '...';
      }
    },
    TogleMobile () {
      this.isOpenMobile = !this.isOpenMobile;
    },
    isResponsive () {
      if (window.innerWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    toggleFull () {
      this.$emit('change-full', true);
    },
    changeVendorUrl () {
      this.$buefy.modal.open({
        parent: this,
        component: ChooseVendorAuth,
        hasModalCard: true,
        canCancel: false,
        customClass: 'backBlack'
      });
    },
    changeVendor () {
      this.activeTermsIcon = false;
      if (this.isOpen) {
        if (window.innerWidth > 500) {
          this.toggleMenu();
        }
      }
      this.$store.commit(authTypes.mutations.setShowChangedVendor, true);
    },
    toUrl (url) {
      this.activeTermsIcon = false;
      const routePath = this.$route.fullPath;
      if (url !== routePath) {
        window.location.href = url;
      }
    },
    validateAccessModule (module) {
      if (this.user.isAdmin) {
        return true;
      } else {
        const exists = _.find(this.modules, {
          name: module
        });
        if (exists) {
          return true;
        }
      }
    },
    closeSideBar () {
      if (window.innerWidth > 600) {
        this.toggleMenu();
      } else {
        this.$store.commit(globalTypes.mutations.setShowSideBar, false);
      }
    },
    toggleMenu () {
      var bodyEl = document.body;
      if (this.isOpen) {
        // eslint-disable-next-line
        classie.remove(bodyEl, "show-menu");
        setTimeout(function () {
          this.isAnimating = false;
        }, 300);
      } else {
        // eslint-disable-next-line
        classie.add(bodyEl, "show-menu");
      }
      this.isAnimating = !this.isAnimating;
      this.isOpen = !this.isOpen;
    },
    openChooseVendor () {
      this.$store.commit(globalTypes.mutations.setChooseVendor, true);
    },
    loadPDF () {
      this.activeTermsIcon = false;
      const thes = this;
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: ModalPdfViewerVue,
        hasModalCard: true,
        customClass: 'custom-load-pdf',
        trapFocus: true,
        props: {
          newTermsForOldUsers: thes.newTermsForOldUsers,
          onlyView: true
        },
        events: {
          registerDateDownloadFile (data) {
            thes.$store.dispatch({ type: 'global:trackInfoDownloadTermsConditions', data }).then(resp => {
              if (resp.data.code === 200) {
                console.log('ok track download');
              } else {
                console.log('error track download');
              }
            });
          },
          trackUserAcceptTermsConditions () {
          }
        }
      });
    },
    getCustomPDF () {
      this.loading = true;
      this.$store.dispatch({ type: 'global:getCustomPDF', data: { vendorId: this.vendor.id, restaurantName: this.vendor.displayName, userDateCreated: this.$moment(this.user.created_at).format('DD/MM/YYYY') } }).then(resp => {
        const { data } = resp;
        if (resp.status === 200) {
          if (data.link) {
            this.allowTermsConditionsIfExist = true;
          } else {
            this.allowTermsConditionsIfExist = false;
          }
          this.loading = false;
        } else {
          this.$buefy.toast.open({
            message: data.message,
            type: 'is-danger'
          });
        }
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    isDashboardDomain () {
      const vendorUrl = this.subDomain();
      return !!vendorUrl;
    },
    getValidationTagNew (date) {
      const creationDate = this.$moment(date).format('YYYY/MM/DD');
      const actualDate = this.$moment().format('YYYY/MM/DD');
      const days = this.$moment(actualDate).diff(this.$moment(creationDate), 'days');
      this.isNewOnboarding = false;
      if (days <= 30) {
        return true;
      } else {
        return false;
      }
    },
    getGeneralDataByTypesForOnboarding () {
      serviceAdmin.get('/onboarding').then(({ data }) => {
        const generalData = data.filter((onboarding) => onboarding.isActive);
        // Verificar y actualizar según el tipo y la validación
        this.newThingsOnboarding = false;
        const somethingNew = generalData.some(onboarding => this.getValidationTagNew(onboarding.created_at));
        if (somethingNew) {
          this.newThingsOnboarding = true;
        }
      }).catch((error) => {
        this.loading = false;
        console.log(error);
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    }
  },
  watch: {
    menuDataCombine: {
      handler (value) {
        if (this.menuDataCombine.processed < this.menuDataCombine.lengthToProcess) {
          this.iconMenuDataCombine = 1;
        } else if (this.menuDataCombine?.error) {
          this.iconMenuDataCombine = 2;
        } else if (!this.menuDataCombine || (Array.isArray(this.menuDataCombine) && this.menuDataCombine.length === 0)) {
          this.iconMenuDataCombine = 0;
        } else if (this.menuDataCombine?.processed && this.menuDataCombine.processed === this.menuDataCombine.lengthToProcess) {
          this.iconMenuDataCombine = 3;
        } else {
          this.iconMenuDataCombine = 0;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.menu-wrap.isHiddenNav{
  @media screen and (max-width:600px) {
    display: none;
  }

}
#close-button{
  border: unset;
}
.menu-wrap{
a:hover{
  color: white;
  i:not(.icon_menu_dinamic){
    background-color: black;
  }
}
}

.lastSection{
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
  justify-content: flex-end;
  min-height: 83px;
  max-height: 83px;
  section{
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 100%;
    justify-content: space-evenly;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: solid 1px white;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    min-height: 80px;
  }
  .version{
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-family: Source Sans Pro;
    min-width: 50%;
  }
  .btn-fullsize , .close-logout{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      height: 80%;
      width: 80%;
    }
  }
  .close-logout{
    i{
      width: 30%;
    }
  }
  &.opened{
    section{
    flex-wrap: wrap;
    flex-direction: row;
    }

    a{
      width:45%;
      i{
        mask-size: contain;
      }
      span{
        font-size: 27px;
      }
    }
  }
}
.icon-list{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.fatherNavURLContainer{
  display: flex;
  position: relative;
  width: calc(100% - 2px);
  margin-right: 2px;
  flex: 1 1 auto;
  min-height: 10px;
  .topArrow,.bottomArrow{
    z-index: 100;
    position: absolute;
    right: 5px;
    display: block;
    width: 8px;
    height: 8px;
    border-top: solid 2px white;
    border-left: solid 2px white;
    border-top-left-radius: 3px;
    opacity: 0.9;
  }
  .topArrow{
    top: 5px;
    transform: rotate(45deg);
  }
  .bottomArrow{
    bottom: 5px;
    transform: rotate(-135deg);
  }
}
.navURLContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  width:100%;
  max-height:100%;
    &.opened{
      a{
        &.active{
          color: black;
        }
        div{
          width:50px;
          height: 66%;
        }
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
      }
      span{
        font-size: 14px;
        font-family: Source Sans Pro;
      }
    }
  a {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  i,div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: unset;
    width: 70%;
    height: 70%;
    mask-size: contain;
    mask-position: center;
    max-height: 60px;
  }
  i{
    width: 100%;
    height: 100%;
    max-height: 28px;
    max-width: 55px;
    margin-right: unset !important;
    transform: scale(0.75);
    &.icon_polls{

      transform: scale(0.8);
    }
    &.icon_gift{
      transform: scale(0.8);
    }
    &.icon_metrics{
      transform: scale(0.62);
    }
  }
  }
  &::-webkit-scrollbar {
  width: 1px  !important;
}

/* Track */
&::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.079) !important
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: white !important;
  border-radius: 2px !important;
}
  a{
    cursor: pointer;
    width: 100%;
    justify-content: center;
    background-color: var(--backgroundColor);
    &.active,&:hover{
      opacity: 0.9;
      background-color: white;
      span{
        color:black;
      }
      i:not(.icon_menu_dinamic){
        background-color: black;
      }
    }
  }
}

.modal {
  z-index: 999999 !important;
}

.close-logout-white {
  color: white;
}

.modal-endSesion {
  display: flex;
  justify-content: center;
  .modal-content {
    max-width: 500px;
  }
  .modal-card-head {
    border-radius: 0px !important;
    background: #444b57 !important;
    .modal-card-title {
      color: white !important;
      text-align: center !important;
    }
  }
  .column {
    &.center {
      text-align: -webkit-center;
    }
  }
  .text-center {
    text-align: center;
  }
  .btn {
    display: flex;
    justify-content: center;
    border: 1px solid #707070;
    color: white;
    background-color: #444b57;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 30px 8px 30px;
    width: max-content;
    margin: 10px;
    align-items: center;
    &.width-porcentaje {
      width: 70%;
    }
  }
}

.icon-list {
  .icon_selected {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0;
  }
}
.icon_config_schedule {
  width: 35px !important;
  height: 35px !important;
  max-height: 35px !important;
}
.clientImg{
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  img{
    object-fit: cover;
    min-width: 26px;
    min-height: 26px;
  }
}
.icon-onboarding-sidebar{
  position: relative;
  & > div:first-child.icon-onboarding-sidebar__activity{
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #CCD600;
    right: 13px;
    top: 13px;
  }
}

.backBlack {
  .modal-background {
    background-color: rgba(10, 10, 10, 0.86)!important;
  }
}
</style>
