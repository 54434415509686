<template>
  <div class="component-box">
    <div class="header-component">
      <i class="icon-mks black icon_zone_add_reservation i-subzone-component"></i>Sub zona</div>
    <div class="subzone-component" v-if="zoneId.length > 0" :class="{one:subZoneList.length === 1,two:subZoneList.length === 2,three:subZoneList.length === 3,four:subZoneList.length === 4,five:subZoneList.length === 5,six:subZoneList.length >= 6 }">
      <span
        class="subzone-item"
        :class="{ selectedZone: isSelectSubZone(section.value) }"
        v-for="(section, d) in subZoneList"
        @click="setSubZone(section)"
        :key="d"
        > <p>{{ section.label }}</p></span
      >
    </div>
    <div class="select-please" v-if="zoneId.length == 0 && !isLoading">
      Selecciona una Zona
    </div>
    <div
      class="select-please"
      v-if="zoneId.length !== 0 && !isLoading && subZoneList.length == 0"
    >
      No hay Subzonas
    </div>
    <loader-custom-section v-if="isLoading" />
  </div>
</template>
<script>
import _ from 'lodash';
import LoaderCustomSection from '@/components/_shared/LoaderCustomSection.vue';
export default {
  name: 'FormReservationSubZone',
  props: ['zoneId', 'people', 'hour', 'subZoneIdValue'],
  components: { LoaderCustomSection },
  data () {
    return {
      subZoneId: [],
      subZoneSelected: [],
      subZoneList: [],
      isLoading: false,
      subSectionsTemp: []
    };
  },
  mounted () {
    this.getSubZoneId();
  },
  methods: {
    initValue () {
      if (this.subZoneIdValue) {
        const dataZoneSelected = [];
        this.subZoneIdValue.forEach((item) => {
          const is = this.subZoneList.find((zoneData) => zoneData.value === item);
          if (is) {
            dataZoneSelected.push({ value: is.value, label: is.label });
          }
        });
        this.subZoneId = dataZoneSelected;
        this.subZoneSelected = this.subZoneIdValue;
      }
    },
    shortText (text) {
      let name = text;
      if (text.length > 7) {
        name = text.substr(0, 7) + '...';
      }
      return name;
    },
    getSubZoneId () {
      if (this.zoneId.length > 0) {
        this.isLoading = true;
        this.$store
          .dispatch({
            type: 'reservation:subSections',
            data: {
              sectionId: this.zoneId,
              people: this.people,
              date: this.hour
            }
          })
          .then((data) => {
            const aux = [];
            let name = '';
            const subSections = data.data;
            this.subSectionsTemp = data.data;
            if (subSections.length === 1) {
              subSections.map(function (data) {
                name = data.title;
                aux.push({ value: data.id, label: name, selected: true });
              });
              this.subZoneList = [];
              this.subZoneList = aux;
              this.setSubZone(aux[0]);
            } else {
              subSections.map(function (data) {
                name = data.title;
                aux.push({ value: data.id, label: name });
              });
              this.subZoneList = [];
              this.subZoneList = aux;
            }
            this.initValue();
            this.isLoading = false;
          })
          .catch(() => {
            this.subZoneList = [];
            this.isLoading = false;
          });
      }
    },
    setSubZone (zone) {
      const is = this.subZoneId.filter((zoneData) => {
        if (zoneData.value === zone.value) {
          return zoneData;
        }
      });
      if (this.subZoneId.length === 1) {
        if (is.length === 0) {
          this.subZoneId.push({ value: zone.value, label: zone.label });
          this.subZoneSelected.push(zone.value);
        }
        if (is.length === 1) {
          this.subZoneId = [];
          this.subZoneSelected = [];
        }
      } else {
        if (is.length === 0) {
          this.subZoneId.push({ value: zone.value, label: zone.label });
          this.subZoneSelected.push(zone.value);
        } else {
          if (_.includes(this.subZoneSelected, zone.value)) {
            const i = this.subZoneSelected.indexOf(zone.value);
            this.subZoneSelected.splice(i, 1);
          }
          const iNd = this.subZoneId.findIndex((item) => item.value === zone.value);
          if (iNd >= 0) {
            this.subZoneId.splice(iNd, 1);
          }
        }
      }
    },
    isSelectSubZone (zone) {
      const is = this.subZoneId.filter((zoneData) => zoneData.value === zone);
      if (is.length === 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    zoneId () {
      this.getSubZoneId();
    },
    subZoneSelected () {
      this.$emit('subZoneSelected', this.subZoneSelected);
    },
    subZoneList () {
      this.$emit('show', this.subZoneList.length > 0);
    }
  }
};
</script>
<style scoped lang="scss">
.subzone-component {
  display:grid;
  //flex-wrap:wrap;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  max-height: 150px;
  width: 100%;
  max-width: 100%;
  grid-template-columns: repeat(5, minmax(50px, 1fr));
  background-color: #F0F1F2;
  gap: 1px;
    &.one{
    grid-template-columns:repeat(1, minmax(50px, 1fr));
  }
  &.two{
    grid-template-columns:repeat(2, minmax(50px, 1fr));
  }
  &.three{
    grid-template-columns:repeat(3, minmax(50px, 1fr));
  }
  &.four{
    grid-template-columns:repeat(4, minmax(50px, 1fr));
  }
  &.five{
    grid-template-columns:repeat(5, minmax(50px, 1fr));
  }
 @media screen and (min-width:600px) {
  &.six{
    grid-template-columns:repeat(6, minmax(50px, 1fr));
  }
 }
  .subzone-item {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    max-width: 100%;
    width: 100%;
    flex-direction:row;
    font-size: 13px;
    color:#444B57;
    font-family: 'Roboto';
    margin-bottom: -1px;
    gap: 1px;
    overflow: hidden;
    p{
      font-size: 13px;
      max-width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &:last-child{
      border-right:unset;
    }
    cursor:pointer;
    i {
      margin-right:5px;
    }
    &:last-child {
      flex-direction: row;
    }
    &:nth-child(5n) {
      border-right: none;
    }
    &.selectedZone {
      background:#444B57!important;
      color:#fff !important;
      i{
        color:#fff !important;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 1px !important;
  }
  &::-webkit-scrollbar-track {
    background: #444B57 !important;
  }
  .selectedZone {
    background-color: #000 !important;
    color: #fff !important;
    font-weight: 700 !important;
  }
}
.i-subzone-component {
  height: 12px;
  width: 12px;
  margin-top: 1px;
}

</style>
