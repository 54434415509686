<template>
  <div id="modalReportEvent">
    <div class="modal_close">
      <i @click="toggleMenuM" class="icons icon_close_modal" style="position: absolute; bottom: -30px;"></i>
    </div>
    <div class="main_icon">
      <i class="icons icon_report"></i>
    </div>
    <div class="main_title">
      <span class="title_modal ffr">
        Se está generando el reporte de la base de datos. En unos minutos lo recibirás al correo
        <span
          class="title_modal ffr bold"
        >{{ email }}</span>.
      </span>
      <span
        class="title_modal ffr"
      >* En caso de que no lo veas en bandeja de entrada, asegúrate de revisar correo no deseado</span>
    </div>
    <div class="container_buttons">
      <button @click="toggleMenuM" class="btn_report close ffr">
        <i class="icons icon_close_modal"></i>
        Cerrar
      </button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ModalReportEvent',
  props: ['email'],
  data () {
    return {
    };
  },
  methods: {
    toggleMenuM () {
      this.$parent.close();
    }
  }
};
</script>
<style lang="scss">
@use "../../../assets/styles/users/modalReportEvent.scss";
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
</style>
