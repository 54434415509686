<template>
  <v-chart class="chart" :option="option" :initOptions="{ renderer: 'svg' }" />
  <!-- autoresize -->
</template>

<script>
// Importación de las dependencias de ECharts
import { use } from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { HeatmapChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart from 'vue-echarts';
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import metricsTypes from '@/store/types/metrics';

use([
  SVGRenderer,
  HeatmapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: 'HeatmapChart',
  props: ['sectionId'],
  components: {
    VChart
  },
  data () {
    return {
      chartKey: 0,
      convertedData: [],
      dataLegends: [],
      tablesChart: [],
      spanishDays: {
        Monday: 'Lun',
        Tuesday: 'Mar',
        Wednesday: 'Mie',
        Thursday: 'Jue',
        Friday: 'Vie',
        Saturday: 'Sab',
        Sunday: 'Dom'
      },
      option: {
        tooltip: {
          trigger: 'item', // Trigger tipo 'item' para el heatmap
          formatter: (params) => {
            return `
              <div>
                <strong>${params.name}</strong><br/>
                Reservas: ${params.value[2]}<br/>
              </div>
            `;
          }
        },
        grid: {
          height: '60%',
          left: '50px',
          right: '0'
        },
        xAxis: {
          type: 'category',
          data: [],
          splitArea: {
            show: true
          },
          name: 'Mesas',
          nameLocation: 'middle',
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            padding: 20
          },
          axisLabel: {
            interval: 0, // Muestra todas las etiquetas
            fontSize: 10, // Tamaño de las etiquetas
            formatter: (value) => {
              const maxLength = 7; // Máximo de caracteres antes de truncar
              return value.length > maxLength ? value.substring(0, maxLength) + '...' : value;
            }
          }
        },
        yAxis: {
          type: 'category',
          splitArea: {
            show: true
          },
          data: [], // Se llenará dinámicamente con los días
          name: 'Días',
          nameLocation: 'middle',
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            padding: 20
          }
        },
        visualMap: {
          type: 'piecewise',
          min: 0,
          max: 10,
          pieces: [],
          orient: 'horizontal',
          left: 'left',
          bottom: '5%',
          inRange: {
          }
        },
        series: [
          {
            name: 'Heatmap',
            type: 'heatmap',
            data: [], // Se llenará con los datos
            label: {
              show: false
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            itemStyle: {
              borderColor: '#ECEDEE',
              borderWidth: 1
            }
          }
        ]
      }
    };
  },
  mounted () {
    this.generateDataChart();
  },
  computed: {
    ...mapGetters({
      tables: [reservationTypes.getters.tables],
      metricReservations: [metricsTypes.getters.metricReservations]
    })
  },
  methods: {
    calculateMinAndMax () {
      const values = this.option.series[0].data.map(item => item[2]); // Extraer valores de la data
      const min = Math.min(...values); // Mínimo valor
      const max = Math.max(...values); // Máximo valor
      return { min, max };
    },
    buildArrayTables () {
      const finalTables = [];
      const tables = { ...this.tables[0] };
      for (const key in tables) {
        if (Object.hasOwnProperty.call(tables, key)) {
          const element = tables[key];
          if (Array.isArray(element) && element.length > 0) {
            element.forEach(zoneTable => {
              finalTables.push(zoneTable.displayName);
            });
          }
        }
      }
      this.tablesChart = finalTables;
      this.option.xAxis.data = this.tablesChart;
    },
    generateDataChart () {
      this.buildArrayTables();
      const heatmapData = [];
      const data = {};

      if (Array.isArray(this.metricReservations) && this.metricReservations.length > 0) {
        this.metricReservations.forEach((reservation) => {
          const day = this.spanishDays[reservation.dayName];
          if (reservation.tableName !== null) {
            const table = reservation.tableName.split(',')[0];
            if (!data[table]) {
              data[table] = {};
            }
            if (!data[table][day]) {
              data[table][day] = 1;
            } else {
              data[table][day] += 1;
            }
          }
        });
      }

      const days = Object.values(this.spanishDays);
      this.option.yAxis.data = days;
      this.tablesChart.forEach((table, tableIndex) => {
        days.forEach((day, dayIndex) => {
          const value = data[table]?.[day] || 0;
          heatmapData.push([tableIndex, dayIndex, value]);
        });
      });

      this.option.series[0].data = heatmapData;

      const { min, max } = this.calculateMinAndMax();
      this.option.visualMap.min = min;
      this.option.visualMap.max = max;

      // Dinámicamente ajustar los rangos
      const step = Math.ceil((max - min) / 5);
      this.option.visualMap.pieces = [
        { min: min, max: min, color: '#FFF', label: '0% de reservación' },
        { min: min + 1, max: min + step, label: '1-20% de reservación' },
        { min: min + step + 1, max: min + 2 * step, label: '21-40% de reservación' },
        { min: min + 2 * step + 1, max: min + 3 * step, label: '41-60% de reservación' },
        { min: min + 3 * step + 1, max: min + 4 * step, label: '61-80% de reservación' },
        { min: min + 4 * step + 1, max: max, label: '81-100% de reservación' }
      ];
      switch (this.sectionId) {
        case 1:
          this.option.visualMap.inRange.color = ['#BCE9F1', '#0693A8'];
          break;
        case 2:
          this.option.visualMap.inRange.color = ['#E8EAA9', '#A5AB12'];
          break;
        default:
          break;
      };
      this.option = { ...this.option };
    },
    resetChart () {
    }
  },
  watch: {
    metricReservations: {
      deep: true, // Detecta cambios profundos en el objeto
      handler () {
        this.generateDataChart();
      }
    }
  }
};
</script>

<style scoped>
.chart {
}
</style>
