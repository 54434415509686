<template>
  <div class="notification-component">
    <div class>
      <div class="notification-component-recepair">
        <div class="notification-component-recepair-text">
          <span class="text">¿Deseas notificar al cliente?</span>
          <b-checkbox name="notifyClient" v-model="notifyClient" @input="checkNotifyClient"></b-checkbox>
        </div>
        <div class="notification-component-recepair-text" v-if="notifyClient">
          <p class="text">¿Por dónde quieres notificar al cliente?</p>
          <b-checkbox name="email" v-model="notificationEmail">Email</b-checkbox>
          <b-checkbox
            :disabled="parseInt(vendor.smsSent) >= parseInt(vendor.limitSMS)"
            name="sms"
            v-if="vendor.isActiveSMS && phoneExist && parseInt(reservationInfo.indicativo) === 57"
            v-model="notificationSms"
          >SMS</b-checkbox>
          <b-checkbox
            name="whatsapp"
            v-if="params.activeWhatsApp && phoneExist && parseInt(reservationInfo.indicativo) !== 54 && !noWssp && !isWaitingList"
            v-model="notificationWhatsApp"
          >WhatsApp</b-checkbox>
        </div>
        <span class="notification-component-recepair-alert-sms" v-if="notificationWhatsApp">
          * Para poder enviar el mensaje por favor seleccione el indicativo del
          país y agregue un número válido (Excepto para Argentina).
        </span>
        <span class="notification-component-recepair-alert-sms" v-if="notificationSms">
          * Para poder enviar el SMS por favor seleccione el indicativo del
          país y agregue un número válido (Solo para Colombia).
        </span>
        <span
          class="notification-component-recepair-alert-sms"
          v-if="
            vendor.isActiveSMS &&
            parseInt(vendor.smsSent) >= parseInt(vendor.limitSMS)
          "
        >
          * Llegó al límite máximo de SMS permitidos durante el mes, el cliente deberá comunicarse con nosotros.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FormReservationNotification',
  props: {
    phoneExist: {
      type: Boolean
    },
    reservationInfo: {
      type: Object
    },
    noWssp: {
      type: Boolean,
      default: false
    },
    isWaitingList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notifyClient: true,
      notificationEmail: true,
      notificationSms: false,
      notificationWhatsApp: false
    };
  },
  mounted () {
    if (this.reservationInfo.typeNotification === 'whatsapp') {
      this.notifyClient = true;
      this.notificationWhatsApp = true;
    } else if (this.reservationInfo.typeNotification === 'sms') {
      this.notifyClient = true;
      this.notificationSms = true;
    } else {
      this.notifyClient = true;
      this.notificationEmail = true;
    }
  },
  methods: {
    checkNotifyClient () {
      if (!this.notifyClient) {
        this.notificationEmail = false;
        this.notificationSms = false;
        this.notificationWhatsApp = false;
      } else {
        if (this.reservationInfo.typeNotification === 'whatsapp') {
          this.notificationWhatsApp = true;
        } else if (this.reservationInfo.typeNotification === 'sms') {
          this.notificationSms = true;
        } else {
          this.notificationEmail = true;
        }
      }
    }
  },
  watch: {
    notificationEmail () {
      if (this.notificationEmail) {
        if (this.notificationSms) {
          this.notificationSms = false;
        }
        if (this.notificationWhatsApp) {
          this.notificationWhatsApp = false;
        }
      }
      this.$emit('change', {
        notificationSms: this.notificationSms,
        notificationEmail: this.notificationEmail,
        notificationWhatsApp: this.notificationWhatsApp
      });
    },
    notificationSms () {
      if (this.notificationSms) {
        if (this.notificationEmail) {
          this.notificationEmail = false;
        }
        if (this.notificationWhatsApp) {
          this.notificationWhatsApp = false;
        }
      }
      this.$emit('change', {
        notificationSms: this.notificationSms,
        notificationEmail: this.notificationEmail,
        notificationWhatsApp: this.notificationWhatsApp
      });
    },
    notificationWhatsApp () {
      if (this.notificationWhatsApp) {
        if (this.notificationEmail) {
          this.notificationEmail = false;
        }
        if (this.notificationSms) {
          this.notificationSms = false;
        }
      }
      this.$emit('change', {
        notificationSms: this.notificationSms,
        notificationEmail: this.notificationEmail,
        notificationWhatsApp: this.notificationWhatsApp
      });
    }
  },
  destroyed () {
    this.notificationSms = false;
    this.notificationEmail = true;
    this.notifyClient = true;
    this.notificationWhatsApp = false;
  }
};
</script>
<style lang="scss">
.notification-component {
  &-recepair {
    font-size: 12px;
    &-text {
      display: flex;
      margin-right: 5px;
      margin-top: 8px;
      @media screen and (max-width: 1000px) {
        margin-top: 15px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
      }
      .b-checkbox.checkbox {
        position: relative;
        top: 1px;
        margin-left: 0.5rem;
        margin-right: 0 !important;
      }
    }
    &-alert-sms {
      color: gray;
      font-weight: 400;
    }
  }
}
</style>
