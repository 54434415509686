<template>
  <div id="contactless-payments">
    <Sidenav @change-full="toggle"></Sidenav>
    <div class="content-wrap" v-if="params.activateContactlessPayment">
      <b-navbar id="navbar_contactless">
        <template #brand>
          <div class="img_client hamburguer_image" v-if="vendor">
            <img :src="vendor.thumbnailURL" @click="openSideBar" />
          </div>
          <div class="user_restaurant">
            <span class="title_nav">Pagos sin Contacto</span>
          </div>
        </template>
        <template #end>
          <b-navbar-item class="dnm" tag="div">
            <div class="buttons dnm">
              <div class="background-print">
                <i
                  @click="generateReport()"
                  class="icon_report icon_download_contactless"
                  v-if="!generate"
                ></i>
                <i class="fa fa-spinner fa-spin" v-else></i>
              </div>
              <a
                @click="openModalDate"
                class="button btn_date"
                style="margin: 0 20px 0 0 !important; background: #303640; width: 220px; height: 30px!important"
              >
                <span
                  class="content_btn ffr"
                  style="display: flex; gap: 10px; font-size: 15px; justify-content: center; align-items: center;"
                >
                  <i class="icon_report icon_arrow_left_contactless" style="height: 13px;"></i>
                  <i class="icon_report icon_calendar_contactless"></i>
                  {{dateInput}}
                  <i
                    class="icon_report icon_arrow_right_contactless"
                    style="height: 13px;"
                  ></i>
                </span>
              </a>
              <a class="button btn_search" style="margin-right: 20px;">
                <div class="content_icon">
                  <i class="icons icon_search"></i>
                </div>
                <div class="content_input">
                  <input class="content_btn ffr" type="text" v-model="search" />
                </div>
              </a>
            </div>
          </b-navbar-item>
          <div class="buttons_mobile dnd">
            <div class="container_dates">
              <div class="container_title">
                <span class="text_select ffr">Seleccione la fecha de búsqueda</span>
              </div>
              <div class="content_button_dates">
                <div class="buttons_dates start">
                  <span style="font-family: Roboto; color:#fff; font-size: 12px;">Inicio</span>
                  <date-pick
                    :months="months"
                    :startWeekOnSunday="true"
                    :weekdays="weekdays"
                    @input="cleanEndDate"
                    v-model="startDate"
                  >
                    <template v-slot:default="{ toggle }">
                      <button
                        @click="toggle"
                        class="btn_dates ffr"
                      >{{(startDate !== null && startDate !== '') ? ($moment(startDate).format('DD/MM/YYYY')) : 'Fecha'}}</button>
                    </template>
                  </date-pick>
                  <div class="main_text_dates" v-if="startDate !== null">
                    <i @click="clean(0, $event)" class="icons icon_close_modal"></i>
                  </div>
                </div>
                <div class="buttons_dates end">
                  <span style="font-family: Roboto; color:#fff; font-size: 12px;">Final</span>
                  <date-pick
                    :isDateDisabled="disabledDates"
                    :months="months"
                    :startWeekOnSunday="true"
                    :weekdays="weekdays"
                    v-model="endDate"
                  >
                    <template v-slot:default="{ toggle }">
                      <button
                        @click="toggle"
                        class="btn_dates ffr"
                      >{{(endDate !== null && endDate !== '') ? ($moment(endDate).format('DD/MM/YYYY')) : 'Fecha'}}</button>
                    </template>
                  </date-pick>
                  <div class="main_text_dates" v-if="endDate !== null">
                    <i @click="clean(1, $event)" class="icons icon_close_modal"></i>
                  </div>
                </div>
              </div>
            </div>
            <div @click="generateReport()" class="background-print-calendar" v-if="!generateMobile">
              <i class="icon_report icon_download_contactless"></i>
              <span style="padding-left: 10px; pointer-events: none;">Descargar Reporte</span>
            </div>
            <div class="background-print-calendar" v-else>
              <i class="fa fa-spinner fa-spin"></i>
            </div>
          </div>
        </template>
      </b-navbar>
      <div class="search-bar-mobile">
        <a class="button search-button-mobile">
          <div class="content_icon_mobile">
            <i class="icons_mobile icon_search_mobile"></i>
          </div>
          <div class="content_input_mobile">
            <input class="content_btn_mobile ffr" type="text" v-model="search" />
          </div>
        </a>
      </div>
      <div
        class="filter_active"
        v-if="datesFilter.length > 0 || (startDate !== null && endDate !== null)"
      >
        <span class="text_filter ffr">Filtro:&nbsp;</span>
        <span
          class="detail_filter ffr"
          v-if="datesFilter.length > 0"
        >{{ converter(datesFilter[0]) }} a {{ converter(datesFilter[1]) }}</span>
        <span
          class="detail_filter ffr"
          v-if="startDate != null && endDate != null"
        >{{ converter(startDate) }} a {{ converter(endDate) }}</span>
        <i @click="deleteDates" class="icons icon_close_modal"></i>
      </div>
      <div class="payment-content-empty" v-if="payments.length === 0">No hay pagos registrados</div>
      <div class="payment-content" v-else>
        <div class="paymentCardContainer">
          <PaymentCard
            :index="index"
            :key="index"
            :payment="item"
            v-for="(item, index) in payments"
          />
        </div>
      </div>
    </div>
    <div class="withoutpermissions" v-else>
      <b-navbar :mobile-burger="false" id="navbar_contactless">
        <template #brand>
          <div class="img_client hamburguer_image" v-if="vendor">
            <img :src="vendor.thumbnailURL" @click="openSideBar" />
          </div>
        </template>
      </b-navbar>No tiene activada esta interfaz
    </div>
    <TempPassword></TempPassword>
  </div>
</template>
<script>
import Sidenav from '@/components/_shared/Sidenav';
import PaymentCard from '@/components/contactlessPayment/PaymentCard';
import ModalDates from '@/components/polls_v2/atoms/modalDates';
import TempPassword from '@/components/_shared/TempPassword';
import DatePick from 'vue-date-pick';
import globalTypes from '@/store/types/global';
import servicePayment from '@/helpers/apis/servicePayment.js';
import serviceReport from '@/helpers/apis/serviceReport.js';
import LocalStorage from 'store';

export default {
  name: 'ContactlessPayment',
  components: { Sidenav, PaymentCard, DatePick, TempPassword },
  data () {
    return {
      bodyIndex: document.body,
      payments: [],
      paymentsFilter: [],
      paymentsFilterDates: [],
      generate: false,
      generateMobile: false,
      datesFilter: [],
      startDate: null,
      endDate: null,
      search: '',
      searchMobile: '',
      dateInput: null,
      weekdays: ['LUN', 'MAR', 'MIE', 'JUV', 'VIE', 'SAB', 'DOM'],
      months: ['Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    };
  },
  mounted () {
    const newDate = this.$moment().locale('es').format('MMMM YYYY');
    this.dateInput = newDate.charAt(0).toUpperCase() + newDate.slice(1);
    // eslint-disable-next-line
    classie.add(this.bodyIndex, 'show-right-bar');
    this.$store.dispatch({
      type: 'auth:refreshUser',
      data: {
        userId: this.user.id
      }
    });
    this.getPayments();
  },
  destroyed () {
    // eslint-disable-next-line
    classie.remove(this.bodyIndex, 'show-right-bar');
  },
  methods: {
    generateReport (mobile = false) {
      if (!mobile) {
        this.generate = true;
      }
      this.generateMobile = true;
      serviceReport({
        url: `/getPaymentsContactlessReport/${this.vendor.id}`,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + LocalStorage.get('token')
        },
        method: 'get'
      }).then(({ data }) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'contactlessPayments' + '.xlsx';
        link.click();
      }).catch((e) => {
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      }).finally(() => {
        this.generate = false;
        this.generateMobile = false;
      });
    },
    converter (date) {
      const newDate = this.$moment(date).locale('es').format('DD MMMM');
      const textSplit = newDate.split(' ');
      const text = textSplit[1].charAt(0).toUpperCase() + textSplit[1].slice(1) + ' ' + textSplit[0];
      return text;
    },
    getPayments () {
      servicePayment.get('contactless/' + this.vendor.id).then(({ data }) => {
        this.payments = data.data;
        this.paymentsFilter = data.data;
      });
    },
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) {
          /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) {
          /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
    openModalDate () {
      this.datesFilter = [];
      this.payments = this.paymentsFilter;
      this.paymentsFilterDates = this.paymentsFilter;
      const thes = this;
      this.$buefy.modal.open({
        parent: this,
        component: ModalDates,
        hasModalCard: true,
        customClass: 'modal_dates_poll',
        canCancel: false,
        events: {
          searchForDates (payload) {
            thes.datesFilter = payload;
          },
          filterDateRange (payload) {
            if (payload !== null && payload.start !== null && payload.end !== null) {
              thes.datesFilter.push(thes.$moment(payload.start).format('YYYY-MM-DD'));
              thes.datesFilter.push(thes.$moment(payload.end).format('YYYY-MM-DD'));
              thes.filterByDates(thes.datesFilter[0], thes.datesFilter[1]);
            }
          }
        }
      });
    },
    clean (i, e) {
      e.stopPropagation();
      if (i === 0) {
        this.startDate = null;
        this.payments = this.paymentsFilter;
        this.paymentsFilterDates = this.paymentsFilter;
        return;
      }
      this.endDate = null;
      this.payments = this.paymentsFilter;
    },
    deleteDates () {
      this.startDate = null;
      this.endDate = null;
      this.datesFilter = [];
      this.payments = this.paymentsFilter;
      this.paymentsFilterDates = this.paymentsFilter;
    },
    disabledDates (endDate) {
      if (this.startDate !== null) {
        return endDate < new Date(this.startDate);
      }
    },
    filterByDates (startDate, endDate) {
      const copy = this.paymentsFilter;
      this.payments = copy.filter(x => this.$moment(x.created_at).format('YYYY-MM-DD') >= startDate && this.$moment(x.created_at).format('YYYY-MM-DD') <= endDate);
      this.paymentsFilterDates = this.payments;
    },
    openSideBar () {
      this.$store.commit(globalTypes.mutations.setShowSideBar, true);
    },
    cleanEndDate () {
      this.endDate = null;
      this.disabledDates();
    },
    searchUser () {
      const el = document.getElementsByClassName('navbar-menu');
      el[0].classList.remove('is-active');
      this.inputSearch = this.searchMobile;
      const tempDates = [];
      tempDates.push(this.startDate);
      tempDates.push(this.endDate);
      this.datesFilter = tempDates;
    }
  },
  watch: {
    search () {
      let copy = this.paymentsFilter;
      if (this.datesFilter.length > 0 || (this.startDate !== null && this.endDate !== null)) {
        copy = this.paymentsFilterDates;
        this.payments = copy.filter(x => ((x.name != null) && String(x.name).toLowerCase().includes(this.search.toLowerCase())) || ((x.numberPaid != null) && String(x.numberPaid).toLowerCase().includes(this.search.toLowerCase())));
        return;
      }
      this.payments = copy.filter(x => ((x.name != null) && String(x.name).toLowerCase().includes(this.search.toLowerCase())) || ((x.numberPaid != null) && String(x.numberPaid).toLowerCase().includes(this.search.toLowerCase())));
    },
    startDate () {
      if (this.startDate !== null && this.endDate !== null) {
        this.filterByDates(this.startDate, this.endDate);
      }
    },
    endDate () {
      if (this.startDate !== null && this.endDate !== null) {
        this.filterByDates(this.startDate, this.endDate);
      }
    }
  }
};
</script>
<style lang="scss">
#app {
  overflow: hidden;
}
#contactless-payments {
  .withoutpermissions {
    text-align: center;
    font-size: 20pt;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #444b57;
  }
  .paymentCardContainer {
    padding-bottom: 0.5rem;
    display: grid;
    grid-template-columns: repeat(5, 300px);
    gap: 0.5rem;
    width: fit-content;
    margin: auto;
    @media screen and (min-width: 1920px) {
      grid-template-columns: repeat(6, 292px);
    }
    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(4, 292px);
    }
    @media screen and (max-width: 1599px) and (min-width: 1500px) {
      grid-template-columns: repeat(5, 265px);
      gap: 1rem;
    }
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 292px);
    }
    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 290px);
      gap: 1.5rem;
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(2, 192px);
      gap: 0.5rem;
    }
  }
  .filter_active {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    align-items: center;
    .icons {
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      display: flex;
    }
    .icon_close_modal {
      -webkit-mask-image: url(../../assets/icons/users/icon_close_modal.svg);
      mask-image: url(../../assets/icons/users/icon_close_modal.svg);
      background: #444b57;
      width: 14px;
      height: 14px;
      cursor: pointer;
      margin-left: 18px;
    }
    .detail_filter {
      font-weight: 600;
    }
  }
  .search-bar-mobile {
    display: none;
    margin-top: 7px;
    padding-left: 12px;
    padding-right: 15px;
    background: #fff;
  }
  .search-button-mobile {
    flex: 1;
    width: 200px;
    height: 24px !important;
    background: #fff;
    border: 1px solid #a8adb5;
    border-radius: 8px;
    margin: 0 !important;
  }
  .content_icon_mobile {
    padding-right: 10px;
  }
  .content_input_mobile {
    flex: 1;
  }
  .content_btn_mobile {
    font-size: 10px;
    color: #444b57;
    background: none;
    border: none;
    width: 100%;
  }
  .ffr {
    font-family: "Roboto", sans-serif;
  }
  .icons_mobile {
    display: flex;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }
  .icon_search_mobile {
    -webkit-mask-image: url(../../assets/icons/users/icon_search.svg);
    mask-image: url(../../assets/icons/users/icon_search.svg);
    background: #444b57;
    width: 14px;
    height: 14px;
  }
  .payment-content {
    margin-right: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    max-height: calc(100vh - 70px);
    overflow-y: scroll;
    gap: 0.5rem;
    margin-top: 0.8rem;
    padding-bottom: 0.5rem;
    &::-webkit-scrollbar-thumb {
      background: #444b57 !important;
      outline: unset !important;
      border-radius: 30px !important;
      border: unset !important;
      box-shadow: unset !important;
    }
    &::-webkit-scrollbar {
      width: 8px !important;
    }
  }
  .content-wrap {
    background: #fff;
  }
  .payment-content-empty {
    display: flex;
    justify-content: center;
    background-color: #fff;
  }
  .background-print {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #303640;
    width: 50px;
    height: 32px;
    border-radius: 7px;
    margin-right: 20px;
    cursor: pointer;
  }
  .background-print-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #303640;
    color: #fff;
    width: 274px;
    height: 32px;
    margin-left: 5px;
    margin-top: 25px;
    border-radius: 7px;
    margin-right: 20px;
    cursor: pointer;
    gap: 5px;
  }
  .icon_report {
    display: flex;
    -webkit-mask-repeat: no-repeat !important;
    mask-repeat: no-repeat !important;
    mask-position: center !important;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    background: #fff;
  }
  #navbar_contactless {
    height: 52px;
    .user_restaurant {
      margin-left: 40px;
    }
    .dnm {
      display: block;
    }
    .fa-spinner {
      color: #ffffff;
      width: 24px;
      height: 24px;
      margin: 0px 50px 0px 50px;
    }
    .title_nav {
      font-size: 25px;
      font-weight: 500;
      color: #ffffff;
    }
    .navbar-start {
      display: none;
    }
    .ffr {
      font-family: "Roboto", sans-serif;
    }
    .buttons {
      margin: auto;
    }
    .buttons.dnm {
      display: flex;
    }
    .btn_date {
      width: 116px;
      height: 24px !important;
      background: #606773;
      border: none;
      border-radius: 8px;
      margin: 0 50px 0 0 !important;
    }
    .btn_search {
      width: 200px;
      height: 29px !important;
      background: #606773;
      border: none;
      border-radius: 8px;
      margin: 0 !important;
      @media screen and (min-width: 600px) and (max-width: 900px) {
        width: 185px;
      }
    }
    .content_btn {
      font-size: 10px;
      color: #ffffff;
      background: none;
      border: none;
      width: 100%;
    }
    .content_btn:focus-visible {
      outline: none;
    }
    .icons {
      display: flex;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
    }
    .icon_download {
      -webkit-mask-image: url(../../assets/icons/users/icon_download.svg);
      mask-image: url(../../assets/icons/users/icon_download.svg);
      background: #ffffff;
      width: 24px;
      height: 24px;
      margin: 0px 50px 0px 50px;
    }
    .icon_search {
      -webkit-mask-image: url(../../assets/icons/users/icon_search.svg);
      mask-image: url(../../assets/icons/users/icon_search.svg);
      background: #ffffff;
      width: 14px;
      height: 14px;
    }
    .content_icon {
      width: 15%;
    }
    .content_input {
      width: 85%;
    }
    .img_client {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      width: 33px;
      height: 33px;
      overflow: hidden;
      border-radius: 50%;
      background: #fff;
      margin-left: 8px;
      margin-right: 12px;
    }
    .navbar-brand {
      display: flex;
      align-items: center;
    }
    .vdpPeriodControls {
      height: 22px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .vdpHeader {
      padding-top: 5px;
      background-color: transparent;
    }
    .vdpPeriodControl {
      font-family: "Roboto", sans-serif;
      padding: 0 5px;
      button {
        color: #ffffff;
        padding: 0 !important;
      }
    }
    .vdpPeriodControl > select {
      font-size: 12px;
    }
    .vdpHeadCellContent {
      font-size: 8px;
      font-family: "Roboto", sans-serif;
      color: #ffffff;
    }
    .vdpInnerWrap {
      min-width: auto;
      background-color: #79808d;
    }
    .vdpArrowPrev:after {
      border-right-color: #ffffff;
    }
    .vdpArrowNext:after {
      border-left-color: #ffffff;
    }
    .vdpCell.selectable {
      color: #ffffff;
    }
    .vdpCell.outOfRange {
      color: #c7c7c7;
    }
    .vdpCell.selected .vdpCellContent {
      background: #4a4a4a;
    }
    .vdpCell.selectable:hover .vdpCellContent {
      background: #4a4a4a;
    }
    .vdpCellContent {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      font-size: 10px;
      font-family: "Roboto", sans-serif;
    }
    .vdpCell {
      padding: 0;
      width: 34px;
      height: 34px;
      border: 1px solid #a8adb5;
    }
    .vdpHeadCell {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 34px;
      height: 34px;
      border: 1px solid #a8adb5;
      font-size: 10px;
    }
    .vdpRow {
      display: flex;
    }
    .vdpTable {
      display: flex;
      flex-direction: column;
      tbody,
      thead {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      thead {
        tr {
          display: flex;
        }
      }
    }
    .vdpPeriodControl > button {
      font-size: 12px;
      font-weight: 600;
    }
    .dnd {
      display: none;
    }
    .navbar-menu {
      align-items: center;
    }
    .navbar-end {
      max-width: 540px;
    }
    .hamburguer_image {
      display: none;
    }
  }
  @media screen and (max-width: 1200px) {
    #navbar_contactless {
      display: flex !important;
      .navbar-menu {
        width: auto !important;
        height: 52px;
        display: flex;
        justify-content: space-between !important;
      }
      .btn_date {
        margin: 0 20px 0 0 !important;
      }
      .icon_download {
        margin: 0px 10px 0px 20px;
      }
      .title_nav {
        font-size: 20px;
      }
      .navbar-end {
        height: 52px;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    #navbar_contactless {
      justify-content: space-between !important;
      .navbar-burger {
        display: none;
      }
      .navbar-menu {
        padding: 0;
      }
      .user_restaurant {
        margin-left: 30px;
      }
    }
  }
  @media screen and (max-width: 740px) {
    .payment-content {
      justify-content: center;
      margin-left: 0px;
      padding-bottom: 10px;
      max-height: calc(100vh - 105px);
    }
    .content-wrap {
      position: absolute;
    }
    .search-bar-mobile {
      display: flex;
      padding-left: 12px;
      padding-right: 48px;
    }
    #navbar_contactless {
      height: auto;
      flex-direction: column;
      .navbar-menu {
        display: none !important;
        &.is-active {
          display: flex !important;
          height: 100vh;
          width: 100% !important;
        }
      }
      .navbar-end {
        padding-top: 20px;
        height: 100%;
        align-items: flex-start !important;
      }
      .navbar-burger {
        color: transparent !important;
        display: block;
        background-image: url("../../assets/icons/contactlessPayment/settings-contactless.svg");
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: 15px;
      }
      .navbar-burger.burger.is-active {
        color: #fff !important;
        background-image: none;
      }
      .navbar-brand {
        width: 100%;
      }
      .user_restaurant {
        margin-left: 15px;
        width: 80%;
        text-align: left;
      }
      .navbar-burger {
        color: #ffffff;
      }
      .dnm {
        display: none !important;
      }
      .dnd {
        display: block;
      }
      .btn_search {
        width: 100%;
        max-width: 315px;
        height: 40px !important;
      }
      .content_btn::placeholder {
        color: #ffffff;
        font-family: "Roboto", sans-serif;
        font-size: 11px;
      }
      .navbar-item {
        width: 100%;
      }
      .content_icon {
        margin-right: 10px;
        width: auto;
      }
      .buttons_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
      .text_select {
        color: #ffffff;
        font-size: 20px;
        text-align: center;
      }
      .container_dates {
        margin-top: 7px;
      }
      .buttons_dates {
        width: auto;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.start {
          margin-right: 15px;
        }
        &.end {
          margin-left: 15px;
        }
      }
      .btn_dates {
        border: 1px solid #fff;
        width: 90px;
        height: 24px;
        color: #ffffff;
        font-size: 10px;
        border-radius: 5px;
        cursor: pointer;
      }
      .div_search {
        margin-top: 80px;
        &.date {
          margin-top: 40px;
        }
      }
      .btn_search_user {
        width: 164px;
        height: 36px;
        background-color: #ffffff;
        border: none;
        color: #444b57;
        font-size: 14px;
        border-radius: 25px;
        font-weight: 500;
      }
      .title_nav {
        font-size: 16px;
      }
      .content_button_dates {
        display: flex;
        justify-content: center;
      }
      .texts_dates {
        color: #ffffff;
        font-size: 12px;
      }
      .main_text_dates {
        margin-top: 20px;
        display: flex;
      }
      .icons {
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        display: flex;
      }
      .icon_close_modal {
        -webkit-mask-image: url(../../assets/icons/users/icon_close_modal.svg);
        mask-image: url(../../assets/icons/users/icon_close_modal.svg);
        background: #ffffff;
        width: 14px;
        height: 14px;
        cursor: pointer;
        margin-left: 18px;
      }
      .container_title {
        display: flex;
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 565px) {
    .search-bar-mobile {
      display: flex;
      padding-left: 12px;
      padding-right: 15px;
    }
    #navbar_contactless {
      height: auto !important;
      .navbar-end {
        height: 100%;
      }
      .user_restaurant {
        margin-left: 0px;
        text-align: center;
      }
      .hamburguer_image {
        display: flex;
      }
      .title_nav {
        margin-left: 0px;
      }
    }
  }
}
</style>
