import namespace from '@/helpers/namespace';

export default namespace('searchs', {
  getters: [
    'statusFilter',
    'nameFilter',
    'emailFilter',
    'phoneFilter',
    'dateInFilter',
    'dateOutFilter',
    'loadingFilter',
    'executeFilter',
    'reservationIdFilter'
  ],
  actions: [
    'getReservationInProcess',
    'getReservationInFilter',
    'generateReportSearch',
    'generateReportWalkIn'
  ],
  mutations: [
    'setStatusFilter',
    'setNameFilter',
    'setEmailFilter',
    'setPhoneFilter',
    'setDateInFilter',
    'setDateOutFilter',
    'setReservationIdFilter',
    'setLoadingFilter',
    'setExecuteFilter'
  ]
});
