import namespace from '@/helpers/namespace';

export default namespace('googleCloud', {
  getters: [

  ],
  actions: [
    'uploadImage',
    'uploadImageMultiple'
  ],
  mutations: [

  ]
});
