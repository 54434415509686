<template>
  <div id="module-sms">
    <b-loading :active="loading" :is-full-page="true"></b-loading>
    <a @click="resetModule" class="return" :style="{position: (isPayment) ? 'absolute' : 'relative'}">
      <img src="@/assets/icons/marketing/return.svg" alt="precompro.com" class="return-icon">
      <span class="return-text">Volver</span>
    </a>
    <div class="module-payment module-payment-sms" :class="{open: openTab, close: !openTab}" v-if="!isPayment" style="padding-bottom: 40px; align-items: start;">
    <div class="module-payment-swipe">
      <div class="module-payment-swipe-pc">
        <Payment :totalFull="totalMessage" :type="provider" :items="taxes" :minT="minTab" @paymentShow="isPayment = $event" @paymentData="infoPayment = $event" @setTotalPayment="setTotalPayment"></Payment>
        <div class="divider"></div>
        <Campaign :totalMessage="totalMessage" @showContact="showContact = $event" :minT="minTab" @changeViewInt="$emit('changeModuleSms', $event)" @chargueTab="minTab = $event"></Campaign>
      </div>
      <!-- div class="module-payment-swipe-mobile" v-if="swipeActive">
        <SwipeGlobal :provider="provider" :totalMessage="totalMessage">
          <template v-slot:paymentComponent>
            <Payment :totalFull="totalMessage" :type="provider" :items="taxes" :minT="minTab" @paymentShow="isPayment = $event" @paymentData="infoPayment = $event" @setTotalPayment="setTotalPayment"></Payment>
          </template>
          <template v-slot:campaignComponent>
            <Campaign :totalMessage="totalMessage" @showContact="showContact = $event" :minT="minTab" @changeViewInt="$emit('changeModuleSms', $event)" @chargueTab="minTab = $event"></Campaign>
          </template>
        </SwipeGlobal>
      </div> -->
    </div>
    </div>
    <div class="module-payment module-payment-sms" :class="{'open-pay': openTab, 'close-pay': !openTab}" :style="{flexDirection: 'column', paddingBottom: '40px'}" v-else>
      <Method :lockInfo="lock" @creditMethodEvent="creditMethod = $event" :totalPayment="totalPayment"></Method>
      <CardComponent
        @selectTypeMethod="cardType = $event"
        @statusOfTransaction="setStatus($event)"
        v-if="creditMethod"
      />
      <DebitComponent v-if="!creditMethod"/>
      <ModalInfo
        :cardNumber="cardItem"
        :isPse="pse"
        :status="state"
        :vendorInfo="vendor"
        :mercadopago="isMercadoPago"
        @close="openModal = $event"
        v-if="openModal"
      />
    </div>
    <Taxes :type="provider" :items="taxes" :open="openTab" @setOpen="openTab = $event"></Taxes>
  </div>
</template>
<script>
import Taxes from '@/components/Marketing/taxes';
import Payment from '@/components/Marketing/payment';
import Campaign from '@/components/Marketing/sms/Campaign';
import Method from '@/components/Marketing/payment/Method';
import CardComponent from '@/components/Marketing/payment/Card';
import DebitComponent from '@/components/Marketing/payment/PsePay';
import serviceMarketing from '@/helpers/apis/serviceMarketing';
import ModalInfo from '@/components/Marketing/payment/TransactionStatusModal';
/* import SwipeGlobal from '@/components/Marketing/SwipeGlobal'; */

export default {
  name: 'MarketingSms',
  props: {
    provider: {
      type: String
    }
  },
  components: {
    Taxes,
    Payment,
    Campaign,
    Method,
    CardComponent,
    DebitComponent,
    ModalInfo
  },
  data () {
    return {
      taxes: [],
      lock: false,
      totalMessage: 0,
      showContact: false,
      openTab: false,
      minTab: 0,
      isPayment: false,
      infoPayment: null,
      creditMethod: true,
      cardItem: null,
      pse: false,
      isMercadoPago: false,
      cardType: null,
      state: '',
      openModal: false,
      loading: false,
      gallery: false,
      carouselPosition: 0,
      swipeActive: false,
      totalPayment: null
    };
  },
  mounted () {
    this.getTaxes();
    window.localStorage.removeItem('marketing_package');
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      this.minTab = 0;
      if (window.innerWidth <= 500) {
        this.swipeActive = true;
      } else {
        this.swipeActive = false;
      }
      if (window.innerWidth <= 414 && this.totalMessage > 0) {
        this.minTab = 1;
      }
    },
    setStatus (data) {
      this.state = data.state;
      this.pse = data.pse;
      this.cardItem = data.cardItem;
      this.isMercadoPago = data.isMercadoPago || false;
      this.openModal = true;
    },
    configMarketing () {
      this.loading = true;
      serviceMarketing.get('configuration/' + this.vendor.id + '?type=sms').then(({ data }) => {
        this.totalMessage = data.data.total || 0;
        this.handleResize();
        this.loading = false;
      }).catch((err) => {
        console.error(err);
        this.loading = false;
        this.handleResize();
        this.totalMessage = 0;
      });
    },
    getTaxes () {
      this.loading = true;
      serviceMarketing.get('taxes?type=sms').then(({ data }) => {
        this.taxes = data.data;
      }).catch(() => {
        this.taxes = [];
      }).finally(() => {
        this.configMarketing();
      });
    },
    resetModule () {
      window.localStorage.removeItem('marketing_package');
      this.$emit('resetModule', {
        module: 'reset'
      });
    },
    changeMinTab (type) {
      if (type === 'left') {
        this.minTab = 0;
      } else {
        this.minTab = 1;
      }
    },
    setTotalPayment (value) {
      this.totalPayment = value;
    }
  },
  destroyed () {
    this.taxes = [];
    this.totalMessage = 0;
    this.showContact = false;
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    totalMessage () {
      if (this.totalMessage > 0) {
        this.minTab = 1;
      } else {
        this.minTab = 0;
      }
    }
  }
};
</script>
<style lang="scss">
#module-sms {
  background: #FFF;
  height: 100%;
  overflow: auto;
  .open {
    height: calc(100% - 100px);
    @media screen and (max-width: 500px) {
      height: calc(100% - 145px);
    }
  }
  .close {
    height: calc(100% - 33px);
    @media screen and (max-width: 500px) {
      height: calc(100% - 33px);
      .divider-v-tab {
        bottom: 65px;
      }
    }
  }
  .module-payment {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    place-content: baseline;
    padding-top: 10px;
    overflow-x: hidden;
    @media screen and (max-width: 500px) {
      display: grid;
    }
    &-swipe{
      height: 100%;
      .carousel-indicator{
        display: none;
      }
      &-pc{
        display: flex;
        height: 100%;
        @media screen and (max-width: 768px) {
          display: grid;
        }
      }
      &-mobile{
        display: none;
        height: 100%;
        @media screen and (max-width: 500px) {
          display: block;
        }
        .carousel{
          .carousel-items{
            height: 83vh;
          }
        }
      }
    }
    &.open{
      justify-content: center;
      @media screen and (max-height: 1048px) {
        overflow-y: auto;
      }
      @media screen and (max-height: 2000px) {
        height: calc(100% - 145px);
      }
      @media screen and (max-width: 768px) {
        display: block;
        height: calc(100% - 145px);
      }
      @media screen and (max-width: 500px) {
        display: block;
        height: calc(100% - 190px);
      }
    }
    &.open-pay{
      @media screen and (max-height: 2000px) {
        height: calc(100% - 70px);
      }
      @media screen and (max-width: 768px) {
        display: block;
        height: calc(100% - 110px);
      }
      @media screen and (max-width: 500px) {
        display: block;
        height: calc(100% - 155px);
      }
    }
    &.close-pay{
      @media screen and (max-height: 2000px) {
        height: calc(100% - 5px);
      }
      @media screen and (max-width: 768px) {
        display: block;
        height: calc(100% - 45px);
      }
      @media screen and (max-width: 500px) {
        display: block;
        height: calc(100% - 45px);
      }
    }
    &.close{
      justify-content: center;
      @media screen and (max-height: 2000px) {
        height: calc(100% - 80px);
      }
      @media screen and (max-width: 768px) {
        display: block;
        height: calc(100% - 80px);
      }
      @media screen and (max-width: 500px) {
        display: block;
        height: calc(100% - 80px);
      }
    }
    &::-webkit-scrollbar {
      width: 3px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #444B57 !important;
      box-shadow: unset !important;
      border-color: #444B57 !important;
      border-radius: 6px !important;
    }
  }
  .divider {
    margin: 0px 70px 0px 70px;
    width: 2px;
    height: 403px;
    flex-shrink: 0;
    background: #444B57;
    @media screen and (max-width: 1024px) {
      margin: 0px 30px;
    }
    @media screen and (max-width: 768px) {
      height: 2px;
      width: 500px;
      margin: 20px auto;
    }
    @media screen and (max-width: 500px) {
      max-width: 380px;
    }
  }
  .divider-v-tab {
    display: none;
    width: 356px;
    height: 4px;
    border-radius: 6px;
    flex-shrink: 0;
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 500px) {
      display: flex;
    }
    &-item {
      width: 50%;
      background: #C9C9C9;
      &-active {
        background: #444B57;
      }
    }
  }
  .divider-v {
    display: none;
    width: 500px;
    height: 2px;
    margin: 20px 0px 20px 0px;
    flex-shrink: 0;
    background: #444B57;
    @media screen and (max-width: 1000px) {
      display: inline;
    }
    @media screen and (max-width: 500px) {
      display: none;
    }
    &-tab{
      &-item{
        cursor: pointer;
      }
    }
  }
  .return {
    color: #444B57;
    text-decoration: none;
    display: flex;
    width: 80px;
    align-content: center;
    padding: 15px 0px 0px 15px;
    &-text {
      color: #444B57;
      font-size: 14px;
      font-family: 'Source Sans Pro';
    }
    &-icon {
      width: 8px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 12px;
    }
  }
}
</style>
