<template>
  <div v-if="(sectionId === 4 && data.length > 0) || (sectionId !== 4 && data?.rows?.length > 0)">
    <v-chart class="chart" :key="chartKey" :option="option" :initOptions="{ renderer: 'svg' }" />
  </div>
  <div class="col-3 noresults" v-else>
    <div class="wrapper__noresults">Sin Resultados</div>
  </div>
  <!-- autoresize -->
</template>

<script>
/*!
* Copyright 2024 CLICK2BUY SAS
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
import { use } from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { defineComponent } from 'vue';

use([
  SVGRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default defineComponent({
  name: 'AreaChartPrec',
  props: ['data', 'sectionId', 'colors'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: ''
  },
  data () {
    return {
      chartKey: 0,
      convertedData: [],
      localDataChart: [],
      dataLegends: [],
      option: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
        ]
      }
    };
  },
  mounted () {
    this.generateDataChart();
  },
  methods: {
    generateDataChart () {
      this.resetChart();
      if (this.sectionId !== 4) {
        this.localDataChart = { ...this.data };
        const dataLegends = [];
        const dataArea = [];
        const axisXData = [];
        const legendDefault = this.localDataChart?.rows?.length > 0 ? Object.keys(this.localDataChart.rows[0])[0] : null; // O un valor por defecto
        dataLegends.push(legendDefault);
        if (this.localDataChart?.rows?.length > 0) {
          this.localDataChart.rows.forEach((item) => {
            // valores que irán en el eje Y
            dataArea.push(item.Reservas);
            axisXData.push(item.mes);
          });
          this.option.series.push({
            name: legendDefault,
            type: 'line',
            stack: 'Total',
            areaStyle: {
            },
            itemStyle: {
              color: this.getCustomColor(),
              opacity: 1
            },
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: dataArea
          });
          this.option.xAxis[0].data = axisXData;
          this.option.legend.data = dataLegends;
        } else {
          this.resetChart();
        }
      }
      if (this.sectionId === 4) {
        this.localDataChart = [...this.data];
        const legends = [];
        const seriesData = {};
        const axisXData = [];
        // Inicializar `seriesData` para cada legend y llenar `axisXData`
        const order = ['Reservas', 'Precompras', 'Walkin']; // Orden deseado
        this.localDataChart.forEach(item => {
          order.forEach(key => { // Iterar en el orden correcto
            if (Object.prototype.hasOwnProperty.call(item, key)) { // Comprobación segura
              if (!legends.includes(key)) {
                legends.push(key); // Agregar en orden predefinido
                seriesData[key] = []; // Inicializar array para cada serie
              }
              seriesData[key].push(Number(item[key])); // Agregar valores convertidos a número
            }
          });
          axisXData.push(item.mes); // Agregar valores del eje X
        });
        // Configurar series en el gráfico
        Object.entries(seriesData).forEach(([name, data], index) => {
          this.option.series.push({
            name,
            type: 'line',
            stack: 'Total',
            areaStyle: {
            },
            itemStyle: {
              color: this.colors[index]
            }, // Configurar área de la gráfica
            smooth: true, // Líneas suaves
            emphasis: {
              focus: 'series'
            },
            data
          });
        });
        // Actualizar leyendas y datos del eje X
        this.option.legend.data = legends;
        this.option.xAxis[0].data = axisXData;
      }
    },
    resetChart () {
      if (this.sectionId === 4) {
        this.localDataChart = [];
      } else {
        this.localDataChart = {};
      }
      this.option.series = [];
      this.option.xAxis[0].data = [];
      this.option.legend.data = [];
      this.chartKey++;
    },
    getCustomColor () {
      let color = '';
      switch (this.sectionId) {
        case 1:
          color = this.colors[0];
          break;
        case 2:
          color = this.colors[1];
          break;
        case 3:
          color = this.colors[2];
          break;
        default:
          break;
      };
      return color;
    }
  },
  watch: {
    data: {
      deep: true, // Detecta cambios profundos en el objeto
      handler (data) {
        this.generateDataChart();
        this.chartKey++;
      }
    },
    sectionId (value) {
      this.chartKey = value;
    }
  }
});
</script>

<style scoped>
.chart {
}
</style>
