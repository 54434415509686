import namespace from '@/helpers/namespace';

export default namespace('auth', {
  getters: [
    'user',
    'userId',
    'changedPassword',
    'changedVendor',
    'showTempPassword'
  ],
  actions: [
    'login',
    'register',
    'reset',
    'validateToken',
    'logout',
    'checkEmail',
    'updateUser',
    'changedPassword',
    'refreshUser',
    'loginInvite',
    'changedTempPassword'
  ],
  mutations: [
    'setShowChangedPassword',
    'setShowChangedVendor',
    'setUser',
    'setShowTempPassword'
  ]
});
