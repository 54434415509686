<template>
  <div id="confirm-alert">
    <b-loading :active="loading" :can-cancel="false"></b-loading>
    <div class="content">
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="24" stroke="#FF0101" stroke-width="2"/>
        <path d="M23.9306 32.2426L23.3806 13.3426L23.2806 8.64258H27.4306L27.3306 13.3426L26.7806 32.2426H23.9306ZM25.3806 42.7426C23.7306 42.7426 22.3806 41.4426 22.3806 39.6426C22.3806 37.7426 23.7306 36.4426 25.3806 36.4426C26.9806 36.4426 28.3306 37.7426 28.3306 39.6426C28.3306 41.4426 26.9806 42.7426 25.3806 42.7426Z" fill="#FF0101"/>
      </svg>
      <h2>¿Estas seguro de cancelar el envío programado para este {{ type == 'email' ? 'correo': 'sms'}}?</h2>
    </div>
    <div class="actions">
      <button class="action accept" @click="cancelSend()">Aceptar</button>
      <button class="action cancel" @click="closeAlert">Cancelar</button>
    </div>
  </div>
</template>
<script>
import serviceMarketing from '@/helpers/apis/serviceMarketing';
export default {
  props: ['type', 'id'],
  data () {
    return {
      loading: false
    };
  },
  methods: {
    cancelSend () {
      this.loading = true;
      serviceMarketing.delete('/marketing-campaign/' + this.id).then(({ data }) => {
        this.loading = false;
        this.$emit('cancelled');
        this.$emit('close');
      }).catch((err) => {
        this.loading = false;
        console.error(err);
      });
    },
    closeAlert () {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss">
  #confirm-alert {
    width: 320px;
    flex-shrink: 0;
    font-family: "Source Sans Pro";
    background: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    .content{
      text-align: center;
      h2{
        color: #444B57;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 10px 0 20px 0;
      }
    }
    .actions{
      display: flex;
      justify-content: center;
      gap: 20px;
      .action{
        cursor: pointer;
        width: 120px;
        height: 30px;
        flex-shrink: 0;
        font-size: 12px;
        &.accept{
          border-radius: 10px;
          border: 1px solid #C9C9C9;
          background: #444B57;
          color: #fff;
        }
        &.cancel{
          border-radius: 10px;
          border: 1px solid #C9C9C9;
          background: #fff;
          color: #444B57;
        }
      }
    }
  }
</style>
