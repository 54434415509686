<template>
  <section class="lastContentNavbar">
    <div
      class="iconHeader"
      v-if="$route.path.split('/')[2] === 'searchs' || $route.path.split('/')[2] === 'reservations' || $route.path.split('/')[2] === 'onboarding'"
    >
      <img :src="vendor.thumbnailURL" @click="$emit('openSideBar')" alt="logo" />
    </div>
    <div class="connectionContainer">
      <i
        :style="{ 'background-color': socket ? '#CDD500' : '#f44336' }"
        class="icon_connection"
      ></i>
    </div>
    <Notifications></Notifications>
    <a @click="$emit('openSearch')" class="searchedinmenu" v-if="!notShow">
      <i class="icon_search"></i>
    </a>
    <div
      v-if="!notShow"
      :class="{ opened: showMenuMobile , filterActive: filterActivate || dateFilterSelect.length > 0}"
      @click="$emit('changeShowMobile')"
      class="controlContainer"
    >
      <i :class="{ icon_close: showMenuMobile }" class="icon_controlPanel"></i>
      <!-- <i class="icon_moreInfo"></i> -->
    </div>
  </section>
</template>

<script>
import Notifications from '@/components/_shared/Notifications';
export default {
  components: {
    Notifications
  },
  props: ['showMenuMobile', 'filterActivate', 'dateFilterSelect', 'notShow']
};
</script>

<style>
</style>
