<template>
  <div class id="calendar-marketing">
    <div class="months-title">
      <div @click.prevent.stop="anteriorMes()" v-if="!loadingReservationCalendar">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
          <path d="M2.80357 7.76542C2.70917 7.6718 2.63425 7.56041 2.58312 7.43769C2.53199 7.31497 2.50567 7.18334 2.50567 7.05039C2.50567 6.91744 2.53199 6.78581 2.58312 6.66309C2.63425 6.54037 2.70917 6.42898 2.80357 6.33536L7.42608 1.72291C7.52048 1.62929 7.5954 1.51791 7.64653 1.39518C7.69765 1.27246 7.72398 1.14083 7.72398 1.00788C7.72398 0.874936 7.69765 0.743304 7.64653 0.620581C7.5954 0.497859 7.52048 0.386474 7.42608 0.292853C7.23739 0.105282 6.98215 0 6.71609 0C6.45003 0 6.19478 0.105282 6.00609 0.292853L1.38358 4.91537C0.817793 5.48186 0.5 6.24976 0.5 7.05039C0.5 7.85103 0.817793 8.61892 1.38358 9.18541L6.00609 13.8079C6.19367 13.994 6.44686 14.0989 6.71105 14.1C6.84359 14.1007 6.97498 14.0753 7.09768 14.0252C7.22038 13.9751 7.33198 13.9013 7.42608 13.8079C7.52048 13.7143 7.5954 13.6029 7.64653 13.4802C7.69765 13.3575 7.72398 13.2258 7.72398 13.0929C7.72398 12.96 7.69765 12.8283 7.64653 12.7056C7.5954 12.5829 7.52048 12.4715 7.42608 12.3779L2.80357 7.76542Z" fill="#444B57"/>
        </svg>
      </div>
      <div>
        <span @click="showMonthChange()">{{monthName}}&nbsp;</span>
        <span @click="showYearChange()">{{yearName}}</span>
      </div>
      <div @click.prevent.stop="siguienteMes()" v-if="!loadingReservationCalendar">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
          <path d="M5.19643 7.76542C5.29083 7.6718 5.36575 7.56041 5.41688 7.43769C5.46801 7.31497 5.49433 7.18334 5.49433 7.05039C5.49433 6.91744 5.46801 6.78581 5.41688 6.66309C5.36575 6.54037 5.29083 6.42898 5.19643 6.33536L0.573917 1.72291C0.479525 1.62929 0.404603 1.51791 0.353474 1.39518C0.302346 1.27246 0.276023 1.14083 0.276023 1.00788C0.276023 0.874936 0.302346 0.743304 0.353474 0.620581C0.404603 0.497859 0.479525 0.386474 0.573917 0.292853C0.762607 0.105282 1.01785 0 1.28391 0C1.54997 0 1.80522 0.105282 1.99391 0.292853L6.61642 4.91537C7.18221 5.48186 7.5 6.24976 7.5 7.05039C7.5 7.85103 7.18221 8.61892 6.61642 9.18541L1.99391 13.8079C1.80633 13.994 1.55315 14.0989 1.28895 14.1C1.15641 14.1007 1.02502 14.0753 0.90232 14.0252C0.77962 13.9751 0.668019 13.9013 0.573917 13.8079C0.479525 13.7143 0.404603 13.6029 0.353474 13.4802C0.302346 13.3575 0.276023 13.2258 0.276023 13.0929C0.276023 12.96 0.302346 12.8283 0.353474 12.7056C0.404603 12.5829 0.479525 12.4715 0.573917 12.3779L5.19643 7.76542Z" fill="#444B57"/>
        </svg>
      </div>
    </div>
    <div class="calendar" v-if="!showMonthAll && !showYearAll">
      <div class="header columns is-mobile">
        <div class="cell br column">DOM</div>
        <div class="cell br column">LUN</div>
        <div class="cell br column">MAR</div>
        <div class="cell br column">MIE</div>
        <div class="cell br column">JUE</div>
        <div class="cell br column">VIE</div>
        <div class="cell column">SAB</div>
      </div>
      <div :id="'calendarMarketing-row' + row" :key="'calendarMarketing-row' + row" class="columns is-mobile br" v-for="row in lastRow()" :class="{bt: row > 1, bb: (row === lastRow())}">
        <div :key="cell" class="column" v-for="cell in 7">
          <div class="cell bl" v-show="!dates[row - 1][cell - 1]"></div>
          <div
            :class="{selected: timestamps[row - 1][cell - 1] === timeCalendar, holiday: isDayHoliday(timestamps[row - 1][cell - 1]), isDisabledT: timestamps[row -1][cell - 1] < todayTimes}"
            @click="setDateSelect(timestamps[row - 1][cell - 1])"
            class="cell bl date available pointer"
            v-if="dates[row - 1][cell - 1]"
          >
            <div
              :class="{daySelect: timestamps[row - 1][cell - 1] === timeCalendar, isDisabled: timestamps[row -1][cell - 1] < todayTimes}"
              class="day"
            >{{days[row - 1][cell - 1]}}</div>
          </div>
            <div
              class="day"
            ></div>
        </div>
      </div>
    </div>
    <div v-if="showMonthAll">
      <div class>
        <div class>
          <span @click="showMonthAll = false" class>
            Cerrar
            <em class="fa fa-times-circle"></em>
          </span>
        </div>
      </div>
      <div class>
        <div class>
          <button @click="setChangeMonth(0)" class>Ene - {{ currentYear }}</button>
          <button @click="setChangeMonth(1)" class>Feb - {{ currentYear }}</button>
          <button @click="setChangeMonth(2)" class>Mar - {{ currentYear }}</button>
        </div>
      </div>
      <div class>
        <div class>
          <button @click="setChangeMonth(3)" class>Abr - {{ currentYear }}</button>
          <button @click="setChangeMonth(4)" class>May - {{ currentYear }}</button>
          <button @click="setChangeMonth(5)" class>Jun - {{ currentYear }}</button>
        </div>
      </div>
      <div class>
        <div class>
          <button @click="setChangeMonth(6)" class>Jul - {{ currentYear }}</button>
          <button @click="setChangeMonth(7)" class>Ago - {{ currentYear }}</button>
          <button @click="setChangeMonth(8)" class>Sep - {{ currentYear }}</button>
        </div>
      </div>
      <div class>
        <div class>
          <button @click="setChangeMonth(9)" class>Oct - {{ currentYear }}</button>
          <button @click="setChangeMonth(10)" class>Nov - {{ currentYear }}</button>
          <button @click="setChangeMonth(11)" class>Dic - {{ currentYear }}</button>
        </div>
      </div>
    </div>
    <div v-if="showYearAll">
      <div class>
        <div class>
          <select @change="setChangeYear(year)" class v-model="year">
            <option value>-- Seleccione --</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
            <option value="2030">2030</option>
          </select>
        </div>
      </div>
      <div class>
        <div class>
          <span @click="showYearAll = false" class>
            Cerrar
            <em class="fa fa-times-circle"></em>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'MarketingCalendar',
  props: ['programingDate'],
  data: () => ({
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
    year: new Date().getFullYear(),
    showMonthAll: false,
    showYearAll: false,
    monthChange: [],
    todayTimes: null,
    timeCalendar: null
  }),
  mounted () {
    this.timeCalendar = this.programingDate;
    if (!this.timeCalendar) {
      const month = parseInt(this.$moment().format('M'));
      const date = this.monthConvert(month);
      this.currentMonth = date;
      this.todayTimes = this.$moment().format('YYYY-MM-DD');
      const year = parseInt(this.$moment().format('YYYY'));
      this.currentYear = year;
      this.year = year;
      return;
    }
    const dater = this.timeCalendar + 'T00:00:00';
    const month = parseInt(this.$moment(dater).format('M'));
    const date = this.monthConvert(month);
    this.currentMonth = date;
    this.todayTimes = this.$moment().format('YYYY-MM-DD');
    const year = parseInt(this.$moment(dater).format('YYYY'));
    this.currentYear = year;
    this.year = year;
  },
  computed: {
    monthName () {
      return this.$moment(new Date(this.currentYear, this.currentMonth + 1, 0, 0, 0, 0)).format('MMMM');
    },
    yearName () {
      return this.$moment(new Date(this.currentYear, this.currentMonth + 1, 0, 0, 0, 0)).format('YYYY');
    },
    nextMonth () {
      return this.$moment(new Date(this.currentYear, this.currentMonth + 2, 0, 0, 0, 0)).format('MMMM');
    },
    prevMonth () {
      return this.$moment(new Date(this.currentYear, this.currentMonth, 0, 0, 0, 0)).format('MMMM');
    },
    dayOfWeekOfFirst () {
      const date = new Date(this.currentYear, this.currentMonth);
      date.setDate(1);
      return this.$moment(date).day();
    },
    dates () {
      const dates = _.map(_.range(6), row => _.map(_.range(7), cell => {
        const initialDate = new Date(this.currentYear, this.currentMonth, 0, 0, 0, 0, 0);
        const date = this.$moment(initialDate).add(cell - this.dayOfWeekOfFirst + (7 * row + 1), 'days').startOf('day');
        const mon = this.$moment(new Date(date)).month();
        const mon2 = this.$moment(initialDate).month() + 1;
        const mon3 = (mon2 === 12) ? 0 : mon2;
        return mon === mon3 ? this.$moment(date) : null;
      }));
      return dates;
    },
    days () {
      return _.map(this.dates, row => _.map(row, cell => cell ? cell.format('D') : null));
    },
    timestamps () {
      return _.map(this.dates, row => _.map(row, cell => cell ? cell.format('YYYY-MM-DD') : null));
    },
    isLargeMonth () {
      return _.some(this.dates[5], date => !!date);
    }
  },
  methods: {
    lastRow () {
      if (this.isLargeMonth) {
        return 6;
      } else {
        return 5;
      }
    },
    setChangeMonth (month) {
      this.currentMonth = parseInt(month);
      this.showMonthAll = false;
    },
    setChangeYear (year) {
      this.currentYear = parseInt(year);
      this.showYearAll = false;
    },
    showMonthChange () {
      this.showMonthAll = true;
    },
    showYearChange () {
      this.showYearAll = true;
    },
    anteriorMes () {
      this.currentMonth--;
    },
    siguienteMes () {
      this.currentMonth++;
    },
    setDateSelect (date) {
      if (this.timeCalendar === date) {
        this.timeCalendar = null;
      } else {
        this.timeCalendar = date;
      }
      this.$emit('dateSelected', this.timeCalendar);
    }
  }
};
</script>
<style lang="scss">
#calendar-marketing {
  background: #ffffff;
  padding: 0px 10.5px;
  /* position: absolute; */
  height: 100%;
  width: 100%;
  z-index: 99;
  font-size: 12px;
  .columns {
    margin: 0;
    padding: 0;
    &:first-child {
      margin: 0 !important;
    }
    &:last-child {
      margin: 0 !important;
    }
    .column {
      margin: 0;
      padding: 0;
    }
  }

  .months-title {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
    }
    span {
      color: #444B57;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .calendar {
    .header {
      color: #000000;
      display: flex;
      text-align: center;
      justify-content: center;
      height: 30px;
      border: 1px solid #444B57;
      background: rgba(255, 255, 255, 0.10);
      margin-top: 10px !important;
    }
    .br {
      border-right: 1px solid #444B57;
    }
    .bl {
      border-left: 1px solid #444B57;
    }
    .bt {
      border-top: 1px solid #444B57;
    }
    .bb {
      border-bottom: 1px solid #444B57;
    }
    .cell {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin: 0px;
      &.selected {
        color: white!important;
        background: #444B57;
        .dot-text2 {
          top: -4px !important;
          right: -4px !important;
        }
      }
      .daySelect {
        font-weight: 500;
      }
      .day {
        line-height: 0;
      }
    }
    .cell.date {
      position: relative;
      color: #000000;
      font-weight: normal;
      cursor: pointer;
      .dot-text2 {
        cursor: pointer;
        border: inherit !important;
        border-radius: 50%;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex-direction: column;
        position: absolute;
        padding: 6px 4px 7px 4px;
        line-height: 0;
        font-size: 7px;
        top: -3px;
        right: -3px;
        height: 15px;
        width: 15px;
        background: #411d39;
        transition: 0.1s;
      }
    }
  }

  .today {
    color: #fff;
    border: 1px solid #fff;
    position: absolute;
    top: 23px;
    right: 1rem;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 10px;
  }
  .load-reservations {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 340px;
    p {
      color: #fff;
      font-weight: normal;
      font-size: 20px;
    }
  }

  .isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .isDisabledT {
    pointer-events: none;
  }
}
</style>
