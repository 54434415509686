<template>
  <div class="sidebar-info-container">
    <div class="sidebar-info-container__card-reservations-people" :class="getTypeClass()[0]" v-if="type !== 'General'">
      <InfoCardReservation
        title="Total"
        :totalReservations="type !== 'Walk-in' ? totalReservations : dataReservations.reservas"
        :totalUsers="totalPeople"
        :customIcon="getTypeClass()[1]"
      />
      <InfoCardReservation
        title="Total efectivas"
        :totalReservations="type !== 'Walk-in' ? totalReservationsEfective : dataReservations.reservas"
        :totalUsers="totalPeopleEfective"
        :customIcon="getTypeClass()[1]"
      />
    </div>
    <GeneralReservationsPeopleCards
      v-else
      :dataReservations="dataReservations"
      :totalReservationGeneral="totalReservationGeneral"
      :totalPeopleGeneral="totalPeopleGeneral"
      :totalReservationPrePurchaseGeneral="totalReservationPrePurchaseGeneral"
      :totalPeoplePrepurchaseGeneral="totalPeoplePrepurchaseGeneral"
    />
    <div class="sidebar-info-container__section-filter-dates">
      <div class="sidebar-info-container__section-filter-dates__year-clean-filters">
        <div class="sidebar-info-container__section-filter-dates__year-clean-filters__year container-dates-years-general" @mouseleave="() => openYearsSelector = false" @click="() => openYearsSelector = !openYearsSelector">
          <div>Año
          </div>
          <div class="sidebar-info-container__section-filter-dates__year-clean-filters__year__list">
            <span>{{year}}</span>
            <div class="sidebar-info-container__section-filter-dates__year-clean-filters__year__list__items" v-if="openYearsSelector">
              <ul>
                <li v-for="yearOption in years" :key="yearOption.id" @click="setYear(yearOption.id)" :class="{'active-year' : year == yearOption.id}" >
                  {{ yearOption.id }}
                </li>
              </ul>
            </div>
          </div>
          <i class="icon-mks icon_arrowDown"></i>
        </div>
        <b-button :disabled="dateFromGlobal === null && dateToGlobal === null && daySection < 1" @click="clearDate()" class="sidebar-info-container__section-filter-dates__year-clean-filters__clean">
          Quitar filtros
        </b-button>
      </div>
      <div class="sidebar-info-container__section-filter-dates__date">
        <div class="sidebar-info-container__section-filter-dates__date__container">
          <h3>Inicio</h3>
          <div class="sidebar-info-container__section-filter-dates__date__container__calendar container-dates-years-general" @click="openResponsiveDatesRanges">
            <div>
              <i class="icon-mks icon_calendar_contactless"></i>
            </div>
            <div class="sidebar-info-container__section-filter-dates__date__container__calendar__display">
              <span>
                {{dateFromGlobal | moment("DD/MM/YYYY")}}
              </span>
            </div>
            <i class="icon-mks icon_arrowDown"></i>
          </div>
        </div>
        <div class="sidebar-info-container__section-filter-dates__date__container">
          <h3>Fin</h3>
          <div class="sidebar-info-container__section-filter-dates__date__container__calendar container-dates-years-general" @click="openResponsiveDatesRanges">
            <div>
              <i class="icon-mks icon_calendar_contactless"></i>
            </div>
            <div class="sidebar-info-container__section-filter-dates__date__container__calendar__display">
              <span>
                {{dateToGlobal | moment("DD/MM/YYYY") }}
              </span>
            </div>
            <i class="icon-mks icon_arrowDown"></i>
          </div>
        </div>
        <DateRanges
          v-if="popupFilterDates"
          :dateMasks="dateMasks"
          :themeStyles="themeStyles"
          @closeFilters="() => popupFilterDates = false"
          @filterWithDates="filterWithDates"
          :modeModal="false"
          :sectionId="sectionId"
          :valueDate="dateDisplayFromRanges"
          @changeDate="changeDate"
        />
      </div>
      <div class="sidebar-info-container__section-filter-dates__types">
        <b-button :class="{'sidebar-info-container__section-filter-dates__types--active' : this.daySection == 3}" @click="getDataByFilter('DESAYUNO', 3)"><i class="icon-mks icon_metrics_breakfast"></i><span>Desayuno</span></b-button>
        <b-button :class="{'sidebar-info-container__section-filter-dates__types--active' : this.daySection == 1}" @click="getDataByFilter('ALMUERZO', 1)"><i class="icon-mks icon_metrics_lunch"></i><span>Almuerzo</span></b-button>
        <b-button :class="{'sidebar-info-container__section-filter-dates__types--active' : this.daySection == 2}" @click="getDataByFilter('CENA', 2)"><i class="icon-mks icon_metrics_dinner"></i><span>Cena</span></b-button>
      </div>
    </div>
    <div class="sidebar-info-container__resume" :class="{'fix-padding-bottom-responsive-resume' : !acordeonResponsiveResume, 'sidebar-info-container__resume-fix' : type === 'prePurchase'}" v-if="type !== 'Walk-in' && type !== 'General'">
      <div class="acordeon-resume" :class="{'acordeon-resume__responsive' : acordeonResponsiveResume}">
        <div class="sidebar-info-container__resume__icons-container">
          <div class="sidebar-info-container__resume__icons-container__info">
            <div class="sidebar-info-container__resume__icons-container__info__icon-description" :class="getTypeClass()[0]">
              <i class="icon-mks icon_metrics_origin"></i>
              <h3>Origen</h3>
            </div>
            <div class="sidebar-info-container__resume__icons-container__info__col">
              <div :key="index" v-for=" (data,index) in dataReservations.byReferrer">
                <img
                  :key="index"
                  :src="getUrlImageReferrer(data.referrer)"
                  style="height: 20px; width: 20px;"
                  v-if="getUrlImageReferrer(data.referrer) !== null"
                  :content="getReferrerForOrigin(data.referrer)"
                  v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', theme: 'light-border'}"
                />
                <i
                  :class="iconReferrer(data.referrer)"
                  :key="data.referrer"
                  style="margin-bottom: 10px;margin-right: 0;height: 20px; width: 20px;"
                  class="icon-mks gray-blue"
                  v-else
                ></i>
                <!-- v-tooltip="{content: validateName(data.referrer), placement: 'right'}" -->
              </div>
            </div>
          </div>
          <div class="sidebar-info-container__resume__icons-container__info">
            <div class="sidebar-info-container__resume__icons-container__info__icon-description" :class="getTypeClass()[0]">
              <i class="icon-mks icon_reservation"></i>
              <h3>Reservas</h3>
            </div>
            <div class="sidebar-info-container__resume__icons-container__info__col">
              <div
                  :key="index"
                  class="sidebar-info-container__resume__icons-container__info__col__data-height"
                  v-for=" (data,index) in dataReservations.byReferrer"
                >
                {{ data.reservations }}
              </div>
            </div>
          </div>
          <div class="sidebar-info-container__resume__icons-container__info">
            <div class="sidebar-info-container__resume__icons-container__info__icon-description" :class="getTypeClass()[0]">
              <i class="icon-mks icon_total_users_default_new"></i>
              <h3>Personas</h3>
            </div>
            <div class="sidebar-info-container__resume__icons-container__info__col">
              <div
                :key="index"
                class="sidebar-info-container__resume__icons-container__info__col__data-height"
                v-for=" (data,index) in dataReservations.byReferrer"
              >
                {{ data.people }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="type === 'prePurchase'" class="sidebar-info-container__resume__recaudo-container">
          <div class="sidebar-info-container__resume__recaudo" :class="getTypeClass()[0]" >
            <h3>Recaudo efectivo</h3>
            <span>
              {{getPriceCop(dataReservations.balance)}}
              <i
                class="icon-mks icon_info_tooltip"
                :content="generateTooltipContent(
                  'Recaudo efectivo',
                  'Reservas próximas y finalizadas que llegaron al restaurante '
                )"
                ref="tooltipTriggerEffective"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light-border tippy-popper__custom-container',
                  placement: 'bottom',
                  interactive: true,
                  trigger: 'click',
                  offset: '-90, 0'
                }"
              ></i>
            </span>
          </div>
          <div class="sidebar-info-container__resume__recaudo" :class="getTypeClass()[0]">
            <h3>Recaudo no efectivo</h3>
            <span>
              {{getPriceCop(dataReservations.totalBalance - dataReservations.balance)}}
              <i
                class="icon-mks icon_info_tooltip"
                :content="generateTooltipContent(
                  'Recaudo no efectivo',
                  'Reservas que no llegaron o canceladas que no se les hizo devolución'
                )"
                ref="tooltipTriggerNoEffective"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light-border tippy-popper__custom-container',
                  placement: 'bottom',
                  interactive: true,
                  trigger: 'click',
                  offset: '-90, 0'
                }"
              ></i>
            </span>
          </div>
          <div class="sidebar-info-container__resume__recaudo" :class="getTypeClass()[0]">
            <h3>Total recaudo</h3>
            <span>
              {{getPriceCop(this.dataReservations.totalBalance)}}
              <i
                class="icon-mks icon_info_tooltip"
                :content="generateTooltipContent(
                  'Total recaudo',
                  'Es la suma del recaudo efectivo y el recaudo no efectivo'
                )"
                ref="tooltipTriggerTotal"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light-border tippy-popper__custom-container',
                  placement: 'bottom',
                  interactive: true,
                  trigger: 'click',
                  offset: '-90, 0'
                }"
              ></i>
            </span>
          </div>
        </div>
      </div>
      <div class="sidebar-info-container__resume__icons-container-action-acordeon" @click="()=> acordeonResponsiveResume = !acordeonResponsiveResume">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="6" viewBox="0 0 18 6" fill="none" :class="{'rotate-icon-active' : acordeonResponsiveResume}">
          <path d="M18 0.645106C18.0007 0.729516 17.978 0.813193 17.9332 0.891339C17.8884 0.969483 17.8224 1.04056 17.739 1.10049L12.1872 5.05784C11.7693 5.35649 11.2728 5.59344 10.7262 5.75511C10.1796 5.91678 9.59362 6 9.00183 6C8.41005 6 7.82408 5.91678 7.27748 5.75511C6.73089 5.59344 6.23441 5.35649 5.81649 5.05784L0.264628 1.10049C0.180731 1.04069 0.114179 0.969693 0.0687744 0.891558C0.0233694 0.813423 -2.29489e-07 0.729679 -2.3407e-07 0.645106C-2.38651e-07 0.560534 0.0233693 0.476789 0.0687743 0.398654C0.114179 0.320519 0.180731 0.249525 0.264628 0.189723C0.348525 0.129921 0.448126 0.0824823 0.557743 0.0501179C0.667361 0.0177536 0.784848 0.00109574 0.903497 0.00109573C1.02215 0.00109573 1.13963 0.0177536 1.24925 0.0501179C1.35887 0.0824823 1.45847 0.129921 1.54237 0.189722L7.09422 4.14707C7.60037 4.50741 8.28648 4.7098 9.00183 4.7098C9.71719 4.7098 10.4033 4.50741 10.9094 4.14707L16.4613 0.189722C16.545 0.129606 16.6445 0.0818894 16.7541 0.0493271C16.8638 0.0167649 16.9814 2.11394e-07 17.1002 2.06202e-07C17.219 2.0101e-07 17.3366 0.0167648 17.4462 0.0493271C17.5559 0.0818893 17.6554 0.129606 17.739 0.189722C17.8224 0.249653 17.8884 0.320728 17.9332 0.398873C17.978 0.477018 18.0007 0.560695 18 0.645106Z" fill="white"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCardReservation from '@/components/MetricsV2/subcomponents/InfoCardReservation';
import DateRanges from '@/components/MetricsV2/subcomponents/DateRanges';
import GeneralReservationsPeopleCards from '@/components/MetricsV2/GeneralReservationsPeopleCards';
/* import DatePick from 'vue-date-pick'; */
import { mapGetters } from 'vuex';
import 'vue-date-pick/dist/vueDatePick.css';
import metricsTypes from '@/store/types/metrics';
import globalTypes from '@/store/types/global';
/* import serviceReport from '@/helpers/apis/serviceReport';
import axios from 'axios'; */
const MIN_YEARS = 5;
export default {
  name: 'SideBarInfo',
  props: [
    'dataReservations',
    'totalPeopleEfective',
    'totalReservationsEfective',
    'totalPeople',
    'totalReservations',
    'type',
    'sectionId',
    'totalReservationGeneral',
    'totalPeopleGeneral',
    'totalReservationPrePurchaseGeneral',
    'totalPeoplePrepurchaseGeneral'
  ],
  components: {
    InfoCardReservation,
    GeneralReservationsPeopleCards,
    DateRanges
  },
  data () {
    return {
      dateFrom: null,
      dateTo: null,
      load: false,
      years: [],
      year: null,
      generate: false,
      currentYear: new Date().getFullYear(),
      startYear: 2018,
      openYearsSelector: false,
      daySection: 0,
      themeStyles: {
        wrapper: {
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '8px'
        }
      },
      dateMasks: {
        weekdays: 'WWW' // Formato abreviado para los nombres de días de la semana
      },
      popupFilterDates: false,
      acordeonResponsiveResume: false,
      isMobile: false,
      dateDisplayFromRanges: null
    };
  },
  created () {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  destroyed () {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  computed: {
    ...mapGetters({
      dataReservation: [metricsTypes.getters.dataReservation],
      dataReservationRange: [metricsTypes.getters.dataReservationRange],
      byMonth: [metricsTypes.getters.byMonth],
      byDay: [metricsTypes.getters.byDay],
      byRange: [metricsTypes.getters.byRange],
      notEffective: [metricsTypes.getters.notEffective],
      timeState: [metricsTypes.getters.timeState],
      yearGlobal: [metricsTypes.getters.year],
      dateFromGlobal: [metricsTypes.getters.dateFrom],
      dateToGlobal: [metricsTypes.getters.dateTo],
      filterTypeDaySection: [metricsTypes.getters.filterTypeDaySection]
    }),
    getTypeClass () {
      return () => {
        let customClassByType = '';
        let customIcon = '';
        switch (this.type) {
          case 'reservation':
            customClassByType = 'bg_reserva';
            customIcon = 'icon_reservation';
            break;
          case 'prePurchase':
            customClassByType = 'bg_precompra';
            customIcon = 'icon_reserva_precompro';
            break;
          case 'Walk-in':
            customClassByType = 'bg_walking';
            customIcon = 'icon_metrics_walkin';
            break;
          case 'General':
            customClassByType = '';
            customIcon = '';
            break;
          default:
            break;
        }
        return [customClassByType, customIcon];
      };
    }
  },
  mounted () {
    const max = parseInt(this.$moment().format('YYYY'));
    const min = (max - MIN_YEARS);
    const years = [];
    for (let index = max; index >= min; index--) {
      years.push({ id: index });
    }
    if (this.dateFromGlobal !== null && this.dateToGlobal !== null) {
      this.dateFrom = this.dateFromGlobal;
      this.dateTo = this.dateToGlobal;
    }
    this.year = (this.yearGlobal !== max) ? this.yearGlobal : max;
    this.years = years;
    this.countVisibleDays();
    document.addEventListener('close-tippy', this.closeTooltip);
  },
  beforeDestroy () {
    document.removeEventListener('close-tippy', this.closeTooltip);
  },
  methods: {
    getReferrerForOrigin (name) {
      const nameFormatted = (name === 'whitelabel') ? 'página web' : (name === 'dashboard') ? 'telefono' : name;
      return nameFormatted;
    },
    closeTooltip () {
      if (this.$refs.tooltipTriggerEffective._tippy) {
        this.$refs.tooltipTriggerEffective._tippy.hide();
      }
      if (this.$refs.tooltipTriggerNoEffective._tippy) {
        this.$refs.tooltipTriggerNoEffective._tippy.hide();
      }
      if (this.$refs.tooltipTriggerTotal._tippy) {
        this.$refs.tooltipTriggerTotal._tippy.hide();
      }
    },
    generateTooltipContent (title, section) {
      return `
        <div class='tippy-popper__custom-container-theme__info'>
          <h3>${title}</h3>
          <span>${section}</span>
          <span class="icon-mks icon_close_alternative close-tooltip" onclick="document.dispatchEvent(new CustomEvent('close-tippy'))"></span>
        </div>
      `;
    },
    getPriceCop (value) {
      const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      const price = formatter.format(Math.abs(value));
      return price;
    },
    onMonthChange (newDate) {
    },
    validateName (name) {
      switch (name) {
        case 'whitelabel':
          return 'Página Web';
        case 'cross-selling':
          return 'Venta cruzada';
        case 'dashboard':
          return 'Teléfono';
        case 'resy':
          return 'Resy';
        case 'pautafacebook':
          return 'Facebook ads';
        default:
          return name;
      }
    },
    getDataByFilter (type, section) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      if (section === this.daySection) {
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            year: this.year,
            vendorId: this.$store.state.idVendor,
            type: this.sectionId
          }
        });
        this.$store.dispatch({
          type: 'metrics:getDataReservation',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        }).then(() => {
          this.daySection = 0;
          this.$store.commit(metricsTypes.mutations.setFilterTypeDaySection, null);
        }).catch(e => {
          this.$buefy.notification.open({
            message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
            type: 'is-danger'
          });
        });
        return;
      }
      this.$store.dispatch({
        type: 'metrics:getMetricReservations',
        data: {
          year: this.year,
          vendorId: this.$store.state.idVendor,
          type: this.sectionId,
          dateTo: this.dateTo,
          dateFrom: this.dateFrom,
          filter: type
        }
      });
      this.$store.dispatch({
        type: 'metrics:getDataReservationFilter',
        data: {
          vendorId: this.$store.state.idVendor,
          type: this.sectionId,
          dateTo: this.dateTo,
          dateFrom: this.dateFrom,
          filter: type,
          year: this.year
        }
      }).then(() => {
        this.daySection = section;
        this.$store.commit(metricsTypes.mutations.setFilterTypeDaySection, type);
      });
    },
    clearDate (fromChangeSection = false) {
      const max = parseInt(this.$moment().format('YYYY'));
      this.year = max;
      this.$store.commit(metricsTypes.mutations.setYear, max);
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.dateFrom = null;
      this.dateTo = null;
      this.dateDisplayFromRanges = null;

      this.$store.commit(metricsTypes.mutations.setDateFrom, null);
      this.$store.commit(metricsTypes.mutations.setDateTo, null);
      if (!fromChangeSection) {
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            year: this.year,
            vendorId: this.$store.state.idVendor,
            type: this.sectionId
          }
        });
        this.$store.dispatch({
          type: 'metrics:getDataReservation',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        }).then(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.daySection = 0;
        });
      }
      this.$store.commit(metricsTypes.mutations.setFilterTypeDaySection, null);
    },
    setYear (year) {
      this.year = year;
    },
    updateRange (range) {
      this.dateRange = range;
    },
    countVisibleDays () {
      const elements1 = document.querySelectorAll('.column-from-end-2 .vc-day[class*="vc-day"]');
      const elements2 = document.querySelectorAll('.column-from-end-1 .vc-day[class*="vc-day"]');
      const visibleElements = Array.from(elements1).filter(element => {
        return getComputedStyle(element).display !== 'none';
      });
      const visibleElements2 = Array.from(elements2).filter(element => {
        return getComputedStyle(element).display !== 'none';
      });
      this.visibleDaysCountColumn1 = visibleElements.length;
      this.visibleDaysCountColumn2 = visibleElements2.length;
    },
    filterWithDates () {
      if (this.dateDisplayFromRanges) {
        /* .set('year', this.year) */
        this.year = parseInt(this.$moment(this.dateDisplayFromRanges.start).format('YYYY'));
        const dateFromFormat = this.$moment(this.dateDisplayFromRanges.start).format('YYYY-MM-DD');
        const dateToFormat = this.$moment(this.dateDisplayFromRanges.end).format('YYYY-MM-DD');
        this.$store.commit(metricsTypes.mutations.setDateFrom, dateFromFormat);
        this.$store.commit(metricsTypes.mutations.setDateTo, dateToFormat);
        this.popupFilterDates = false;
      }
    },
    changeDate (value) {
      this.dateDisplayFromRanges = value;
    },
    checkScreenSize () {
      this.isMobile = window.innerWidth <= 600;
    },
    openResponsiveDatesRanges () {
      if (!this.isMobile) {
        this.popupFilterDates = true;
        return;
      }
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: DateRanges,
        hasModalCard: true,
        customClass: 'modal-date-ranges-metrics',
        trapFocus: true,
        props: {
          modeModal: true,
          themeStyles: instance.themeStyles,
          dateMasks: instance.dateMasks,
          valueDate: instance.dateDisplayFromRanges
        },
        events: {
          closeFilters () {
            instance.popupFilterDates = false;
          },
          filterWithDates () {
            instance.filterWithDates();
          },
          changeDate (val) {
            instance.changeDate(val);
          }
        }
      });
    }
  },
  watch: {
    year (value) {
      if (this.dateDisplayFromRanges) {
        const dateFromFormat = this.$moment(this.dateDisplayFromRanges.start).set('year', value).format('YYYY-MM-DD');
        const dateToFormat = this.$moment(this.dateDisplayFromRanges.end).set('year', value).format('YYYY-MM-DD');
        this.$store.commit(metricsTypes.mutations.setDateFrom, dateFromFormat);
        this.$store.commit(metricsTypes.mutations.setDateTo, dateToFormat);
      }
      this.$store.commit(metricsTypes.mutations.setYear, value);
    },
    sectionId (newVal) {
      this.clearDate(true);
      if (newVal === 4) {
        if (this.dateFromGlobal && this.dateToGlobal) {
          this.dateFrom = this.dateFromGlobal;
          this.dateTo = this.dateToGlobal;
        }
      }
    },
    openYearsSelector () {
      this.popupFilterDates = false;
    }
  }
};
</script>

<style lang="scss">
  @use "@/assets/styles/metrics/_sidebar_info";
</style>
