<template>
  <div class="">
    <form autocomplete="off" @submit.prevent="validateBeforeSubmit">
      <p class="p-normal p-normal-left">Restablecer contraseña</p>
      <div class="">
        <p class="p-normal">Por favor diligencia los siguientes campos para poder cambiar tu contraseña.</p>
        <div class="positionar">
          <b-field :class="{ 'has-error' : errors.has('password') }" style="color: black !important;width: 100%;margin-top: 10px;">
            <b-input type="password" icon-pack="fa" icon="lock" class="" id="password" :class="{ 'has-error' : errors.has('password') }" placeholder="Nueva Contraseña" autocomplete="off" ref="password" v-validate="'required|min:6'" name="password" v-model="password" @keypress="setMsgNull()"/>
          </b-field>
        </div>
        <div class="error-s" v-if="errors.first('password') && msg === null" >
          <span class="">{{ errors.first('password') }}</span>
        </div>
      </div>
      <div class="">
        <div class="positionar">
          <b-field :class="{ 'has-error' : errors.has('password_confirmation') }" style="color: black !important;width: 100%;margin-top: 10px;">
            <b-input type="password" icon-pack="fa" icon="lock" id="password_confirmation" placeholder="Confirmar Contraseña" autocomplete="off" v-validate="'required|confirmed:password'" name="password_confirmation" v-model="repassword" @keypress="setMsgNull()"/>
          </b-field>
        </div>
        <div class="error-s" v-if="errors.first('password_confirmation') && msg === null" >
          <span class="">{{ errors.first('password_confirmation') }}</span>
        </div>
      </div>
      <div class="error-s" v-if="error != null">
        <span class="">{{ error }}</span>
      </div>
      <div v-if="msg != null">
        <span class="error-s">{{ msg }}</span>
      </div>
      <div class="">
        <button class="btn-recovery" v-if="!loading" type="submit"><i class="fa fa-refresh"></i> Cambiar</button>
        <span class="" v-if="loading"><i class="fa fa-spinner fa-spin"></i>Cambiando Contraseña... </span>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import authTypes from '@/store/types/auth';
export default {
  data () {
    return {
      tokenUserId: this.tokUserId,
      loading: false,
      password: null,
      repassword: null,
      error: null,
      msg: null
    };
  },
  props: {
    tokUserId: Number
  },
  methods: {
    ...mapActions({
      changed: authTypes.actions.changedPassword,
      login: authTypes.actions.login

    }),
    danger (error) {
      this.$buefy.toast.open({
        duration: 5000,
        message: error,
        position: 'is-bottom',
        type: 'is-danger'
      });
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.changedPasswordUser();
        }
      });
    },
    changedPasswordUser () {
      this.loading = true;
      this.changed({
        userId: this.tokenUserId,
        password: this.password,
        password_confirmation: this.repassword
      }).then(response => {
        if (response.status === 200) {
          this.loading = false;
          this.repassword = null;
          this.msg = 'Se actualizó con éxito!';
          this.processLogin(response.data.user.email, this.password);
          this.password = null;
        } else {
          this.loading = false;
          this.error = response;
        }
      }).catch(fallo => {
        this.loading = false;
        this.error = fallo;
      });
    },
    processLogin (usuario, password) {
      this.login({
        email: usuario,
        password: password,
        deviceId: this.deviceId,
        system: 'dashboard'
      })
        .then(() => {
          this.loadingLogin = false;
        })
        .catch(() => {
          this.loadingLogin = false;
          this.error = 'Error al procesar la petición';
          this.danger('Error al procesar la petición');
        });
    },
    setMsgNull () {
      this.msg = null;
    }
  }
};
</script>
<style scoped>
  .error-s {
    color: red;
    font-size: 10px;
  }
</style>
