import namespace from '@/helpers/namespace';

export default namespace('waitingList', {
  getters: [

  ],
  actions: [
    'addWaitingListItem',
    'getWaitingList',
    'updateWaitingListItem',
    'deleteWaitingListItem',
    'addMoreWaitTime',
    'addReservationFromWaitingList',
    'sendSMSnotification'
  ],
  mutations: [

  ]
});
