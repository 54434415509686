import store from '@/store';
export const uploadImagePluginCloud = {
  install (Vue, options) {
    Vue.prototype.uploadImageGlobalCloud = async (file, type, field = null, restaurant, initialPath, optimize = 0, originalName = '', optinalDirectory = '') => {
      try {
        const extention = file.name.split('.');
        console.log(type);
        const path = (restaurant.sede == null) ? restaurant.url : restaurant.url + '/' + restaurant.sede;
        const formData = new FormData();
        if (initialPath !== 'requirements') {
          formData.append('fileName', `${type}.${extention[1]}`);
          formData.append('dirNoFile', `${initialPath}/${path}/${optinalDirectory}`);
        } else {
          formData.append('fileName', `${type}.${extention[1]}`);
          formData.append('dirNoFile', `${initialPath}/`);
        }
        formData.append('content', file);
        formData.append('vendorId', restaurant.id);
        formData.append('optimize', optimize);
        if (originalName !== '') {
          formData.append('fileNameTest', `${originalName}.${extention[1]}`);
        } else {
          formData.append('fileNameTest', `${type}.${extention[1]}`);
        }
        const data = await store.dispatch({ type: 'googleCloud:uploadImage', data: formData });
        return {
          data: data,
          field: field,
          type: type,
          code: 200
        };
      } catch (error) {
        return {
          message: error,
          code: 400
        };
      }
    };
  }
};

export const uploadImageMultiplePluginCloud = {
  install (Vue, options) {
    Vue.prototype.uploadImageMultipleCloud = async (files, restaurant, initialPath, optionalPath = null) => {
      try {
        const sortedArray = files.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        const path = (restaurant.sede == null) ? restaurant.url : restaurant.url + '/' + restaurant.sede;
        const formData = new FormData();
        sortedArray.forEach((file) => {
          formData.append('content[]', file);
        });
        if (optionalPath != null) {
          formData.append('path', `${initialPath}/${path}/${optionalPath}`);
        } else {
          formData.append('path', `${initialPath}/${path}/`);
        }
        formData.append('vendorId', restaurant.id);
        const data = await store.dispatch({ type: 'googleCloud:uploadImageMultiple', data: formData });
        return {
          data: data,
          code: 200
        };
      } catch (error) {
        return {
          message: error,
          code: 400
        };
      }
    };
  }
};
