<template>
  <div class="general-metrics-layer__container__charts-general__card" v-if="data?.rows?.length > 0">
    <h2>{{titleChart}}</h2>
    <div class="general-metrics-layer__container__charts-general__card__filters" v-if="type === 'notEffective' && enableFilters && data.rows && data.rows.length > 0" >
      <b-button
        class="general-metrics-layer__container__charts-general__card__filters__action"
        :class="{'general-metrics-layer__container__charts-general__card__filters__action--active' : filterActive() === 1}"
        @click="changeInternalFilter(1)"
      >
        <i class="icon-mks icon_reservation"></i>
        <span>Canceladas</span>
      </b-button>
      <b-button
        class="general-metrics-layer__container__charts-general__card__filters__action"
        :class="{'general-metrics-layer__container__charts-general__card__filters__action--active' : filterActive() === 2}"
        @click="changeInternalFilter(2)"
      >
        <i class="icon-mks icon_nollego"></i>
        <span>No llegaron</span>
      </b-button>
    </div>
    <div class="container-graphs-info">
      <div
        class="container-graphs-info__chart-aux-cont"
        :class="{
          'container-graphs-info__chart-aux-cont__effective' : graph === 'perEffective',
          'container-graphs-info__chart-aux-cont__not-effective' : graph === 'perNotEffective',
          'container-graphs-info__chart-aux-cont__requeriments' : graph === 'perRequeriments',
          'container-graphs-info__chart-aux-cont__effective-not-effective' : graph === 'perEffectiveNotEffective'
        }"
      >
        <v-chart :key="chartKey" class="chart" :option="option" :initOptions="{ renderer: 'svg' }" />
      </div>
      <Conventions
        v-if="enableConventions"
        :dataConventions="dataConventions"
        :sectionId="sectionId"
        :generalData="data"
        :walkingData="null"
        :filtersBy="1"
        :secondFiltersBy="filterActive()"
        :graph="graph"
        :colorsByDefault="null"
        :onlyOneCol="true"
      />

    </div>
  </div>
  <div class="general-metrics-layer__container__charts-general__card col-3 noresults" v-else>
    <div class="wrapper__noresults">Sin Resultados</div>
  </div>
  <!-- autoresize -->
</template>

<script>
/*!
* Copyright 2024 CLICK2BUY SAS
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
import { use } from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { defineComponent } from 'vue';
import Conventions from '@/components/MetricsV2/Charts/Conventions';

use([
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  SVGRenderer
]);

export default defineComponent({
  name: 'PieChartPrec',
  props: ['data', 'type', 'graph', 'sectionId', 'titleChart', 'enableConventions', 'dataConventions', 'enableFilters', 'secondFiltersBy'],
  components: {
    VChart,
    Conventions
  },
  provide: {
    [THEME_KEY]: ''
  },
  data () {
    return {
      chartKey: 0,
      convertedData: [],
      dataLegends: [],
      option: {
        title: {
          text: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          borderWidth: '0',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          padding: 0,
          position: (point) => {
            const x = point[0] + 10; // Posición X (10px a la derecha del cursor)
            const y = point[1]; // Mantener la posición Y del cursor
            return [x, y];
          },
          extraCssText: `
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); /* Sombra hacia abajo y derecha */
            border-radius: 10px; /* Opcional: Bordes redondeados para suavizar */
          `,
          formatter: this.getTooltipContent
        },
        series: [
          {
            name: 'Efectivas',
            type: 'pie',
            radius: ['35%', '70%'],
            center: ['50%', '60%'],
            startAngle: 280,
            data: [],
            label: {
              show: false
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      filterByCancelledArrived: 1
    };
  },
  mounted () {
    this.generateDataChart();
  },
  methods: {
    changeInternalFilter (type) {
      if (this.sectionId !== 4) {
        this.$emit('changeFilter', this.graph, type);
      } else {
        this.filterByCancelledArrived = type;
      }
    },
    filterActive () {
      switch (this.graph) {
        case 'perNotEffective':
          if (this.sectionId !== 4) {
            return this.secondFiltersBy;
          } else {
            return this.filterByCancelledArrived;
          }
      }
      return null;
    },
    getTooltipContent (params) {
      const icon = params.data.url;
      return `
        <div style="
          background-color: ${params.color};
          color: #fff;
          padding: 8px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          gap: 8.5px;
        ">
          ${
            this.graph === 'perEffectiveNotEffective' || (this.sectionId === 4 && this.graph === 'perNotEffective') ? ''
            : `<i
              style="
                background-color: white;
                -webkit-mask-repeat: no-repeat !important;
                mask-repeat: no-repeat !important;
                mask-position: center !important;
                min-width: 20px; min-height: 20px;
                -webkit-mask-image: url('${!icon ? (this.getUrlImageReferrer(params.name) ? this.getUrlImageReferrer(params.name) : '') : icon}');
                mask-image: url('${!icon ? (this.getUrlImageReferrer(params.name) ? this.getUrlImageReferrer(params.name) : '') : icon}');
              "
            ></i>`
          }
          <span>${params.name}</span>
          <span>${params.value}</span>
          <span>${params.percent}%</span>
        </div>
      `;
    },
    resetData () {
      // Limpia los datos previos
      this.convertedData = [];
      this.dataLegends = [];
      this.option.series[0].data = [];
      /* this.option.legend.data = []; */
    },
    generateDataChart () {
      this.resetData(); // Limpia los datos antes de procesar los nuevos
      // Procesa los datos según el tipo

      this.data.rows && this.data.rows.forEach((item, index) => {
        this.dataLegends.push(item.name ? item.name : '');
        if (this.type === 'effective') {
          this.convertedData.push({
            value: item.value,
            name: item.name,
            itemStyle: {
              color: item.color
            },
            people: item.people
          });
        }
        if (this.type === 'notEffective') {
          if (this.sectionId !== 4) {
            const name = (item.referrer === 'whitelabel') ? 'página web' : (item.referrer === 'dashboard') ? 'telefono' : item.referrer;
            this.convertedData.push({
              value: item.reservations,
              name: name,
              itemStyle: {
                color: item.color
              },
              people: item.people
            });
          } else {
            const name = (item.name === 'whitelabel') ? 'página web' : (item.name === 'dashboard') ? 'telefono' : item.name;
            this.convertedData.push({
              value: this.filterByCancelledArrived === 1 ? item.cancelled : item.arrived,
              name: name,
              itemStyle: {
                color: item.color
              }
            });
          }
        }
        if (this.type === 'quantityReservations') {
          this.convertedData.push({
            value: item.value,
            name: item.name,
            itemStyle: {
              color: index === 0 ? '#FF4560' : '#48c9b0'
            }
          });
        }
        if (this.type === 'byRequeriments') {
          if (item.name) {
            this.convertedData.push({
              value: item.value ? item.value : '',
              name: item.name ? item.name : '',
              url: item.iconURL ? item.iconURL : '',
              itemStyle: {
                color: item.color
              }
            });
          }
        }
        if (this.type === 'effectiveNotEffective') {
          this.convertedData.push({
            value: item.value,
            name: item.name,
            itemStyle: {
              color: index === 0 ? '#12A2DF' : '#58d68d'
            }
          });
        }
      });
      // Asigna los datos procesados al gráfico
      this.option.series[0].data = this.convertedData;
      /* this.option.legend.data = this.dataLegends; */
    }
  },
  watch: {
    filterByCancelledArrived () {
      this.generateDataChart();
    },
    data: {
      deep: true, // Detecta cambios profundos en el objeto
      handler () {
        this.generateDataChart();
        this.chartKey++;
      }
    },
    type () {
      this.generateDataChart(); // Genera el gráfico nuevamente al cambiar el tipo
    },
    sectionId (value) {
      this.chartKey = value;
    }
  }
});
</script>

<style lang="scss" scoped>
  .container-graphs-info{
    gap: 10px;
    &__chart-aux-cont{
      position: relative;
      max-width: 402px;
      width: 100%;
      .chart {
        position: absolute;
        width: 412px!important;
        height: 315px; /* Ajusta la altura según tus necesidades */
      }
      &__requeriments{
        max-width: 230px;
        min-height: 230px;
        .chart{
          left: -4.9em;
          top: -4.7em;
        }
      }
      &__effective{
        max-width: 230px;
        height: 230px;
        .chart{
          left: -4.9em;
          top: -4.9em;
          height: 320px; /* Ajusta la altura según tus necesidades */
        }
      }
      &__effective-not-effective{
        max-width: 230px;
        height: 220px;
        .chart{
          left: -5.9em;
          top: -5.2em;
          height: 318px; /* Ajusta la altura según tus necesidades */
        }
      }
      &__not-effective{
        max-width: 230px;
        height: 230px;
        .chart{
          left: -5em;
          top: -4.7em;
        }
      }
    }
  }
  .general-metrics-layer__container__charts-general__card{
    padding: 20px;
  }
</style>
