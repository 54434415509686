<template>
  <div class="content-modal_calendar" id="modal-unblockday">
    <button class="close_button" @click.prevent.stop="closeBlocking" v-if="!isLoading"><i class="fa fa-times"></i></button>
    <div class="Atention">¡Atención!</div>
    <div class="Litle-text">Desea desbloquear este día:</div>
    <div class="icon_box">
      <i class="icon-mks white icon_calendar_voild"></i>
      <div class="number-d">{{ the_day(selectedDate) }}</div>
    </div>
    <div class="date">{{ date(selectedDate)  }}</div>
    <ValidateAction @changeInfo="setData($event)" @disableButton="disableButtonOk = $event"/>
    <button class="button-white-ok" @click.prevent.stop="unBlockDay" :loading="isLoading" v-if="!blocked" :class="{'button-ok-disable': disableButtonOk}">
      <i class="fa fa-check"></i> Ok
    </button>
  </div>
</template>
<script>
import ValidateAction from './ValidationAction.vue';
import reservationTypes from '@/store/types/reservation';
export default {
  props: ['value'],
  components: {
    ValidateAction
  },
  data: () => ({
    isLoading: false,
    unblocked: false,
    blocked: false,
    disableButtonOk: false,
    msg: '',
    hostId: null
  }),
  computed: {
    isOpen: {
      get () { return this.value; },
      set (value) { this.$emit('input', value); }
    }
  },
  methods: {
    closeBlocking () {
      this.isOpen = false;
      this.$store.commit(reservationTypes.mutations.setBlockingTime, false);
    },
    date (timestamp) {
      return this.$moment(timestamp).format('dddd - DD - MMM');
    },
    the_day (timestamp) {
      return this.$moment(timestamp).format('DD');
    },
    unBlockDay () {
      this.isLoading = true;
      const time = this.getSelectedDate;
      this.$store.dispatch('reservation:unBlockedDay', {
        vendorId: this.vendor.id,
        userId: this.user.id,
        date: time,
        hostId: this.hostId
      }).then((res) => {
        if (res.data.code === 200) {
          this.unblocked = true;
          this.isOpen = false;
          this.$emit('eventBlockCalendar');
          this.$store.commit(reservationTypes.mutations.setBlockingTime, false);
        } else {
          this.msg = 'Oops! No habian horarios para desbloquear';
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    setData (data) {
      this.hostId = data.hostId || null;
      this.disableButtonOk = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#modal-unblockday{
  &.content-modal_calendar{
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
    width: 260px;
    border-right:1px solid #fff;
  }
 .close_button{
    background: transparent;
    color: #fff;
    border: none;
    position: absolute;

    top: 0;
    right: 0;
    padding: 10px;
 }
 .Atention{
   font-weight:bold;
   color:#fff !important;
   font-size:20px;
 }
 .Litle-text{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
 }
 .icon_box{
   position:relative;
   .number-d{
    color: #fff;
    position: absolute;

    top: 42%;
    left: 42%;
    font-size: 14px !important;
   }
   i {
      padding: 0px 10px;
      margin-top: -4px;
      height: 50px;
      width: 50px;
      margin-left: 10px;
   }
 }
 .date{
   color:#fff;
   font-weight:600;
   font-size:14px;
   text-transform:capitalize;
 }
 button.button-white-ok{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 5px 20px 5px 20px;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    i{
      padding: 0px;
      margin-top:0px;
      font-size:20px;
      height:auto;
      width:auto;
      margin-right: 5px;
    }
 }
  .button-ok-disable {
    pointer-events: none;
    cursor: no-drop;
    opacity: 0.7;
    user-select: none;
  }
}
</style>
