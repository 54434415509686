import searchsTypes from '@/store/types/searchs';
import serviceReservation from '@/helpers/apis/serviceReservation';
import serviceReport from '@/helpers/apis/serviceReport';

const state = {
  statusFilter: 'En proceso',
  nameFilter: null,
  emailFilter: null,
  phoneFilter: null,
  dateInFilter: null,
  dateOutFilter: null,
  loadingFilter: false,
  executeFilter: false,
  reservationIdFilter: null
};

const getters = {
  [searchsTypes.getters.statusFilter]: (state) => {
    return state.statusFilter;
  },
  [searchsTypes.getters.nameFilter]: (state) => {
    return state.nameFilter;
  },
  [searchsTypes.getters.emailFilter]: (state) => {
    return state.emailFilter;
  },
  [searchsTypes.getters.phoneFilter]: (state) => {
    return state.phoneFilter;
  },
  [searchsTypes.getters.reservationIdFilter]: (state) => {
    return state.reservationIdFilter;
  },
  [searchsTypes.getters.loadingFilter]: (state) => {
    return state.loadingFilter;
  },
  [searchsTypes.getters.executeFilter]: (state) => {
    return state.loadingFilter;
  },
  [searchsTypes.getters.dateInFilter]: (state) => {
    return state.dateInFilter;
  },
  [searchsTypes.getters.dateOutFilter]: (state) => {
    return state.dateOutFilter;
  }
};

const actions = {
  [searchsTypes.actions.generateReportSearch]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('/generateReportSearch', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [searchsTypes.actions.getReservationInProcess]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('/dashboard/reservationInProcess', data.data)
        .then(informacion => {
          resolve(informacion.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [searchsTypes.actions.getReservationInFilter]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.post('/dashboard/reservationInFilter', data.data)
        .then(informacion => {
          resolve(informacion.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [searchsTypes.actions.generateReportWalkIn]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('/generateReportWalkin', data.data)
        .then(informacion => {
          resolve(informacion.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  [searchsTypes.mutations.setStatusFilter]: (state, value) => {
    state.statusFilter = value;
  },
  [searchsTypes.mutations.setNameFilter]: (state, value) => {
    state.nameFilter = value;
  },
  [searchsTypes.mutations.setEmailFilter]: (state, value) => {
    state.emailFilter = value;
  },
  [searchsTypes.mutations.setPhoneFilter]: (state, value) => {
    state.phoneFilter = value;
  },
  [searchsTypes.mutations.setReservationIdFilter]: (state, value) => {
    state.reservationIdFilter = value;
  },
  [searchsTypes.mutations.setLoadingFilter]: (state, value) => {
    state.loadingFilter = value;
  },
  [searchsTypes.mutations.setExecuteFilter]: (state, value) => {
    state.executeFilter = value;
  },
  [searchsTypes.mutations.setDateInFilter]: (state, value) => {
    state.dateInFilter = value;
  },
  [searchsTypes.mutations.setDateOutFilter]: (state, value) => {
    state.dateOutFilter = value;
  }
};

export default { state, getters, actions, mutations };
