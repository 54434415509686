<template>
  <div class="modal-unify-users__content__info__row">
    <Avatar :name="name" />
    <div class="modal-unify-users__content__info__row__detail">
      <h3 class="capitalize">{{ name }}</h3>
      <div class="modal-unify-users__content__info__row__detail__icons-data">
        <div class="modal-unify-users__content__info__row__detail__icons-data__document" v-if="identityDocument">
          <i class="icon-mks icon_unify_identification"></i>
          <span>{{ identityDocument }}</span>
        </div>
        <div class="modal-unify-users__content__info__row__detail__icons-data__email" v-if="email">
          <i class="icon-mks icon_unify_email"></i>
          <span>{{ email }}</span>
        </div>
        <div class="modal-unify-users__content__info__row__detail__icons-data__phone" v-if="phone">
          <i class="icon-mks icon_unify_phone"></i>
          <span>{{ phone }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from '@/components/users/users/Avatar';
export default {
  props: {
    email: {
      type: String,
      required: false
    },
    identityDocument: {
      type: [String, Number],
      required: false
    },
    name: {
      type: String,
      required: false
    },
    phone: {
      type: [String, Number], // fix de tipo en consola, algunos telefonos vienen en modo entero y otros en string
      required: false
    }
  },
  components: {
    Avatar
  }
};
</script>
