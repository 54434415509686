<template>
  <div class="toggle">
    <label class="switch">
      <input v-model="content" type="checkbox" @change="handleChange" />
      <span class="slider round"></span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'SwitchBoolean',
  props: {
    value: { type: Boolean, default: false }
  },
  data () {
    return {
      content: this.$props.value
    };
  },
  watch: {
    value () {
      this.content = this.$props.value;
    }
  },
  methods: {
    handleChange () {
      this.$emit('input', this.content);
      this.$emit('change', this.content);
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: unset;
}

.switch input {
  opacity: 0;
  width: 6rem;
  height: 2rem;
  transition: all 0.5s;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5e5b2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 95%;
  width: 45%;
  left: 5%;
  bottom: 1px;
  background-color: #444B57;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #E5E5E5;
}

input:checked + .slider:before {
  transform: translateX(100%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
