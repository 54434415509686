<template>
  <div id="giftCards">
    <div>
      <Sidenav @change-full="toggle"></Sidenav>
      <div class="content-wrap">
        <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword>
        <ToolBar
          :query="query"
          :searcherResponsive="searcherResponsive"
          @closeSearch="closeSearch"
          @search="search"
        />
        <div class="columns" id="container_general_giftcards">
          <NavBarOptions
            :filterType="filterType"
            :status="status"
            @filterGiftCards="filterGiftCards"
            @openModalGiftCardConfig="openModalGiftCardConfig"
          />
          <div class="container__gift_cards columns is-mobile" v-if="giftCards.length > 0">
            <GiftCard
              :giftCard="giftCard"
              :icon="icon"
              :key="index"
              :giftCardParam="giftcardInfo"
              @openModalGiftCardRedemption="openModalGiftCardRedemption"
              @setGiftCardRedemption="sendRedeemGift($event)"
              v-for="(giftCard, index) in giftCards"
            />
          </div>
          <div
            class="container__gift_cards columns is-mobile not_found_data"
            v-cloak
            v-if="giftCards.length === 0"
          >
            <h2>No se encontraron bonos de regalo.</h2>
          </div>
        </div>
      </div>
      <PaginationGiftsCards
        :giftCards="giftCards"
        :giftsLength="giftsLength"
        :giftVendorId="giftVendorId"
        :isLoading="isLoading"
        :paginatorData="paginatorData"
        :query="query"
        @refreshGiftCardArray="refreshGiftCardArray"
        @refreshLoading="refreshLoading"
      />
    </div>
    <TempPassword></TempPassword>
  </div>
</template>
<script>
import Sidenav from '@/components/_shared/Sidenav';
import ChangedPassword from '@/components/_shared/ChangedPassword';
import TempPassword from '@/components/_shared/TempPassword';
import NavBarOptions from '@/components/gifCards_v2/molecules/navBarOptions';
import GiftCard from '@/components/gifCards_v2/molecules/giftCard';
import ToolBar from '@/components/gifCards_v2/molecules/toolBar';
import ModalRedeem from '@/components/gifCards_v2/modalRedeem';
import ModalConfigGiftCard from '@/components/gifCards_v2/modalConfigGiftCard';
import PaginationGiftsCards from '@/components/gifCards_v2/paginationGiftsCards';
import globalTypes from '@/store/types/global';

export default {
  name: 'GiftCards',
  components: { Sidenav, ChangedPassword, TempPassword, NavBarOptions, GiftCard, ToolBar, PaginationGiftsCards },
  mounted () {
    setTimeout(() => {
      this.$store.dispatch({
        type: 'auth:refreshUser',
        data: {
          userId: this.user.id
        }
      });
    }, 200);
    this.getGiftCards();
    this.getInfoGiftCardConfig();
  },
  data () {
    return {
      status: {
        status_new: 'active',
        status_redeem: 'inactive',
        status_overdue: 'inactive',
        status_partial: 'inactive'
      },
      filterType: 'new',
      searcherResponsive: false,
      giftCards: [],
      isLoading: false,
      icon: 'strip_new',
      query: '',
      giftcardInfo: {
        id: null,
        logo: null,
        vendorId: null,
        title: null,
        message: null,
        terms: null,
        priceList: null,
        payMethod: null,
        payMethodTC: null,
        usePartial: 0
      },
      giftsLength: null,
      paginatorData: [],
      giftVendorId: null
    };
  },
  methods: {
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
    switchFilter (filter, data) {
      this.giftVendorId = this.vendorId;
      switch (filter) {
        case 'new':
          this.status.status_new = 'active';
          this.status.status_redeem = 'inactive';
          this.status.status_overdue = 'inactive';
          this.status.status_partial = 'inactive';
          this.giftCards = data.giftCardRecibidas.data;
          this.paginatorData = data.giftCardRecibidas;
          this.giftsLength = data.giftCardRecibidas.data.length;
          this.icon = 'strip_new';
          this.refreshLoading(false);
          break;
        case 'redeem':
          this.status.status_new = 'inactive';
          this.status.status_redeem = 'active';
          this.status.status_overdue = 'inactive';
          this.status.status_partial = 'inactive';
          this.giftCards = data.giftCardRedimidas.data;
          this.paginatorData = data.giftCardRedimidas;
          this.giftsLength = data.giftCardRedimidas.data.length;
          this.icon = 'strip_redeem';
          this.refreshLoading(false);
          break;
        case 'overdue':
          this.status.status_new = 'inactive';
          this.status.status_redeem = 'inactive';
          this.status.status_overdue = 'active';
          this.status.status_partial = 'inactive';
          this.giftCards = data.giftCardVencidas.data;
          this.paginatorData = data.giftCardVencidas;
          this.giftsLength = data.giftCardVencidas.data.length;
          this.icon = 'strip_overdue';
          this.refreshLoading(false);
          break;
        case 'partial':
          this.status.status_new = 'inactive';
          this.status.status_redeem = 'inactive';
          this.status.status_overdue = 'inactive';
          this.status.status_partial = 'active';
          this.giftCards = data.giftCardPartial.data;
          this.paginatorData = data.giftCardPartial;
          this.giftsLength = data.giftCardPartial.data.length;
          this.icon = 'strip_new';
          this.refreshLoading(false);
          break;
        default:
          break;
      }
    },
    filterGiftCards (e) {
      const filter = e.target.value;
      const this_ = this;
      this.refreshLoading(true);
      if (this.query.length >= 3) {
        this.$store
          .dispatch({
            type: 'reservation:getGiftCardVendor',
            data: { vendorId: this.vendorId, filter: this_.query }
          })
          .then((data) => {
            this_.switchFilter(filter, data);
          });
      } else {
        this.$store
          .dispatch({
            type: 'reservation:getGiftCardVendor',
            data: { vendorId: this.vendorId }
          })
          .then((data) => {
            this_.switchFilter(filter, data);
          });
      }
    },
    refreshLoading (state) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: state });
    },
    getGiftCards () {
      const this_ = this;
      const filter = document.querySelector('input[name="filterType"]:checked').value;
      this.refreshLoading(true);
      this.$store
        .dispatch({
          type: 'reservation:getGiftCardVendor',
          data: { vendorId: this.vendorId }
        })
        .then((data) => {
          this_.switchFilter(filter, data);
        });
    },
    sendRedeemGift (data) {
      const instance = this;
      instance.$store
        .dispatch({
          type: 'reservation:giftCardRedemption',
          data
        })
        .then((response) => {
          if (response.code === 200) {
            instance.$buefy.notification.open({
              duration: 5000,
              message: 'Bono de regalo redimido exitosamente!',
              position: 'is-bottom-right',
              type: 'is-success',
              hasIcon: true,
              progressBar: true
            });
            instance.search(instance.query);
          } else {
            instance.refreshLoading(false);
            instance.$buefy.notification.open({
              duration: 5000,
              message: response.message ? response.message : 'Ups! ocurrió un error',
              position: 'is-bottom-right',
              type: 'is-danger',
              hasIcon: true,
              progressBar: true
            });
          }
        }).catch(error => {
          instance.refreshLoading(false);
          instance.$buefy.notification.open({
            duration: 5000,
            message: JSON.parse(error.request.response).message,
            position: 'is-bottom-right',
            type: 'is-danger',
            hasIcon: true,
            progressBar: true
          });
        });
    },
    openModalGiftCardRedemption (giftCard) {
      const instance = this;
      this.$buefy.modal.open({
        props: {
          giftCard: giftCard
        },
        events: {
          redeem (data) {
            instance.sendRedeemGift(data);
          }
        },
        parent: this,
        component: ModalRedeem,
        hasModalCard: true,
        customClass: 'modal_redeem_gift_card',
        trapFocus: true
      });
    },
    openModalGiftCardConfig () {
      const instance = this;
      this.$buefy.modal.open({
        props: {
          giftcardInfo: instance.giftcardInfo
        },
        events: {
          updateConfig () {
            if (instance.giftcardInfo.id != null) {
              instance.$store
                .dispatch({
                  type: 'reservation:updateGiftCardConfig',
                  data: instance.giftcardInfo
                })
                .then((response) => {
                  if (response.message === 'ok') {
                    instance.$buefy.notification.open({
                      duration: 5000,
                      message: 'Configuración guardada exitosamente!',
                      position: 'is-bottom-right',
                      type: 'is-success',
                      hasIcon: true,
                      progressBar: true
                    });
                    instance.search(instance.query);
                  } else {
                    instance.refreshLoading(false);
                    instance.$buefy.notification.open({
                      duration: 5000,
                      message: response.message ? response.message : 'Upps! ocurrió un error',
                      position: 'is-bottom-right',
                      type: 'is-danger',
                      hasIcon: true,
                      progressBar: true
                    });
                  }
                  instance.getGiftCards();
                }).catch(error => {
                  instance.refreshLoading(false);
                  instance.$buefy.notification.open({
                    duration: 5000,
                    message: JSON.parse(error.request.response).message,
                    position: 'is-bottom-right',
                    type: 'is-danger',
                    hasIcon: true,
                    progressBar: true
                  });
                });
            } else {
              instance.$store
                .dispatch({
                  type: 'reservation:newGiftCardConfig',
                  data: instance.giftcardInfo
                })
                .then((data) => {
                  instance.getGiftCards();
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          },
          updateGiftCardInfo (data) {
            instance.giftcardInfo = data;
          }
        },
        parent: this,
        component: ModalConfigGiftCard,
        hasModalCard: true,
        customClass: 'modal_config_gift_card',
        trapFocus: true
      });
    },
    search (query) {
      this.query = query;
      const filter = document.querySelector('input[name="filterType"]:checked').value;
      const this_ = this;
      this.refreshLoading(true);
      if (this.query.length >= 3) {
        this.$store
          .dispatch({
            type: 'reservation:getGiftCardVendor',
            data: { vendorId: this.vendorId, filter: this.query }
          })
          .then((data) => {
            switch (filter) {
              case 'new':
                this_.giftCards = data.giftCardRecibidas.data;
                this_.paginatorData = data.giftCardRecibidas;
                this_.giftsLength = data.giftCardRecibidas.data.length;
                this.refreshLoading(false);
                break;
              case 'redeem':
                this_.giftCards = data.giftCardRedimidas.data;
                this_.paginatorData = data.giftCardRedimidas;
                this_.giftsLength = data.giftCardRedimidas.data.length;
                this.refreshLoading(false);
                break;
              case 'overdue':
                this_.giftCards = data.giftCardVencidas.data;
                this_.paginatorData = data.giftCardVencidas;
                this_.giftsLength = data.giftCardVencidas.data.length;
                this.refreshLoading(false);
                break;
              case 'partial':
                this_.giftCards = data.giftCardPartial.data;
                this_.paginatorData = data.giftCardPartial;
                this_.giftsLength = data.giftCardPartial.data.length;
                this.refreshLoading(false);
                break;
              default:
                break;
            }
          });
      } else if (this.query.length === 0) {
        this.getGiftCards();
      } else {
        this.refreshLoading(false);
      }
    },
    closeSearch () {
      this.searcherResponsive = !this.searcherResponsive;
    },
    getInfoGiftCardConfig () {
      this.$store
        .dispatch({
          type: 'reservation:getGiftCardConfig',
          data: { vendorId: this.decryptMx(this.vendorId) }
        })
        .then((data) => {
          this.giftcardInfo.vendorId = this.decryptMx(this.vendorId);
          if (Object.keys(data).length > 0) {
            this.giftcardInfo.id = data.id;
            this.giftcardInfo.vendorId = data.vendorId;
            this.giftcardInfo.title = this.strippedContent(data.title);
            this.giftcardInfo.logo = data.logo;
            this.giftcardInfo.message = this.strippedContent(data.message);
            this.giftcardInfo.priceList = data.priceList;
            this.giftcardInfo.terms = this.strippedContent(data.terms);
            this.giftcardInfo.payMethod = data.payMethod;
            this.giftcardInfo.payMethodTC = data.payMethodTC || null;
            this.giftcardInfo.usePartial = data.usePartial || 0;
            data.priceList.split(',').map((element, index) => {
              this.giftcardInfo['price_' + (index + 1)] = element;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    strippedContent (string) {
      return string.replace(/<\/?[^>]+>/ig, '');
    },
    refreshGiftCardArray (data) {
      this.giftCards = data;
    }
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/giftCards/_index";
</style>
