<template>
  <div class="sidebar-info-container__card-reservations-people__info">
    <h3>{{title}}</h3>
    <div class="sidebar-info-container__card-reservations-people__info__totals">
      <div class="sidebar-info-container__card-reservations-people__info__totals__section">
        <i class="icon-mks" :class="customIcon"></i>
        <span>{{totalReservations}}</span>
      </div>
      <div class="simple-divider"></div>
      <div class="sidebar-info-container__card-reservations-people__info__totals__section">
        <i class="icon-mks icon_total_users_default_new"></i>
        <span>{{totalUsers}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'totalReservations', 'totalUsers', 'customIcon']
};
</script>

<style>

</style>
