<template>
  <div id="Error">
    <div class="infoImg">
      <div class="infoImg-cont">
        <img class="img-icon" src="@/assets/images/icons/icono-inhabilitado.svg">
        <span class="text-up">¡Error!</span>
        <span class="text-down">{{ getError() }}</span>
        <button @click="goIntent()">Intentar de nuevo</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ErrorPage',
  methods: {
    goIntent () {
      window.localStorage.removeItem('ErrorApp');
      window.location.href = '/dashboard/reservations';
    },
    getError () {
      return window.localStorage.getItem('ErrorApp');
    }
  }
};
</script>
<style lang="scss">
  @font-face {
    font-family: confortaaBold;
    src: url(../assets/fonts/confortaa/Comfortaa-Bold.ttf);
  }
  #Error {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    height: 100vh;
    background-image: url(../assets/images/maintenance.jpg);
    padding: 80px;
    .infoImg{
      display: flex;
      background-color: #fff;
      width: 550px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 30px;
      border-radius: 15px;
      &-cont{
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-icon{
          width: 30%;
        }
        .text-up{
          padding: 10px;
          font-size: 30px;
          font-family: confortaaBold;
          color:#000;
        }
        .text-down{
          color: #000;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          border-bottom: solid 1px #E6E6E6;
          padding: 10px;
          margin-bottom: 30px;
        }
      }
    }
  }
</style>
