<template>
  <div @click="toggleSet(total.displayName)" :class="{ isSelected: isSelect(total.displayName)}" id="ZoneItem">
    <div class="tilter">{{total.displayName}}</div>
    <div class="is-mobile mainContent">
      <div v-if="purchase" class="counter">
        <i class="icon_precompro icon-mks black"></i>
        <p>{{total.prepurchases}}</p>
      </div>

      <div class=" counter">
         <i class="icon_reservation icon-mks black"></i>
         <p>{{total.reservations}}</p>

      </div>
            <div class=" counter">
        <i class="icon_ThinTable  icon-mks black"></i>
        <p>{{total.tables}}</p>

      </div>
      <div class=" counter">
        <i class="icon_person icon-mks black"></i>
        <p>{{total.people}}</p>
      </div>
      <div class=" counter">
        <i class="icon_walk-in icon-mks black"></i>
        <p>{{total.walking}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';
export default {
  props: ['total', 'purchase'],
  data () {
    return {
      isActive: false
    };
  },
  methods: {
    toggleSet (section) {
      if (_.includes(this.sectionFilterSelect, section)) {
        const i = this.sectionFilterSelect.indexOf(section);
        this.sectionFilterSelect.splice(i, 1);
      } else {
        this.$store.commit(reservationTypes.mutations.setSectionFilterSelect, section);
      }
      this.isActive = true;
    },
    isSelect (section) {
      return _.includes(this.sectionFilterSelect, section);
    }
  }
};
</script>
<style lang="scss" >

#ZoneItem{
    cursor: pointer;
    min-width: 323px;
    width: 323px;
    height: 68px;
    border: 1px solid #D9DBDE;
    border-radius: 6px;
    .mainContent{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  .tilter{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #444B57;
text-align: center;
padding: 6px 0;
  }
  &.isSelected .tilter{
    color: white;
  }
  &.isSelected .counter {
    background-color: white;
  }
  .counter{
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    display: flex !important;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    width: 55px;
    height: 32px;
    i{
      margin-right: 0;
      margin-top: -1px;
    }
    p{
      font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #444B57;
    }
    .numbered{
      background: #000;
      color: #fff;
      height: 21px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      font-size: 12px;
      position: absolute;

      right: -10px;
      border-radius: 50%;
    }
  }
      &.isSelected{
        background:#444b57;
        color: #fff;
        .numbered{
          background: #fff;
          color: #000;
        }
  }
}

</style>
