<template>
  <div class="alert_attention">
    <b-loading :active="loading" :can-cancel="false" :is-full-page="true"></b-loading>
    <svg @click="$parent.close()" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path d="M12.8414 0.158595C12.7398 0.0570469 12.6021 0 12.4584 0C12.3148 0 12.1771 0.0570469 12.0755 0.158595L6.5 5.73407L0.924526 0.158595C0.822946 0.0570469 0.685194 0 0.541561 0C0.397927 0 0.260175 0.0570469 0.158595 0.158595C0.0570469 0.260175 0 0.397927 0 0.541561C0 0.685194 0.0570469 0.822946 0.158595 0.924526L5.73407 6.5L0.158595 12.0755C0.0570469 12.1771 0 12.3148 0 12.4584C0 12.6021 0.0570469 12.7398 0.158595 12.8414C0.260175 12.943 0.397927 13 0.541561 13C0.685194 13 0.822946 12.943 0.924526 12.8414L6.5 7.26593L12.0755 12.8414C12.1771 12.943 12.3148 13 12.4584 13C12.6021 13 12.7398 12.943 12.8414 12.8414C12.943 12.7398 13 12.6021 13 12.4584C13 12.3148 12.943 12.1771 12.8414 12.0755L7.26593 6.5L12.8414 0.924526C12.943 0.822946 13 0.685194 13 0.541561C13 0.397927 12.943 0.260175 12.8414 0.158595Z" fill="#444B57"/>
    </svg>
    <div class="alert_attention_tittle">¡ATENCIÓN!</div>
    <div class="alert_attention_description">
      <span>Recuerda que al modificar el estado de la {{ type }}
      <b>({{ option }})</b> estás cambiando la disponibilidad y tus clientes no podrán hacer
      reservas en esta {{ type }}.</span>
    </div>
    <div class="alert_attention_question">¿Estás seguro que deseas hacerlo?</div>
    <div class="alert_attention_action">
      <button @click="check()" class="active">¡Si, estoy seguro!</button>
      <button
        @click="$parent.close()">
        ¡No estoy seguro!
      </button>
    </div>
    <div></div>
  </div>
</template>
<script>
import serviceVendor from '@/helpers/apis/serviceVendor';

export default {
  props: ['timestamp', 'section', 'keyId', 'all', 'type', 'option', 'info'],
  data () {
    return {
      loading: false
    };
  },
  methods: {
    check () {
      switch (this.type) {
        case 'Zona':
          if (this.option === 'Bloqueada') {
            if (this.all) {
              this.blockedSectionAll();
            } else {
              this.blockedSection();
            }
          }
          if (this.option === 'Privada') {
            this.privateSection();
          }
          break;
        case 'Sub Zona':
          if (this.option === 'Bloqueada') {
            if (this.all) {
              this.blockedSubSectionAll();
            } else {
              this.blockedSubSection();
            }
          }
          if (this.option === 'Privada') {
            this.privateSubSection();
          }
          break;
      }
    },
    privateSection () {
      this.loading = true;
      this.$emit('loadingChange', true);
      this.$store
        .dispatch({
          type: 'reservation:setBlockedSection',
          data: {
            vendorId: this.idVendor,
            seatingSectionId: this.section
          }
        })
        .then(() => {
          this.$emit('loadingChange', false);
          this.$emit('update', 1);
          this.loading = false;
          this.$parent.close();
        }).catch(() => {
          this.$emit('loadingChange', false);
          this.loading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Ocurrió un error al procesar la petición',
            position: 'is-bottom',
            type: 'is-danger'
          });
        });
    },
    blockedSection () {
      this.loading = true;
      this.$emit('changeCounterForBlock');
      this.$emit('loadingHourChange', this.keyId);
      this.$store
        .dispatch({
          type: 'reservation:blockedHourSeatingSection',
          data: {
            userId: this.user.id,
            vendorId: this.idVendor,
            seatingSectionId: this.section,
            date: this.timestamp
          }
        })
        .then(() => {
          this.$emit('loadingHourChange', 0);
          this.loading = false;
          this.$parent.close();
        })
        .catch(() => {
          this.$emit('loadingHourChange', 0);
          this.loading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Ocurrió un error al procesar la petición',
            position: 'is-bottom',
            type: 'is-danger'
          });
        });
    },
    blockedSectionAll () {
      this.loading = true;
      this.$emit('loadingHourChange', this.keyId);
      this.$store
        .dispatch({
          type: 'reservation:blockedDaySeatingSection',
          data: {
            userId: this.user.id,
            vendorId: this.idVendor,
            seatingSectionId: this.section,
            date: this.timestamp
          }
        })
        .then(() => {
          this.$emit('loadingHourChange', 0);
          this.loading = false;
          this.$parent.close();
        })
        .catch(() => {
          this.$emit('loadingHourChange', 0);
          this.loading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Ocurrió un error al procesar la petición',
            position: 'is-bottom',
            type: 'is-danger'
          });
        });
    },
    privateSubSection () {
      this.loading = true;
      serviceVendor
        .put('/admin/subsection/' + this.info.seatingSubSectionId, this.info)
        .then(() => {
          this.$emit('update');
          this.loading = false;
          this.$parent.close();
        })
        .catch(() => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Ocurrió un error al procesar la petición',
            position: 'is-bottom',
            type: 'is-danger'
          });
        });
    },
    blockedSubSection () {
      this.loading = true;
      this.$emit('loadingHourChange', this.keyId);
      this.$store
        .dispatch({
          type: 'reservation:blockedHourSeatingSubSection',
          data: {
            userId: this.user.id,
            vendorId: this.idVendor,
            seatingSubSectionId: this.section,
            date: this.timestamp
          }
        })
        .then(() => {
          this.$emit('loadingHourChange', 0);
          this.loading = false;
          this.$parent.close();
        })
        .catch(() => {
          this.$emit('loadingHourChange', 0);
          this.loading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Ocurrió un error al procesar la petición',
            position: 'is-bottom',
            type: 'is-danger'
          });
        });
    },
    blockedSubSectionAll () {
      this.loading = true;
      this.$emit('loadingHourChange', this.keyId);
      this.$store
        .dispatch({
          type: 'reservation:blockedDaySeatingSubSection',
          data: {
            userId: this.user.id,
            vendorId: this.idVendor,
            seatingSubSectionId: this.section,
            date: this.timestamp
          }
        })
        .then(() => {
          this.$emit('loadingHourChange', 0);
          this.loading = false;
          this.$parent.close();
        })
        .catch(() => {
          this.$emit('loadingHourChange', 0);
          this.loading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Ocurrió un error al procesar la petición',
            position: 'is-bottom',
            type: 'is-danger'
          });
        });
    }
  }
};
</script>
<style lang="scss">
.alert_attention {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 500px;
  max-height: 300px;
  height: 100%;
  background: #FFFFFF;
  color: #444B57;
  text-align: center;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.40);
  position: relative;
  svg {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  &_tittle{
    color: #FFB904;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
  }

  &_description{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 96px);
    margin-top: 30px;
    span {
      color: #444B57;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &_question{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 263px);
    margin-top: 30px;
    color: #444B57;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &_action{
    margin-top: 30px;
    margin-bottom: 40px;
    display:flex;
    justify-content: center;
    justify-content: center;
    align-items: center;
    gap: 30px;
    button {
      padding: 7px 10px;
      border-radius: 6px;
      border: 1px solid #444B57;
      color: #444B57;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
    .active {
      background: #444B57;
      color: #FFFFFF;
    }
  }
}
@media screen and (max-width: 600px) {
  .alert_attention {
    width: 351px;
    max-height: 286px;
    &_tittle {
      font-size: 21px;
      height: 26px;
    }
    &_description {
      width: calc(100% - 77px);
      margin-top: 20px;
    }
    &_question {
      width: calc(100% - 114px);
      margin-top: 20px;
    }
    &_action {
      margin-top: 20px;
    }
  }
}
</style>
