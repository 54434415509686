<template>
  <div id="methodMarketing">
    <div class="section-payment" v-if="creditMethod">
      <div class="section-payment__title">Valor total a pagar</div>
      <div class="section-payment__value">$ {{totalPayment}}</div>
    </div>
    <div class="title-method" v-if="creditMethod">{{ title }}</div>
    <div class="title-method" v-if="!creditMethod">{{ titlePse }}</div>
    <section class="payInfo flex flex-col mx-auto">
      <nav>
        <div
          :class="{ selected: creditMethod, 'payInfo--cantUse': lockInfo }"
          @click="creditMethod = true"
          class="payInfo__tabs"
        >
          <div class="borderInside" :class="{ active: creditMethod}">
            <span class="TcTitle">Tarjeta de crédito {{ cardTypeInfo }}</span>
            <div class="icons-payment">
              <span
                :class="{cardSelected: (cardTypeInfo === 'visa' || cardTypeInfo === 'Visa') ? true :false}"
                class="VisaIcon"
              ></span>
              <span
                :class="{cardSelected: (cardTypeInfo === 'master' || cardTypeInfo === 'MasterCard') ? true :false}"
                class="masterCardIcon"
              ></span>
              <span
                :class="{cardSelected: (cardTypeInfo === 'diners' || cardTypeInfo === 'Diners') ? true :false}"
                class="clubIcon"
              ></span>
              <span
                :class="{cardSelected: (cardTypeInfo === 'amex' || cardTypeInfo === 'Amex') ? true :false}"
                class="americanExpressIcon"
              ></span>
            </div>
          </div>
        </div>
        <div
          :class="{ selected: !creditMethod, 'payInfo--cantUse': lockInfo, ajust: !creditMethod }"
          @click="creditMethod = false"
          class="payInfo__tabs"
        >
          <div class="borderInside" :class="{ active: !creditMethod}">
            <span class="PSETitle">Tarjeta de débito</span>
            <span class="PSEIcon"></span>
          </div>
        </div>
      </nav>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MethodComponent',
  props: ['lockInfo', 'totalPayment'],
  data () {
    return {
      creditMethod: true,
      title: 'Ingresa una nueva tarjeta de crédito',
      titlePse: 'Selecciona el banco para tu pago PSE'
    };
  },
  watch: {
    creditMethod () {
      this.$emit('creditMethodEvent', this.creditMethod);
      const returnText = document.querySelector('.return-text');
      if (this.creditMethod && window.innerWidth <= 1024) {
        if (returnText) {
          returnText.style.display = 'none';
        }
      }
      if (!this.creditMethod && window.innerWidth <= 414) {
        returnText.style.display = 'none';
      }
      if (!this.creditMethod && window.innerWidth >= 414) {
        returnText.style.display = 'inline';
      }
    }
  },
  mounted () {
    const returnText = document.querySelector('.return-text');
    if (this.creditMethod && window.innerWidth <= 1024) {
      if (returnText) {
        returnText.style.display = 'none';
      }
    }
    if (!this.creditMethod && window.innerWidth <= 414) {
      returnText.style.display = 'none';
    }
    if (!this.creditMethod && window.innerWidth >= 414) {
      returnText.style.display = 'inline';
    }
  }
};
</script>

<style lang="scss">
  @use "../../../assets/styles/marketing/method.scss";
</style>
