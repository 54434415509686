<template>
  <div class="container-tags-reservation-global" :class="customClass">
    <div class="container-tags-reservation-global__tag" v-if="infoReserva.isRecommended" v-tooltip="{content: 'Recomendado', placement: 'bottom'}">
      <i class="icon-mks icon-recommended-reservation"></i>
    </div>
    <div class="container-tags-reservation-global__tag container-tags-reservation-global__tag__habitual" v-if="infoReserva.isHabitual" v-tooltip="{content: 'Habitual', placement: 'bottom'}">
      <i class="icon-mks icon-habitual-reservation"></i>
    </div>
    <div class="container-tags-reservation-global__tag container-tags-reservation-global__tag__custom-tag" v-if="existTag">
      <i class="icon-mks icon-tag-reservation"></i>
    </div>
    <div class="container-tags-reservation-global__tag container-tags-reservation-global__tag__vip" v-if="infoReserva.isVip" v-tooltip="{content: 'Vip', placement: 'bottom'}">
      <i class="icon-mks icon-vip-reservation"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneralTagsComponent',
  props: ['infoReserva', 'existTag', 'customClass']
};
</script>

<style lang="scss">
  .container-tags-reservation-global{
    margin-right: 3px;
    display: flex;
    gap: 4px;
    &__tag{
      border-radius: 4px;
      background-color: #FFE500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 19px;
      i{
        background-color: #444B57!important;
      }
      &__vip{
          background-color: #F7BE56;
          i{
              background-color: #DC8744!important;
          }
      }
      &__habitual{
        background-color: #3A65BA;
        i{
          background-color: #fff!important;
        }
      }
      &__custom-tag{
        background-color: #159385;
        i{
          background-color: #fff!important;
          margin-left: -1.5px;
        }
      }
    }
    &-in-detail{
      margin-right: 20px;
      .container-tags-reservation-global__tag{
        width: 31.579px;
        height: 25px;
        .icon-vip-reservation{
          width: 23.763px;
          height: 11.842px;
        }
        .icon-habitual-reservation{
          width: 13.026px;
          height: 15.789px;
        }
        .icon-recommended-reservation{
          width: 16.625px;
          height: 21px;
        }
        .icon-tag-reservation{
          width: 25.663px;
          height: 10.539px;
        }
      }
    }
    &-in-items{
    }
    &-in-items-list{
      &:not(:last-child){
        margin-right: 8px;
      }
    }
    &-in-cronology{
      .container-tags-reservation-global__tag{
        width: 20px;
        height: 16px;
        .icon-vip-reservation{
          width: 14px;
          height: 9px;
        }
        .icon-habitual-reservation{
          width: 9px;
          height: 10px;
        }
        .icon-recommended-reservation{
          width: 11px;
          height: 14px;
        }
        .icon-tag-reservation{
          width: 16px;
          height: 9px;
        }
      }
    }

  }
  @media only screen and (max-width: 600px) {
    .container-tags-reservation-global{
      &-in-cronology{
        .container-tags-reservation-global__tag{
          width: 18px;
          height: 15px;
          i.icon-recommended-reservation{
            margin-top: 0;
            width: 11px;
            height: 14px;
          }
          i.icon-habitual-reservation{
            margin-top: 0;
            width: 8px;
            height: 14px;
          }
        }
      }
    }
  }
</style>
