<template>
  <div class="gift-card-component">
    <div class="gift-card-component-item">
      <InputComponent
        :iconLeft="'gift-card'"
        :placeholder="'Código Giftcard'"
        :model="true"
        :type="'text'"
        :field="'giftcard'"
        @changeValue="giftcardcodeGiftCard = $event.giftcard"
        :disabled="giftcardId != null ? true : false"
      />
    </div>
    <div class="gift-card-component-item-text">Tienes un saldo a favor de {{ parseInt(priceGiftCard) | currency('$ ', 0, { thousandsSeparator: '.' }) }}</div>
    <div class="gift-card-component-item-text gift-card-component-item-text-end" v-if="!giftcardId">
      <a class="gift-card-component-button"  @click="validateCode">Agregar código</a>
    </div>
  </div>
</template>
<script>
import InputComponent from '@/components/Extends/Input';
export default {
  name: 'FormReservationGiftCard',
  components: {
    InputComponent
  },
  data () {
    return {
      codeGiftCard: null,
      giftcardId: null,
      priceGiftCard: 0
    };
  },
  methods: {
    validateCode () {
      if (this.codeGiftCard) {
        this.$store.dispatch({
          type: 'reservation:validateGiftCard',
          data: {
            vendorId: this.vendor.id,
            codeGiftCard: this.codeGiftCard
          }
        })
          .then(response => {
            if (parseInt(response.code) === 200) {
              if (response.status) {
                this.priceGiftCard = response.value;
                this.giftcardId = response.id;
                this.$emit('changeGiftCardId', this.giftcardId);
                this.$emit('changePriceGiftCard', this.priceGiftCard);
              } else {
                this.priceGiftCard = 0;
                this.giftcardId = null;
                this.$buefy.notification.open({
                  message: '<i class="icon_alert_danger icon-mks red"></i>Código ya utilizado',
                  type: 'is-danger'
                });
              }
            } else {
              this.$buefy.notification.open({
                message: '<i class="icon_alert_danger icon-mks red"></i>Código inválido',
                type: 'is-danger'
              });
              this.priceGiftCard = 0;
              this.giftcardId = null;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.$buefy.notification.open({
          message: '<i class="icon_alert_danger icon-mks red"></i>Por favor ingrese código giftcard',
          type: 'is-danger'
        });
      }
    }
  }
};
</script>
<style lang="scss">
.gift-card-component {
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  margin-top: 15px;
  margin-bottom: 1rem;
                            @media(max-width:565px){
gap: 0.6rem;
        }
        @media only screen and (min-width: 1001px) and (max-width: 1280px) {
          gap: 12px;
          flex-wrap: nowrap;
        }
  &-item {
    width: 40%;
    #input-personalizado {
      margin-bottom: 0px;
    }
                              @media(max-width:565px){
                                order: 1;
width: 55%;
        }
  }
  &-item-text {
    font-size: 11px;
    font-family: 'Roboto';
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
                              @media(max-width:565px){
                                font-size: 12px;
width: 50%;
order: 3;
align-items: flex-start;
justify-content: flex-start;
text-align: start;
text-indent: 2px;
        }
    &-end {
      justify-content: flex-end;
      @media only screen and (min-width: 1001px) and (max-width: 1280px) {
        justify-content: flex-end;
        width: 150px;
      }
                                @media(max-width:565px){
                                  font-size: 10px;
                                  order: 2;
justify-content: center;
width: 42%;
        }
    }
  }
  &-button {
    font-size: 14px;
    font-family: 'Roboto';
    background: rgba(68, 75, 87, 1);
    color: white;
    padding: 5px 15px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    white-space: nowrap;
    justify-content: center;
    width: 100%;
    @media only screen and (min-width: 1001px) and (max-width: 1280px) {
      width: 98%;
    }
    &:hover {
      color: #fff;
    }
    &:disabled {
      opacity: .8;
    }
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1270px) {
  .gift-card-component-item-text:not(.gift-card-component-item-text-end) {
    font-size: 12px;
    max-width: 105px;
    line-height: 1.2em;
    padding-right: 5px;
  }
}
</style>
