<template>
  <v-chart class="chart" :option="option" :key="chartKey" :initOptions="{ renderer: 'svg' }" />
  <!-- autoresize -->
</template>

<script>
/*!
* Copyright 2024 CLICK2BUY SAS
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
import { use } from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { defineComponent } from 'vue';

use([
  SVGRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default defineComponent({
  name: 'LineChartPrec',
  props: ['data', 'sectionId', 'colors'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: ''
  },
  data () {
    return {
      chartKey: 0,
      convertedData: [],
      dataLegends: [],
      option: {
        legend: {},
        tooltip: {
          trigger: 'axis',
          showContent: true
        },
        dataset: {
          source: [
          ]
        },
        xAxis: {
          type: 'category',
          name: 'Hora de creación', // Etiqueta del eje X
          nameLocation: 'middle', // Ubicación de la etiqueta
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            padding: 20 // Espacio entre la etiqueta y el eje
          }
        },
        yAxis: {
          gridIndex: 0,
          name: 'Cantidad de reservas', // Etiqueta del eje X
          nameLocation: 'middle', // Ubicación de la etiqueta
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            padding: 20 // Espacio entre la etiqueta y el eje
          }
        },
        series: [
        ]
      }
    };
  },
  mounted () {
    this.generateDataChart();
  },
  methods: {
    generateDataChart () {
      this.resetChart();
      if (this.sectionId !== 4) {
        // Preparar los datos para el gráfico
        const xAxisData = this.data.rows.map(item => item.hour); // Eje X con las horas
        const yAxisData = this.data.rows.map(item => item.Reservas); // Eje Y con reservas

        // Configurar las opciones del gráfico
        this.option.dataset = {
          source: [
            ['Hora', ...xAxisData], // Etiquetas para el eje X
            ['Reservas', ...yAxisData] // Datos de reservas
          ]
        };

        // Configuración de ejes
        this.option.xAxis.name = 'Horas'; // Etiqueta para el eje X
        this.option.xAxis.data = xAxisData; // Datos para el eje X

        this.option.yAxis.name = 'Reservas'; // Etiqueta para el eje Y

        // Configuración de la serie
        this.option.series = [
          {
            name: 'Reservas',
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
            itemStyle: {
              color: this.getCustomColor() // Aplicar el color personalizado
            },
            lineStyle: {
              color: this.getCustomColor() // Aplicar el color personalizado para la línea
            }
          }
        ];
      }
      if (this.sectionId === 4) {
        // Obtener dinámicamente los nombres de las columnas a usar
        const keys = Object.keys(this.data.rows[0])
          .filter(key => key !== 'hora' && key !== 'hour') // Excluir campos no relevantes
          .slice(0, 3); // Usar solo los primeros 3 campos relevantes

        // Extraer datos para el eje X (horas)
        const xAxisData = this.data.rows.map(item => item.hour);

        // Configurar el dataset dinámico
        const datasetSource = [
          ['Hora', ...xAxisData] // Etiqueta para el eje X
        ];

        // Generar dinámicamente las series y el dataset
        this.option.series = keys.map((key, index) => {
          // Agregar datos al dataset
          const seriesData = this.data.rows.map(item => Number(item[key]));
          datasetSource.push([key, ...seriesData]);

          return {
            name: key,
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
            itemStyle: {
              color: this.colors[index] // Color personalizado para la serie
            },
            lineStyle: {
              color: this.colors[index] // Color de la línea para la serie
            }
          };
        });

        // Configurar opciones del gráfico
        this.option.dataset = {
          source: datasetSource // Asignar el dataset generado
        };

        this.option.xAxis.name = 'Hora de creación'; // Etiqueta para el eje X
        this.option.xAxis.data = xAxisData; // Datos del eje X

        this.option.yAxis.name = 'Cantidad de reservas'; // Etiqueta para el eje Y
      }
    },
    resetChart () {
      this.option.dataset = {};
      this.option.series = [];
      this.option.xAxis.data = [];
    },
    getCustomColor () {
      let color = '';
      switch (this.sectionId) {
        case 1:
          color = this.colors[0];
          break;
        case 2:
          color = this.colors[1];
          break;
        case 3:
          color = this.colors[2];
          break;
        default:
          break;
      };
      return color;
    }
  },
  watch: {
    data: {
      deep: true, // Detecta cambios profundos en el objeto
      handler () {
        this.generateDataChart();
      }
    },
    sectionId (value) {
      this.chartKey = value;
    }
  }
});
</script>

<style scoped>
.chart {
}
</style>
