<template>
  <div style="width: 100%; height: 100%;">
    <ChooseVendor :loginChoose="true" :active="true"></ChooseVendor>
  </div>
</template>

<script>
import ChooseVendor from '@/components/_shared/ChooseVendor.vue';
import authTypes from '@/store/types/auth';
export default {
  components: {
    ChooseVendor
  },
  mounted () {
    const vendorUrl = this.subDomain();
    this.$store.commit(authTypes.mutations.setUser);
    if (vendorUrl !== undefined && vendorUrl != null) {
      this.$store.dispatch({
        type: 'global:vendorId',
        data: {
          url: vendorUrl
        }
      }).then(({ data }) => {
        localStorage.setItem('vendorUrl', vendorUrl);
        localStorage.setItem('_id', data.Id);
        localStorage.setItem('keyWs', data.Id);
      });
    }
  },
  watch: {
    user () {
      const isArray = Array.isArray(this.user);
      if (isArray) {
        window.localStorage.clear();
      }
    }
  }
};
</script>
