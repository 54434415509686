<template>
  <div class="cointainer-tablechar">
    <v-chart :options="heatmap" autoresize v-if="dataDays.length > 0" />
    <div class="metric-container">
      <div
        :class="{'metric-selector-selected': typeByTable === 1}"
        @click="typeByTable = 1"
        class="metric-selector"
      >Días</div>
      <div
        :class="{'metric-selector-selected': typeByTable === 2}"
        @click="typeByTable = 2"
        class="metric-selector"
      >Horas</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import metricsTypes from '@/store/types/metrics';
export default {
  props: ['sectionId'],
  data () {
    const getMaxReservationsDay = (indexTable) => {
      const table = this.tablesChart[indexTable];
      let maxDay = '';
      let max = 0;
      for (const key in this.dataPerTable[table]) {
        if (Object.hasOwnProperty.call(this.dataPerTable[table], key)) {
          const reservationsPerDay = this.dataPerTable[table][key];
          if (max <= reservationsPerDay) {
            max = reservationsPerDay;
            maxDay = key;
          }
        }
      }
      if (max === 0) {
        return 'No tiene reservas';
      }
      return maxDay;
    };
    return {
      typeByTable: 1,
      dataDays: [],
      dataPerTable: [],
      days: [
        'Sab', 'Vie', 'Jue',
        'Mie', 'Mar', 'Lun', 'Dom'],
      tablesChart: [],
      colors: {
        1: ['#FFFFFF', '#BCE9F1', '#92DDE7', '#60CCDC', '#2CB4C9', '#0693A8'], // Tipo reservation
        3: ['#FFFFFF', '#D9C6E6', '#B591CF', '#A477C5', '#8F57B7', '#6F3F92'], // TIpo Precompras
        2: ['#FFFFFF', '#E8EAA9', '#DCDE7C', '#CDD247', '#BBC030', '#A5AB12'] // Tipo WalkIn
      },
      heatmap: {
        backgroundColor: 'white',
        color: ['#FFFFFF', '#BCE9F1', '#92DDE7', '#60CCDC', '#2CB4C9', '#0693A8'],
        /* title: {
          top: 0,
          left: 'center',
          text: 'Mesas Más Reservadas'
        }, */
        tooltip: {
          position: 'bottom',
          backgroundColor: '#FFFFFF',
          textStyle: {
            color: '#444B57',
            fontFamily: 'Roboto',
            fontSize: 15
          },
          confine: true,
          padding: 12,
          extraCssText: 'box-shadow: 5px 5px 15px -2px rgba(0, 0, 0, 0.59); height: auto; width: auto;',
          formatter: (params, ticket, callback) => {
            // params.value[0] posición en X
            // params.value[1] posición en Y
            // params.value[2] Valor
            const text = `Mesa:  ${this.tablesChart[params.value[0]]}<br /> Reservas: ${params.value[2]} <br /> ${this.typeByTable === 1 ? 'Día de mayor reservación:' : 'Hora de Mayor Reservación'} ${getMaxReservationsDay(params.value[0])}`;
            return text;
          }
        },
        animation: false,
        grid: {
          height: '50%',
          top: '10%'
        },
        xAxis: {
          type: 'category',
          data: this.tablesChart,
          name: 'Mesas',
          nameLocation: 'center',
          nameGap: 30,
          alignTicks: true,
          axisLabel: {
            formatter: function (value) {
              return '{textStyle|' + value + '}';
            },
            interval: 0,
            rich: {
              textStyle: {
                color: '#79808D',
                fontFamily: 'Roboto',
                fontSize: 12
              }
            }
          },
          nameTextStyle: {
            fontSize: 14,
            color: '#79808D',
            fontFamily: 'Roboto'
          },
          axisTick: {
            interval: 0
          },
          splitArea: {
            show: true
          }
        },
        yAxis: {
          type: 'category',
          name: 'Días',
          nameLocation: 'center',
          nameGap: 35,
          alignTicks: true,
          data: [],
          axisLabel: {
            formatter: function (value) {
              return '{textStyle|' + value + '}';
            },
            padding: 4,
            interval: 0,
            rich: {
              textStyle: {
                color: '#444B57',
                fontFamily: 'Roboto',
                fontSize: 12
              }
            }
          },
          nameTextStyle: {
            fontSize: 14,
            color: '#79808D',
            fontFamily: 'Roboto'
          },
          axisLine: {
            show: true
          },
          splitArea: {
            show: true
          }
        },
        visualMap: [{
          min: 0,
          max: 10,
          precision: 2,
          seriesIndex: 0,
          type: 'piecewise',
          pieces: [],
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '15%',
          hoverLink: false,
          inRange: {
            // The visual configuration in the selected range
            color: ['#FFFFFF', '#BCE9F1', '#92DDE7', '#60CCDC', '#2CB4C9', '#0693A8']// A list of colors that defines the graph color mapping
          }
        }],
        series: []
      },
      spanishDays: {
        Sunday: 'Dom',
        Monday: 'Lun',
        Tuesday: 'Mar',
        Wednesday: 'Mie',
        Thursday: 'Jue',
        Friday: 'Vie',
        Saturday: 'Sab'
      }
    };
  },
  computed: {
    ...mapGetters({
      tables: [reservationTypes.getters.tables],
      metricReservations: [metricsTypes.getters.metricReservations]
    })
  },
  mounted () {
    this.buildArrayTables();
    this.getInitialData();
  },
  methods: {
    getPorcentaje (percentage, max) {
      const number = (Math.floor(max * percentage)) / 100;
      return Math.round(number);
    },
    getInitialData () {
      if (this.heatmap.series.length > 0) {
        this.heatmap.series = [];
      }
      if (this.heatmap.visualMap.length > 1) {
        this.heatmap.visualMap.length = 1;
      }
      const colors = this.colors[this.sectionId];
      const data = {};
      const finalData = [];
      if (Array.isArray(this.metricReservations) && this.metricReservations.length > 0) {
        this.metricReservations.forEach((reservation) => {
          const day = this.spanishDays[reservation.dayName];
          if (reservation.tableName !== null) {
            const table = reservation.tableName.split(',')[0];
            console.log(data, 'test');
            if (!data[table]) {
              data[table] = {};
            }
            if (!data[table][day]) {
              data[table][day] = 1;
              return;
            }
            if (data[table][day]) {
              data[table][day] = (data[table][day] + 1);
            }
          }
        });
      }
      this.heatmap.yAxis.data = this.days;
      this.heatmap.yAxis.name = 'Días';
      this.heatmap.yAxis.nameGap = 35;
      this.tablesChart.forEach((table, index) => {
        const tableData = [];
        let max = 0;
        if (!data[table]) {
          data[table] = {};
        }

        const dataTable = data[table];
        this.days.forEach((day, indexDay) => {
          if (!data[table][day]) {
            data[table][day] = 0;
          }
          const reservations = (Object.hasOwnProperty.call(dataTable, day)) ? dataTable[day] : 0;
          max += reservations;
          finalData.push([index, indexDay, reservations]);
          tableData.push([index, indexDay, reservations]);
        });
        if (index === 0) {
          this.heatmap.visualMap[0].pieces = [
            // Range of a piece can be specified by property min and max,
            // where min will be set as -Infinity if ignored,
            // and max will be set as Infinity if ignored.
            { min: 0, max: 0, label: '0% de reservación' },
            { min: 1, max: this.getPorcentaje(20, max), label: '1-20% de reservación' },
            { min: this.getPorcentaje(21, max), max: this.getPorcentaje(40, max), label: '21-40% de reservación' },
            // Label of the piece can be specified.
            { min: this.getPorcentaje(41, max), max: this.getPorcentaje(60, max), label: '41-60% de reservación' },
            { min: this.getPorcentaje(61, max), max: this.getPorcentaje(80, max), label: '61-80% de reservación' },
            { min: this.getPorcentaje(81, max), max: max, label: '81-100% de reservación' }
          ];
          this.heatmap.visualMap[0].inRange = {
            color: colors
          };
          this.heatmap.visualMap[0].show = false;
        } else {
          this.heatmap.visualMap.push({
            min: 0,
            max: max,
            seriesIndex: index,
            type: 'piecewise',
            precision: 2,
            show: false,
            pieces: [
              { min: 0, max: 0, label: '0% de reservación' },
              { min: 1, max: this.getPorcentaje(20, max), label: '1-20% de reservación' },
              { min: this.getPorcentaje(21, max), max: this.getPorcentaje(40, max), label: '21-40% de reservación' },
              { min: this.getPorcentaje(41, max), max: this.getPorcentaje(60, max), label: '41-60% de reservación' },
              { min: this.getPorcentaje(61, max), max: this.getPorcentaje(80, max), label: '61-80% de reservación' },
              { min: this.getPorcentaje(81, max), max: max, label: '81-100% de reservación' }
            ],
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '15%',
            hoverLink: false,
            inRange: {
              color: colors
            }
          });
        }
        this.heatmap.series.push({
          type: 'heatmap',
          label: {
            show: false
          },
          itemStyle: {
            borderColor: '#ECEDEE'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: tableData
        });
      });
      this.dataPerTable = data;
      this.dataDays = finalData;
      this.heatmap.visualMap.push({
        min: 0,
        max: 100,
        seriesIndex: this.heatmap.series.length + 1,
        type: 'piecewise',
        precision: 2,
        show: true,
        pieces: [
          { min: 0, max: 0, label: '0% de reservación' },
          { min: 1, max: 20, label: '1-20% de reservación' },
          { min: 21, max: 40, label: '21-40% de reservación' },
          { min: 41, max: 60, label: '41-60% de reservación' },
          { min: 61, max: 80, label: '61-80% de reservación' },
          { min: 81, max: 100, label: '81-100% de reservación' }
        ],
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '22%',
        hoverLink: false,
        inRange: {
          color: colors
        }
      });
    },
    buildArrayTables () {
      const finalTables = [];
      const tables = { ...this.tables[0] };
      for (const key in tables) {
        if (Object.hasOwnProperty.call(tables, key)) {
          const element = tables[key];
          if (Array.isArray(element) && element.length > 0) {
            element.forEach(zoneTable => {
              finalTables.push(zoneTable.displayName);
            });
          }
        }
      }
      this.tablesChart = finalTables;
      this.heatmap.xAxis.data = this.tablesChart;
    },
    getDataHours () {
      if (this.heatmap.series.length > 0) {
        this.heatmap.series = [];
      }
      if (this.heatmap.visualMap.length > 1) {
        this.heatmap.visualMap.length = 1;
      }
      const colors = this.colors[this.sectionId];
      const data = {};
      const finalData = [];
      const hours = [];
      if (Array.isArray(this.metricReservations) && this.metricReservations.length > 0) {
        this.metricReservations.forEach((reservation) => {
          const hour = reservation.dateHour;
          if (reservation.tableName !== null) {
            const table = reservation.tableName.split(',')[0];
            if (hours.indexOf(hour) === -1) {
              hours.push(hour);
            }
            if (!data[table]) {
              data[table] = {};
            }
            if (!data[table][hour]) {
              data[table][hour] = 1;
              return;
            }
            if (data[table][hour]) {
              data[table][hour] = (data[table][hour] + 1);
            }
          }
        });
      }
      let intersection = hours.map((hour) => this.$moment(`1970-01-01 ${hour}`, 'YYYY-MM-DD hh:mm A'));
      intersection = intersection.sort((a, b) => b.diff(a));
      intersection = intersection.map((hourMoment) => hourMoment._i.substr(11));
      this.heatmap.yAxis.data = intersection;
      this.heatmap.yAxis.name = 'Horas';
      this.heatmap.yAxis.nameGap = 70;
      this.tablesChart.forEach((table, index) => {
        const tableData = [];
        let max = 0;
        if (!data[table]) {
          data[table] = {};
        }
        const dataTable = data[table];
        intersection.forEach((time, indexTime) => {
          if (!data[table][time]) {
            data[table][time] = 0;
          }
          const reservations = (Object.hasOwnProperty.call(dataTable, time)) ? dataTable[time] : 0;
          max += reservations;
          finalData.push([index, indexTime, reservations]);
          tableData.push([index, indexTime, reservations]);
        });
        if (index === 0) {
          this.heatmap.visualMap[0].pieces = [
            // Range of a piece can be specified by property min and max,
            // where min will be set as -Infinity if ignored,
            // and max will be set as Infinity if ignored.
            { min: 0, max: 0, label: '0% de reservación' },
            { min: 1, max: this.getPorcentaje(20, max), label: '1-20% de reservación' },
            { min: this.getPorcentaje(21, max), max: this.getPorcentaje(40, max), label: '21-40% de reservación' },
            // Label of the piece can be specified.
            { min: this.getPorcentaje(41, max), max: this.getPorcentaje(60, max), label: '41-60% de reservación' },
            { min: this.getPorcentaje(61, max), max: this.getPorcentaje(80, max), label: '61-80% de reservación' },
            { min: this.getPorcentaje(81, max), max: max, label: '81-100% de reservación' }
          ];
          this.heatmap.visualMap[0].inRange = {
            color: colors
          };
          this.heatmap.visualMap[0].show = false;
        } else {
          this.heatmap.visualMap.push({
            min: 0,
            max: max,
            seriesIndex: index,
            type: 'piecewise',
            precision: 2,
            show: false,
            pieces: [
              { min: 0, max: 0, label: '0% de reservación' },
              { min: 1, max: this.getPorcentaje(20, max), label: '1-20% de reservación' },
              { min: this.getPorcentaje(21, max), max: this.getPorcentaje(40, max), label: '21-40% de reservación' },
              { min: this.getPorcentaje(41, max), max: this.getPorcentaje(60, max), label: '41-60% de reservación' },
              { min: this.getPorcentaje(61, max), max: this.getPorcentaje(80, max), label: '61-80% de reservación' },
              { min: this.getPorcentaje(81, max), max: max, label: '81-100% de reservación' }
            ],
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '15%',
            hoverLink: false,
            inRange: {
              color: colors
            }
          });
        }
        this.heatmap.series.push({
          type: 'heatmap',
          label: {
            show: false
          },
          itemStyle: {
            borderColor: '#ECEDEE'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: tableData
        });
      });
      this.dataPerTable = data;
      this.dataDays = finalData;
      this.heatmap.visualMap.push({
        min: 0,
        max: 100,
        seriesIndex: this.heatmap.series.length + 1,
        type: 'piecewise',
        precision: 2,
        show: true,
        pieces: [
          { min: 0, max: 0, label: '0% de reservación' },
          { min: 1, max: 20, label: '1-20% de reservación' },
          { min: 21, max: 40, label: '21-40% de reservación' },
          { min: 41, max: 60, label: '41-60% de reservación' },
          { min: 61, max: 80, label: '61-80% de reservación' },
          { min: 81, max: 100, label: '81-100% de reservación' }
        ],
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '22%',
        hoverLink: false,
        inRange: {
          color: colors
        }
      });
    }
  },
  watch: {
    metricReservations () {
      if (this.typeByTable === 1) {
        this.getInitialData();
        return;
      }
      this.getDataHours();
    },
    typeByTable () {
      if (this.typeByTable === 1) {
        this.getInitialData();
        return;
      }
      this.getDataHours();
    }
  }
};
</script>

<style>
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
.cointainer-tablechar {
  width: 100%;
  min-width: 1100px;
  margin-top: 10px;
  padding-right: 0.4rem;
  height: 400px;
}
.echarts {
  width: 100% !important;
  height: 400px;
  position: relative;
  /* height: 100% !important; */
}
</style>
