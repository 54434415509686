<template>
  <div class="Map animated fadeIn" id="Mapa" v-if="vendor">
    <div id="initialModal" class="modalZona" :class="{showblockHours}" v-click-outside="() => blockClose()">
      <nav>
        <button
          :class="{ active: getZoneIsBlockedDetail(section.blocked) }"
          @click="() => {showblockHours = !showblockHours;block(sectionIdActive, section.id,true ,showblockHours)}"
        >
          <i class="icon-mks icon_lockFill"></i>
        </button>
        <button :class="{ active: section.isPrivate === 1 }" v-if="validatePermission('PrivateZone') && !validatePermission('ReadOnly')">
          <i
            class="icon-mks black icon_key"
            @click.stop="
              section.isPrivate === 1
                ? unPrivateSection(section.id)
                : privateSection(section.id)
            "
          ></i>
        </button>
        <button><i class="icon-mks icon_close" @click="blockClose"></i></button>
      </nav>
      <div id="massage-block" class="zoneBlockHour justZone" v-show="showblockHours">
        <div class="bybloqued">
          <h2 v-show="timesDashboard[getDayOfWeek].length > 0">
            Bloquear hora/zona
          </h2>
          <section
            v-if="timesDashboard[getDayOfWeek].length > 0"
            class="blockedHoursContainer"
          >
            <div class="AmContainer">
              <h3>AM</h3>
              <div class="columns blokerHour is-mobile">
                <div v-for="infoHours in amHours" :key="'no-coma-cuento-' + infoHours">
                  <a
                    v-if="getHourZoneIsBlocked(section.blocked, infoHours)"
                    class="hour-link bloked"
                    @click.prevent.stop="
                      desbloquear(section.id, infoHours, infoHours)
                    "
                  >
                    <i
                      v-if="loadingHour == infoHours"
                      class="fa fa-spinner fa-spin"
                    ></i>
                    <span v-if="loadingHour != infoHours">{{
                      infoHours | moment("hh:mm")
                    }}</span>
                  </a>
                  <a
                    v-if="!getHourZoneIsBlocked(section.blocked, infoHours)"
                    class="hour-link"
                    @click.prevent.stop="
                      bloquear(section.id, infoHours, infoHours)
                    "
                  >
                    <i
                      v-if="loadingHour == infoHours"
                      class="fa fa-spinner fa-spin"
                    ></i>
                    <span v-if="loadingHour != infoHours">{{
                      infoHours | moment("hh:mm")
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="PmContainer">
              <h3>PM</h3>
              <div class="columns blokerHour is-mobile">
                <div v-for="infoHours in pmHours" :key="'parale-bola-se-esta-acabando-' + infoHours">
                  <a
                    v-if="getHourZoneIsBlocked(section.blocked, infoHours)"
                    class="hour-link bloked"
                    @click.prevent.stop="
                      desbloquear(section.id, infoHours, infoHours)
                    "
                  >
                    <i
                      v-if="loadingHour == infoHours"
                      class="fa fa-spinner fa-spin"
                    ></i>
                    <span v-if="loadingHour != infoHours">{{
                      infoHours | moment("hh:mm")
                    }}</span>
                  </a>
                  <a
                    v-if="!getHourZoneIsBlocked(section.blocked, infoHours)"
                    class="hour-link"
                    @click.prevent.stop="bloquear(section.id, infoHours, infoHours)"
                  >
                    <i
                      v-if="loadingHour == infoHours"
                      class="fa fa-spinner fa-spin"
                    ></i>
                    <span v-if="loadingHour != infoHours">{{
                      infoHours | moment("hh:mm")
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <div v-else>
            <h1 style="text-align: center; padding-bottom: 40px">
              <b>No hay Horarios disponibles para este dia.</b>
            </h1>
          </div>
          <div
            class="btn-action-messager locky"
            v-if="
              getZoneIsBlockedDetail(section.blocked) &&
              timesDashboard[getDayOfWeek].length > 0
            "
            @click.prevent.stop="desbloquearZone(section.id)"
          >
            <i v-if="!loadingHourAll" class="icon_lock white icon-mks lock"></i>
            <i v-if="loadingHourAll" class="fa fa-spinner fa-spin"></i>
            Desbloquear zona
          </div>
          <div
            class="btn-action-messager unlocky"
            v-if="
              !getZoneIsBlockedDetail(section.blocked) &&
              timesDashboard[getDayOfWeek].length > 0
            "
            @click.prevent.stop="bloquearZone(section.id)"
          >
            <i
              v-if="!loadingHourAll"
              class="icon_lockFill white icon-mks lock"
            ></i>
            <i v-if="loadingHourAll" class="fa fa-spinner fa-spin"></i>
            Bloquear zona
          </div>
        </div>
      </div>
    </div>
    <a
      v-if="hasScroll"
      class="move-section left"
      @click="moveSections('left')"
      :class="{ disable:probeIsFirstForScrolling}"
    >
      <i class="leftIcon"></i>
    </a>
    <a
      v-if="hasScroll"
      class="move-section rigth"
      @click="moveSections('rigth')"
      :class="{ disable: probeIsLastForScrolling }"
    >
      <i class="iconRight"></i>
    </a>
    <a
      class="move-section config"
      :class="{ active: configActive }"
      @click="configActive = !configActive"
    >
      <i class="icon_configuration"></i>
    </a>
    <div
      class="tabs-mapa"
      id="grandContent"
      style="position: unset"
      :class="{ notScroll: !hasScroll }"
    >
      <div
        class="tabs-mapa-content"
        :class="{ sections: !moreSections }"
        id="sectionsButtons"
      >
        <template v-for="(section, sectionId) in sections">
          <button
            :id="'btn-sections-' + sectionId"
            class="btn-area"
            @click="desSelect()"
            :key="'vistes-eso-' + sectionId"
            :class="{
              active: sectionId == currentActive,
              opacity3: sectionId != currentActive,
              sections: !moreSections,
              keyButtonActive:
                section.isPrivate == 1 &&
                !getZoneIsBlockedDetail(section.blocked),
              zoneBlocked:
                getZoneIsBlockedDetail(section.blocked) &&
                section.isPrivate !== 1,
            }"
            @click.prevent.stop="currentActive = sectionId"
          >
            <p>{{ section.displayName }}</p>
            <a
              v-if="
                validatePermission('BlockZones') &&
                !validatePermission('ReadOnly')
              "
              :id="'btn-block-' + sectionId"
              class="btn-block-zone"
              :class="{active:sectionIdActive===sectionId}"
              @click.stop="block(sectionId, section.id);sectionIdActive=sectionIdActive===sectionId ? '' : sectionId;"
            >
              <div
                v-if="
                  section.isPrivate == 1 &&
                  getZoneIsBlockedDetail(section.blocked)
                "
                class="btn-block-zone twoContainer alertContainer"
              >
                <i class="two">2</i>
              </div>
              <div
                v-else-if="section.isPrivate == 1"
                class="btn-block-zone keyContainer alertContainer"
              >
                <i class="icon-mks black icon_key"></i>
              </div>
              <div
                v-else-if="getZoneIsBlockedDetail(section.blocked)"
                class="btn-block-zone lockContainer alertContainer"
              >
                <i class="icon-mks black icon_lockFill"></i>
              </div>
              <i class="icon_configuration white icon-mks lock" @click="() => showTooltip = !showTooltip"></i>
            </a>
          </button>
        </template>
      </div>
    </div>
    <div id="mapa">
      <div class="zomm-butons" v-if="configActive">
        <button @click="zoomPlus"><i class="icon_zoomIn"></i></button>
        <button @click="zoomLess"><i class="icon_zoomOut"></i></button>
      </div>
      <div @click="desSelect()" class="Map-canvas" v-dragscroll>
        <div
          class="map-content"
          :class="{
            'zoom-2': zoomber == -1,
            'zoom-1': zoomber == 0,
            zoom1: zoomber == 1,
            zoom2: zoomber == 2,
            zoom3: zoomber == 3,
            zoom4: zoomber == 4,
            zoom5: zoomber == 5,
          }"
          :style="{
            width: sections[currentActive].width
              ? sections[currentActive].width + 'px'
              : '',
            height: sections[currentActive].height
              ? sections[currentActive].height + 'px'
              : '',
          }"
        >
          <div
            v-for="(table, index) in tables[
              sections[currentActive].displayName
            ]"
            :key="'Ojo-' + index"
          >
            <div
              class="not-show-menu"
              :id="'MesaMovida#' + table.id"
              :style="{
                transform:
                  'matrix(1, 0, 0, 1,' +
                  table.x +
                  ',' +
                  (table.y + table.height) +
                  ')',
              }"
              v-if="!isResponsive()"
            >
              <!-- show-menu-messager -->
              <div class="menu-wrap-messager">
                <div class="menu-messager">
                  <div class="circle-absolute"></div>
                  <div class="message-dargdrop">
                    <h4 class="Titulo-Seguro">¿Esta seguro?</h4>
                    <p>
                      ¿Desea desplazar la reserva de
                      <b>{{ infoDropx.name }} </b> a esta mesa?
                    </p>
                    <div class="into-btns">
                      <button class="cancel" @click="desSelect">
                        Cancelar
                      </button>
                      <button class="accept" @click="changeTable">
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="not-show-menu"
              :id="'Mesa#' + table.id"
              :style="{
                transform:
                  'matrix(1, 0, 0, 1,' +
                  table.x +
                  ',' +
                  (table.y + table.height) +
                  ')',
              }"
              v-if="!isResponsive()"
            >
              <!-- show-menu-messager -->
              <div class="menu-wrap-messager">
                <div class="menu-messager">
                  <div class="circle-absolute"></div>
                  <div>
                    <div
                      v-show="
                        reverseLoading == false && accionLoadingF == false
                      "
                    ></div>
                    <div v-show="reverseLoading || accionLoadingF">
                      <span>Realizando acción...</span>
                      <i class="fa fa-spinner fa-spin"></i>
                    </div>
                  </div>
                  <TableDetail v-show="map && tableId != ''"></TableDetail>
                </div>
              </div>
            </div>
            <div
              class="not-show-menu alert-cloud"
              :id="'MesaAlert#' + table.id"
              :style="{
                transform:
                  'matrix(1, 0, 0, 1,' +
                  (table.x + 0) +
                  ',' +
                  (table.y + 40) +
                  ')',
              }"
              v-if="!isResponsive()"
            >
              <!-- show-menu-messager -->
              <div class="menu-wrap-messager">
                <div class="menu-messager">
                  <div class="circle-absolute"></div>
                  <i
                    class="icon-mks black icon_close close-alert"
                    @click="closeAlert"
                  ></i>
                  <h4 class="sobre-title">
                    <i class="icon-mks red icon_alert_danger"></i> Esta mesa
                    esta sobre reservada
                  </h4>
                  <ul v-if="sobReserv.length > 0" class="sobre-list">
                    <li v-for="(sreser, sindex) in sobReserv" :key="'talvez-' + sindex">
                      <b>Reserva #{{ sreser.id }}</b> {{ sreser.displayName }} |
                      {{ convertDate(sreser.date) }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="alertYellow"
              @mouseleave="delShowAlert('alertYellow-' + table.id)"
              :id="'alertYellow-' + table.id"
              style="display: none"
              :style="{
                transform:
                  'matrix(1, 0, 0, 1,' +
                  (table.x + 0) +
                  ',' +
                  (table.y + 0) +
                  ')',
              }"
              v-if="!is_touch_device()"
            >
              <div class="point-row"></div>
              <div class="bars">
                <span class="title-alert">
                  <img class="icon-mks" src="@/assets/icons/alert_yellow.svg" />
                  &nbsp;¡Atención!
                </span>
                <center class="cont-alert">
                  <span class="comentarios"
                    >Este usuario tiene una reserva en otros restaurantes a la
                    misma hora.</span
                  >
                </center>
                <hr />
                <center class="cont-logo">
                  <img
                    class="icon-style-alert"
                    :src="vendor.logo"
                    v-for="(vendor, llave) in vendorLogos[table.id]"
                    :key="'ojo-pelao-' + llave"
                    :class="{ display_none: !vendor.logo }"
                  />
                </center>
              </div>
            </div>
            <div
              class="alertYellow is-touch"
              :id="'alertYellow-' + table.id"
              style="display: none"
              :style="{
                transform:
                  'matrix(1, 0, 0, 1,' +
                  (table.x + 0) +
                  ',' +
                  (table.y + 0) +
                  ')',
              }"
              v-if="is_touch_device()"
            >
              <div class="point-row"></div>
              <div class="bars">
                <span class="title-alert">
                  <img class="icon-mks" src="@/assets/icons/alert_yellow.svg" />
                  &nbsp;¡Atención!
                </span>
                <center class="cont-alert">
                  <span class="comentarios"
                    >Este usuario tiene una reserva en otros restaurantes a la
                    misma hora.</span
                  >
                </center>
                <hr />
                <center
                  class="cont-logo"
                  v-if="vendorLogos[table.id] != 'undefined'"
                >
                  <img
                    class="icon-style-alert"
                    :src="vendor.logo"
                    v-for="(vendor, llave) in vendorLogos[table.id]"
                    :key="'nerda-loco-' + llave"
                    :class="{ display_none: !vendor.logo }"
                  />
                </center>
              </div>
            </div>
            <TableComponent
              v-if="!table.isHidden"
              :mesa="table"
              @logos="vendorLogos = $event"
              @dataAlertYellow="infoAlertYellow = $event"
              v-on:alertable="readoAlert"
              v-on:infoDrop="TableDrop"
            ></TableComponent>
          </div>
          <div
            v-for="(item, key) in sections[currentActive].subSections"
            :key="'se-acaba-' + sections[currentActive].subSections + key"
            :style="{
              position: 'absolute',
              left: item.x + 'px',
              top: item.y + 'px',
              'z-index': 100,
            }"
          >
            <b-tag
                class="tagF"
              :style="{
                backgroundColor: design.colorDashboard,
                color: '#fff',
                fontWeight: 600,
              }"
            >
              <div class="subzoneContainer">
                <p>{{ item.title }}</p>
                <button
                  class="wid"
                  @click="openModalSubSection(item)"
                  :class="{
                    modalActive: subzoneWithModalOpen === item.id + '',
                  }"
                >
                  <i class="icon_configuration icon-mks lock"></i>
                                <div
                v-if="
                  item.isPrivate == 1 &&
                  getZoneIsBlockedDetail(item.blocked)
                "
                class="btn-block-zone twoContainer alertContainer"
              >
                <i class="two">2</i>
              </div>
              <div
                v-else-if="item.isPrivate == 1"
                class="btn-block-zone keyContainer alertContainer"
              >
                <i class="icon-mks black icon_key"></i>
              </div>
              <div
                v-else-if="getZoneIsBlockedDetail(item.blocked)"
                class="btn-block-zone lockContainer alertContainer"
              >
                <i class="icon-mks black icon_lockFill"></i>
              </div>
                </button>
                <div
                  id="initialModalSubZone"
                  class="subZone"
                  v-if="subzoneWithModalOpen === item.id + ''"
                  v-click-outside="() => openModalSubSection(item)"
                >
                  <nav>
                    <button
                      :class="{
                        active: getZoneIsBlockedDetail(item.blocked),
                      }"
                      @click="
                        () => (showblockHoursSubZone = !showblockHoursSubZone)
                      "
                    >
                      <i class="icon-mks icon_lockFill"></i>
                    </button>
                    <button :class="{ active: subZonePrivate }" v-if="validatePermission('PrivateZone') && !validatePermission('ReadOnly')">
                      <i
                        class="icon-mks black icon_key"
                        @click.stop="
                          () => {
                            subZonePrivate = !subZonePrivate;
                            privateSubZone(item);
                          }
                        "
                      ></i>
                    </button>
                    <button>
                      <i
                        class="icon-mks icon_close"
                        @click="openModalSubSection(item)"
                      ></i>
                    </button>
                  </nav>
                  <div
                    id="massage-block"
                    class="zoneBlockHour"
                    v-show="showblockHoursSubZone"
                  >
                    <div class="circle-absolute"></div>
                    <button
                      class="closeModalButton"
                      @click="showblockHours = false"
                    >
                      <i
                        class="icon-mks icon_close"
                        @click="() => (showblockHoursSubZone = false)"
                      ></i>
                    </button>
                    <div class="bybloqued">
                      <h2
                        v-show="timesDashboard[getDayOfWeek].length > 0"
                        style="color: #4a4a4a"
                      >
                        Bloquear hora/subZona
                      </h2>
                      <section
                        v-if="timesDashboard[getDayOfWeek].length > 0"
                        class="blockedHoursContainer"
                      >
                        <div class="AmContainer">
                          <h3>AM</h3>
                          <div class="columns blokerHour is-mobile">
                            <div
                              v-for="infoHours in amHours"
                              :key="'se-fue-' + infoHours + 's'"
                            >
                              <a
                                v-if="
                                  getHourZoneIsBlocked(item.blocked, infoHours)
                                "
                                class="hour-link bloked"
                                @click.prevent.stop="
                                  desbloquearSubSection(
                                    item.id,
                                    infoHours,
                                    infoHours
                                  )
                                "
                              >
                                <i
                                  v-if="loadingHour == infoHours"
                                  class="fa fa-spinner fa-spin"
                                ></i>
                                <span v-if="loadingHour != infoHours">{{
                                  infoHours | moment("hh:mm")
                                }}</span>
                              </a>
                              <a
                                v-if="
                                  !getHourZoneIsBlocked(item.blocked, infoHours)
                                "
                                class="hour-link"
                                @click.prevent.stop="
                                  bloquearSubSection(item.id, infoHours, infoHours)
                                "
                              >
                                <i
                                  v-if="loadingHour == infoHours"
                                  class="fa fa-spinner fa-spin"
                                ></i>
                                <span v-if="loadingHour != infoHours">{{
                                  infoHours | moment("hh:mm")
                                }}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="PmContainer">
                          <h3>PM</h3>
                          <div class="columns blokerHour is-mobile">
                            <div
                              v-for="infoHours in pmHours"
                              :key="'hay-dios-' + infoHours + 's'"
                            >
                              <a
                                v-if="
                                  getHourZoneIsBlocked(item.blocked, infoHours)
                                "
                                class="hour-link bloked"
                                @click.prevent.stop="
                                  desbloquearSubSection(
                                    item.id,
                                    infoHours,
                                    infoHours
                                  )
                                "
                              >
                                <i
                                  v-if="loadingHour == infoHours"
                                  class="fa fa-spinner fa-spin"
                                ></i>
                                <span v-if="loadingHour != infoHours">{{
                                  infoHours | moment("hh:mm")
                                }}</span>
                              </a>
                              <a
                                v-if="
                                  !getHourZoneIsBlocked(
                                    item.blocked,
                                    infoHours,
                                    infoHours
                                  )
                                "
                                class="hour-link"
                                @click.prevent.stop="
                                  bloquearSubSection(
                                    item.id,
                                    infoHours,
                                    infoHours
                                  )
                                "
                              >
                                <i
                                  v-if="loadingHour == infoHours"
                                  class="fa fa-spinner fa-spin"
                                ></i>
                                <span v-if="loadingHour != infoHours">{{
                                  infoHours | moment("hh:mm")
                                }}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div v-else>
                        <h1 style="text-align: center; padding-bottom: 40px">
                          <b>No hay Horarios disponibles para este dia.</b>
                        </h1>
                      </div>
                      <div
                        class="btn-action-messager locky"
                        v-if="
                          getZoneIsBlockedDetail(item.blocked) &&
                          timesDashboard[getDayOfWeek].length > 0
                        "
                        @click.prevent.stop="desbloquearSubZone(item.id)"
                      >
                        <i
                          v-if="!loadingHourAll"
                          class="icon_lock white icon-mks lock"
                        ></i>
                        <i
                          v-if="loadingHourAll"
                          class="fa fa-spinner fa-spin"
                        ></i>
                        Desbloquear subZona
                      </div>
                      <div
                        class="btn-action-messager unlocky"
                        v-if="
                          !getZoneIsBlockedDetail(item.blocked) &&
                          timesDashboard[getDayOfWeek].length > 0
                        "
                        @click.prevent.stop="bloquearSubZone(item.id)"
                      >
                        <i
                          v-if="!loadingHourAll"
                          class="icon_lockFill white icon-mks lock"
                        ></i>
                        <i
                          v-if="loadingHourAll"
                          class="fa fa-spinner fa-spin"
                        ></i>
                        Bloquear subZona
                      </div>
                    </div>
                  </div>
                </div>
              </div></b-tag
            >
          </div>
        </div>
        <div v-if="isResponsive()">
          <div
            v-for="(table, index) in tables[
              sections[currentActive].displayName
            ]"
            :key="'lloralo-papi-' + index"
            style="position: absolute; left: 0; bottom: 0"
          >
            <div class="not-show-menu" :id="'Mesa#' + table.id">
              <!-- show-menu-messager -->
              <div class="menu-wrap-messager">
                <div class="menu-messager">
                  <div class="circle-absolute"></div>
                  <div v-show="reverseLoading || accionLoadingF">
                    <span>Realizando acción...</span>
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                  <TableDetail v-show="map && tableId != ''"></TableDetail>
                </div>
              </div>
            </div>
            <div
              class="not-show-menu alert-cloud"
              :id="'MesaAlert#' + table.id"
            >
              <!-- show-menu-messager -->
              <div class="menu-wrap-messager">
                <div class="menu-messager">
                  <div class="circle-absolute"></div>
                  <i
                    class="icon-mks black icon_close close-alert"
                    @click="closeAlert"
                  ></i>
                  <h4 class="sobre-title">
                    <i class="icon-mks red icon_alert_danger"></i> Esta mesa
                    esta sobre reservada
                  </h4>
                  <ul v-if="sobReserv.length > 0" class="sobre-list">
                    <li v-for="(sreser, sindex) in sobReserv" :key="'nerda-viste-tu-' + sindex">
                      <b>Reserva #{{ sreser.id }}</b> {{ sreser.displayName }} |
                      {{ convertDate(sreser.date) }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="settings-button offer"
        v-if="
          vendor &&
          $route.path.split('/')[2] === 'reservations' &&
          (user || {}).isAdmin &&
          !validatePermission('ReadOnly')
        "
        @click="sidelateral = !sidelateral"
        v-tooltip="{ content: 'Configuracón Mapa', placement: 'bottom' }"
      >
        <div class="settings-icon">
          <i class="icon-mks white icon_cogs"></i>
        </div>
      </div>
    </div>
    <div class="conventions-button">
      <b-dropdown position="is-top-left" aria-role="list">
        <!-- <a slot="trigger" class="convButton mapaConv">CONVENCIONES</a> -->
        <b-dropdown-item
          :focusable="false"
          custom
          class="item-dd"
          aria-role="listitem"
          ><div class="msg">Walk-in sentado</div>
          <div class="res-sit convention-color"></div
        ></b-dropdown-item>
        <b-dropdown-item
          :focusable="false"
          custom
          class="item-dd"
          aria-role="listitem"
          ><div class="msg">Reserva sentada</div>
          <div class="walk-sit convention-color"></div
        ></b-dropdown-item>
        <b-dropdown-item
          :focusable="false"
          custom
          class="item-dd"
          aria-role="listitem"
          ><div class="msg">Mesa bloqueada</div>
          <div class="block-table convention-color"></div
        ></b-dropdown-item>
        <b-dropdown-item
          :focusable="false"
          custom
          class="item-dd"
          aria-role="listitem"
          ><div class="msg">Mesa libre</div>
          <div class="free-table convention-color"></div
        ></b-dropdown-item>
        <b-dropdown-item
          :focusable="false"
          custom
          class="item-dd"
          aria-role="listitem"
          ><div class="msg">Mesa privada</div>
          <span class="privada"><i class="icon-mks white icon_key"></i></span
        ></b-dropdown-item>
        <b-dropdown-item
          :focusable="false"
          custom
          class="item-dd"
          aria-role="listitem"
          ><div class="msg">Reserva proxima</div>
          <div class="reser-proxima-table convention-color"></div
        ></b-dropdown-item>
        <b-dropdown-item
          :focusable="false"
          custom
          class="item-dd"
          aria-role="listitem"
          ><div class="msg">Reserva sobre la hora</div>
          <div class="reser-atra-table convention-color"></div
        ></b-dropdown-item>
        <b-dropdown-item
          :focusable="false"
          custom
          class="item-dd"
          aria-role="listitem"
          ><div class="msg">Reserva retrasada</div>
          <div class="reser-retrasada-table convention-color"></div
        ></b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      id="lgMenu"
      class="settingsbar"
      :class="{ enter: sidelateral }"
      v-if="(user || {}).isAdmin"
    >
      <span id="exit" @click="sidelateral = false">&times;</span>
      <SectionSettings
        :section="sections[currentActive]"
        :seatingTable="tables"
        :mergings="getMergings"
      ></SectionSettings>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import sectionTypes from '@/store/types/section';
import TableComponent from './Table';
import SectionSettings from './SectionSettings';
import TableDetail from '@/components/reservations/Center/TableDetail';
import { dragscroll } from 'vue-dragscroll';
import BlockedAlert from '@/components/_shared/BlockedAlert';
import serviceVendor from '@/helpers/apis/serviceVendor';

export default {
  name: 'MapVue',
  components: { TableComponent, SectionSettings, TableDetail },
  directives: {
    dragscroll: dragscroll
  },
  data () {
    return {
      sectionIdActive: '',
      showblockHoursSubZone: false,
      configActive: false,
      currentActive: '0',
      sidelateral: false,
      loading: false,
      isBloked: false,
      zoomber: 1,
      showFinish: [],
      reverseLoading: false,
      accionLoadingF: false,
      respon: false,
      sobReserv: [],
      infoDropx: [],
      move: 0,
      ancho: false,
      moreSections: true,
      blockZone: true,
      hourZone: false,
      section: {
        blocked: []
      },
      loadingHour: null,
      loadingHourAll: false,
      idSection: null,
      vendorLogos: [],
      subzoneWithModalOpen: '',
      modalSubZoneInput: '',
      subZonePrivate: false,
      subZoneBlocked: false,
      infoAlertYellow: {
        show: false,
        vendors: []
      },
      hasScroll: false,
      elementFocusOnScroll: 0,
      showblockHours: false,
      counterForBlock: 1,
      showTooltip: false
    };
  },
  mounted () {
    if (Object.values(this.sections).length >= 5) {
      var grande = parseInt(
        getComputedStyle(document.getElementById('grandContent')).width
      );
      var ancho = parseInt(
        getComputedStyle(document.getElementById('sectionsButtons')).width
      );
      if (ancho < grande) {
        this.ancho = true;
      } else {
        this.ancho = false;
      }
    } else {
      this.moreSections = false;
    }
    if (this.isResponsive()) {
      this.zoomber = -1;
    }
    this.desSelect();
  },
  methods: {
    privateSubZone (subzone) {
      const thes = this;
      const info = {
        seatingSubSectionId: subzone.id,
        sectionId: subzone.seatingSectionId,
        vendorId: this.vendor.id,
        section: {
          userId: this.user.id,
          isPrivate: this.subZonePrivate,
          date: this.$moment().format('YYYY-mm-DD')
        }
      };
      if (this.subZonePrivate) {
        thes.$buefy.modal.open({
          parent: thes,
          component: BlockedAlert,
          hasModalCard: true,
          canCancel: false,
          customClass: 'blocked-alert-general-modal modal-center',
          props: {
            info,
            type: 'Sub Zona',
            option: 'Privada'
          },
          events: {
            update () {
              thes.getDataVendorReservation();
            }
          }
        });
      } else {
        serviceVendor
          .put('/admin/subsection/' + subzone.id, info)
          .then(() => {
            this.getDataVendorReservation();
          })
          .catch(() => {
            thes.$buefy.toast.open({
              message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición subZone',
              type: 'is-danger'
            });
          });
      }
    },
    openModalSubSection (subzone) {
      if (this.subzoneWithModalOpen !== subzone.id + '') {
        this.subzoneWithModalOpen = subzone.id + '';
        this.modalSubZoneInput = subzone.title;
        this.subZonePrivate = subzone.isPrivate;
        this.subZoneBlocked = subzone.IsBlOcKlEd;
      } else {
        this.subzoneWithModalOpen = '';
      }
    },
    privateSection (sectionId) {
      const thes = this;
      thes.$buefy.modal.open({
        parent: thes,
        component: BlockedAlert,
        hasModalCard: true,
        canCancel: false,
        customClass: 'blocked-alert-general-modal modal-center',
        props: {
          section: sectionId,
          type: 'Zona',
          option: 'Privada'
        },
        events: {
          loadingChange (data) {
            thes.loading = data;
          },
          update (data) {
            thes.section.isPrivate = data;
          }
        }
      });
    },
    unPrivateSection (sectionId) {
      this.loading = true;
      this.$store
        .dispatch({
          type: 'reservation:setUnBlockedSection',
          data: {
            vendorId: this.idVendor,
            seatingSectionId: sectionId
          }
        })
        .then(() => {
          this.section.isPrivate = 0;
          this.loading = false;
          this.getDataVendorReservation();
        });
    },
    is_touch_device () {
      return !!('ontouchstart' in window || navigator.maxTouchPoints);
    },
    size: _.size,
    desSelect () {
      const els = document.getElementsByClassName('show-menu-messager');
      const els2 = document.getElementsByClassName('over');
      // eslint-disable-next-line
      classie.remove(document.body, "show-mobile-map");
      if (els.length > 0) {
        for (let x = 0; x < els.length; x++) {
          els[x].classList.remove('show-menu-messager');
        }
      }
      if (els2.length > 0) {
        for (let i = 0; i < els2.length; i++) {
          els2[i].classList.remove('over');
        }
      }
      this.closeAlertYellow();
      this.$store.commit(reservationTypes.mutations.setSelectTableId, '');
      this.$store.commit(reservationTypes.mutations.setSelectTableInfo, '');
    },
    block (index, sectionId, openViaBlockZone, showBlockHours) {
      if (!this.showblockHours) {
        this.counterForBlock = 1;
        this.sectionIdActive = '';
        this.showblockHours = false;
        this.hourZone = false;
        document.getElementById('initialModal').style.display = 'none';
      }
      const elemento = document.getElementById('btn-sections-' + index);
      const iconConfig = document.getElementById('btn-block-' + index);
      const padre = document.getElementById('grandContent');
      const rectElemento = elemento.getBoundingClientRect();
      const rectPadre = padre.getBoundingClientRect();
      const halfModalSizePlusPadding = 0;
      const initialModal = document.getElementById('initialModal');
      const totalModalWidth = openViaBlockZone && showBlockHours ? 375 : 200;
      const spaceAdjustment = this.hasScroll ? 25 : 0;
      let posicionLeft =
        rectElemento.left -
        rectPadre.left -
        halfModalSizePlusPadding + spaceAdjustment;
      let positionButtonWithRespectOfModal = elemento.offsetWidth - 25;
      if (elemento.offsetWidth > totalModalWidth) {
        posicionLeft = (posicionLeft + iconConfig.offsetLeft) - (totalModalWidth / 2) + 36;
        positionButtonWithRespectOfModal = (totalModalWidth / 2) - 26;
      } else {
        posicionLeft = (posicionLeft + iconConfig.offsetLeft) - (totalModalWidth / 2) + 10;
        positionButtonWithRespectOfModal = (totalModalWidth / 2);
      }
      if (!openViaBlockZone) {
        if (this.hourZone === true) {
          this.hourZone = false;
          this.showblockHours = false;
          initialModal.style.display = 'none';
        } else {
          this.hourZone = true;
          this.idSection = sectionId;
          initialModal.style.display = 'flex';
          const aux = [];
          this.sections.map(function (section) {
            if (section.id === sectionId) {
              aux.push(section);
            }
          });
          if (aux.length > 0) {
            this.section = aux[0];
          }
        }
      }
      if (window.innerWidth <= 600) {
        posicionLeft = rectElemento.left - rectPadre.left - halfModalSizePlusPadding + spaceAdjustment;
        /* initialModal.style.left = 0 + 'px'; */
        if (openViaBlockZone && showBlockHours) {
          const calcAuxContainer = (index % 2 === 0 ? ((totalModalWidth / 2) - 70) : ((totalModalWidth / 2) + 55));
          const calcAuxTriangle = index % 2 === 0 ? (totalModalWidth / 2) - 60 : (totalModalWidth / 2) + 65;
          posicionLeft = (posicionLeft + iconConfig.offsetLeft) - calcAuxContainer;
          positionButtonWithRespectOfModal = calcAuxTriangle;
        } else {
          posicionLeft = (posicionLeft + iconConfig.offsetLeft) - (totalModalWidth / 2) + 20;
          positionButtonWithRespectOfModal = (totalModalWidth / 2) - 10;
        }
      }
      initialModal.style.setProperty('--leftIconModal', positionButtonWithRespectOfModal + 'px');
      initialModal.style.left = posicionLeft + 'px';
    },
    blockClose () {
      const activeModal = document.querySelector('.blocked-alert-general-modal');
      if (activeModal) {
        return;
      }
      this.counterForBlock = 1;
      this.sectionIdActive = '';
      this.showblockHours = false;
      this.hourZone = false;
      document.getElementById('initialModal').style.display = 'none';
    },
    changeId (id) {
      return id.replace(':', '');
    },
    convertHour (hour) {
      const hor = hour.split(':');
      const hora = hor[0] >= 9 ? '0' + hor[0] : hor[0];
      const minuto = hor[1];
      const hoy = this.$moment(this.dateComplete)
        .hours(parseInt(hora))
        .minutes(parseInt(minuto))
        .format('YYYY-MM-DD HH:mm:ss');
      return hoy;
    },
    getHourZoneIsBlocked (blocked, timestamp) {
      if (blocked.length > 0) {
        const hay = blocked.length;
        if (hay > 0) {
          for (let i = 0; i < hay; i++) {
            if (blocked[i].fechaCompleta === timestamp) {
              return true;
            }
          }
        } else {
          return false;
        }
      } else if (blocked.length === undefined) {
        return false;
      }
    },
    bloquear (section, timestamp, keyId) {
      const thes = this;
      if (this.counterForBlock === 1) {
        thes.$buefy.modal.open({
          parent: thes,
          component: BlockedAlert,
          hasModalCard: true,
          canCancel: false,
          customClass: 'blocked-alert-general-modal modal-center',
          props: {
            section,
            timestamp,
            keyId,
            type: 'Zona',
            option: 'Bloqueada'
          },
          events: {
            loadingHourChange (data) {
              thes.loadingHour = (data === 0) ? null : data;
            },
            changeCounterForBlock () {
              thes.counterForBlock++;
            }
          }
        });
      } else {
        this.loadingHour = keyId;
        this.$store
          .dispatch({
            type: 'reservation:blockedHourSeatingSection',
            data: {
              userId: this.user.id,
              vendorId: this.idVendor,
              seatingSectionId: section,
              date: timestamp
            }
          })
          .then(() => {
            this.loadingHour = null;
          })
          .catch(() => {
            this.loadingHour = null;
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Ocurrió un error al procesar la petición',
              position: 'is-bottom',
              type: 'is-danger'
            });
          });
      }
    },
    desbloquear (section, timestamp, keyId) {
      this.loadingHour = keyId;
      this.$store
        .dispatch({
          type: 'reservation:unBlockedHourSeatingSection',
          data: {
            userId: this.user.id,
            vendorId: this.idVendor,
            seatingSectionId: section,
            date: timestamp
          }
        })
        .then(() => {
          this.loadingHour = null;
        })
        .catch(() => {
          this.loadingHour = null;
        });
    },
    bloquearSubSection (section, timestamp, keyId) {
      const thes = this;
      thes.$buefy.modal.open({
        parent: thes,
        component: BlockedAlert,
        hasModalCard: true,
        canCancel: false,
        customClass: 'blocked-alert-general-modal modal-center',
        props: {
          section,
          timestamp,
          keyId,
          type: 'Sub Zona',
          option: 'Bloqueada'
        },
        events: {
          loadingHourChange (data) {
            thes.loadingHour = (data === 0) ? null : data;
          }
        }
      });
    },
    desbloquearSubSection (section, timestamp, keyId) {
      this.loadingHour = keyId;
      this.$store
        .dispatch({
          type: 'reservation:unBlockedHourSeatingSubSection',
          data: {
            userId: this.user.id,
            vendorId: this.idVendor,
            seatingSubSectionId: section,
            date: timestamp
          }
        })
        .then(() => {
          this.loadingHour = null;
        })
        .catch(() => {
          this.loadingHour = null;
        });
    },
    bloquearZone (section) {
      const thes = this;
      thes.$buefy.modal.open({
        parent: thes,
        component: BlockedAlert,
        hasModalCard: true,
        canCancel: false,
        customClass: 'blocked-alert-general-modal modal-center',
        props: {
          section,
          timestamp: thes.selectedDate,
          keyId: 1,
          all: 1,
          type: 'Zona',
          option: 'Bloqueada'
        },
        events: {
          loadingHourChange (data) {
            thes.loadingHour = data !== 0;
          }
        }
      });
    },
    bloquearSubZone (subzone) {
      const thes = this;
      thes.$buefy.modal.open({
        parent: thes,
        component: BlockedAlert,
        hasModalCard: true,
        canCancel: false,
        customClass: 'blocked-alert-general-modal modal-center',
        props: {
          section: subzone,
          timestamp: thes.selectedDate,
          keyId: 1,
          all: 1,
          type: 'Sub Zona',
          option: 'Bloqueada'
        },
        events: {
          loadingHourChange (data) {
            thes.loadingHour = data !== 0;
          }
        }
      });
    },
    desbloquearZone (subsection) {
      this.loadingHourAll = true;
      this.$store
        .dispatch({
          type: 'reservation:unBlockedDaySeatingSection',
          data: {
            userId: this.user.id,
            vendorId: this.idVendor,
            seatingSectionId: subsection,
            date: this.selectedDate
          }
        })
        .then(() => {
          this.loadingHourAll = false;
        })
        .catch(() => {
          this.loadingHourAll = false;
        });
    },
    desbloquearSubZone (section) {
      this.loadingHourAll = true;
      this.$store
        .dispatch({
          type: 'reservation:unBlockedDaySeatingSubSection',
          data: {
            userId: this.user.id,
            vendorId: this.idVendor,
            seatingSubSectionId: section,
            date: this.selectedDate
          }
        })
        .then(() => {
          this.loadingHourAll = false;
        })
        .catch(() => {
          this.loadingHourAll = false;
        });
    },
    getZoneIsBlockedDetail (blocked) {
      if (blocked.length > 0) {
        if (blocked.find((i) => i.fecha === this.selectedDate)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    closeAlertYellow () {
      const alertYellow = document.getElementsByClassName('alertYellow');
      if (alertYellow.length > 0) {
        for (var i = 0; i < alertYellow.length; i++) {
          alertYellow[i].style.display = 'none';
        }
      }

      this.infoAlertYellow.show = false;
      this.infoAlertYellow.vendors = [];
      this.$emit('dataAlertYellow', this.infoAlertYellow);
    },
    closeAlert () {
      var els = document.getElementsByClassName('show-menu-alert');
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-alert');
        }
      }
    },
    isResponsive () {
      if (window.innerWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    zoomPlus () {
      if (this.zoomber <= 4) {
        this.zoomber = this.zoomber + 1;
      }
    },
    zoomLess () {
      if (this.zoomber > -1) {
        this.zoomber = this.zoomber - 1;
      }
    },
    finish (reservationId) {
      this.accionLoadingF = true;
      const userId = this.user.id;
      const score = this.showFinish.score;
      const comments = this.showFinish.vendorComments;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsFinish',
          data: {
            reservationId: reservationId,
            userId: userId,
            comments: comments,
            score: score
          }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.accionLoadingF = false;
            this.reverseLoading = false;
            this.showFinish = [];
          } else {
            this.accionLoadingF = false;
            this.reverseLoading = false;
            this.showFinish = [];
          }
        })
        .catch(() => {
          this.accionLoadingF = false;
          this.reverseLoadin = false;
          this.showFinish = [];
        });
    },
    oldStatus (type, reservationId) {
      this.reverseLoading = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:undoReservationStatus',
          data: { reservationId: reservationId, item: type, userId: userId }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.accionLoadingF = false;
            this.reverseLoading = false;
            this.showFinish = [];
          } else {
            this.accionLoadingF = false;
            this.reverseLoading = false;
            this.showFinish = [];
          }
        })
        .catch(() => {
          this.accionLoadingF = false;
          this.reverseLoading = false;
          this.showFinish = [];
        });
    },
    readoAlert: function (alerts) {
      this.sobReserv = alerts;
    },
    TableDrop: function (info) {
      this.infoDropx = info;
    },
    getDataVendorReservation () {
      this.$store
        .dispatch({
          type: 'section:sections',
          data: { vendorId: this.idVendor }
        })
        .finally(() => {
          this.$store
            .dispatch({
              type: 'reservation:tables',
              data: { vendorId: this.getVendorId }
            })
            .finally(() => {
              this.$store.dispatch({
                type: 'reservation:reservations',
                data: {
                  vendorId: this.getVendorId,
                  date: this.selectedDate
                }
              });
            });
        });
    },
    changeTable () {
      this.desSelect();
      this.$store
        .dispatch({
          type: 'reservation:DragReservation',
          data: {
            reservationId: this.infoDropx.reservation,
            seatingTableIds: this.infoDropx.mesa,
            vendorId: parseInt(this.decryptMx(this.$store.state.vendorId)),
            userId: parseInt(this.user.id)
          }
        })
        .then((response) => {
          if (response.message === 'Ok') {
            this.$buefy.notification.open({
              message:
                '<i class="icon_alert_success icon-mks green"></i>Se ha movido la reserva',
              type: 'is-success'
            });
            this.getDataVendorReservation();
          } else {
            this.$buefy.notification.open({
              message:
                '<i class="icon_alert_danger icon-mks red"></i>No es posible mover la reserva',
              type: 'is-danger'
            });
          }
        })
        .catch(() => {
          this.$buefy.notification.open({
            message:
              '<i class="icon_alert_danger icon-mks red"></i>No es posible mover la reserva',
            type: 'is-danger'
          });
        });
    },
    convertDate (date) {
      return this.$moment(new Date(parseInt(date))).format('hh:mm a');
    },
    moveSections (direccion) {
      const childs = document.getElementsByClassName('btn-area');
      const parent = document.getElementById('grandContent');
      if (direccion === 'rigth') {
        this.elementFocusOnScroll = this.getLastVisibleChildIndex(
          childs,
          parent
        );
        if (childs[this.elementFocusOnScroll + 1]) {
          const child = childs[this.elementFocusOnScroll + 1];

          const parentRect = parent.getBoundingClientRect();
          const childRect = child.getBoundingClientRect();
          if (
            childRect.left < parentRect.left || // El elemento está parcialmente fuera a la izquierda
            childRect.right > parentRect.right // El elemento está parcialmente fuera a la derecha
          ) {
            this.scrollParentToChild(parent, child);
            this.elementFocusOnScroll++;
          } else {
            this.elementFocusOnScroll++;
          }
        } else {
          return '';
        }
      } else {
        this.elementFocusOnScroll = this.getFirstVisibleChildIndex(
          childs,
          parent
        );
        if (childs[this.elementFocusOnScroll - 1]) {
          const child = childs[this.elementFocusOnScroll - 1];

          const parentRect = parent.getBoundingClientRect();
          const childRect = child.getBoundingClientRect();

          if (
            childRect.left < parentRect.left || // El elemento está parcialmente fuera a la izquierda
            childRect.right > parentRect.right // El elemento está parcialmente fuera a la derecha
          ) {
            this.scrollParentToChild(parent, child);
            this.elementFocusOnScroll--;
          } else {
            this.elementFocusOnScroll--;
          }
        } else {
          return '';
        }
      }
    },
    getLastVisibleChildIndex (childs, parent) {
      const parentRect = parent.getBoundingClientRect();
      for (let i = childs.length - 1; i >= 0; i--) {
        const childRect = childs[i].getBoundingClientRect();
        if (childRect.right <= parentRect.right) {
          return i;
        }
      }
      return -1;
    },
    hasExtraScroll (element) {
      return element.scrollWidth > element.clientWidth;
    },

    getFirstVisibleChildIndex (childs, parent) {
      const parentRect = parent.getBoundingClientRect();
      for (let i = 0; i < childs.length; i++) {
        const childRect = childs[i].getBoundingClientRect();
        if (childRect.left >= parentRect.left) {
          return i;
        }
      }
      return -1;
    },
    scrollParentToChild (parent, child) {
      const parentRect = parent.getBoundingClientRect();
      const childRect = child.getBoundingClientRect();
      const scrollLeft = childRect.left - parentRect.left;
      parent.scrollLeft += scrollLeft;
      return scrollLeft;
    },
    delShowAlert (table) {
      const $elementAlert = document.getElementById(table);
      $elementAlert.style.display = 'none';
    },
    isBlokedHour (section) {
      var hourBlock = 0;
      const thes = this;
      _.forEach(section.blocked, function (item) {
        if (item.fecha === thes.selectedDate) {
          hourBlock += 1;
        }
      });
      if (hourBlock > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  updated () {
    const element = document.getElementById('grandContent');
    if (element) {
      this.hasScroll = this.hasExtraScroll(element);
    }
  },
  computed: {
    ...mapGetters({
      getSections: [sectionTypes.getters.sections],
      getmapTables: [reservationTypes.getters.sections],
      getTables: [reservationTypes.getters.tables]
    }),
    probeIsLastForScrolling () {
      const childs = document.getElementsByClassName('btn-area');
      if (childs[this.elementFocusOnScroll + 1]) {
        return false;
      } else {
        return true;
      }
    },
    probeIsFirstForScrolling () {
      if (this.elementFocusOnScroll === 0) {
        return true;
      } else {
        return false;
      }
    },
    amHours () {
      const hoursFormated = [];
      this.timesDashboard[this.getDayOfWeek].forEach((element) => {
        hoursFormated.push(this.convertHour(element.hora));
      });
      return hoursFormated.filter((e) => {
        return this.$moment(e).format('aa').includes('am');
      });
    },
    pmHours () {
      const hoursFormated = [];
      this.timesDashboard[this.getDayOfWeek].forEach((element) => {
        return hoursFormated.push(this.convertHour(element.hora));
      });
      return hoursFormated.filter((e) => {
        return this.$moment(e).format('aa').includes('pm');
      });
    },
    sections () {
      return this.getSections;
    },
    tables () {
      return this.getTables[0];
    },
    getMergings () {
      const data = [];
      _.forEach(
        this.tables[this.sections[this.currentActive].displayName],
        function (table) {
          if (table.mergings.length > 0) {
            data.push({ tables: table.displayName, data: table.mergings });
          }
        }
      );
      return data;
    }
  },
  watch: {
    currentActive () {
      this.$store.commit(reservationTypes.mutations.setSelectTableId, '');
      this.$store.commit(reservationTypes.mutations.setSelectTableInfo, '');
    },
    map () {
      this.desSelect();
    },
    sections () {
      const con = _.find(this.sections, { id: this.idSection });
      if (con !== undefined) {
        this.section.blocked = con.blocked;
      }
    },
    infoAlertYellow () {
      this.$emit('dataAlertYellow', this.infoAlertYellow);
    }
  }
};
</script>
<style lang="scss">
#Mapa {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  #lgMenu.settingsbar {
    height: 100% !important;
    position: fixed !important;
    display: none;
    &.enter {
      display: block;
    }
    #exit {
      z-index: 999999999999 !important;
    }
  }

  .zomm-butons {
    background-color: white;
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
    right: 4px;
    display: flex;
    flex-direction: column;
    top: 0px;
    border-radius: 4px;
    width: 50px;
    position: absolute;
    margin: 5px 5px;
    align-items: stretch;
    justify-content: stretch;
    z-index: 9999;
    margin: unset;
    align-items: center;
    padding: 10px 0px;
    gap: 4px;
    border: #444b57 1px solid;
    box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.184);
    button {
      padding: unset !important;
      background: #fff;
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      border: #444b57 solid 1px;
      i {
        display: block;
        background-color: #444b57;
        mask-position: center;
        mask-size: 50%;
        mask-repeat: no-repeat;
        height: 100%;
        width: 100%;
      }
      cursor: pointer;
      &:hover {
        background-color: #444b57;
        i {
          background-color: white;
        }
      }
    }
  }
  .move-section {
    position: absolute;
    z-index: 1;
    border: 1px solid #444b57;
    border-radius: 4px;
    background-color: white;
    height: 32px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 24px;
    i {
      display: block;
      height: 10px;
      width: 10px;
      border-left: solid black 2px;
      border-top: solid black 2px;
      border-radius: 2px;
    }
    &.left {
      padding-left: 3px;
      i {
        transform: rotate(-45deg);
      }
      top: 0px;
      left: 0px;
    }
    &.rigth {
      padding-right: 3px;
      i {
        transform: rotate(135deg);
      }
      right: 30px;
      top: 0px;
    }
    &.config {
      right: 1px;
      i {
        padding: unset;
        width: 65%;
        height: 65%;
        border: unset;
        mask-position: center;
        mask-repeat: no-repeat;
        background-color: black;
        mask-size: contain;
      }
      &.active {
        background-color: #444b57;
        i {
          background-color: white;
          mask-image: url(../../../../assets/icons/allTimeLineIcons/cerrar.svg);
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: 80%;
        }
      }
    }
    &.disable {
      opacity: 0.3;
      pointer-events: none;
    }
    .icon-mks {
      &.invert {
        transform: rotateY(180deg);
      }
      &.flechas {
        height: 25px;
        width: 25px;
      }
    }
  }
  .btn-block-zone {
    background: white;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #a8adb5;
    position: relative;
    .icon-mks {
      height: 55%;
      width: 55%;
      margin: unset;
      padding: unset;
      background-color: #a8adb5;
    }
    &.keyContainer {
      &.active {
        background: #444b57;
        i {
          background: white;
        }
      }
    }
    .alertContainer {
      position: absolute;
      top: -5px;
      right: -30%;
      width: 65%;
      height: 65%;
      border: unset;
      &.keyContainer {
        background: #444b57;
        i {
          background-color: white;
        }
      }
      &.lockContainer {
        background: red;
        i {
          background-color: white;
          mask-size: 85%;
        }
      }
      &.twoContainer {
        background: white;
        border: solid 1px #444b57;
        i {
          background-color: transparent;
          color: #444b57;
          font-size: 6.5px;
          mask-position: unset !important;
        }
      }
    }

    .icon_key.active {
      background-color: red;
    }
    &.red {
      background: red;
      border: unset;
      i {
        background-color: white;
      }
    }
    &.active{
      background-color: #444b57;
      i{
        background-color: white;
      }
    }
  }
  .message {
    &.block-zone-hour {
      z-index: 10;
      width: 366px;
      border-radius: 10px;
      top: 36px;
      background: white;
      animation: anim-jelly 0.8s linear forwards;
    }
  }
  .zoneBlockHour {
    z-index: 10;
    width: 331px;
    border-radius: 10px;
    top: 60px;
    background: white;
    animation: anim-jelly 0.8s linear forwards;
    min-width: 306px;
    max-width: 331px;
    left: -8px;
    position: absolute;
    transform: translateX(-50%);
    box-shadow: 1px -1px 10px rgba(0, 0, 0, 0.3);
    &.justZone{
      position: initial;
      transform: unset;
            box-shadow: unset;
      background-color: transparent;
      padding-top: unset;
      .bybloqued{
        padding-top: 5px !important;
      }
      .blockedHoursContainer{
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 600px) {
      top: 60px;
    }
    .circle-absolute {
      background: #fff;
      height: 18px;
      width: 18px;
      position: absolute;

      left: 30px;
      top: -5px;
      border-radius: 4px;
      z-index: 1;
      transform: rotate(45deg);
      @media screen and (max-width: 600px) {
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    &.lastModal .circle-absolute {
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  #message-block {
    left: 0;
  }
  #mapa {
    height: calc(100% - 30px);
    position: relative;
  }

  .tabs-mapa {
    overflow-x: auto;
    position: relative;
    display: block;
    align-self: center;
    width: calc(100% - 85px);
    transform: translateX(-15px);
    &.notScroll {
      width: calc(100% - 30px);
    }
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }
    .tabs-mapa-content {
      width: 100%;
      flex-wrap: nowrap;
      position: relative;
      transition: all 1s ease 0s;
      display: inline-flex;
      left: 0;
      gap: 2px;
      padding-bottom: 2px;
      &.sections {
        min-width: 100%;
        width: fit-content;
      }
    }
    .btn-area {
      display: flex;
      justify-content: center;
      gap: 25px;
      align-items: center;
      position: relative;
      background-color: white;
      flex-basis: content;
      flex-grow: 1;
      border: 1px solid #d9dbde;
      border-radius: 4px;
      color: #000;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0px 10px;
      cursor: pointer;
      height: 32px;
      &.keyButtonActive {
        background: #d9dbde;
      }

      p {
        margin: unset;
        line-height: 1;
        color: #606773;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        transform: translateY(1px);
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.active {
        background-color: #444b57;
        p {
          color: white;
        }
      }
      &.zoneBlocked {
        background-color: #f8abab;
        p {
          color: black;
        }
      }
    }
  }
  .paddingPrivate {
    padding: 12px 30px 12px 12px;
  }
  .Map-canvas {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid #ccc;
    padding: 20px 0;
    border-radius: 0 0 5px 5px;
    background: #fff;
    overflow: hidden;
    cursor: move;
    .map-content {
      width: 100%;
      height: 100%;
      transform-origin: left top;
      -moz-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      -o-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    /* Menu */
    .menu-wrap-messager {
      position: absolute;

      z-index: 999;
      background: transparent;
      -webkit-transition: width 0.3s, height 0.3s;
      transition: width 0.3s, height 0.3s;
      width: 0;
      height: 0;
      font-size: 1.5em;
      left: 0;
      top: 0;
      -webkit-transform-origin: left top;
      transform-origin: left top;
      border-radius: 10px;
    }

    .menu-messager {
      opacity: 0;
      font-size: 0.65em;
      color: #000;
      text-align: right;
      border-radius: 10px;
      padding: 10px;
      z-index: 2;
      position: relative;
      background: #fff;
      transform-origin: left top;
    }
    .not-show-menu {
      display: none;
      z-index: 9999;
      position: relative;
      max-width: 338px;
    }
    .show-menu-messager {
      display: flex;
      .circle-absolute {
        background: #fff;
        height: 30px;
        width: 30px;
        position: absolute;

        left: 30px;
        top: 0px;
        border-radius: 0;
        z-index: 1;
        transform: rotate(45deg);
      }
    }
    /* Shown menu */
    .show-menu-messager .table-detail {
      opacity: 0;
      -webkit-transition: opacity 0.6s;
      transition: opacity 0.6s;
    }

    .show-menu-messager .menu-wrap-messager {
      width: 340px;
      -webkit-animation: anim-jelly 0.8s linear forwards;
      animation: anim-jelly 0.8s linear forwards;
      top: 0;
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    .show-menu-messager .menu-messager {
      opacity: 1;
      -webkit-transition: opacity 0.3s 0.3s;
      transition: opacity 0.3s 0.3s;
      -webkit-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
      z-index: 2;
      .table-detail {
        opacity: 1;
      }
      .message-dargdrop {
        font-size: 12px;
        .Titulo-Seguro {
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          width: 100%;
          margin-bottom: 10px;
        }
        p {
          font-size: 13px;
          width: 100%;
          text-align: center;
          padding: 0px 10px;
          margin-bottom: 10px;
        }
        .into-btns {
          display: flex;
          align-items: center;
          justify-content: center;
          .cancel,
          .accept {
            font-size: 12px;
            font-weight: 600;
            background: #f2f2f2;
            border-radius: 100px;
            padding: 3px 20px;
            margin: 0 10px 5px;
            width: 30%;
          }
          .accept {
            background: #444b57;
            color: #fff;
          }
        }
      }
    }

    .show-menu-alert {
      display: flex;
      .circle-absolute {
        background: #fff;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 10px;
        top: 0px;
        border-radius: 0;
        z-index: 1;
        transform: rotate(45deg);
      }
      .close-alert {
        position: absolute;
        right: 3px;
        z-index: 99;
        cursor: pointer;
      }
      .sobre-title {
        color: red;
        display: flex;
        align-items: center;
        font-weight: bold;
        z-index: 2;
        position: relative;
        i {
          margin-right: 10px !important;
        }
      }
      .sobre-list {
        z-index: 2;
        position: relative;
        li {
          text-align: left;
          font-size: 13px;
          padding: 5px;
        }
      }
    }

    @media (max-width: 1000px) {
      .show-menu-alert {
        -webkit-transform: none !important;
        transform: none !important;
        position: fixed !important;
        bottom: 75px !important;
        right: 5px !important;
        width: calc(100% - 10px) !important;
        align-items: flex-start !important;
        justify-content: flex-end !important;
        max-width: 100% !important;
        z-index: 99999 !important;
      }
      .show-menu-alert .menu-wrap-messager {
        width: 100% !important;
        -webkit-animation: none !important;
        animation: none !important;
        top: 0 !important;
        height: 100% !important;
        -webkit-transform: inherit !important;
        transform: inherit !important;
        position: relative !important;
      }
    }
    /* Shown menu */
    .show-menu-alert .table-detail {
      opacity: 0;
      -webkit-transition: opacity 0.6s;
      transition: opacity 0.6s;
    }

    .show-menu-alert .menu-wrap-messager {
      width: 340px;
      -webkit-animation: anim-jelly 0.8s linear forwards;
      animation: anim-jelly 0.8s linear forwards;
      top: 0;
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    .show-menu-alert .menu-messager {
      opacity: 1;
      -webkit-transition: opacity 0.3s 0.3s;
      transition: opacity 0.3s 0.3s;
      -webkit-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
      z-index: 2;
      .table-detail {
        opacity: 1;
      }
    }

    .zoom-2 {
      .item-map {
        font-size: 15px !important;
      }
      .table-name {
        margin-bottom: 1px;
        padding-bottom: 1px;
      }
      -moz-transform: scale(0.6);
      -webkit-transform: scale(0.6);
      -o-transform: scale(0.6);
      -ms-transform: scale(0.6);
      transform: scale(0.6);
      .show-menu-messager .menu-wrap-messager .menu-messager {
        -moz-transform: scale(1.6);
        -webkit-transform: scale(1.6);
        -o-transform: scale(1.6);
        -ms-transform: scale(1.6);
        transform: scale(1.6);
      }
    }
    .zoom-1 {
      .item-map {
        font-size: 14px !important;
      }
      .table-name {
        margin-bottom: 2px;
        padding-bottom: 2px;
      }

      -moz-transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -o-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8);
      .show-menu-messager .menu-wrap-messager .menu-messager {
        -moz-transform: scale(1.24);
        -webkit-transform: scale(1.24);
        -o-transform: scale(1.24);
        -ms-transform: scale(1.24);
        transform: scale(1.24);
      }
    }
    .zoom1 {
      -moz-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      -o-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
    .zoom2 {
      .item-map {
        font-size: 10px !important;
      }

      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      -o-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
      .show-menu-messager .menu-wrap-messager .menu-messager {
        -moz-transform: scale(0.762);
        -webkit-transform: scale(0.762);
        -o-transform: scale(0.762);
        -ms-transform: scale(0.762);
        transform: scale(0.762);
      }
      .privada {
        height: 20px;
        width: 20px;
      }
      .timer-content {
        .timer {
          font-size: 5px;
        }
      }
    }
    .zoom3 {
      .item-map {
        font-size: 10px !important;
      }
      .table-name {
        border-bottom: 1px #000 solid;
      }

      -moz-transform: scale(1.6);
      -webkit-transform: scale(1.6);
      -o-transform: scale(1.6);
      -ms-transform: scale(1.6);
      transform: scale(1.6);
      .show-menu-messager .menu-wrap-messager .menu-messager {
        -moz-transform: scale(0.619);
        -webkit-transform: scale(0.619);
        -o-transform: scale(0.619);
        -ms-transform: scale(0.619);
        transform: scale(0.619);
      }
      .privada {
        height: 19px;
        width: 19px;
      }
      .timer-content {
        .timer {
          font-size: 5px;
        }
      }
    }
    .zoom4 {
      .item-map {
        font-size: 9px !important;
      }
      .table-name {
        border-bottom: 1px #000 solid;
      }

      -moz-transform: scale(1.9);
      -webkit-transform: scale(1.9);
      -o-transform: scale(1.9);
      -ms-transform: scale(1.9);
      transform: scale(1.9);
      .show-menu-messager .menu-wrap-messager .menu-messager {
        -moz-transform: scale(0.52);
        -webkit-transform: scale(0.52);
        -o-transform: scale(0.52);
        -ms-transform: scale(0.52);
        transform: scale(0.52);
      }
      .privada {
        height: 16px;
        width: 16px;
      }
      .timer-content {
        .timer {
          font-size: 5px;
        }
      }
    }
    .zoom5 {
      .item-map {
        font-size: 12px !important;
      }
      .table-name {
        border-bottom: 1px #000 solid;
      }
      -moz-transform: scale(2.2);
      -webkit-transform: scale(2.2);
      -o-transform: scale(2.2);
      -ms-transform: scale(2.2);
      transform: scale(2.2);
      .show-menu-messager .menu-wrap-messager .menu-messager {
        -moz-transform: scale(0.45);
        -webkit-transform: scale(0.45);
        -o-transform: scale(0.45);
        -ms-transform: scale(0.45);
        transform: scale(0.45);
      }
      .privada {
        height: 13px;
        width: 13px;
      }
      .timer-content {
        .timer {
          font-size: 5px;
        }
      }
    }
  }
  #initialModal,
  #initialModalSubZone {
    display: none;
    position: absolute;
    width: 154px;
    height: 56px;
    border-radius: 10px;
    background-color: white;
    z-index: 10;
    top: 36px;
    box-shadow: 0px 5px 10px rgba(68, 75, 87, 0.50);
    align-items: center;
    border: 1px solid #80808023;
    &.modalZona{
      width: 331px;
      height: fit-content;
      align-items: center;
      flex-direction: column;
      padding: 10px 0px;
      &:not(.showblockHours){
        transform:unset;
        width: 154px;
      }
      &:not(.showblockHours){
        transform:unset;
        width: 154px;
      }
      &::after{
        right: unset;
        left: var(--leftIconModal);
        @media screen and (max-width:600px) {
          /* display: none; */
        }
      }
    }
    &:not(#initialModalSubZone) {
      @media screen and (max-width: 600px) {
        /* right: 0;
        margin: auto; */
      }
    }
    &:not(#initialModal) {
      #massage-block {
        @media screen and (max-width: 600px) {
          left: 0;
          right: unset;
          .circle-absolute {
            left: 30px;
            right: unset;
          }
        }
      }
    }
    &.subZone {
      display: flex;
      left: 0;
      right: unset;
      top: 30px;
    }
    &::after {
      content: "";
      position: absolute;
      top: -2px; /* Ajusta la posición según necesites */
      left: 50%;
      transform: translateX(-50%);
      width: 9px;
      height: 9px;
      background-color: white;
      border-radius: 4px 0 0 0;
      transform: rotate(45deg) translateX(-50%);
      box-shadow: -7px -8px 20px rgba(68, 75, 87, 0.5);
    }
    &.subZone {
      &::after {
        transform: translateX(-3px);
      }
    }
    &.lastModal {
      .zoneBlockHour {
        left: -150px;
        .circle-absolute {
          left: 170px;
        }
      }
      &:after {
        margin: auto;
        right: 0;
        left: 0;
      }
    }
    nav {
      margin: auto;
      width: 154px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      button {
        padding: unset  !important;
        border: unset;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          display: block;
          height: 55%;
          width: 55%;
          mask-position: center;
          mask-size: contain;
          position: unset;
          margin: unset;
        }
        &:nth-child(1) {
          border: 1px solid red;
          i {
            background-color: red;
          }
          &.active {
            background-color: red;
            i {
              background-color: white;
            }
          }
        }
        &:nth-child(2) {
          border: 1px solid #444b57;
          &.active {
            background-color: #444b57;
            i {
              background-color: white;
            }
          }
        }
        &:nth-child(3) {
          border: 1px solid #444b57;
          i {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  #massage-block {
    .bybloqued {
      width: 100%;
      bacco .btn-close-Block-Zone {
        background: rgba(82, 87, 90, 1);
        position: absolute;
        top: 9px;
        border-radius: 100%;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon-mks {
          height: 12px;
          width: 12px;
          margin: 0;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      h2 {
        text-align: center;
        justify-content: center;
        display: flex;
        font-weight: 600;
        margin-bottom: 11px;
        padding: 5px;
        font-size: 13px;
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 10px 10px;
      .btn-action-messager {
        color: #fff;
        background: #ccc;
        font-weight: 600;
        display: flex;
        padding: 0 20px;
        font-size: 10px;
        border-radius: 6 + px;
        line-height: 22px;
        margin-right: 1px;
        height: 30px;
        width: fit-content;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        min-height: 30px;
        margin: auto;
        margin-top: 10px;
        gap: 6px;
        &.locky {
          background: #3fc769;
        }
        &.unlocky {
          background: #de2828;
        }
        i {
          height: 17px;
          width: 17px;
          margin: unset;
          &.lock {
            height: 15px;
            width: 15px;
          }
        }
      }

      .blokerHour {
        width: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-height: 212px;
        background-color: transparent;
        .column {
          width: 46px;
        }
        .hour-link {
          border: 1px #ebeced solid;
          background-color: white;
          span {
            font-weight: 400;
            font-size: 13px;
          }
          .fa-spinner {
            margin-right: 5px;
          }
          &.bloked {
            color: red;
            background: #fbeeee;
          }
          color: #000;
          height: 100%;
          width: 100%;
          width: 46px;
          height: 27px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          font-size: 13px;
        }
      }
    }
    @media screen and (max-width: 600px) {
      margin: auto;
      left: -60%;
    }
  }
  .alertYellow {
    background-color: white;
    padding: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: 275px;
    display: flex;
    position: absolute;
    z-index: 10;
    top: -5px;
    left: 50px;
    .point-row {
      // width: 30px;
      // height: 30px;
      position: absolute;
      z-index: 10;
      top: 5px;
      left: -15px;
      width: 0;
      height: 0;
      border-right: 25px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
    .bars {
      width: 100%;
      display: flex;
      flex-direction: column;
      .item-requirement {
        display: inline-block;
        width: 80px;
        color: #000;
        font-size: 11px;
        border: 1px solid rgba(202, 211, 0, 1);
        border-radius: 14px;
        padding: 2px 8px;
        margin: 0 2px;
        background: rgba(252, 255, 193, 1);
      }
      .title-alert {
        display: flex;
        font-weight: bold;
        align-items: center !important;
        padding-bottom: 5px;
        font-size: 18px;
      }
      .cont-alert {
        text-align: left;
        line-height: normal;
        font-size: 13px;
      }
      .cont-logo {
        display: flex;
        flex-wrap: wrap;
        width: 100% !important;
      }
      .icon-style-alert {
        width: 20% !important;
      }
      hr {
        margin: 10px 0 !important;
      }
    }
  }
}

@media (max-width: 1000px) {
  #Mapa .Map-canvas .show-menu-messager .menu-wrap-messager .menu-messager {
    -moz-transform: inherit !important;
    -webkit-transform: inherit !important;
    -o-transform: inherit !important;
    -ms-transform: inherit !important;
    transform: inherit !important;
  }
}
.lockForSubZone {
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 50%;
  background-color: red;
  i {
    display: block;
    background-color: white;
    mask-size: 60%;
    mask-repeat: no-repeat;
    mask-position: center;
    height: 100%;
    width: 100%;
  }
}
.wid {
  display: flex;
  height: 18px;
  border: unset;
  width: 18px;
  background-color: white;
  padding: unset;
  border-radius: 4px;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .btn-block-zone{
    position: absolute !important;
    top: -8px !important;
    right: -8px !important;
    width: 15px !important;
    height: 15px !important;
    &.keyContainer {
      border-color:transparent !important;
          background-color: #444b57 !important;
          i{
            background-color: white !important;
          }
    }
    &.lockContainer {
      border-color:transparent !important;
          background-color: red !important;
          i{
            background-color: white !important;
          }
    }
    i{
      font-size: 11px;
    }
  }
  &.modalActive {
    background-color: #444b57;
    i {
      background-color: white;
    }
  }
  .icon_configuration {
    height: 76%;
    width: 70%;
    background-color: black;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100% !important;
    padding: unset !important;
    margin: unset !important;
    border-radius: 4px;
  }
}
.subzoneContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 7px;
  position: relative;
  .modalInSubZone {
    position: relative;
    padding: 10px;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 312px;
    position: absolute;
    left: 125%;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -5px;
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      background-color: white;
      margin: auto;
      border-radius: 3px;
      box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.178);
    }
    input {
      height: 100%;
      border: 1px solid #d9dbde;
      border-radius: 4px;
      color: black;
      width: 125px;
      text-indent: 3px;
    }
    button {
      padding: unset !important;
      cursor: pointer;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: unset;
      i {
        display: block;
        mask-size: contain;
        mask-position: center;
        height: 100%;
        width: 100%;
        mask-position: center;
        mask-repeat: no-repeat;
      }
      &:nth-child(2) {
        border: solid black 1px;
        i {
          background-color: black;
        }
      }
      &:nth-child(3) {
        background-color: #7b7e80;
        i {
          mask-size: 72%;
          background-color: white;
        }
        &.active {
          background-color: red;
        }
      }
      &:nth-child(4) {
        background-color: #7b7e80;
        i {
          background: white;
        }
        &.active {
          background-color: #444b57;
        }
      }
      &:nth-child(5) {
        background-color: white;
        border: solid black 1px;
        i {
          mask-size: 75%;
          background: black;
        }
      }
    }
  }
}
.blockedHoursContainer {
  width: 100%;
  background-color: #fafbfb;
  padding: 0px 5px;
  max-height: 200px;
  overflow: auto;
}
.AmContainer,
.PmContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 3px 0px;
  gap: 3px;
  h3 {
    display: flex;
    flex: 1;
    justify-content: center;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;

    /* Primario/444b57 */

    color: #444b57;
  }
}

.closeModalButton {
  padding: unset !important;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #444b57;
  i {
    width: 80%;
    height: 70%;
    display: block;
    mask-position: center;
    mask-size: cover;
    position: unset;
    margin: unset;
  }
}
.tagF{
  @media screen and (max-width:600px) {
      transform: scale(1.3);
  }

}
@media screen and (max-width: 768px) {
  .modal-center {
    .animation-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
