import peopleTypes from '@/store/types/people';
import serviceUser from '@/helpers/apis/serviceUser';

const state = {
};

const actions = {
  [peopleTypes.actions.add]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('/addPeople', data.data)
        .then(response => { resolve(response); })
        .catch((error) => reject(error));
    });
  },
  [peopleTypes.actions.findByReservationId]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('/findByReservationId', data.data)
        .then(response => { resolve(response.data); })
        .catch((error) => reject(error));
    });
  },
  [peopleTypes.actions.search]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('/people/search', data.data)
        .then(response => { resolve(response.data.data); })
        .catch((error) => reject(error));
    });
  },
  [peopleTypes.actions.update]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('/people/update', data.data)
        .then(response => { resolve(response.data); })
        .catch((error) => reject(error));
    });
  },
  [peopleTypes.actions.getOneUser]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('/getPerson', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [peopleTypes.actions.getStatisticsOneUser]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('/getStatisticsPerson', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [peopleTypes.actions.getMetricsUser]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.get('/userMetricsView/' + data.data.userId)
        .then(response => { resolve(response.data); })
        .catch((error) => reject(error));
    });
  }
};

const getters = {
};

const mutations = {
};

export default {
  state,
  actions,
  getters,
  mutations
};
