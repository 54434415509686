import namespace from '@/helpers/namespace';

export default namespace('menuQr', {
  getters: [
    'typeLanguage'
  ],
  actions: [
  ],
  mutations: [
    'setTypeLanguage'
  ]
});
