<template>
  <div class="RestaurantArea" ref="area">
    <slot></slot>
    <div class="RestaurantArea-resizer" ref="resizer" v-if="this.scalable"></div>
  </div>
</template>

<script>
export default {
  name: 'RestaurantArea',
  props: {
    scalable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      width: 500,
      height: 300
    };
  },
  mounted () {
    if (this.scalable) {
      this.$refs.resizer.addEventListener('mousedown', this.initResize, false);
    }
  },
  methods: {
    initResize () {
      window.addEventListener('mousemove', this.resize, false);
      window.addEventListener('mouseup', this.stopResize, false);
    },
    resize (event) {
      var area = this.cumulativeOffset(this.$refs.area);
      this.width = (event.clientX - area.left);
      this.height = (event.clientY - area.top);
      this.$refs.area.style.width = this.width + 'px';
      this.$refs.area.style.height = this.height + 'px';
    },
    stopResize () {
      window.removeEventListener('mousemove', this.resize, false);
      window.removeEventListener('mouseup', this.stopResize, false);
    },
    cumulativeOffset (element) {
      var top = 0;
      var left = 0;
      do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
      } while (element);

      return {
        top: top,
        left: left
      };
    }
  }
};
</script>

<style scoped lang="scss">
.RestaurantArea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 10px;
  &-resizer {
    background: url("../../../assets/resize.png");
    cursor: se-resize;
    width: 6px;
    height: 6px;
    opacity: .5;
    position: absolute;
    right: 2px;
    bottom: 2px;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
