<template>
  <div
    class="alert-bigbox youSure"
    id="validateComponent"
    v-if="tablesWithReservations ? Object.keys(tablesWithReservations).length > 0 : false"
  >
    <em class="icon-mks red icon_danger danger"></em>
    <div class="blockContainer">
      <div class="textTypeError validate">
        <label class="title-dange">La mesa que estas escogiendo ya está reservada.</label>
        <p>¿ Estas seguro de continuar con la reserva ?</p>
      </div>

      <div class="right-danger validate">
        <div :key="t" class="reservationErrorRow" v-for="(twr, t) in tablesWithReservations">
          <div class="tableContainer start">
            <span>Mesa:</span>
            <span class="rereserv-dg">
              <p>{{ t }}</p>
            </span>
          </div>
          <div class="tableContainer center">
            <span>Codigo:</span>
            <div :key="key" class="rereserv-dg" v-for="(resId, key) in twr">
              <span class="rereserv-dg">
                <p>{{ resId.reservationId }}</p>
              </span>
            </div>
          </div>
          <div class="tableContainer last">
            <span>Nombre:</span>
            <div :key="key" class="rereserv-dg" v-for="(resId, key) in twr">
              <span class="rereserv-dg">
                <p>{{ resId.client }}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import LocalStorage from 'store';
export default {
  props: ['typeMethod', 'reservation'],
  data () {
    return {
      tablesWithReservations: []
    };
  },
  mounted () {
    this.validateReservationTable();
  },
  methods: {
    validateReservationTable () {
      if (this.reservation.selectTable.length > 0) {
        if (this.reservation.hour !== null) {
          this.$store
            .dispatch({
              type: 'reservation:validateReservationTable',
              data: {
                date: this.reservation.hour,
                tables: this.reservation.selectTable,
                rotationTime: this.params.rotationTime,
                vendorId: this.vendor.id,
                people: this.reservation.people
              }
            })
            .then((result) => {
              let isAlert = true;
              const dataPrueba = Object.keys(result.data.data);
              this.reservation.selectTable.forEach((e) => {
                dataPrueba.forEach((el) => {
                  // if (e.idTable === result.data.data[el][0].seatingTableId && e.state === true) { ya no es valida
                  if (e.idTable === result.data.data[el][0].seatingTableId) {
                    const tableElement = document.getElementById(
                      'tableId-' + e.idTable
                    );
                    if (result.data.data[el][0].isShared) {
                      if (result.data.data[el][0].sharedFull === false) {
                        isAlert = false;
                        tableElement.querySelector('i').style = 'color: #006EB3; font-size: 8px;';
                      } else {
                        tableElement.querySelector('i').style = 'color: #F83037; font-size: 8px;';
                      }
                    } else {
                      tableElement.querySelector('i').style = 'color: #F83037; font-size: 8px;';
                    }
                  }
                });
              });
              if (this.typeMethod === 'edit' && isAlert === true) {
                this.tablesWithReservations = result.data.data;
                Object.keys(result.data.data).forEach((tableName) => {
                  result.data.data[tableName].forEach((x, i) => {
                    if (x.reservationId === this.reservation.reservationId) {
                      this.tablesWithReservations[tableName].splice(i, 1);
                      if (this.tablesWithReservations[tableName].length === 0) {
                        this.tablesWithReservations = _.omit(
                          this.tablesWithReservations,
                          tableName
                        );
                      } else {
                        this.tablesWithReservations = null;
                      }
                    }
                  });
                });
              } else {
                if (isAlert === true) {
                  this.tablesWithReservations = result.data.data;
                }
              }
              if (Object.keys(this.tablesWithReservations).length > 0) {
                if (LocalStorage.get('sendReservation') !== undefined) {
                  this.$emit('blockedSend', false);
                } else {
                  this.$emit('blockedSend', true);
                }
              } else {
                this.$emit('blockedSend', true);
              }
            })
            .catch(() => {
              this.tablesWithReservations = [];
            });
        }
      } else {
        this.tablesWithReservations = [];
      }
    }
  },
  watch: {
    reservation: {
      handler () {
        this.validateReservationTable();
      },
      deep: true
    }
  }
};
</script>
<style type="text/css">
.notify-container {
  background: #fdd5d7;
  padding: 10px;
  margin: 20px 0px !important;
  margin-left: -28px !important;
  width: calc(100% + 56px);
}
.notify {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto !important;
}
.a-icon {
  height: 40px;
  width: 40px;
  position: relative;
  display: block;
  margin-right: 20px !important;
  background: #cf1b4b;
}
.masking {
  mask-image: url("../../assets/icons/danger.svg");
  -webkit-mask-image: url("../../assets/icons/danger.svg");
  mask-mode: alpha;
  -webkit-mask-mode: alpha;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 100%;
  -webkit-mask-size: 100%;
  mask-position: center;
  -webkit-mask-position: center;
}
.description {
  text-align: center;
}
p {
  font-size: 13px;
}
</style>
