<template>
  <div class="content-modal_calendar" id="modal-blockday">
    <button class="close_button" @click.prevent.stop="closeBlocking" v-if="!isLoading"><i class="fa fa-times"></i></button>
    <p class="Atention">¡Atención!</p>
    <p class="Litle-text">Desea bloquear este día:</p>
    <section class="date-block">
      <div class="icon_box">
        <i class="icon-mks white icon_calendar_voild"></i>
        <div class="number-d">{{ the_day() }}</div>
      </div>
      <div class="date">{{ date() }} <br> {{ month() }}</div>
    </section>
    <ValidateAction @changeInfo="setData($event)" @disableButton="disableButtonOk = $event"/>
    <section class="waiting-list" v-if="params.activateWaitingList">
      <p class="waiting-text">¿Deseas desactivar la lista de espera?</p>
      <div class="waiting-checkbox-container">
        <b-field>
          <b-checkbox v-model="waitingYes" @input="waitingNo = false; updateCheckbox()" type="is-warning">
            Si
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="waitingNo" @input="waitingYes = false; updateCheckbox()" type="is-warning">
            No
          </b-checkbox>
        </b-field>
      </div>
    </section>
    <button class="button-white-ok" @click.prevent.stop="blockDay" :class="{'button-ok-disable': disableButtonOk}">
      <i class="fa fa-check"></i> Aceptar
    </button>
  </div>
</template>
<script>
import ValidateAction from './ValidationAction.vue';
import reservationTypes from '@/store/types/reservation';
export default {
  props: ['value'],
  components: {
    ValidateAction
  },
  data () {
    return {
      isLoading: false,
      disableButtonOk: false,
      msg: '',
      hostId: null,
      waitingYes: false,
      waitingNo: true
    };
  },
  computed: {
    isOpen: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      }
    },
    schedules () {
      return this.getSchedules;
    }
  },
  methods: {
    updateCheckbox () {
      setTimeout(() => {
        if (!this.waitingYes && !this.waitingNo) {
          this.waitingNo = true;
        }
      }, 100);
    },
    closeBlocking () {
      this.isOpen = false;
      this.$store.commit(reservationTypes.mutations.setBlockingTime, false);
    },
    date () {
      return this.$moment(this.dateComplete).format('dddd DD');
    },
    month () {
      return this.$moment(this.dateComplete).format('MMMM');
    },
    the_day () {
      return this.$moment(this.dateComplete).format('DD');
    },
    blockDay () {
      this.isLoading = true;
      const time = this.selectedDate;
      this.$store.dispatch('reservation:blockedDay', {
        vendorId: this.vendor.id,
        userId: this.user.id,
        date: time,
        hostId: this.hostId,
        isWaitinglistBlocked: this.waitingYes
      }).then((res) => {
        if (res.data.code === 200) {
          this.isOpen = false;
          this.$emit('eventBlockCalendar');
          this.$store.commit(reservationTypes.mutations.setBlockingTime, false);
        } else {
          this.msg = 'Oops! No habian horarios para bloquear';
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    setData (data) {
      this.hostId = data.hostId || null;
      this.disableButtonOk = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#modal-blockday{
  &.content-modal_calendar{
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    width: 280px;
    border-right:1px solid #fff;
    padding: 25px 10px;
  }
 .close_button{
    background: transparent;
    color: #fff;
    border: none;
    position: absolute;
    cursor: pointer;
    top: 12px;
    right: 8px;
  }
 .Atention{
    font-weight:bold;
    color:#fff !important;
    font-size:18px;
    line-height: 1;
  }
 .Litle-text{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
  }
  .date-block{
    display: flex;
    margin: 10px 0;
    align-items: center;
  }
 .icon_box{
   position:relative;
    .number-d{
      color: #fff;
      position: absolute;
      top: 43%;
      left: 38%;
      font-size: 16px !important;
    }
    i {
      height: 55px;
      width: 55px;
      margin-left: 10px;
    }
  }
  .listHost {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .field {
      outline: none;
      .control.has-icons-right {
        margin-top: 5px;
      }
    }
    .selected {
      width: 100%;
      border-radius: 6px;
      background-color: white;
    }
    .host-description {
      width: 100%;
      border-radius: 6px;
      height: 60px;
      max-height: 60px;
      min-height: 60px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding: 5px;
      background-color: white;
      outline: none;
      margin-top: 5px;
    }
  }
  .date{
    color:#fff;
    font-weight:600;
    font-size:14px;
    text-transform:capitalize;
    text-align: left;
    margin-left: 15px;
  }
  button.button-white-ok{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 5px 20px 5px 20px;
    font-size: 13px;
    font-weight: 700;
    border: 1px solid black;
    cursor: pointer;
    i{
      padding: 0px;
      margin-top:0px;
      font-size:20px;
      height:auto;
      width:auto;
      margin-right: 5px;
    }
  }
  .button-ok-disable {
    pointer-events: none;
    cursor: no-drop;
    opacity: 0.7;
    user-select: none;
  }
  @media screen and (max-width: 550px) {
    padding: 20px 40px !important;
  }
}
</style>
<style lang="scss">
@use "@/assets/styles/waitingList/_index";
</style>
