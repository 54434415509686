<template>
  <div id="detail-message">
    <b-loading :active="loading" :can-cancel="false"></b-loading>
    <div class="close">
      <i class="icon" @click="$parent.close()"></i>
    </div>
    <div class="title">
      <h2>{{ (type === 'sms') ? 'Mensaje' : 'Correo' }} seleccionado:</h2>
      <h3>{{title}}</h3>
    </div>
    <div class="tabs">
      <div :class="`tab ${tabActive !== 1 ? 'inactive': ''}`" @click="changeTab(1)">
        <i :class="`custom-icon list-send-icon ${tabActive !== 1 ? 'inactive': ''}`"></i>
        <div>Lista de envios</div>
      </div>
      <div :class="`tab ${tabActive !== 2 ? 'inactive': ''}`" @click="changeTab(2)">
        <i :class="`custom-icon message-send-icon ${tabActive !== 2 ? 'inactive': ''}`"></i>
        <div>{{ (type === 'sms') ? 'Mensaje' : 'Correo' }} enviado</div>
      </div>
      <div :class="`tab ${tabActive !== 3 ? 'inactive': ''}`" @click="changeTab(3)">
        <i :class="`custom-icon status-send-message-icon ${tabActive !== 3 ? 'inactive': ''}`"></i>
        <div>Estado del envío</div>
      </div>
    </div>
    <div class="content">
      <div v-if="tabActive == 1 ">
        <h3>Detalle campaña {{ (type === 'sms') ? 'mensaje de texto' : 'correo electrónico' }}</h3>
        <!-- TABLE HISTORY HEADER -->
        <div class="tableHistory">
          <div class="tableHistory-header" style="width: 25%;">
            <div class="tableHistory-header-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 9 5" fill="none">
                <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 9 5" fill="none">
                <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="tableHistory-header-item-text">
              <span>Destinatarios</span>
            </div>
          </div>
          <div class="tableHistory-header" style="width: 50%;">
            <div class="tableHistory-header-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 9 5" fill="none">
                <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 9 5" fill="none">
                <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="tableHistory-header-item-text">
              <span>Mensaje Id</span>
            </div>
          </div>
          <div class="tableHistory-header" style="width: 25%;">
            <div class="tableHistory-header-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 9 5" fill="none">
                <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 9 5" fill="none">
                <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="tableHistory-header-item-text">
              <span>Estado</span>
            </div>
          </div>
        </div>
        <!-- TABLE HISTORY BODY -->
        <div class="tableHistory-body" v-if="detailInfo.length > 0">
          <div class="tableHistory-body-row" v-for="(item, index) in detailInfo" :key="'transaction-' + item._id + index">
            <div class="tableHistory-body-item" style="width: 25%;">
              <span>{{ item.to }}</span>
            </div>
            <div class="tableHistory-body-item" style="width:50%;">
              <span>{{ item.messageId }}</span>
            </div>
            <div class="tableHistory-body-item" style="width: 25%;">
              <span v-if="item.status === 200" style="color: #3DAF41;">Enviado</span>
              <span v-if="item.status !== 200" style="color: #FF0101;">No enviado</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tabActive == 2">
        <div class="preview">
          <h2>Vista previa del {{type == 'sms' ? 'mensaje' : 'correo'}}</h2>
          <div v-if="type === 'email'" class="preview-html-fragment">
            <div class="design-left-title">
              <h3 class="">{{title}}</h3>
              <div class="tag">
                <div>Recibidos</div>
                <div class="close">
                  <i class="icon"></i>
                </div>
              </div>
            </div>
            <div class="design-left-subject">
              <div class="design-left-subject-logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                  <circle cx="20" cy="20" r="20" fill="#BDC30E"/>
                </svg>
                <span>P</span>
              </div>
              <div class="design-left-subject-for">
                <div class="design-left-subject-for-subject">
                  <span class="design-left-subject-for-subject-title">{{ vendor.displayName }}</span>
                  <span class="design-left-subject-for-subject-subtitle">{{ vendor.email }}</span>
                </div>
                <div class="design-left-subject-from">
                  <span>para Clientes recurrentes</span>
                  <div class="design-left-subject-from-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="3" viewBox="0 0 6 3" fill="none">
                      <path d="M3 3L2.62268e-07 -4.76995e-08L6 4.76837e-07L3 3Z" fill="black"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="preview-html-fragment-divider"></div>
            <div class="preview-html-fragment-formated">
              <div v-html="emailMessage"></div>
            </div>
          </div>
          <div v-if="type === 'sms'" class="preview-sms">
            <div class="preview-sms-content">
              <div>
                <div class="preview-sms-content-for">Para:</div>
                <div class="preview-sms-content-message">
                  <div class="preview-sms-content-message-input">
                    <div class="tag">Lista seleccionada manualmente</div>
                  </div>
                  <div class="preview-sms-content-message-text-area">
                    <textarea readonly name="message" id="message" style="resize: none;" v-validate="'required'" class="destination-text" :class="{'border-alert': errors.has('message') || limitChar}" placeholder="Escriba el mensaje"  maxlength="160" v-model="message"></textarea>
                  </div>
                  <div class="preview-sms-content-message-text-area-characters">
                    <span class="" :class="{'preview-sms-content-message-text-area-characters-validation': (count >= characters)}">{{ count }}/{{ maxLength }} caracteres</span>
                  </div>
                </div>
              </div>
              <div class="preview-sms-content-message-actions" style="">
                <button class="action">Enviado</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tabActive == 3">
        <h3 style="margin-bottom: 10px;">Detalle del estado del envío</h3>
        <div class="content-detail-status-send">
          <div>El envío del {{ (type === 'sms') ? 'mensaje de texto' : 'correo' }} fue programado para el día:</div>
          <div>
            <div>
              <img src="@/assets/icons/marketing/date-send-icon.svg" alt="">
            </div>
            <div>
              <div class="date">
                <div>Fecha</div>
                <div>{{dateProgramming | moment('timezone', timezone, 'DD/MMMM/YY')}}</div>
              </div>
              <div class="date">
                <div>Hora</div>
                <div>{{dateProgramming | moment('timezone', timezone, 'hh:mm:ss A')}}</div>
              </div>
            </div>
          </div>
          <div class="actions">
            <button v-if="status === 'Cancelado'" :class="`action cancel`">Cancelado</button>
            <button v-else :class="`action ${ status === 'Programado' ? 'cancel' : (status === 'Pendiente' ? 'pending': 'send')}`" @click="status !== 'Programado' ? null : openConfirmAlert()">{{ status === 'Pendiente' ? 'Pendiente' : (status === 'Programado' ? 'Cancelar envío' : 'Enviado') }}</button>
            <button v-if="status === 'Programado'" :class="`action edit-date`" @click="reprogramDate">Editar fecha de envío</button>
          </div>
          <div class="copy" v-if="status !== 'Programado'">
            <strong>*Nota: </strong>no puede cancelar el envío faltando 15 minutos para la hora programada.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfirmAlert from '@/components/Marketing/ConfirmAlert.vue';
import Program from '@/components/Marketing/Program.vue';
import serviceMarketing from '@/helpers/apis/serviceMarketing';

export default {
  props: ['provider', 'detailInfo', 'title', 'emailMessage', 'status', 'type', 'dateProgramming', 'id'],
  data () {
    return {
      tabActive: 1,
      alertConfirm: false,
      message: '',
      limitChar: false,
      count: 0,
      characters: 160,
      maxLength: 160,
      loading: false
    };
  },
  methods: {
    closeModal () {
      this.$parent.close();
    },
    changeTab (tab) {
      this.tabActive = tab;
    },
    openConfirmAlert () {
      const thes = this;
      this.$buefy.modal.open({
        parent: thes,
        hasModalCard: true,
        canCancel: false,
        customClass: 'modal-fix',
        component: ConfirmAlert,
        props: {
          id: thes.id,
          type: this.type
        },
        events: {
          cancelled () {
            thes.$emit('reloadList');
            thes.closeModal();
          }
        }
      });
    },
    reprogramDate () {
      const thes = this;
      thes.$buefy.modal.open({
        parent: thes,
        component: Program,
        hasModalCard: true,
        trapFocus: true,
        props: {
          type: this.type
        },
        canCancel: false,
        events: {
          accept (acceptData) {
            thes.loading = true;
            const info = {
              programmingFullTime: parseInt(thes.$moment(acceptData.date + ' ' + acceptData.hour).format('x')),
              isProgramming: 1,
              programmingDate: acceptData.date,
              programmingHour: acceptData.hour,
              dateTime: parseInt(thes.$moment().format('x'))
            };
            serviceMarketing.patch('/marketing-campaign/' + thes.id, info).then(() => {
              thes.loading = false;
              thes.$emit('reloadList');
              thes.closeModal();
            }).catch((err) => {
              thes.loading = false;
              console.error(err);
            });
            thes.loading = false;
          }
        }
      });
    }
  },
  watch: {
    message () {
      this.count = (this.message.length > 0) ? this.message.length : 0;
    }
  },
  mounted () {
    this.message = this.emailMessage;
  }
};
</script>
<style lang="scss">
  .modal-fix{
    .animation-content{
      #confirm-alert{
        margin: 0 auto;
      }
    }
  }
  #detail-message {
    font-family: "Source Sans Pro";
    position: relative;
    width: 640px;
    border-radius: 8px;
    max-height: 633px;
    flex-shrink: 0;
    padding: 30px;
    background: white;
    overflow: auto;
    @media screen and (max-width: 768px) {
      width: 90%;
      margin: 0 auto;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      padding: 20px 6px;
    }
    &::-webkit-scrollbar {
      width: 3px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #444B57 !important;
      border-color: #444B57 !important;
      box-shadow: unset !important;
      border-radius: 6px !important;
    }
    .close{
      z-index: 100;
      .icon{
        cursor: pointer;
        display: flex;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-image: url(../../../assets/icons/viewReservation/icon_cerrar_view.svg);
        mask-image: url(../../../assets/icons/viewReservation/icon_cerrar_view.svg);
        width: 14px;
        height: 14px;
        background: #444B57;
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }
    .tabs{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: 768px) {
        overflow-x: auto;
      }
      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
      &::-webkit-scrollbar {
        width: 3px !important;
        height: 3px!important;
      }
      &::-webkit-scrollbar-thumb {
        background: #444B57 !important;
        border-color: #444B57 !important;
        box-shadow: unset !important;
        border-radius: 6px !important;
      }
      .tab{
        cursor: pointer;
        justify-content: center;
        width: 180px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #444B57;
        color: #FFF;
        align-items: center;
        display: flex;
        gap: 8px;
        &.inactive{
          color: #7C8189;
          background: #FAFBFC;
        }
        .custom-icon{
          display: flex;
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          &.inactive{
            background: black;
          }
        }
        .list-send-icon{
          -webkit-mask-image: url(../../../assets/icons/marketing/list-send-icon.svg);
          mask-image: url(../../../assets/icons/marketing/list-send-icon.svg);
          width: 18px;
          height: 16px;
          background: white;
        }
        .message-send-icon{
          -webkit-mask-image: url(../../../assets/icons/marketing/message-send-icon.svg);
          mask-image: url(../../../assets/icons/marketing/message-send-icon.svg);
          width: 22px;
          height: 16px;
          background: white;
        }
        .status-send-message-icon{
          -webkit-mask-image: url(../../../assets/icons/marketing/status-send-message-icon.svg);
          mask-image: url(../../../assets/icons/marketing/status-send-message-icon.svg);
          width: 16.828px;
          height: 14.312px;
          background: white;
        }
        @media screen and (max-width: 500px) {
          width: 130px;
          gap: 6px;
        }
      }
    }
    .title{
      font-style: normal;
      @media screen and (max-width: 500px) {
        padding: 0px 6px;
      }
      h2{
        color: #444B57;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 2px;
      }
      h3{
        color: #444B57;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
      }
    }
    .content{
      padding: 16px 15px;
      border-radius: 8px;
      border: 1px solid #A1A5AB;
      @media screen and (max-width: 500px) {
        padding: 16px 0;
      }
      h3:first-child{
        color: #444B57;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;
      }
      .tableHistory {
        display: flex;
        height: 30px;
        flex-shrink: 0;
        width: 100%;
        border-radius: 4px;
        background: #FAFBFC;
        .item1, .item2 {
          width: 30%;
        }
        .item3, .item4 {
          width: 15%;
        }
        .item5 {
          width: 10%;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          .item2 {
            width: 25%;
          }
          .item3, .item4 {
            width: 20%;
          }
          .item5 {
            width: 15%;
          }
        }
        &-header {
          display: flex;
          align-items: center;
          padding: 0px 9px;
          @media screen and (min-width: 768px) and (max-width: 1023px) {
          }
          &-item {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: 8px;
            user-select: none;
            &-text {
              cursor: pointer;
              color: #444B57;
              font-family: 'Source Sans Pro';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              user-select: none;
              @media screen and (min-width: 768px) and (max-width: 1023px) {
                font-size: 12px;
              }
            }
            svg:first-child{
              margin-bottom: 3.5px;
            }
          }
        }
        &-body {
          flex-direction: column;
          width: 100%;
          margin-top: 8px;
          max-height: 344px;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 3px !important;
          }
          &::-webkit-scrollbar-thumb {
            background: #444B57 !important;
            border-color: #444B57 !important;
            box-shadow: unset !important;
            border-radius: 6px !important;
          }
          .bItem1, .bItem2 {
            width: 30%;
          }
          .bItem3, .bItem4 {
            width: 15%;
          }
          .bItem5 {
            width: 10%;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            .bItem2 {
              width: 25%;
            }
            .bItem3, .bItem4 {
              width: 20%;
            }
            .bItem5 {
              width: 15%;
            }
          }
          &-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            background: rgb(168 173 181 / 10%);
            border-radius: 4px;
            margin-bottom: 10px;
          }
          &-item {
            display: flex;
            align-items: center;
            padding: 0 27px;
            height: 100%;
            span {
              color: #444B57;
              font-family: 'Source Sans Pro';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              white-space: nowrap;
              overflow: hidden;
              @media screen and (min-width: 768px) and (max-width: 1023px) {
                font-size: 12px;
                display: flex;
                /* align-items: center; */
                /* justify-content: space-around; */
                width: 100%;
              }
            }
            &-empty {
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              height: 40px;
              background: rgb(168 173 181 / 10%);
              border-radius: 4px;
              margin-bottom: 10px;
              span {
                color: #444B57;
                font-family: 'Source Sans Pro';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
            &-button {
              width: 68px;
              height: 30px;
              border-radius: 4px;
              border: 1px solid #444B57;
              background: #FFF;
              flex-shrink: 0;
              cursor: pointer;
              span {
                color: #444B57;
                text-align: center;
                font-family: 'Source Sans Pro';
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
              &:hover {
                background: #000;
                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      &-detail-status-send{
        text-align: center;
        > div:first-child{
          color: #444B57;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 16px;
        }
        > div:nth-child(2){
          justify-content: center;
          display: flex;
          gap: 20.42px;
        }
        .date{
          margin-bottom: 5px;
          text-align: left;
          div:first-child{
            color: #444B57;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 2px;
          }
          div:nth-child(2){
            color: #444B57;
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
          }
        }
        .actions{
          .action{
            width: 142px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 10px;
            border: 0px;
            margin-top: 20px;
            color: #fff;
            cursor: pointer;
            &.send{
              background: #3DAF41;
              }
            &.cancel{
              background: #E52E2E;
            }
            &.pending{
              background: #ffdd57;
            }
            &.edit-date{
              background: #444B57;
              margin-left: 20px;
            }
          }
        }
        .copy{
          color: #444B57;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 20px;
        }
        .alert-confirm{
          margin: 0 auto;
          background: #fff;
          .body{
            position: relative;
            height: 529px;
          }
        }
      }
      .preview{
        > h2 {
          color: #444B57;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 16px;
        }
        > div{
          border-radius: 10px;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
        }
        &-html-fragment{
          width: 429px;
          overflow: auto;
          padding: 20px 11.19px;
          margin: 0 auto;
          height: 350px;
          @media screen and (max-width: 500px){
            width: 97%;
          }
          &::-webkit-scrollbar {
            width: 3px !important;
          }
          &::-webkit-scrollbar-thumb {
            background: #444B57 !important;
            border-color: #444B57 !important;
            box-shadow: unset !important;
            border-radius: 6px !important;
          }
          .design-left {
            &-title{
              display: flex;
              color: #000;
              align-items: center;
              gap: 8.7px;
              padding: 0 42.81px;
              margin-bottom: 10.84px;
              h3{
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 0;
                text-align: left;
                width: 270px;
              }
              .tag{
                width: 42.356px;
                height: 9.774px;
                flex-shrink: 0;
                border-radius: 2px;
                background: #D9D9D9;
                font-size: 6px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                padding: 0 3.26px;
                text-align: left;
                place-content: flex-start;
                .close{
                  position: relative;
                  .icon{
                    width: 3.258px;
                    height: 3.258px;
                    flex-shrink: 0;
                    position: relative;
                    top: 0;
                    right: -10px;
                  }
                }
              }
            }
            &-subject {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              &-logo {
                flex-shrink: 0;
                position: relative;
                margin-right: 9.77px;
                svg{
                  width: 32.581px;
                  height: 32.581px;
                  vertical-align: text-top;
                }
                span {
                  position: absolute;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  top: 0;
                  left: 0;
                  width: 100%;
                  color: #FFF;
                  text-align: center;
                  font-family: 'Source Sans Pro';
                  font-size: 28px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
              &-for {
                display: flex;
                justify-content: center;
                align-content: flex-start;
                flex-direction: column;
                &-subject {
                  display: flex;
                  justify-content: center;
                  align-content: center;
                  height: 15px;
                  &-title {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #000;
                    font-family: 'Source Sans Pro';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }
                  &-subtitle {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 4px;
                    color: #000;
                    font-family: 'Source Sans Pro';
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                  }
                }
              }
              &-from {
                display: flex;
                justify-content: flex-start;
                align-content: center;
                margin-top: 2px;
                height: 15px;
                span {
                  color: #000;
                  font-family: 'Source Sans Pro';
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                &-icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  margin-left: 3px;
                  width: 6px;
                  flex-shrink: 0;
                }
              }
              &-body {
                margin-top: 10px;
                height: calc(100% - 94px);
                overflow-y: auto;
                &::-webkit-scrollbar {
                  width: 6px !important;
                }
                &::-webkit-scrollbar-thumb {
                  background: #444B57 !important;
                  border-color: #444B57 !important;
                  box-shadow: unset !important;
                  border-radius: 6px !important;
                }
                &-title {
                  display: flex;
                  width: 271px;
                  height: 43px;
                  flex-direction: column;
                  justify-content: center;
                  flex-shrink: 0;
                  flex-wrap: wrap;
                  margin: 0 auto;
                  @media screen and (max-width: 400px) {
                    width: 240px;
                    height: 63px;
                  }
                  span {
                    color: #000;
                    text-align: center;
                    font-family: 'Source Sans Pro';
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }
                }
                &-subtitle {
                  display: flex;
                  width: 271px;
                  height: 31px;
                  flex-direction: column;
                  justify-content: center;
                  flex-shrink: 0;
                  flex-wrap: wrap;
                  margin: 0 auto;
                  margin-top: 15px;
                  @media screen and (max-width: 400px) {
                    width: 240px;
                    height: 53px;
                  }
                  span {
                    color: #000;
                    text-align: center;
                    font-family: 'Source Sans Pro';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
                &-image {
                  width: 392px;
                  height: 260px;
                  flex-shrink: 0;
                  border-radius: 10px;
                  border: 1px solid #C9C9C9;
                  background: url('../../../assets/icons/marketing/test.png'), linear-gradient(lightgray, lightgray);
                  background-color: #FFF;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: 50%;
                  margin: 25px auto auto auto;
                  @media screen and (min-width: 769px) and (max-width: 1024px) {
                    width: 342px;
                  }
                  @media screen and (max-width: 500px) {
                    width: 366px;
                  }
                  @media screen and (max-width: 400px) {
                    width: 336px;
                  }
                }
                &-content {
                  width: 407px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  flex-shrink: 0;
                  flex-wrap: wrap;
                  margin: 30px auto auto auto;
                  @media screen and (min-width: 769px) and (max-width: 1024px) {
                    width: 342px;
                  }
                  @media screen and (max-width: 500px) {
                    width: 366px;
                  }
                  @media screen and (max-width: 400px) {
                    width: 340px;
                  }
                  &-one {
                    color: #000;
                    text-align: justify;
                    font-family: 'Source Sans Pro';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
                &-content-two {
                  width: 271px;
                  height: 31px;
                  flex-shrink: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  margin: 30px auto auto auto;
                  span {
                    color: #000;
                    font-family: 'Source Sans Pro';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
                &-image-all {
                  margin: 0px auto;
                  width: 474px;
                  height: 409px;
                  flex-shrink: 0;
                  border-radius: 10px;
                  border: 1px solid #C9C9C9;
                  background: url('../../../assets/icons/marketing/test.png'), linear-gradient(lightgray, lightgray);
                  background-color: #FFF;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: 50%;
                  @media screen and (min-width: 769px) and (max-width: 1024px) {
                    width: 342px;
                  }
                  @media screen and (max-width: 500px) {
                    width: 366px;
                  }
                }
              }
            }
          }
          &-divider{
            max-width: 320px;
            width: 100%;
            height: 1px;
            flex-shrink: 0;
            background: #D9D9D9;
            margin: 15.56px auto 20.5px;
          }
          &-formated{
            max-width: 320px;
            width: 100%;
            text-align: center;
            margin: 0 auto;
          }
        }
        &-sms{
          width: 417px;
          margin: 0 auto;
          padding: 30px;
          @media screen and (max-width: 768px){
            width: 100%;
          }
          @media screen and (max-width: 500px){
            width: 95%;
          }
          &-content{
            > div:first-child{
              display: flex;
            }
            &-for{
              width: 31px;
              margin-right: 12px;
            }
            &-message{
              width: 100%;
              &-input{
                border-radius: 10px;
                border: 1px solid #C9C9C9;
                height: 40px;
                flex-shrink: 0;
                background: #FFF;
                margin-bottom: 12px;
                padding: 5px 12px;
                .tag{
                  height: 22px;
                  width: fit-content;
                  border-radius: 10px;
                  background: #444B57;
                  color: #fff;
                  font-size: 12px;
                }
              }
              &-text-area{
                border-radius: 10px;
                border: 1px solid #C9C9C9;
                overflow: hidden;
                textarea{
                  height: 111px;
                  width: 100%;
                  padding: 10px;
                  border: none;
                  font-size: 14px;
                  outline: none!important;
                }
                &-characters{
                  margin-top: 12px;
                  font-size: 14px;
                  text-align: right;
                }
                &-characters-validation{
                  color: red;
                }
              }
              &-actions{
                text-align: center;
                justify-content: center;
                .action{
                  color: white;
                  margin-top: 20px;
                  width: 160px;
                  height: 40px;
                  flex-shrink: 0;
                  border-radius: 10px;
                  border: 1px solid #a2a5ab;
                  background: #a2a5ab;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
