<template>
  <div id="modulepolls" style="background: #ededed">
    <Sidenav @change-full="toggle"></Sidenav>
    <div class="content-wrap">
      <Toolbar class="Toolbar-poll is-mobile-custom"></Toolbar>
      <div id="pollsContainer">
        <Detail
          :pollLenght="pollLenght"
          :paginatorData="paginatorData"
          :pollId="pollId"
          @pagi="setPag($event)"
          @poll-exist2="pollExist"
          @filters-exist="filtersExist"
        />
      </div>
    </div>
    <PaginationPolls
      :pollLenght="pollLenght"
      :paginatorData="paginatorData"
      :pollId="pollId"
      :filterDate="filterDate"
      :filterDateEnd="filterDateEnd"
      :DayPollFilter="DayPollFilter"
      :query="query"
      :group="group"
    />
    <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword>
    <TempPassword></TempPassword>
  </div>
</template>
<script>
import Sidenav from '@/components/_shared/Sidenav';
import Toolbar from '@/components/_shared/Toolbar';
import Detail from '@/components/polls_v2/pollDetail';
import PaginationPolls from '@/components/polls_v2/molecules/paginationPolls';
import ChangedPassword from '@/components/_shared/ChangedPassword';
import TempPassword from '@/components/_shared/TempPassword';

export default {
  name: 'PollsVue',
  components: { Sidenav, Toolbar, Detail, PaginationPolls, ChangedPassword, TempPassword },
  data () {
    return {
      pollLenght: 0,
      pollId: 0,
      paginatorData: [],
      filterDate: null,
      filterDateEnd: null,
      DayPollFilter: 7,
      query: '',
      group: '',
      openMenu: true
    };
  },
  mounted () {
    setTimeout(() => {
      this.$store.dispatch({
        type: 'auth:refreshUser',
        data: {
          userId: this.user.id
        }
      });
    }, 200);
  },
  methods: {
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
    pollExist (val, paginatorData, pollId) {
      this.pollLenght = val;
      this.pollId = pollId;
      this.paginatorData = paginatorData;
    },
    setPag (p) {
      this.paginatorData = p;
    },
    filtersExist (filterDate, filterDateEnd, DayPollFilter, query, group) {
      this.filterDate = filterDate;
      this.filterDateEnd = filterDateEnd;
      this.DayPollFilter = DayPollFilter;
      this.query = query;
      this.group = group;
    }
  }
};
</script>
<style lang="scss">
  @use "@/assets/styles/polls/_index";
  @import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
</style>
