var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"notifications"}},[_c('a',{staticClass:"notificationItem",class:{ 'new-notifications': _vm.newNotifications },on:{"click":function($event){return _vm.reviewNotch()}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newNotifications),expression:"newNotifications"}]},[_c('div',{staticClass:"noti-cont"},[_vm._v(_vm._s(_vm.newNotifications))])]),_c('i',{staticClass:"icon-mks white icon_notification"})]),_c('div',{staticClass:"listNotifications",class:{ enter: _vm.sideNot },attrs:{"id":"lgMenu"}},[_c('div',{staticClass:"cont-header"},[_c('span',{staticClass:"exit",on:{"click":function($event){return _vm.closeMenu()}}},[_vm._v("×")]),_c('div',{staticClass:"tle"},[_vm._v("Notificaciones")]),_c('i',{staticClass:"icon_delete_outlined icon-mks black-pre",on:{"click":function($event){return _vm.openDeleteAll()}}})]),_c('div',{staticClass:"search-notification"},[_c('b-field',[_c('b-input',{attrs:{"placeholder":"Buscar...","type":"search","icon":"magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"cont-notifications"},[(!(_vm.filteredNotifications || []).length)?_c('div',{staticClass:"notification-empty"},[_c('div',[_vm._v("No hay notificaciones")])]):_vm._e(),_vm._l((_vm.filteredNotifications),function(notification,index){return _c('div',{key:index,staticClass:"cont-notification",class:{ notificationSeen: notification.isSeen, 'delete-notification-cont': _vm.showDeleteIcon(notification.id), 'first-not': index === 0 }},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center"}},[_c('div',{staticClass:"mini-avatar",class:{
              'borde-precompra':
                notification.referrer != 'Walk-In' &&
                notification.balancePaid > 0 &&
                notification.type != 'vendorCancelReservation' && notification.type != 'clientCancelReservation',
              'borde-reserva':
                notification.referrer != 'Walk-In' &&
                notification.codeStatus != 8 &&
                (notification.balancePaid == null ||
                  notification.balancePaid == 0) &&
                notification.type != 'vendorCancelReservation' && notification.type != 'clientCancelReservation',
              'borde-reserva-req':
                notification.referrer != 'Walk-In' &&
                notification.codeStatus == 8 &&
                (notification.balancePaid == null ||
                  notification.balancePaid == 0) &&
                notification.type != 'vendorCancelReservation' && notification.type != 'clientCancelReservation',
              'borde-cancel': notification.type == 'vendorCancelReservation' || notification.type == 'clientCancelReservation',
              'borde-walkin': notification.referrer == 'Walk-In',
              'bg-disabled': notification.type == 'newWaitlist',
              'delete-notification': _vm.showDeleteIcon(notification.id),
            },on:{"click":function($event){return _vm.ViewNotificationReservation(notification)}}},[_c('div',[_vm._v(" "+_vm._s(_vm.showDeleteIcon(notification.id) ? '×' :_vm.getInitials(notification.displayName ? notification.displayName : notification.userName))+" ")])]),(notification.isSeen && !_vm.showDeleteIcon(notification.id))?_c('div',{staticClass:"has-text-centered is-size-7"},[_vm._v(" Leída ")]):_vm._e()]),_c('div',{staticClass:"notification-body",class:{'bg-disabled': notification.type == 'newWaitlist'},on:{"click":function($event){return _vm.ViewNotificationReservation(notification)}}},[_c('div',{staticClass:"notification-type"},[_vm._v(_vm._s(_vm.getNotificationType(notification.type)))]),_c('div',{staticClass:"notification-text",class:{'text-small': notification.type == 'newWaitlist'}},[_vm._v(_vm._s(notification.text))]),_c('div',{staticClass:"notification-time"},[_vm._v(_vm._s(_vm.timeAgo(parseInt(notification.timestamp)))+" por "+_vm._s((notification.referrer === 'dashboard') ? notification.userName : 'Cliente'))])]),_c('div',{staticClass:"notification-remove"},[_c('i',{staticClass:"icon_delete_outlined icon-mks black-pre",on:{"click":function($event){return _vm.openSingleDelete(notification.id)}}})])])})],2)]),_c('b-modal',{attrs:{"active":_vm.confirmDelete,"id":"confirm-delete-notifications"},on:{"update:active":function($event){_vm.confirmDelete=$event}}},[_c('section',{staticClass:"modal-card-body"},[_c('i',{staticClass:"icon_delete_outlined icon-mks red"}),_c('p',{staticClass:"main-text-confirm"},[_vm._v("Confirmar")]),_c('p',{staticClass:"secondary-text-confirm"},[_vm._v(_vm._s(_vm.deleteText))]),_c('div',{staticClass:"buttons-cancel"},[_c('button',{staticClass:"btn",on:{"click":_vm.validateRemove}},[_vm._v("Si")]),_c('button',{staticClass:"btn btn-inverse",on:{"click":function($event){_vm.confirmDelete = false; _vm.deleteSingleNotificationId = null}}},[_vm._v("No")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }