<template>
  <div>
    <Sidenav v-if="vendorParams" @change-full="toggle"></Sidenav>
    <div class="content-wrap">
      <div id="metrics-index">
        <Toolbar @sectionId="sectionId = $event"></Toolbar>
        <Wrapper :sectionId="sectionId"></Wrapper>
        <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword>
      </div>
    </div>
    <TempPassword></TempPassword>
  </div>
</template>
<script>
import Toolbar from '@/components/_shared/Toolbar';
import Sidenav from '@/components/_shared/Sidenav';
import Wrapper from '@/components/Metrics/Wrapper';
import ChangedPassword from '@/components/_shared/ChangedPassword';
import TempPassword from '@/components/_shared/TempPassword';

export default {
  name: 'metricIndex',
  components: { Toolbar, Sidenav, Wrapper, ChangedPassword, TempPassword },
  data () {
    return {
      sectionId: 1,
      isHidden: true,
      verticalReservation: false
    };
  },
  mounted () {
    this.$store.dispatch({
      type: 'auth:refreshUser',
      data: {
        userId: this.user.id
      }
    });
  },
  methods: {
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    }
  }
};
</script>
<style lang="scss">
  #metrics-index {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: rgba(237, 237, 237, 1);
  }
</style>
