<template>
  <div class="general-metrics-layer__container__charts-general__card" v-if="data?.rows?.length > 0">
    <h2>{{titleChart}}</h2>
    <div class="general-metrics-layer__container__charts-general__card__filters" v-if="type === 'byQuantityAllTotalReservations' || type === 'quantityReservations'">
      <b-button
        class="general-metrics-layer__container__charts-general__card__filters__action"
        :class="{'general-metrics-layer__container__charts-general__card__filters__action--active' : filterActive() === 1}"
        @click="changeInternalFilter(1)"
      >
        <i class="icon-mks icon_reservation"></i>
        <span>Totales</span>
      </b-button>
      <b-button
        class="general-metrics-layer__container__charts-general__card__filters__action"
        :class="{'general-metrics-layer__container__charts-general__card__filters__action--active' : filterActive() === 2}"
        @click="changeInternalFilter(2)"
      >
        <i class="icon-mks icon_effective_reservation"></i>
        <span>Efectivas</span>
      </b-button>
    </div>
    <div class="container-graphs-info">
      <div class="container-graphs-info__chart-aux-cont">
        <v-chart class="chart" :key="chartKey" :option="option" :initOptions="{ renderer: 'svg' }" />
      </div>
      <Conventions
        v-if="enableConventions"
        :dataConventions="dataConventions"
        :sectionId="sectionId"
        :generalData="data"
        :walkingData="null"
        :filtersBy="1"
        :graph="graph"
        :colorsByDefault="colorsByDefault"
        :secondFiltersBy="filterActive()"
        :onlyOneCol="true"
      />
    </div>
  </div>
  <div class="general-metrics-layer__container__charts-general__card col-3 noresults" v-else>
    <div class="wrapper__noresults">Sin Resultados</div>
  </div>
</template>

<script>
/*!
* Copyright 2024 CLICK2BUY SAS
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
import { use } from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import Conventions from '@/components/MetricsV2/Charts/Conventions';
import { defineComponent } from 'vue';

use([
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  SVGRenderer
]);

export default defineComponent({
  name: 'PieChartPrec',
  props: ['data', 'type', 'sectionId', 'titleChart', 'enableConventions', 'dataConventions', 'enableFilters', 'graph'],
  components: {
    VChart,
    Conventions
  },
  provide: {
    [THEME_KEY]: ''
  },
  data () {
    return {
      chartKey: 0,
      convertedData: [],
      dataLegends: [],
      colorsByDefault: ['#FF4560', '#48c9b0'],
      option: {
        title: {
          text: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          borderWidth: '0',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          padding: 0,
          position: (point) => {
            const x = point[0] + 10; // Posición X (10px a la derecha del cursor)
            const y = point[1]; // Mantener la posición Y del cursor
            return [x, y];
          },
          extraCssText: `
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); /* Sombra hacia abajo y derecha */
            border-radius: 10px; /* Opcional: Bordes redondeados para suavizar */
          `,
          formatter: function (params) {
            const icon = params.data.icon || '';
            return `
              <div style="
                background-color: ${params.color};
                color: #fff;
                padding: 8px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                gap: 8.5px;
              ">
                ${icon ? `<img src="${icon}" style="width: 20px; height: 20px; margin-right: 8px;" alt="${params.name}" />` : ''}
                <span>${params.name}</span>
                <span>${params.value}</span>
                <span>${params.percent}%</span>
              </div>
            `;
          }
        },
        series: [
          {
            name: 'Efectivas',
            type: 'pie',
            label: {
              show: true,
              position: 'inside',
              formatter: '{d}%',
              color: '#fff',
              fontSize: 14,
              fontWeight: 'bold'
            },
            radius: '55%',
            center: ['50%', '60%'],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      filterByTotalEffective: 1
    };
  },
  mounted () {
    this.generateDataChart();
  },
  methods: {
    hasValidValue (arr) {
      return arr.some(item => item.effective > 0 || item.total > 0);
    },
    filterActive () {
      switch (this.graph) {
        case 'quantityAllTotalReservations':
          return this.filterByTotalEffective;
        case 'perQuantityReservations':
          return this.filterByTotalEffective;
      }
      return null;
    },
    generateDataChart () {
      this.resetChart();
      if (this.type === 'byQuantityAllTotalReservations' || this.type === 'quantityReservations') {
        this.data.rows.forEach((item, index) => {
          this.dataLegends.push(item.name);
          this.convertedData.push({
            value: this.filterByTotalEffective === 1 ? item.total : item.effective,
            name: item.name,
            // huesped no huesped colores
            itemStyle: {
              color: this.type === 'byQuantityAllTotalReservations' ? item.color : (index === 0 ? '#FF4560' : '#48c9b0')
            }
          });
        });
      }
      if (this.graph === 'quantityAllTotalReservations') {
        this.option.series[0].label.show = false;
      } else {
        this.option.series[0].label.show = true;
      }
      this.option.series[0].data = this.convertedData;
      /* this.option.legend.data = this.dataLegends; */
    },
    resetChart () {
      // Restablece las propiedades relacionadas con la gráfica
      this.option.series[0].data = [];
      /* this.option.legend.data = []; */
      this.localDataChart = [];
      this.convertedData = [];
      this.dataLegends = [];
    },
    changeInternalFilter (type) {
      if (this.graph === 'quantityAllTotalReservations' || this.type === 'quantityReservations') {
        this.filterByTotalEffective = type;
      }
    }
  },
  watch: {
    filterByTotalEffective () {
      this.generateDataChart();
    },
    data: {
      deep: true, // Detecta cambios profundos en el objeto
      handler () {
        this.generateDataChart();
        this.chartKey++;
      }
    },
    sectionId (value) {
      this.chartKey = value;
    }
  }
});
</script>

<style lang="scss" scoped>
  .container-graphs-info{
    gap: 10px;
    &__chart-aux-cont{
      position: relative;
      width: 100%;
      height: 240px;
      max-width: 250px;
      .chart {
        position: absolute;
        left: -6.5em;
        top: -8.5em;
        width: 452px!important;
        height: 430px;
      }
      /* &__effective{
        .chart{
          left: -6.2em;
          top: -1.8em;
        }
      }
      &__not-effective{
        height: 230px;
        .chart{
          left: -6.2em;
          top: -3.5em;
        }
      } */
    }
  }
  .general-metrics-layer__container__charts-general__card{
    padding: 20px;
  }
</style>
