<template>
  <div class="btn-large-quantity-people" @mouseleave="lookStatusModal">
    <div @click="closePopUp">
      <i class="icon-mks icon_new_plus_reservation"></i>
      <i class="icon-mks icon_new_plus_reservation"></i>
    </div>
    <!-- v-if="largeQuantity" -->
    <div
      class="btn-large-quantity-people__popup" :class="`btn-large-quantity-people__popup__${type}`"
      v-if="largeQuantity"
      @mouseleave="closePopUp"
      @mouseover="setInsidePopup(true)"
      @mouseout="setInsidePopup(false)"
    >
      <p>Escribe la cantidad de personas para la reserva</p>
      <input
        placeholder="Digita un número"
        class="btn-large-quantity-people__popup__quantity"
        :class="{ 'btn-large-quantity-people__popup__quantity--inactive' : quantity && quantity <= 0}"
        type="text"
        v-model="quantity"
        @input="filterInput"
      >
      <div class="btn-large-quantity-people__popup__actions">
        <b-button class="btn-large-quantity-people__popup__actions--accept" :disabled="!quantity && (quantity <= 0 || quantity !== '')" @click="setQuantityWithType">Aceptar</b-button>
        <b-button class="btn-large-quantity-people__popup__actions--close" @click="closePopUp">Cerrar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['type'],
  data () {
    return {
      largeQuantity: false,
      quantity: null,
      insidePopup: false
    };
  },
  methods: {
    closePopUp () {
      this.largeQuantity = !this.largeQuantity;
    },
    setQuantityWithType () {
      this.$emit('setQuantity', parseInt(this.quantity), this.type);
      this.$emit('setVisualQuantity', parseInt(this.quantity), this.type);
      this.largeQuantity = false;
      this.quantity = '';
    },
    lookStatusModal () {
      setTimeout(() => {
        if (this.largeQuantity && !this.insidePopup) {
          this.largeQuantity = false; // Solo cerrar si no está dentro del popup
        }
      }, 50);
    },
    setInsidePopup (status) {
      this.insidePopup = status; // Actualizar el estado basado en la posición del mouse
    },
    filterInput (event) {
      const value = event.target.value.replace(/[^\d]/g, '');
      if (value === '') {
        this.quantity = '';
      } else {
        this.quantity = parseInt(value);
      }
    }
  }
};
</script>

<style lang="scss">
.btn-large-quantity-people{
  border-radius: 4px;
  background: white;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  right: 3px;
  top: 3px;
  /* z-index: 50; */
  justify-content: center;
  padding: 2px 0;
  > div:first-child{
    cursor: pointer;
    gap: 1px;
    display: flex;
    align-items: center;
    > i:nth-child(2){
      margin-left: -1px;
    }
  }
  i{
    margin: 0;
    background-color: #444B57;
  }
  &--active{
    background-color: #444B57;
    i{
      margin: 0;
      background-color: white;
    }
  }
  &__popup{
    width: 305px;
    min-height: 130px;
    z-index: 100!important;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
    padding: 16px;
    position: absolute;
    right: 0;
    top: 23px;
    text-align: center;
    justify-items: center;
    display: grid;
    gap: 10px;
    font-size: 14px;
    &__quantity{
      border: 1px solid var(--Primario-444b57, #444B57);
      width: 200px;
      height: 30px;
      font-size: 14px;
      font-weight: 400;
      border-radius: 10px;
      text-align: center;
      padding: 0 6px;
      &--inactive{
        opacity: 0.5;
      }
      &::placeholder{
        opacity: 0.5;
      }
    }
    &__actions{
      display: flex;
      gap: 20px;
      justify-content: center;
      button{
        border-radius: 8px;
        width: 100px;
        height: 30px!important;
        cursor: pointer;
        font-size: 14px!important;
        font-style: normal;
        font-weight: 600!important;
      }
      &--accept{
        border: 0;
        color: white!important;
        background: #444B57!important;
      }
      &--close{
        border: 1px solid #444B57!important;
      }
    }
  }
}
@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .btn-large-quantity-people{
    > div:first-child{
    }
    i{
    }
    &--active{
    }
    &__popup{
      &__people{
        right: -60px;
      }
      &__quantity{
      }
      &__actions{
      }
    }
  }
}
</style>
