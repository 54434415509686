<template>
  <div class="header-actions-metrics">
    <div class="header-actions-metrics__sections">
      <div class="toolbar-logo" @click="openSideBar()">
        <div class="toolbar-logo-img">
          <img :src="vendor.thumbnailURL" />
        </div>
      </div>
      <div class="header-actions-metrics__sections__tab header-actions-metrics__sections__tab--active">Métricas de reservas</div>
      <div class="header-actions-metrics__sections__tab" style="display: grid; padding-top: 0px; padding-bottom: 0px; line-height: normal;opacity: 0.6">
        <span>Métricas QR</span>
        <span style="font-size: 9px;margin-top: -6px;">(Próximamente)</span>
      </div>
      <div class="header-actions-metrics__sections__tab" style="display: grid; padding-top: 0px; padding-bottom: 0px; line-height: normal;opacity: 0.6">
        <span>Métricas encuestas</span>
        <span style="font-size: 9px;margin-top: -6px;">(Próximamente)</span>
      </div>
    </div>
    <div class="header-actions-metrics__info">
      <h2>Métricas de reservas</h2>
      <div class="header-actions-metrics__info__actions">
        <div class="header-actions-metrics__info__actions__filters">
          <b-button class="" :class="{ 'header-actions-metrics__info__actions__filters__filter--active' : sectionMetric == 1 }" @click="activeMetrics(1)">Reservas</b-button>
          <b-button class="" :class="{ 'header-actions-metrics__info__actions__filters__filter--active' : sectionMetric == 2 }" @click="activeMetrics(2)">Precompras</b-button>
          <b-button class="" :class="{ 'header-actions-metrics__info__actions__filters__filter--active' : sectionMetric == 3 }" @click="activeMetrics(3)">Walk-In</b-button>
          <b-button class="" :class="{ 'header-actions-metrics__info__actions__filters__filter--active' : sectionMetric == 4 }" @click="activeMetrics(4)">General</b-button>
        </div>
        <i class="icon-mks icon_download_default_new" @click="generateReport"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalTypes from '@/store/types/global';
import reservationTypes from '@/store/types/reservation';
import metricsTypes from '@/store/types/metrics';
import serviceReport from '@/helpers/apis/serviceReport';
import axios from 'axios';

export default {
  props: ['sectionId'],
  data () {
    return {
      sectionMetric: 1
    };
  },
  computed: {
    ...mapGetters({
      dataReservation: [metricsTypes.getters.dataReservation],
      dataReservationRange: [metricsTypes.getters.dataReservationRange],
      byMonth: [metricsTypes.getters.byMonth],
      byDay: [metricsTypes.getters.byDay],
      byRange: [metricsTypes.getters.byRange],
      notEffective: [metricsTypes.getters.notEffective],
      timeState: [metricsTypes.getters.timeState],
      yearGlobal: [metricsTypes.getters.year],
      dateFromGlobal: [metricsTypes.getters.dateFrom],
      dateToGlobal: [metricsTypes.getters.dateTo],
      filterTypeDaySection: [metricsTypes.getters.filterTypeDaySection]
    })
  },
  methods: {
    activeMetrics (section) {
      this.$emit('sectionId', section);
      this.sectionMetric = section;
    },
    openSideBar () {
      this.$store.commit(globalTypes.mutations.setShowSideBar, true);
    },
    generateReport () {
      switch (this.sectionId) {
        case 1:
          this.reportReservation();
          break;
        case 2:
          this.reportReservationPrepurchase();
          break;
        case 3:
          this.reportWalkIn();
          break;
        case 4:
          this.reportGeneral();
          break;
      }
    },
    isDownload (resource = null) {
      const reportApi = resource !== null ? resource : this.apiReportDownload;
      axios({
        url: process.env.VUE_APP_URL_REPORT + '/downloadReport/' + reportApi,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('_token')
        }
      }).then((response) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        const year = new Date().getFullYear();
        const hour = new Date().getHours();
        const hour2 = (hour <= 9) ? '0' + hour : hour;
        const minute = new Date().getMinutes();
        const minute2 = (minute <= 9) ? '0' + minute : minute;
        const second = new Date().getSeconds();
        const second2 = (second <= 9) ? '0' + second : second;
        const month = new Date().getMonth() + 1;
        const month2 = (month <= 9) ? '0' + month : month;
        const date = new Date().getDate();
        const date2 = (date <= 9) ? '0' + date : date;
        const filename = year + '_' + month2 + '_' + date2 + '_' + hour2 + ':' + minute2 + ':' + second2;
        if (this.typeApiReportDownload === 'xlsx') {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Metricas' + filename + '.xlsx';
          link.click();
        } else {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Metricas' + filename + '.pdf';
          link.click();
        }
      }).catch((e) => {
        console.error(e);
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.notification.open({
          message: 'Archivo no encontrado',
          type: 'is-danger'
        });
      });
    },
    reportReservation () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.$store.dispatch({ type: 'metrics:generateReportMetrics', data: { vendorId: this.vendor.id, dateFrom: this.dateFromGlobal, dateTo: this.dateToGlobal, filter: this.filterTypeDaySection, year: this.yearGlobal } }).then(({ data }) => {
        this.generate = false;
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
        this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
        this.isDownload();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    reportReservationPrepurchase () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.$store.dispatch({ type: 'metrics:generateReportPrepurchaseMetrics', data: { vendorId: this.vendor.id, dateFrom: this.dateFromGlobal, dateTo: this.dateToGlobal, filter: this.filterTypeDaySection, year: this.yearGlobal } }).then(({ data }) => {
        this.generate = false;
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
        this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
        this.isDownload();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    reportWalkIn () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.$store.dispatch({ type: 'metrics:generateReportWalkIn', data: { vendorId: this.vendor.id, dateFrom: this.dateFromGlobal, dateTo: this.dateToGlobal, filter: this.filterTypeDaySection, year: this.yearGlobal } }).then(({ data }) => {
        this.generate = false;
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
        this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
        this.isDownload();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    reportGeneral () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceReport.post('generateGeneralMetricsReport', {
        vendorId: this.vendor.id,
        dateTo: this.dateToGlobal,
        dateFrom: this.dateFromGlobal,
        year: this.yearGlobal,
        filter: this.filterTypeDaySection
      })
        .then(({ data }) => {
          this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
          this.isDownload(data.urlApi);
        }).finally(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
    }
  }
};
</script>

<style lang="scss">
  @use "@/assets/styles/metrics/_header_actions";
</style>
