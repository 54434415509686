<template>
  <div id="link-payment">
    <div class="divider"></div>
    <span class="title">Valor Precompra</span>
    <div class="info" :style="{marginBottom: (open && (isMobileTab || isMobileMin)) ? '0px' : '12px'}">
      <div class="info-flex" style="width: 182px;">
        <img src="@/assets/icons/addReservation/uncheck.svg" alt="precompro.com" class="image" @click="setConfig()" v-if="!config">
        <img src="@/assets/icons/addReservation/checked.svg" alt="precompro.com" class="image" @click="setConfig()" v-if="config">
        <span class="description">Valor configurado del sistema</span>
      </div>
      <div class="info-flex ml-50" style="width: 92px;">
        <img src="@/assets/icons/addReservation/uncheck.svg" alt="precompro.com" class="image" @click="setOpen()" v-if="!open">
        <img src="@/assets/icons/addReservation/checked.svg" alt="precompro.com" class="image" @click="setOpen()" v-if="open">
        <span class="description">Valor abierto</span>
      </div>
      <div class="info-flex ml-10" v-if="open && isMobile" style="width: calc(100% - 334px);">
        <div class="input">
          <div class="input-logo">
            <img src="@/assets/icons/addReservation/cop.svg" alt="precompro.com">
          </div>
          <div class="divider-vertical"></div>
          <input type="text" class="amount" style="width: 100%;" @input="cambioAlValuedConsume($event)" id="amount" placeholder="Ingresar valor de consumo" :value="amount">
        </div>
      </div>
    </div>
    <div class="info" v-if="open && (isMobileTab || isMobileMin)" style="justify-content: flex-end; align-items: flex-end;" :style="{marginTop: (isMobileTab) ? 'unset' : '6px'}">
      <div class="info-flex" :style="{width: (isMobileTab) ? '48%' : (isMobileMin) ? '100%' : 'unset'}">
        <div class="input">
          <div class="input-logo">
            <img src="@/assets/icons/addReservation/cop.svg" alt="precompro.com">
          </div>
          <div class="divider-vertical"></div>
          <input type="text" class="amount" style="width: 100%;" @input="cambioAlValuedConsume($event)" id="amount" placeholder="Ingresar valor de consumo" :value="amount">
        </div>
      </div>
    </div>
    <div class="link-payment">
      <button class="link-payment-item active">
        <i class="icon-mks white icon_link_payment"></i>Enviar link de pago
        <i class="icon-mks white icon_link_payment_check" style="height: 7px; width: 10px;"></i>
      </button>
      <button class="link-payment-item disabled">
        <i class="icon-mks black icon_link_payment_card"></i> Registrar tarjeta
        <i class="icon-mks white icon_link_payment_check" style="height: 7px; width: 10px;"></i>
      </button>
    </div>
    <div class="divider"></div>
  </div>
</template>
<script>
export default {
  name: 'FormReservationPayment',
  props: ['reservationInfo'],
  data () {
    return {
      localReservationInfo: { ...this.reservationInfo },
      config: true,
      open: false,
      amount: null,
      payInfo: {
        paymentType: 'normal',
        amount: null
      },
      indefinteToast: null,
      isMobile: false,
      isNotMobile: false,
      isMobileTab: false
    };
  },
  mounted () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    synChangesReservationInfo () {
      this.localReservationInfo.loading = false;
      this.$emit('updateReservationInfoPay', this.payInfo);
    },
    handleResize () {
      // isMobileMin Menores de 658px
      // isMobile Mayores de 658px hasta 1000px y desde 1290px
      // isMobileTab Mayores de 1000px hasta 1290px
      if ((window.innerWidth > 658 && window.innerWidth < 1000) || window.innerWidth > 1290) {
        this.isMobileTab = false;
        this.isMobileMin = false;
        this.isMobile = true;
      } else if ((window.innerWidth > 1000 && window.innerWidth < 1290) || window.innerWidth < 658) {
        if (window.innerWidth < 658) {
          this.isMobile = false;
          this.isMobileTab = false;
          this.isMobileMin = true;
        } else {
          this.isMobile = false;
          this.isMobileTab = true;
          this.isMobileMin = false;
        }
      }
    },
    setOpen () {
      this.open = !this.open;
      if (this.open) {
        this.config = false;
        this.payInfo.paymentType = 'fixed';
        this.synChangesReservationInfo();
        setTimeout(() => {
          document.getElementById('amount').focus();
        }, 300);
      }
    },
    setConfig () {
      this.config = !this.config;
      if (this.config) {
        this.payInfo.paymentType = 'normal';
        this.payInfo.amount = null;
        this.synChangesReservationInfo();
        this.amount = null;
        this.open = false;
      }
    },
    cambioAlValuedConsume (e) {
      let number = e.target.value;
      if (/[^0-9.]/g.test(number)) {
        number = number.slice(0, -1);
      }
      const numberWithoutFormat = number.replace(/[.,]/g, '');
      const opciones = { maximumFractionDigits: 0, currency: 'COP' };
      const formatoNumero = new Intl.NumberFormat('es-CO', opciones);
      this.payInfo.amount = parseInt(numberWithoutFormat);
      this.synChangesReservationInfo();
      this.amount = formatoNumero.format(numberWithoutFormat);
      if (this.indefinteToast) {
        this.indefinteToast.close();
        this.indefinteToast = null;
      }
      if (parseInt(numberWithoutFormat) < 10000) {
        this.indefinteToast = this.$buefy.toast.open({
          duration: 5000,
          message: 'El monto a cobrar debe ser mayor a $ 10.000',
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style lang="scss">
#link-payment {
  .divider {
    width: 100%;
    height: 1px;
    background: #AFB3B9;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .title {
    color: #444B57;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 12px;
    width: 100%;
    user-select: none;
    .ml-10 {
      margin-left: 10px;
    }
    .ml-50 {
      margin-left: 50px;
    }
    &-flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 30px;
      .input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30px!important;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #444B57;
        opacity: 0.5;
        background: #FFF;
        &-logo {
          display: flex;
          height: 30px;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          margin-left: 5px;
        }
        img {
          width: 7px;
          height: 12px;
          flex-shrink: 0;
        }
        .divider-vertical {
          width: 1px;
          height: 20px;
          margin-right: 10px;
          flex-shrink: 0;
          border-radius: 4px;
          background:#A8ADB5;
        }
        .amount {
          display: flex;
          height: 28px;
          border: unset;
        }
      }
      .image {
        width: 14px;
        height: 14px;
        margin: 0px 8px 0px 0px;
        cursor: pointer;
      }
      .description {
        color: #444B57;
        font-family: 'Roboto';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        user-select: none;
      }
    }
  }
  .link-payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      border-radius: 12px;
      border: 1px solid rgb(68, 75, 87);
      width: 49%;
      height: 40px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &.active {
        background-color: #444b57;
        color: white;
      }
      &.disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
}
</style>
