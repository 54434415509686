<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <div class="column is-tablet">
          <b-field
            :message="errors.first('title')"
            :type="{'is-danger': errors.has('title')}"
            label="Nombre"
          >
            <b-input
              icon="table-edit"
              name="title"
              placeholder="Nombre Sub Zona"
              type="text"
              v-model="subSection.title"
              v-validate="'required'"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-tablet">
          <b-field
            :message="errors.first('copy')"
            :type="{'is-danger': errors.has('copy')}"
            label="Información"
          >
            <vue-editor :editor-toolbar="editorToolbar" v-model="subSection.copy" />
            {{ wordCounter(subSection.copy) }}
          </b-field>
        </div>
        <div class="columns">
          <div class="column is-tablet">
            <b-field
              :message="errors.first('x')"
              :type="{'is-danger': errors.has('x')}"
              label="X (Left)"
            >
              <b-input name="x" placeholder="X" type="number" v-model.number="subSection.x"></b-input>
            </b-field>
          </div>
          <div class="column is-tablet">
            <b-field
              :message="errors.first('y')"
              :type="{'is-danger': errors.has('y')}"
              label="Y (Top)"
            >
              <b-input name="y" placeholder="Y" type="number" v-model.number="subSection.y"></b-input>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="$parent.close()" class="button" type="button">Cerrar</button>
        <button class="button is-primary" v-if="subSection.id != null">Actualizar</button>
        <button class="button is-primary" v-else>Guardar</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { VueEditor } from 'vue2-editor';
export default {
  name: 'RestaurantSubSection',
  props: ['title', 'subzone', 'seatingSectionId'],
  components: {
    VueEditor
  },
  data () {
    return {
      subSection: JSON.parse(JSON.stringify(this.subzone)),
      editorToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ script: 'sub' }, { script: 'super' }],
        [{ color: [] }, { background: [] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ list: 'ordered' }],
        ['clean']
      ]
    };
  },
  methods: {
    wordCounter (string) {
      return string ? string.replace(/<[^>]*>?/gm, '').replace(/ +(?= )/g, '').split(' ').length : 0;
    },
    submit () {
      var instance = this;
      var data = {
        sectionId: instance.seatingSectionId,
        section: instance.subSection,
        vendorId: instance.vendor.id
      };
      instance.$validator.validateAll().then((result) => {
        if (result) {
          instance.$parent.close();
          instance.$emit('update', data);
        } else {
          instance.$buefy.toast.open({
            message: 'El formulario no es válido! Por favor verifique los campos.',
            type: 'is-danger'
          });
        }
      });
    }
  }
};
</script>
