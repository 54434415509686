<template>
  <main id="modalMask">
    <section class="modal">
      <span
        :style="{'color':colorStatus}"
        class="material-icons-outlined statusLogo"
      >{{this.statusLogo}}</span>
      <h1>{{statusTitle}}</h1>
      <p class="content" v-if="(!isPse && !mercadopago)">
        El pago realizado con la tarjeta terminada en
        <b v-if="cardNumber">****{{(cardNumber+'').slice(-4)}}</b>
        {{statusCopy}}
      </p>
      <p class="content" v-if="(!isPse && mercadopago)">
        El pago realizado {{statusCopyMercadoPago}}
      </p>
      <p class="content" v-if="isPse">El pago realizado {{statusCopy}}</p>
      <button @click="endOperation()" style="cursor: pointer;">{{status === 'error' ? 'Cerrar' : 'Finalizar'}}</button>
    </section>
  </main>
</template>
<script>
import LocalStorage from 'store';
export default {
  props: ['status', 'cardNumber', 'isPse', 'vendorInfo', 'mercadopago'],
  computed: {
    statusLogo () {
      switch (this.status) {
        case 'ok':
          return 'task_alt';
        case 'error':
          return 'highlight_off';
        default:
          return 'schedule';
      }
    },
    statusCopyMercadoPago () {
      switch (this.status) {
        case 'ok':
          return ', fue realizado con éxito.';
        case 'error':
          return ' ha sido rechazado.';
        default:
          return ', esta pendiente por confirmación del banco.';
      }
    },
    statusCopy () {
      switch (this.status) {
        case 'ok':
          return ', fue realizado con éxito.';
        case 'error':
          return ', ha sido rechazado.';
        default:
          return ', esta pendiente por confirmación del banco.';
      }
    },
    statusTitle () {
      switch (this.status) {
        case 'ok':
          return '¡Su pago ha sido realizado con éxito!';
        case 'error':
          return 'Su pago ha sido rechazado';
        default:
          return 'Transacción Pendiente';
      }
    },
    colorStatus () {
      switch (this.status) {
        case 'ok':
          return '#3DAF41';
        case 'error':
          return '#FF0101';
        default:
          return '#102C3A';
      }
    }
  },
  methods: {
    endOperation () {
      LocalStorage.remove('contactless_amount');
      window.location.assign('/dashboard/marketing');
      this.$emit('close', false);
    }
  }
};
</script>
<style lang="scss" scoped>
#modalMask {
  .statusLogo {
    font-size: 80px;
    margin: 0 auto;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.256);
  .modal {
    padding: 40px 36px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 400px;
    min-height: 310px;
    height: fit-content;
    border-radius: 6px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width:600px) {
      width: 335px;
    }
    h1{
      font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 26px;
text-align: center;
padding: 20px 0px;
color: #000000;
    }
  }
  .content {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}

button {
  min-width: 294px;
  color: white;
  height: 30px;
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  border: unset;
  background: #102c3a;
  border-radius: 6px;
}

b{
  font-weight: bold;
  font-size: 17px;
  color: black;
}
</style>
