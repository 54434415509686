<template>
  <div class="column is-4 space_between pr">
    <div class="gift_card">
      <div class="gift_card__strip"><img :src="require(`@/assets/icons/giftCards/${icon}.svg`)" alt=""></div>
      <div class="gift_card__title d-flex"><div class="icon_container"><img src="@/assets/icons/giftCards/gift.svg" alt=""></div> <h2>Bono de Regalo</h2></div>
      <div class="gift_card__profile_info ">
        <div class="columns">
          <div class="column is-12 d-flex info">
            <div class="d-flex column is-6"><label for="">Para:</label><div>{{giftCard.nameTo}}</div></div>
            <div class="d-flex column is-6"><img src="@/assets/icons/giftCards/mail.svg" alt="" srcset=""><div style="display: inline;">{{giftCard.emailTo}}</div></div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12 d-flex info">
            <div class="d-flex column is-6"><label for="">De:</label><div>{{giftCard.nameFrom}}</div></div>
            <div class="d-flex column is-6"><img src="@/assets/icons/giftCards/mail.svg" alt="" srcset=""><div style="display: inline;">{{giftCard.emailFrom}}</div></div>
          </div>
        </div>
      </div>
      <div class="gift_card__price_info" :class="icon === 'strip_new' ? 'd-flex' : ''">
        <div class="code_value" :style="icon !== 'strip_new' ? {'width' : '60%', 'margin': '0 auto'}  : ''">
          <div class="d-flex code"><label for="">Código:</label><div>{{giftCard.code}}</div></div>
          <div class="d-flex" v-if="!giftCard.isPartial"><label for="">Valor:</label><div>{{giftCard.value| currency('$ ', 0, { thousandsSeparator: ',' }) }}</div></div>
          <div class="d-flex" v-if="giftCard.isPartial">
            <label for="" class="info-partial">Valor redimido:</label>
            <div>{{giftCard.redemptionAmount| currency('$ ', 0, { thousandsSeparator: ',' }) }}</div>
          </div>
          <div class="d-flex" v-if="giftCard.isPartial">
            <label for="" class="info-partial">Valor pendiente:</label>
            <div>{{giftCard.pendingAmount| currency('$ ', 0, { thousandsSeparator: ',' }) }}</div>
          </div>
        </div>
        <div class="container_action" v-if="icon === 'strip_new'" :class="{redeem_list: giftCard.isPartial}">
          <button class="action_redeem" @click="redimeShow(giftCard)"><i class="fa fa-check"></i>Redimir</button>
          <button class="action_redeem_list" @click="redemptionList(giftCard)" v-if="giftCard.isPartial">Redenciones</button>
        </div>
      </div>
      <div class="gift_card__dates columns is-mobile">
        <div class="column is-4" v-if="giftCard.created_at">
          <label for="">Fecha de compra</label>
          <div>{{giftCard.created_at | moment('DD[/]MM[/]YYYY[, ]hh:mm A')}}</div>
        </div>
        <div class="column is-4" v-if="giftCard.redemption_date">
          <label for="">Fecha de envío</label>
          <div>{{giftCard.redemption_date | moment('DD[/]MM[/]YYYY[, ]hh:mm A')}}</div>
        </div>
        <div class="column is-4" v-if="giftCard.expiration_date">
          <label for="">Válido hasta</label>
          <div>{{giftCard.expiration_date | moment('DD[/]MM[/]YYYY[, ]hh:mm A')}}</div>
        </div>
      </div>
      <div class="gift_card__redime" v-if="giftCard.redime">
        <span>Redimido por: <b>{{ giftCard.redime }}</b></span>
      </div>
      <div class="floatRedemption" v-show="giftCard.id === redemptionItems.giftCardId">
        <div class="floatRedemption-title">Total redenciones</div>
        <div class="floatRedemption-header mb6">
          <div class="floatRedemption-header-item item0">Valor redimido:</div>
          <div class="floatRedemption-header-item item1">Fecha de redención:</div>
          <div class="floatRedemption-header-item item2">Host:</div>
        </div>
        <div class="floatRedemption-body-list">
          <div class="floatRedemption-body-list-item" v-for="(inf, indexInf) in redemptionItems.info" :key="'redemptionItem#' + indexInf">
            <div class="floatRedemption-body-list-item-info item0">{{ inf.amount | currency('$ ', 0, { thousandsSeparator: ',' }) }}</div>
            <div class="floatRedemption-body-list-item-info item1">{{ inf.dateRedemption | moment("DD/MM/YY, hh:mm a") }}</div>
            <div class="floatRedemption-body-list-item-info item2">{{ inf.userName }}</div>
          </div>
        </div>
        <button class="floatRedemption-button" @click="closeRedemptionList()">Cerrar</button>
      </div>
    </div>
    <b-modal :active="edit" class="pa">
      <div class="card">
        <i class="icon_close" @click="edit = false" style="cursor: pointer;"></i>
        <div class="card-content">
          <div class="title-center">
            <span>
              Persona del restaurante que redime
            </span>
          </div>
          <div class="title-inp">
            <b-input type="text" ref="redimeName" id="redimeName" v-model="redimeName" placeholder="Escriba el nombre" expanded></b-input>
          </div>
          <div class="title-btn">
            <b-button class="title-btn-info" :class="{'title-btn-active': active}" @click="saveRedimePopUp" :disabled="!active">Aceptar</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['giftCard', 'icon', 'giftCardParam'],
  data () {
    return {
      edit: false,
      redimeName: null,
      info: null,
      active: false,
      redemptionItems: {
        giftCardId: null,
        info: []
      }
    };
  },
  methods: {
    closeRedemptionList () {
      this.redemptionItems.giftCardId = null;
      this.redemptionItems.info = [];
    },
    redemptionList (data) {
      this.redemptionItems.giftCardId = data.id;
      this.redemptionItems.info = data.redemptions.sort((a, b) => new Date(b.dateRedemption) - new Date(a.dateRedemption));
    },
    redimeShow (data) {
      let show = false;
      if (this.giftCardParam.usePartial === 0) {
        show = true;
      }
      this.info = data;
      if (show) {
        this.edit = true;
        setTimeout(() => {
          document.getElementById('redimeName').focus();
        }, 300);
      } else {
        this.edit = false;
        this.$emit('openModalGiftCardRedemption', this.info);
      }
    },
    saveRedimePopUp () {
      const regex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+){1,4}$/;
      const nombreApellido = document.getElementById('redimeName').value ? document.getElementById('redimeName').value.trim() : document.getElementById('redimeName').value;
      this.redimeName = null;
      if (regex.test(nombreApellido)) {
        this.info.redime = nombreApellido;
        this.$emit('setGiftCardRedemption', { id: this.info.id, redime: this.info.redime });
        this.edit = false;
      } else {
        this.$buefy.toast.open({
          message: 'Debes escribir nombre y apellido!',
          type: 'is-danger'
        });
        setTimeout(() => {
          document.getElementById('redimeName').focus();
        }, 300);
      }
    }
  },
  watch: {
    redimeName () {
      const regex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+){1,4}$/;
      const nombreApellido = this.redimeName ? this.redimeName.trim() : this.redimeName;
      if (regex.test(nombreApellido)) {
        this.active = true;
      } else {
        this.active = false;
      }
    }
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/giftCards/_gift_card";
</style>
