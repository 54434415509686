<template>
    <div id="modalCombineProcess" :class="['modal-unify-users-process', { 'success': newProgress === 100, 'failed': error}]">
      <section class="modal-unify-users-process__header success" v-if="newProgress == 100">
        <i class="icon_menu_user_done icon_menu_dinamic"></i>
        <p>¡Los contactos se combinaron exitosamente!</p>
        <div>
          <button @click="handleAccept">Aceptar</button>
        </div>
      </section>
      <section class="modal-unify-users-process__header failed" v-else-if="error">
        <i class="icon_menu_user_failed icon_menu_dinamic"></i>
        <p>Durante el proceso de combinar contactos se genero un error, ¡vuelva a intentarlo!</p>
        <div>
          <button @click="handleAccept">Aceptar</button>
        </div>
      </section>
      <section class="modal-unify-users-process__header" v-else>
          <i class="icons icon_close_modal" @click="$parent.close()"></i>
          <div class="modal-unify-users-process__header__image">
              <img src='@/assets/icons/icon_precompro_grey.svg' width="80" height="80" alt='Precompro icon'>
              <!--<i class="icon_precompro_grey"></i>-->
          </div>
          <h2>En este momento se estan combinando <span>{{cant}}</span> contactos</h2>
          <div class="modal-unify-users-process__body">
              <p>Este proceso tardará al rededor de {{ el_timer }}, puede seguir navegando por el sistema (módulo de reservas, métricas, etc.) el módulo "Usuarios" quedará inactivo durante el proceso.</p>
              <div class="modal-unify-users-process__body__action">
                  <slot></slot>
                  <div class="progress-bar">
                  <div class="progress-bar-fill" :style="{ width: newProgress + '%' }"></div>
                  </div>
              </div>
          </div>
      </section>
    </div>
  </template>
<script>
import Axios from 'axios';
import { mapGetters } from 'vuex';
import globalTypes from '@/store/types/global';

export default {
  props: {
    progress: {
      type: Number,
      default: 0
    },
    cant: {
      type: Number,
      default: 0
    },
    time: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.processCheckTime();
    const timePerUser = 0.020;
    this.processFormatime((this.cant * timePerUser));
  },
  created () {
    this.$on('close-modal', this.handleCloseModal);
    this.$on('success', this.handleSuccess);
    this.$on('error', this.handleError);
  },
  computed: {
    ...mapGetters({
      showSide: [globalTypes.getters.showSideBar],
      menuDataCombine: [globalTypes.getters.menuDataCombine]
    }),
    menuDataCombine () {
      return globalTypes.getters.menuDataCombine;
    },
    formattedTime () {
      return this.processFormatime();
    },
    checkTime () {
      return this.processCheckTime();
    }
  },
  data: () => ({
    timeShow: '',
    setMenuDataCombine: globalTypes.mutations.setMenuDataCombine,
    newProgress: 0,
    el_tiempo: 0,
    la_cant: 0,
    error: false,
    el_timer: '00:00:00',
    internalCant: 0,
    internalTime: 0
  }),
  watch: {
  },
  methods: {
    handleCloseModal () {
      console.log('Aquí cerrar event');
      this.$store.commit(globalTypes.mutations.setMenuDataCombine, {});
      this.$parent.close();
    },
    handleSuccess () {
      const valor = { avance: 100, processed: 100, lengthToProcess: 100, tiempo: 0 };
      this.$store.commit(globalTypes.mutations.setMenuDataCombine, valor);
    },
    handleError () {
      this.$store.commit(globalTypes.mutations.setMenuDataCombine, { error: true });
    },
    processFormatime (totalSegundos) {
      const horas = Math.floor(totalSegundos / 3600);
      totalSegundos %= 3600;
      const minutos = Math.floor(totalSegundos / 60);
      const segundos = Math.floor(totalSegundos % 60);
      const timer = horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
      this.el_timer = timer;
      return timer;
    },
    actionDataToMerge () {
      this.timeShow = String(this.time);
    },
    async processCheckTime (p = 0) {
      const params = {
        vendorId: this.vendor.id,
        p: p
      };
      await Axios.post(process.env.VUE_APP_IMPROVE_DATA + '/status_process/', params)
        .then(response => {
          if (this.newProgress < 50) {
            // this.processFormatime(response.data.tiempo);
            if (this.la_cant === 0) {
              this.ejecutarProceso(response.data.tiempo + 1000 || 7000);
            }
          }
          this.el_tiempo = response.data.tiempo;
          this.la_cant = response.data.total;
          if (response.data.error) {
            this.error = true;
            this.newProgress = 0;
            this.$emit('error');
            return { error: true };
          }
          return response.data;
        })
        .catch(err => {
          console.error('Error al verificar el estado del proceso:', err);
          this.newProgress = 0;
          this.$emit('error');
          this.error = true;
        });
    },
    incrementarProgresoSuave (i) {
      let incremento = i;
      const intervaloSuave = setInterval(() => {
        if (incremento < i + 10) {
          incremento++;
          this.newProgress = incremento;
        } else {
          clearInterval(intervaloSuave);
          // this.$emit('success');
        }
      }, 100);
      return incremento;
    },
    async ejecutarProceso (duracion) {
      let porcentaje = 0;
      // Determinar el tiempo de espera adicional según la duración
      let tiempoEsperaAdicional;
      if (duracion < 10000) { // Menos de 10 segundos
        tiempoEsperaAdicional = 1000; // 1 segundo
      } else if (duracion < 60000) { // Entre 10 segundos y 1 minuto
        tiempoEsperaAdicional = 10000; // 10 segundos
      } else { // Más de 1 minuto
        tiempoEsperaAdicional = 50000; // 50 segundos
      }
      const tiempoCheck = (duracion + tiempoEsperaAdicional);
      const intervalo = setInterval(async () => {
        if (porcentaje < 90) {
          porcentaje += 10;
          this.newProgress = this.incrementarProgresoSuave(porcentaje);
        } else {
          try {
            const params = {
              vendorId: this.vendor.id,
              p: 1
            };
            await Axios.post(process.env.VUE_APP_IMPROVE_DATA + '/status_process/', params)
              .then(response => {
                if (response.data?.avance) {
                  this.newProgress = response.data.avance;
                  porcentaje = response.data.avance;
                  if (response.data.avance === 100) {
                    clearInterval(intervalo);
                    this.$emit('success');
                  }
                }
                if (response.data?.error) {
                  this.error = true;
                  this.$emit('error');
                  this.newProgress = 0;
                  clearInterval(intervalo);
                }
              }).catch(err => {
                console.error('Error en la petición:', err);
                this.error = true;
                this.newProgress = 0;
                this.$emit('error');
                clearInterval(intervalo);
              });
          } catch (error) {
            console.error('Error en la petición:', error);
            this.error = true;
            this.newProgress = 0;
            this.$emit('error');
            clearInterval(intervalo);
          }
        }
      }, tiempoCheck);
    },
    handleAccept () {
      this.$parent.close();
      this.$emit('close-modal');
    }
  }
};
</script>

<style lang="scss">
@import '../../../assets/styles/users/modalProgress';
</style>
