var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.toggle || (_vm.toggle && !_vm.map)),expression:"!toggle || (toggle && !map)"}],class:{
    'component-lateral': _vm.toggle,
    islist: !_vm.isMap,
    'is-sidebar-active': _vm.$store.getters['global:sideBarState'],
  },attrs:{"id":"Time"}},[_c('div',{staticClass:"card-header",class:{ active: _vm.mactive },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.activeHours()}}},[(_vm.isMap)?_c('p',{staticClass:"Filters"},[_vm._v("Reservas")]):_c('p',{staticClass:"Filters"},[_vm._v("Filtros")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.dateFilterSelect.length > 0 || _vm.selectedDates.length > 0) && !_vm.mactive),expression:"(dateFilterSelect.length > 0 || selectedDates.length > 0) && !mactive"}],staticClass:"alertContainer"},[_c('i',{staticClass:"icon_alert_danger"})]),_c('a',{staticClass:"icon_left_filter icon-mks white desplegar",staticStyle:{"width":"7px","right":"14px"},on:{"click":function($event){return _vm.contraer()}}})]),(_vm.toggle == true)?_c('a',{staticClass:"card-title",class:{ 'tittle-lateral': _vm.toggle },on:{"click":function($event){return _vm.expand()}}},[_c('i',{staticClass:"icon_dashboard_clock icon-mks gray-blue"}),_vm._v(" Filtros "),_c('i',{staticClass:"icon_left icon-mks white"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.dateFilterSelect.length > 0 || _vm.selectedDates.length > 0)),expression:"(dateFilterSelect.length > 0 || selectedDates.length > 0)"}],staticClass:"alertContainer lateralposition"},[_c('i',{staticClass:"icon_alert_danger"})])]):_vm._e(),_c('div',{staticClass:"hour-content",class:{ hidden: _vm.toggle, active: _vm.mactive }},[_c('Hours',{attrs:{"isMap":_vm.isMap,"theActivate":_vm.filterActivateSelected},on:{"activatefilter":_vm.SetFilter}})],1),(!_vm.isMap && !_vm.validatePermission('ReadOnly') && !_vm.validatePermission('MixSFCN') && !_vm.toggle)?_c('div',{staticClass:"btn-box",class:{ noPrecompra: !_vm.params.prePurchaseDashboard }},[_c('a',{staticClass:"buton-action reservation",class:{
        'btn-reserva-lateral': _vm.toggle,
        'buttons-change-size': _vm.params.prePurchaseDashboard && _vm.selectedDate == _vm.today
      },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.setAddReservation()}}},[_c('div',[_vm._v("Reserva")]),_c('i',{staticClass:"icon_buttonPlus"})]),(_vm.params.prePurchaseDashboard)?_c('a',{staticClass:"buton-action precompra",class:{
        'btn-reserva-lateral': _vm.toggle,
        'buttons-change-size':
          _vm.params.prePurchaseDashboard && _vm.selectedDate == _vm.today,
      },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.setAddPrecompra()}}},[_c('div',[_vm._v("Precompra")]),_c('i',{staticClass:"icon_buttonPlus"})]):_vm._e(),(_vm.selectedDate == _vm.today)?_c('a',{staticClass:"buton-action walking",class:{
        'btn-walkin-lateral': _vm.toggle,
        'buttons-change-size': _vm.params.prePurchaseDashboard,
      },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.setAddWalkin()}}},[_c('div',[_vm._v("Walk-In")]),_c('i',{staticClass:"icon_buttonPlus"})]):_vm._e(),_c('button',{staticClass:"buttonList",class:{ peopleWaiting: _vm.listLength > 0 },attrs:{"peopleAwaiting":_vm.listLength},on:{"click":_vm.toggleMenuL}},[_c('img',{attrs:{"src":require("@/assets/icons/addReservation/clock.svg"),"alt":"icon"}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }