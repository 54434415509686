<template>
  <div class="card" id="newInformationModal" >
    <div class="card-content">
      <i v-if="!alerts" class="icon-mks icon_close" @click="setClose()"></i>
      <div class="content">
        <div class="newInformationModalImagen" :style="backgroundImageStyle"></div>
        <div class="newInformationModalTitle">{{ titleDefault }}</div>
        <div class="newInformationModalDescription">{{ descriptionDefault }}</div>
        <div class="newInformationModalClose">
          <button v-if="!alerts" class="newInformationModalClose__redirect" @click="redirectOnboardingModule">
            <span>¡Conoce más!</span>
          </button>
          <button v-else class="newInformationModalClose__close" @click="setClose()">
            <span>Cerrar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['info', 'alerts'],
  data () {
    return {
      titleDefault: '¡Conoce el nuevo modulo de marketing!',
      descriptionDefault: 'Podrás crear tus mensajes de texto y correos electrónicos con toda la información para tus clientes. Este nuevo modulo lo tendrás disponible en la barra de herramientas costado izquierdo de la pantalla principal del dashboard.',
      imageDefault: null,
      idDefault: null,
      fallbackImageUrl: 'https://images.homify.com/c_fill,f_auto,q_0,w_740/v1526483607/p/photo/image/2561426/3.jpg',
      backgroundImageStyle: {},
      typeOnboarding: {
        type: null,
        id: null
      }
    };
  },
  mounted () {
    const data = this.info;
    if (data) {
      if (data.title) {
        this.titleDefault = data.title;
      }
      if (data.description) {
        this.descriptionDefault = data.description;
      }
      if (data.image && this.alerts) {
        this.imageDefault = data.image;
      }
      if (!this.alerts) {
        if (data.urlImage) {
          this.imageDefault = data.urlImage;
        }
        this.typeOnboarding.type = data.type;
        this.typeOnboarding.id = data.id;
      }
      if (data._id) {
        this.idDefault = data._id;
      }
      this.checkImage(this.imageDefault, this.fallbackImageUrl);
    }
  },
  methods: {
    setClose () {
      this.$emit('close', true);
    },
    checkImage (url, fallbackUrl) {
      if (url) {
        const img = new Image();
        img.src = url;

        img.onload = () => {
          this.backgroundImageStyle = {
            backgroundImage: `url(${url})`
          };
        };

        img.onerror = () => {
          this.backgroundImageStyle = {
            backgroundImage: `url(${fallbackUrl})`
          };
        };
      } else {
        this.backgroundImageStyle = {
          backgroundImage: `url(${fallbackUrl})`
        };
      }
    },
    redirectOnboardingModule () {
      window.location.href = `/dashboard/onboarding?typeOnboarding=${this.typeOnboarding.type}&onboarding=${this.typeOnboarding.id}`;
    }
  }
};
</script>
<style lang="scss">
#newInformationModal {
  position: relative;
  .icon_close{
    right: 20px;
    top: 20px;
    position: absolute;
    background: #444B57;
    width: 20px;
    height: 20px;
    margin: 0;
    cursor: pointer;
  }
  .newInformationModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    @media screen and (max-width: 500px) {
      font-size: 25px;
    }
  }
  .newInformationModalDescription {
    /* width: 580px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }
  .newInformationModalImagen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 344px;
    height: 162px;
    flex-shrink: 0;
    margin-bottom: 20px;
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
    border-radius: 10px;
  }
  .newInformationModalClose {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 150px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 8px;
      cursor: pointer;
      span {
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    &__redirect{
      border: 1px solid #444B57;
      background: #444B57;
      span{
        color: white;
      }
    }
    &__close{
      border: 1px solid #444B57;
      background: white;
      span{
        color: #444B57;
      }
    }
  }
}
</style>
