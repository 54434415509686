<template>
  <form id="sesion" class="modal-endSesion">
    <div class="modal-card" style="width: 500px">
      <header class="modal-card-head">
        <p class="modal-card-title"><i class="fa fa-warning"></i>Confirmar</p>
      </header>
      <section class="modal-card-body">
        <div class="container-columns">
          <p class="text-center">
            ¿Estás seguro(a) de que quieres
            <b>cerrar sesión</b> ?
          </p>
        </div>
        <div class="columns">
          <div class="column center">
            <a
              class="btn width-porcentaje"
              @click="$parent.close()"
              >No</a
            >
          </div>
          <div class="column center">
            <a class="btn width-porcentaje" @click="userLogout">Si</a>
          </div>
        </div>
      </section>
    </div>
  </form>
</template>

<script>
export default {
  name: 'SesionVue',
  data () {
    return {
      activo: true
    };
  },
  methods: {
    userLogout () {
      this.$parent.close();
      this.$socket.disconnect();
      this.logout({ id: this.user.id });
    }
  }
};
</script>
<style lang="scss">
#sesion {
  .modal {
    z-index: 999999 !important;
  }
  .modal-endSesion {
    display: none;
    justify-content: center;
    .modal-content {
      max-width: 500px;
    }
    .modal-card-head {
      border-radius: 0px !important;
      background: #444b57 !important;
      .modal-card-title {
        color: white !important;
        text-align: center !important;
      }
    }
    .column {
      &.center {
        text-align: -webkit-center;
      }
    }
    .text-center {
      text-align: center;
    }
    .btn {
      display: flex;
      justify-content: center;
      border: 1px solid #707070;
      color: white;
      background-color: #444b57;
      border-radius: 20px;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 30px 8px 30px;
      width: max-content;
      margin: 10px;
      align-items: center;
      &.width-porcentaje {
        width: 70%;
      }
      &:hover {
        background: rgba(112, 112, 112, 1);
        color: white;
        transition: all 0.2s ease-in;
        i {
          background: white;
          transition: all 0.3s ease-in;
        }
      }
    }
  }
}
</style>
