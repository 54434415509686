<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Asignar Hosts a
        <b>{{ userAdd.name }}</b>
      </p>
    </header>
    <section class="modal-card-body">
      <b-table
        :checkbox-position="checkboxPosition"
        :checked-rows.sync="checkedRows"
        :data="data"
        checkable
        ref="hosts"
      >
        <template slot-scope="props">
          <b-table-column centered field="name" label="Nombres" sortable>{{ props.row.name }}</b-table-column>
          <b-table-column centered field="pin" label="Pin" sortable width="100">{{ props.row.pin }}</b-table-column>
          <b-table-column centered label="Estado">
            <span class="tag is-success" style="font-weight: bold;" v-if="props.row.isActive">Activo</span>
            <span
              class="tag is-danger"
              style="font-weight: bold;"
              v-if="!props.row.isActive"
            >Inactivo</span>
          </b-table-column>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <b-button
        :disabled="isLoading"
        @click="$parent.close()"
        class="button"
        type="button"
      >{{ 'Cerrar' }}</b-button>
      <b-button
        :loading="isLoading"
        @click="assignedHost()"
        class="button is-primary"
      >{{ 'Guardar' }}</b-button>
    </footer>
  </div>
</template>

<script>
import _ from 'lodash';
import serviceUser from '@/helpers/apis/serviceUser';
export default {
  props: ['id', 'userAdd'],
  mounted () {
    this.listHost(this.id);
  },
  data () {
    return {
      isLoading: false,
      data: [],
      checkboxPosition: 'right',
      checkedRows: [],
      columns: [
        {
          field: 'name',
          label: 'Nombres'
        },
        {
          field: 'pin',
          label: 'Pin'
        },
        {
          field: 'isActive',
          label: 'Activo'
        }
      ]
    };
  },
  methods: {
    listHost (id) {
      this.$nextTick().then(() => {
        serviceUser.get('/restaurants/' + id + '/hosts/').then(response => {
          const hosts = this.userAdd.items;
          const info = response.data.filter(host => {
            if (!_.find(hosts, { name: host.name })) {
              return host;
            }
          });
          this.data = info;
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
            type: 'is-danger'
          });
        });
      });
    },
    assignedHost () {
      this.isLoading = true;
      const instance = this;
      serviceUser.post('/restaurants/members/assign', {
        userId: this.userAdd.id,
        vendorId: this.id,
        hosts: this.checkedRows
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Se han asignado los host',
          type: 'is-success'
        });
        this.isLoading = false;
        instance.$emit('update');
        instance.$parent.close();
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    }
  }
};
</script>
