<template>
  <div>
    <LoaderPrecompro />
    <ContaclessPayment v-if="vendor && vendorParams"></ContaclessPayment>
  </div>
</template>
<script>
import globalTypes from '@/store/types/global';
import ContaclessPayment from '@/components/contactlessPayment';
import authTypes from '@/store/types/auth';
import LoaderPrecompro from '@/components/_shared/LoaderPrecompro';
import moment from 'moment-timezone';
import verifyUser from '@/helpers/verifyUser';

export default {
  name: 'ContactlessPaymentsPage',
  metaInfo: {
    title: 'Pago Sin Contacto'
  },
  components: {
    ContaclessPayment,
    LoaderPrecompro
  },
  mounted () {
    const vendorUrl = this.subDomain();
    if (vendorUrl !== undefined && vendorUrl != null) {
      this.$store.commit(authTypes.mutations.setUser);
      this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, {
        status: true,
        typeMessage: 1
      });
      this.$store
        .dispatch({
          type: 'global:vendorId',
          data: {
            url: vendorUrl
          }
        })
        .then(({ data }) => {
          if (data.vendorId) {
            localStorage.setItem('vendorUrl', vendorUrl);
            localStorage.setItem('_id', data.Id);
            localStorage.setItem('keyWs', data.Id);
            this.$store.dispatch({
              type: 'global:getPermissions',
              data: { userId: this.user.id }
            });
            this.$store.dispatch({
              type: 'reservation:getModules',
              data: {
                vendorId: this.idVendor,
                userId: this.user.id
              }
            });
            this.$store
              .dispatch({
                type: 'global:vendor',
                data: {
                  vendorId: this.idVendor
                }
              })
              .then((resp) => {
                if (resp.data.vendor.timezone != null) {
                  moment.tz.setDefault(resp.data.vendor.timezone);
                } else {
                  moment.tz.setDefault('America/Bogota');
                }
                this.load = false;
                this.$store.commit(globalTypes.mutations.setLoaderPrecompro, {
                  status: false
                });
              }).finally(() => {
                document.querySelector('title').text = this.vendor.displayName + ' - Pago sin contacto';
                document.querySelector("link[rel*='icon']").href = this.vendor.thumbnailURL;
              });
          }
        });
    }
  },
  watch: {
    user () {
      verifyUser(this.user);
    }
  }
};
</script>
<style></style>
