<template>
  <div id="marketing-items">
    <div>
      <div class="module-sms" v-if="params.activateMarketingSms">
        <div class="title">Envío de mensajes de textos</div>
        <div class="image">
            <img src="@/assets/icons/marketing/module-sms.svg" alt="precompro.com">
        </div>
        <div class="description">
            Enviando mensajes de texto a tus clientes
            podrán estar informados de todos tus
            eventos, promociones y mucho más.
        </div>
        <button class="action" @click="selectedModule('sms')">
            <span class="action-text">Seleccionar</span>
        </button>
      </div>
      <div class="module-email" v-if="params.activateMarketingEmail">
          <div class="title">Envío de correos electrónicos</div>
          <div class="image">
              <img src="@/assets/icons/marketing/module-email.svg" alt="precompro.com">
          </div>
          <div class="description">
              Con los correo electrónicos envías a tus
              clientes las últimas novedades de tu
              restaurante.
          </div>
          <button class="action" @click="selectedModule('email')">
              <span class="action-text">Seleccionar</span>
          </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      htmlForEditor: '',
      editor: null,
      editorConfig: {
        language: 'es',
        toolbar: {
          items: [
            'undo', 'redo',
            '|', 'heading',
            '|', 'bold', 'italic',
            '|', 'link', 'insertImage', 'mediaEmbed',
            '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
          ]
        }
      }
    };
  },
  methods: {
    selectedModule (option) {
      this.$emit('showModule', {
        module: option
      });
    }
  }
};
</script>
<style lang="scss">
#marketing-items {
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  &::-webkit-scrollbar {
    width: 3px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #444B57 !important;
    border-color: #444B57 !important;
    box-shadow: unset !important;
    border-radius: 6px !important;
  }
  > div:first-child{
    display: flex;
    align-self: self-start;
    @media screen and (min-width: 1280px) {
      gap: 100px;
      margin-top: 105px;
      margin-bottom: 105px;
    }
    @media screen and (min-width: 769px) and (max-width: 1279px) {
      gap: 60px;
      margin-top: 107px;
      margin-bottom: 107px;
    }
    @media screen and (max-width: 768px) {
      display: grid;
      gap: 105px;
      margin-top: 69px;
      margin-bottom: 69px;
    }
    @media screen and (max-width: 500px) {
      display: grid;
      gap: 75px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  .module-sms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
    width: 400px;
    height: 387px;
    flex-shrink: 0;
    @media screen and (max-width: 768px) {
      width: 360px;
      height: 323px;
    }
    .title {
      color: #444B57;
      text-align: center;
      font-size: 18px;
      font-family: 'Source Sans Pro';
      font-weight: 600;
      margin-bottom: unset;
    }
    .image {
      width: 118px;
      height: 118px;
      flex-shrink: 0;
      margin: 30px 0px 30px 0px;
    }
    .description {
      display: flex;
      width: 265px;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      color: #444B57;
      text-align: center;
      font-size: 14px;
      font-family: 'Source Sans Pro';
    }
    .action {
      width: 200px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid #444B57;
      background: #444B57;
      margin: 30px 0px 0px 0px;
      cursor: pointer;
      &-text {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-family: 'Source Sans Pro';
        font-weight: 600;
        user-select: none;
      }
    }
  }
  .module-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
    width: 400px;
    height: 387px;
    flex-shrink: 0;
    .title {
      color: #444B57;
      text-align: center;
      font-size: 18px;
      font-family: 'Source Sans Pro';
      font-weight: 600;
      margin-bottom: unset;
    }
    .image {
      width: 118px;
      height: 118px;
      flex-shrink: 0;
      margin: 30px 0px 30px 0px;
    }
    .description {
        display: flex;
        width: 265px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: #444B57;
        text-align: center;
        font-size: 14px;
        font-family: 'Source Sans Pro';
    }
    .action {
      width: 200px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid #444B57;
      background: #444B57;
      margin: 30px 0px 0px 0px;
      cursor: pointer;
      &-text {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-family: 'Source Sans Pro';
        font-weight: 600;
        user-select: none;
      }
    }
  }
  @media screen and (min-width: 1280px) {
    justify-content: center;
    .module-sms {
      margin-top: unset;
    }
    .module-email {
      margin-top: unset;
      margin-bottom: unset;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    flex-wrap: wrap;
    overflow-y: auto;
    .module-sms {
      margin-top: unset;
      margin-bottom: unset;
      width: 400px;
      height: 387px;
      .description {
        font-size: 14px;
        width: 100%;
        max-width: 259px;
      }
      .image {
        width: 118px;
        height: 118px;
        margin: 30.43px 0px 32.35px 0px;
      }
    }
    .module-email {
      margin-bottom: unset;
      width: 400px;
      height: 387px;
      .description {
        font-size: 14px;
        width: 100%;
        max-width: 265px;
      }
      .image {
        width: 116.143px;
        height: 116.22px;
        margin: 30px 0px 20px 0px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    overflow-y: auto;
    .module-sms {
      width: 400px;
      height: 387px;
      padding: 0 30px;
      margin-top: unset;
      margin-bottom: unset;
      .description {
        font-size: 14px;
      }
      .image {
        width: 118px;
        height: 118px;
        margin: 30px 0px 31px 0px;
      }
    }
    .module-email {
      width: 400px;
      height: 387px;
      margin-bottom: unset;
      margin-top: -50px;
      .description {
        font-size: 14px;
      }
      .image {
        width: 118px;
        height: 118px;
        margin: 30.43px 0px 32.35px 0px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .module-sms {
      width: 360px;
      height: 323px;
      padding: 0 30px;
      margin-top: unset;
      margin-bottom: unset;
      .description {
        font-size: 12px;
        width: 100%;
        max-width: 307px;
      }
      .image {
        width: 90px;
        height: 90px;
        margin: 30px 0px 20px 0px;
      }
    }
    .module-email {
      width: 360px;
      height: 323px;
      margin-bottom: unset;
      margin-top: -50px;
      .description {
        font-size: 12px;
        width: 100%;
        max-width: 307px;
      }
      .image {
        width: 90px;
        height: 90px;
        margin: 30.43px 0px 19.57px 0px;
      }
    }
  }
}
</style>
