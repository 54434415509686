import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { VueHammer } from 'vue2-hammer';
import LocalStorage from 'store';
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
import VueMeta from 'vue-meta';
import VeeValidate, { Validator } from 'vee-validate';
import validatorEs from '@/lang/validations/es';
import Multiselect from 'vue-multiselect';
import Vue2Filters from 'vue2-filters';
import VTooltip from 'v-tooltip';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import VueTheMask from 'vue-the-mask';
import VCalendar from 'v-calendar';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueI18n from 'vue-i18n';
import VueDragDrop from 'vue-drag-drop';
import Chartkick from 'vue-chartkick';
import Buefy from 'buefy';
import fullscreen from 'vue-fullscreen';
import api from '@/helpers/api';
import VueDragscroll from 'vue-dragscroll';
import VueSocketIO from 'vue-socket.io-extended';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { Datetime } from 'vue-datetime';
import Treeselect from '@riophae/vue-treeselect';
import { uploadImagePlugin, uploadImageMultiplePlugin } from '@/helpers/Plugins/digitalOceanPlugin';
import { uploadImagePluginCloud, uploadImageMultiplePluginCloud } from '@/helpers/Plugins/googleCloudPlugin';
import Vue2Editor from 'vue2-editor';
import mixinDashboard from '@/mixins';

// charts
import VueECharts from 'vue-echarts';
import * as echarts from 'echarts';
import Highcharts from 'highcharts';

// You need a specific loader for CSS files
import VueTippy, { TippyComponent } from 'vue-tippy';

// estilos
import 'moment/locale/es';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/google.css';
import 'tippy.js/themes/translucent.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'buefy/dist/buefy.css';
// fin estilos

import vClickOutside from 'v-click-outside';
import { report as discordNotify } from './helpers/apis/discord';
import VuePortal from '@linusborg/vue-simple-portal';
import VueBreakpointMixin from 'vue-breakpoint-mixin';
import CKEditor from '@ckeditor/ckeditor5-vue2';

// prototypes
Vue.prototype.$http = api;
Vue.prototype.$echarts = echarts;

api.interceptors.request.use(
  config => {
    config.headers.common.Authorization = 'Bearer ' + window.localStorage.getItem('_token');
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response.status !== undefined) {
      switch (error.response.status) {
        case 401:
          LocalStorage.clearAll();
          window.localStorage.clear();
          break;
        default:
          break;
      }
      return Promise.reject(error.response);
    } else {
      console.error(error.response);
      return Promise.reject(error.response);
    }
  });

moment.tz.setDefault('America/Bogota');
moment.locale('es');
Vue.use(Buefy);
Vue.use(VueMoment, {
  moment
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(VeeValidate);
Vue.use(VuePortal, {
  name: 'portal' // optional, use to rename component
});
Vue.use(Multiselect);
Vue.use(Vue2Filters);
Vue.use(VTooltip);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(Notifications);
Vue.use(VueI18n);
Vue.use(fullscreen);
Vue.use(VueDragscroll);
Vue.use(VueDragDrop);
Vue.use(uploadImagePlugin);
Vue.use(uploadImageMultiplePlugin);
Vue.use(uploadImagePluginCloud);
Vue.use(uploadImageMultiplePluginCloud);
Vue.use(VCalendar);
Vue.use(Datetime);
Vue.use(vClickOutside);
Vue.use(CKEditor);
Vue.use(Chartkick.use(Highcharts));
Vue.use(VueTippy);

VueHammer.config.press = {
  threshold: 1000,
  time: 1000
};
Vue.use(VueHammer);
Vue.use(Vue2Editor);

Vue.component('datetime-vue', Datetime);
Vue.component('treeselect-vue', Treeselect);
Vue.component('v-chart', VueECharts);
Vue.component('tippy-tooltip', TippyComponent);
// change the threshold for all swipe recognizers

Validator.localize('es', validatorEs);
Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  console.error(err.message);
  console.error(err.stack);
  console.error(info);
  discordNotify({ title: `  💥\n\nAmbiente: ${process.env.NODE_ENV} \n\n Dashboard`, description: `${info}\n\n${err.stack}`, color: '16723509' });
};
Vue.config.warnHandler = (err, vm, info) => {
  console.warn(err);
  discordNotify({ title: `  ⚠️\n\nAmbiente: ${process.env.NODE_ENV} \n\n Dashboard`, description: err, color: '16776960' });
};
Vue.mixin(Vue2Filters.mixin);
Vue.mixin(VueBreakpointMixin);
Vue.mixin(mixinDashboard);

// WEBSOCKET
if (LocalStorage.get('keyWs') === undefined || LocalStorage.get('keyWs') === null) {
  LocalStorage.set('keyWs', uuidv4());
}
Vue.use(VueSocketIO, io(process.env.VUE_APP_WS, {
  autoConnect: true,
  reconnection: true,
  forceNew: false,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  transports: ['websocket'],
  upgrade: false,
  query: {
    vendorId: LocalStorage.get('_id'),
    id: LocalStorage.get('keyWs')
  }
}));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://04baae2f7a16422a839a06e5eaf651d1@sentry.io/1528413',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  });
}
