<template>
  <div id="Settings-Table">
       <div class="big-tt">
          {{section.displayName}} - ID: <b>{{section.id}}</b>
          <button v-if="section.isPrivate == 0 && loading == false" @click.prevent.stop="blockedSection(section.id)" class="status-button"><i class="fa fa-eye-slash"></i>&nbsp;Volver Privada</button>
          <span v-if="loading && isBloked"  class="status-button" ><i class="fa fa-spinner fa-spin"></i>&nbsp;</span>
          <button v-if="section.isPrivate == 1 && loading == false" @click.prevent.stop="unblockedSection(section.id)"  class="status-button"><i class="fa fa-globe"></i>&nbsp;Volver Pública</button>
          <span v-if="loading && !isBloked"  class="status-button"><i class="fa fa-spinner fa-spin"></i>&nbsp;</span>
       </div>
       <div v-show="section.minSize">Min: {{section.minSize}}</div>

      <div class="setContainer">
        <div class="title-section">MESAS</div>
        <b-collapse
              class="card"
              v-for="(table, index) in seatingTable[section.displayName]"
              :key="index"
              :open="true"
              @open="isOpen != index">
              <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button">
                  <p class="card-header-title">
                    {{table.displayName}} - ID: {{table.id}}
                  </p>
                  <a class="card-header-icon">
                    <i :class="!props.open ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"></i>
                  </a>
              </div>
              <div class="card-content">
                  <div class="content">
                    <div v-show="table.canBeMerged">Auto-unir</div>
                    <div v-show="table.isShared">Compartida</div>
                    <div v-show="table.preventReservations"><b>Privada</b></div>
                    <!-- <div v-show="table.size">Tamaño:&nbsp;<b>{{table.size}} (Ya no se usa)</b></div> -->
                    <div v-show="table.maxSize">Max:&nbsp;<b>{{table.maxSize}}</b></div>
                    <div v-show="table.minSize">Min:&nbsp;<b>{{table.minSize}}</b></div>
                  </div>
              </div>
          </b-collapse>
          <div v-show="mergings.length" class="title-section">UNIONES</div>
          <b-collapse
                class="card"
                v-for="(combination, con) in mergings" :key="'com' + con"
                :open="true"
                @open="isOpen != 'com' + con">
                <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                      {{con}}. {{combination.tables}}
                    </p>
                    <a class="card-header-icon">
                      <i :class="!props.open ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"></i>
                    </a>
                </div>
                <div class="card-content">
                    <div class="content">
                      <div v-for="(tab, tK) in combination.data" :key="'tab' + tK">
                        <div v-for="(conbina, cK) in combinationMerged(tab)" :key="'cok' + cK">
                          <div v-show="conbina.displayName">Mesas:&nbsp;<b>{{conbina.displayName}}</b></div>
                          <div v-show="conbina.size">Tamaño:&nbsp;<b>{{conbina.size}}</b></div>
                          <div v-show="conbina.maxSize">Max:&nbsp;<b>{{conbina.maxSize}}</b></div>
                          <div v-show="conbina.minSize">Min:&nbsp;<b>{{conbina.minSize}}</b></div>
                          <div v-show="conbina.isPrioritary">Unión prioritaria</div>
                        </div>
                      </div>
                    </div>
                </div>
            </b-collapse>
      </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  props: ['section', 'seatingTable', 'mergings'],
  data () {
    return {
      loading: false,
      isBloked: false,
      isOpen: 0
    };
  },
  methods: {
    total (data) {
      return _.size(data);
    },
    combinationMerged (data) {
      let nombres = '';
      const total = this.total(data);
      let size = 0;
      let maxSize = 0;
      let minSize = 0;
      let isPrioritary = 0;
      let van = 0;
      const datos = [];
      _.forEach(data, function (item) {
        van++;
        if (nombres === '') {
          nombres = item?.displayName + ', ';
        } else {
          if (van === total) {
            nombres += item?.displayName;
          } else {
            nombres += item?.displayName + ', ';
          }
        }
        size = item?.size;
        maxSize = item?.maxSize;
        minSize = item?.minSize;
        isPrioritary = item?.isPrioritary;
      });
      datos.push({ displayName: nombres, size, maxSize, minSize, isPrioritary });
      return datos;
    },
    blockedSection (sectionId) {
      this.loading = true;
      this.isBloked = true;
      this.$store.dispatch({
        type: 'reservation:setBlockedSection',
        data: {
          vendorId: this.idVendor,
          seatingSectionId: sectionId
        }
      }).then(() => {
        this.loading = false;
      });
    },
    unblockedSection (sectionId) {
      this.loading = true;
      this.$store.dispatch({
        type: 'reservation:setUnBlockedSection',
        data: {
          vendorId: this.idVendor,
          seatingSectionId: sectionId
        }
      }).then(() => {
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss" >
#Settings-Table{
  max-height: 100%;
  display: block;
  height: 99%;
  overflow: visible;
  padding: 40px 10px 10px 10px;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;

  .big-tt{
    font-size: 15px;
    position: absolute;
    width: 99%;
    top: -3px;
    padding-top: 10px;
    padding-left: 22px;
    text-transform: capitalize;
    font-weight: 900;
  }

  .setContainer{
    overflow: auto;
    height: 100%;
    padding-left: 1px;
    padding-right: 5px;
  }
  .status-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    position: absolute;
    color: #fff;
    border-radius: 100px;
    right: 10px;
    top: 10px;
    background: #444b57;
    cursor:pointer;
    i{
      margin-right: 5px;
    }
  }

  .card-header-title {
    align-items: center;
    color: #363636;
    display: flex;
    flex-grow: 1;
    font-weight: 700;
    padding: 0.1rem 0.4rem !important;
    font-size: 12px;
  }

  .card-header-icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.7rem;
    font-size: 11px;
    i{
      color:#000 !important;
    }
  }

  .card-content {
    background-color: transparent;
    padding: .5rem;
    font-size: 14px;
  }

  .title-section{
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
  }
}
</style>
