<template>
  <div style="height: 100%;">
    <LoaderPrecompro />
    <Marketing v-if="vendor && vendorParams"></Marketing>
  </div>
</template>
<script>
import LoaderPrecompro from '@/components/_shared/LoaderPrecompro';
import Marketing from '@/components/Marketing';
import _ from 'lodash';
import globalTypes from '@/store/types/global';
import moment from 'moment-timezone';
import authTypes from '@/store/types/auth';

export default {
  name: 'MarketingPage',
  metaInfo: {
    title: 'Marketing'
  },
  components: {
    Marketing,
    LoaderPrecompro
  },
  mounted () {
    const vendorUrl = this.subDomain();
    this.$store.commit(authTypes.mutations.setUser);
    this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
    this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 1 });
    this.$store.dispatch({
      type: 'global:vendorId',
      data: {
        url: vendorUrl
      }
    }).then((response) => {
      if (response.data.vendorId) {
        this.$store.dispatch({
          type: 'global:getPermissions',
          data: { userId: this.user.id }
        });
        this.$store.dispatch({
          type: 'reservation:getModules',
          data: {
            vendorId: this.idVendor,
            userId: this.user.id
          }
        });
        this.$store.dispatch({
          type: 'global:vendor',
          data: {
            vendorId: this.idVendor
          }
        }).then((resp) => {
          if (resp.data.vendor.timezone != null) {
            moment.tz.setDefault(resp.data.vendor.timezone);
          } else {
            moment.tz.setDefault('America/Bogota');
          }
          this.load = false;
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        }).finally(() => {
          document.querySelector('title').text = this.vendor.displayName + ' - Mercadeo';
          document.querySelector("link[rel*='icon']").href = this.vendor.thumbnailURL;
        });
      }
    });
  },
  watch: {
    user () {
      const isArray = Array.isArray(this.user);
      let isAdminVendor = false;
      if (!isArray) {
        _.forEach(this.user.vendors, function (value) {
          if (parseInt(value.vendorId) === parseInt(window.localStorage.getItem('_id'))) {
            isAdminVendor = true;
          }
        });
        if (!this.user.isAdmin && !isAdminVendor) {
          this.logout({
            id: this.user.id
          });
        }
      } else {
        window.location.href = '/dashboard/login';
      }
    }
  }
};
</script>
