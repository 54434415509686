<template>
  <form @submit.prevent="submit" class="RestaurantTableForm">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <b-field
            class="column"
            label="Nombre"
            :message="errors.first('name')"
            :type="{'is-danger': errors.has('name')}"
          >
            <b-input
              placeholder="Ej: C3"
              icon="table-edit"
              name="name"
              expanded
              type="text"
              v-model="theTable.displayName"
              v-validate="'required'"
            ></b-input>
          </b-field>
          <b-field
            class="column ml-5"
            label="Prioridad"
            :message="errors.first('prioritary')"
            :type="{'is-danger': errors.has('prioritary')}"
          >
            <b-input
              placeholder="Ej: 0"
              icon="priority-high"
              name="prioritary"
              type="number"
              expanded
              v-model.number="theTable.isPrioritary"
              v-validate="'required'"
            ></b-input>
          </b-field>
        </div>
        <div class="columns">
          <b-field
            class="column"
            label="Minímo"
            :message="errors.first('minimum')"
            :type="{'is-danger': errors.has('minimum')}"
          >
            <b-input
              placeholder="Ej: 2"
              icon="chevron-down"
              name="minimum"
              type="number"
              v-model.number="theTable.minSize"
              v-validate="'required'"
            ></b-input>
          </b-field>
          <b-field
            class="column ml-5"
            label="Máximo"
            :message="errors.first('maximum')"
            :type="{'is-danger': errors.has('maximum')}"
          >
            <b-input
              placeholder="Ej: 4"
              icon="chevron-triple-up"
              name="maximum"
              type="number"
              v-model.number="theTable.maxSize"
              v-validate="'required'"
            ></b-input>
          </b-field>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Id Evento">
              <b-input
                icon="table-edit"
                name="minimum"
                placeholder="Ej: 1"
                type="number"
                v-model.number="theTable.eventId"
              ></b-input>
            </b-field>
          </div>
          <div class="column ml-5">
            <b-field label="Sub Zona">
              <b-input
                icon="table-edit"
                name="subzone"
                placeholder="Ej: 2"
                type="number"
                v-model.number="theTable.seatingSubSectionId"
              ></b-input>
            </b-field>
          </div>
        </div>
        <br>
        <div class="columns">
          <div class="column">
            <b-field label="Id Zona">
              <b-input
                :disabled="theTable.id != undefined"
                icon="table-edit"
                name="section"
                placeholder="Ej: 2"
                type="number"
                v-model.number="theTable.seatingSectionId"
              ></b-input>
            </b-field>
          </div>
        </div>
        <br>
        <div class="columns">
          <div class="column">
            <b-field>
              <b-checkbox
                :false-value="0"
                name="private"
                :true-value="1"
                v-model="theTable.preventReservations"
              >Privada</b-checkbox>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-checkbox
                :false-value="0"
                name="shared"
                :true-value="1"
                v-model="theTable.isShared"
              >Compartida</b-checkbox>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-checkbox
                :false-value="0"
                name="shared"
                :true-value="1"
                v-model="theTable.isActive"
              >Activa</b-checkbox>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field>
              <b-checkbox name="rounded" v-model="theTable.isRounded">Redonda</b-checkbox>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-checkbox
                :false-value="0"
                name="isEvent"
                :true-value="1"
                v-model="theTable.isEvent"
              >Evento</b-checkbox>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="$parent.close()" class="button" type="button">Cerrar</button>
        <button class="button is-primary" v-if="theTable">Guardar</button>
        <button class="button is-primary" v-else>Actualizar</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: 'RestaurantTableForm',
  props: ['title', 'table', 'seatingSectionId'],
  data () {
    return {
      theTable: JSON.parse(JSON.stringify(this.table))
    };
  },
  methods: {
    submit () {
      var instance = this;
      var data = {
        sectionId: instance.seatingSectionId,
        table: instance.theTable,
        vendorId: instance.vendor.id
      };
      instance.$validator.validateAll().then((result) => {
        if (result) {
          instance.$emit('update', data);
          instance.$parent.close();
        } else {
          instance.$buefy.toast.open({
            message: 'El formulario no es válido! Por favor verifique los campos.',
            type: 'is-danger'
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.RestaurantTableForm {
  .ml-5 {
    margin-left: 5px;
  }
  .columns {
    margin: 0px!important;
    padding: 0px!important;
  }
}
</style>
