<template>
  <div id="filters" :class="{'display': value == true }">
    <div class="header principal">
      <i class="lup2 icon_search icon-mks icon-mks-size-fix white"></i>
      <p class="text">Realizar Consultas</p>
      <a @click="close()" class="lup2 icon_close icon-mks white"></a>
    </div>

    <div class="filters-body">
      <section class="margin-botton">
        <b-field label="Nombre:">
          <b-input v-model="name" placeholder="Escribir Nombre..."></b-input>
        </b-field>
        <b-field label="Email:">
          <b-input v-model="email" placeholder="Escribir Email..."></b-input>
        </b-field>
        <b-field label="Teléfono:">
          <b-input v-model="phone" placeholder="Escribir Teléfono..."></b-input>
        </b-field>
      </section>
      <section class="margin-botton">
        <label class="label label-margin">Mostrar reservas</label>
        <b-field>
          <div class="columns columns-wrap columns-space is-mobile">
            <div class="column column-center">
              <b-radio
                v-model="typeProcess"
                type="is-dark"
                name="process"
                :native-value="false"
              >RECIBIDAS</b-radio>
            </div>
            <div class="column column-center">
              <b-radio
                v-model="typeProcess"
                type="is-dark"
                name="process"
                :native-value="true"
              >EN PROCESO</b-radio>
            </div>
          </div>
        </b-field>
      </section>
      <section class="margin-botton">
        <label class="label label-margin">Rangos de fechas</label>

        <b-field>
          <div id="datePicker">
            <div class="header"></div>
            <div class="columns columns-wrap is-mobile">
              <div class="column column-border-rigth">
                <i class="icon_calendar icon-mks black"></i>
                <b-field label="Fecha inicio">
                  <date-pick
                    v-model="dateIn"
                    :format="'YYYY-MM-DD'"
                    :inputAttributes="{placeholder: 'Seleccione Fecha'}"
                  ></date-pick>
                </b-field>
              </div>
              <div id="datePickRigth" class="column column-display-flex">
                <i class="icon_calendar icon-mks black"></i>
                <b-field label="Fecha fin">
                  <date-pick
                    v-model="dateOut"
                    :format="'YYYY-MM-DD'"
                    :inputAttributes="{placeholder: 'Seleccione Fecha'}"
                  ></date-pick>
                </b-field>
              </div>
            </div>
          </div>
        </b-field>
      </section>
      <section class="margin-botton" v-if="!typeProcess">
        <b-field label="Id Reserva">
          <b-input v-model="reservationId"></b-input>
        </b-field>
      </section>
      <section class="margin-botton">
        <b-field>
          <div class="columns columns-wrap columns-space is-mobile">
            <div class="column column-center column-margin-rigth">
              <a class="btn" @click.prevent="searchList()">
                <i class="icon_search_normal icon-mks black"></i>Buscar
              </a>
            </div>
            <div class="column column-center">
              <a class="btn" @click.prevent="clearList()">
                <i class="icon_trash icon-mks icon-mks-mask black"></i>Limpiar
              </a>
            </div>
          </div>
        </b-field>
      </section>
    </div>
  </div>
</template>

<script>
import searchsTypes from '@/store/types/searchs';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

export default {
  name: 'FiltersVue',
  components: { DatePick },
  props: ['value'],
  data () {
    return {
      cont: 0,
      cont2: 0,
      dateIn: null,
      dateOut: null,
      date1: null,
      date2: null,
      name: null,
      email: null,
      range: false,
      menu: false,
      menu2: false,
      phone: null,
      typeProcess: true,
      showMensage: false,
      reservationId: null,
      date: new Date()
    };
  },
  methods: {
    searchList () {
      this.$store.commit(searchsTypes.mutations.setLoadingFilter, true);
      if (this.typeProcess === true) {
        this.$store.commit(
          searchsTypes.mutations.setStatusFilter,
          'En proceso'
        );
      } else {
        this.$store.commit(searchsTypes.mutations.setStatusFilter, 'Recibidas');
      }
      if (this.range === true) {
        this.$store.commit(searchsTypes.mutations.setDateInFilter, this.date1);
        this.$store.commit(searchsTypes.mutations.setDateOutFilter, this.date2);
      } else {
        this.dateIn = null;
        this.dateOut = null;
        this.date1 = null;
        this.date2 = null;
        this.$store.commit(searchsTypes.mutations.setDateInFilter, this.date1);
        this.$store.commit(searchsTypes.mutations.setDateOutFilter, this.date2);
      }
      if (window.innerWidth < 768) {
        this.$emit('valuehijo', false);
      }
      this.$store.commit(searchsTypes.mutations.setNameFilter, this.name);
      this.$store.commit(searchsTypes.mutations.setEmailFilter, this.email);
      this.$store.commit(searchsTypes.mutations.setPhoneFilter, this.phone);
      this.$store.commit(searchsTypes.mutations.setReservationIdFilter, this.reservationId);
      this.$store.commit(searchsTypes.mutations.setExecuteFilter, true);
      this.showMensage = false;
    },
    clearList () {
      this.name = null;
      this.email = null;
      this.phone = null;
      this.reservationId = null;
      this.dateIn = null;
      this.dateOut = null;
      this.date1 = null;
      this.date2 = null;
      this.cont = 0;
      this.cont2 = 0;
    },
    alert () {
      this.$buefy.toast.open({
        message: 'Recuerda presionar BUSCAR!',
        position: 'is-bottom',
        type: 'is-danger'
      });
    },
    close () {
      this.$emit('valuehijo', false);
    }
  },
  watch: {
    dateOut () {
      if (this.dateOut != null) {
        this.range = true;
      } else {
        this.range = false;
      }
    },
    name () {
      if (this.name != null && this.name !== '' && this.cont === 0) {
        this.alert();
        this.cont = this.cont + 1;
      }
    },
    email () {
      if (this.email != null && this.email !== '' && this.cont === 0) {
        this.alert();
        this.cont = this.cont + 1;
      }
    },
    phone () {
      if (this.phone != null && this.phone !== '' && this.cont === 0) {
        this.alert();
        this.cont = this.cont + 1;
      }
    },
    reservationId () {
      if (
        this.reservationId != null &&
        this.reservationId !== '' &&
        this.cont2 === 0
      ) {
        this.alert();
        this.cont2 = this.cont2 + 1;
      }
    },
    range () {
      if (this.range) {
        this.date1 = this.$moment(this.dateIn)
          .format('YYYY-MM-DD');
        this.date2 = this.$moment(this.dateOut)
          .format('YYYY-MM-DD');
      } else {
        this.date1 = null;
        this.date2 = null;
      }
    }
  }
};
</script>
<style lang="scss">
 .display{
    display: flex !important;
    position: absolute;

    z-index: 1;
    margin: 0px!important;
    height: 100% !important;
  }
#filters {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 5px 5px 0 0;
  margin: 0 10px 0 0;
  height: 100%;
  overflow:visible;
  input {
    border-radius: 17px;
    border-color: #ebeced;
    box-shadow: none;
    padding: 20px;
  }
  .margin-botton {
    margin-bottom: 40px;
  }

  .icon-mks {
    align-self: center;
    &-size-fix {
      margin-right: -20px;
      height: 50px;
      width: 50px;
    }
    &-mask {
      -webkit-mask-size: contain;
      mask-size: contain;
    }
  }
  .icon_close{
    display: none;
  }
  .icon_search_normal {
    &:hover {
      color: white;
    }
  }
  .label {
    font-size: 14px;
    text-align: left;
    &-margin {
      margin: 15px 0px 15px 0px !important;
    }
  }
  .margin {
    margin: 15px 0px 15px 0px;
  }
  .label:not(:last-child) {
    margin-bottom: 0px;
  }
  .header {
    background: rgba(68, 75, 87, 1);
    text-align: center;
    border-radius: 5px 5px 0 0;
    padding: 5px;
    display: flex;
    flex-direction: row;
    place-content: space-between;
    &.principal{
    justify-content: center;
    height: 50px;
      .icon-mks{
        position: relative;
        right: 10px;
      }
    }
  }
  .text {
    color: white;
    align-self: center;
  }
  .filters-body {
    padding: 30px 10px;
    height: 100%;
    overflow: auto;
  }

  .btn {
    display: flex;
    justify-content: center;
    border: 1px solid rgba(112, 112, 112, 1);
    color: black;
    background-color: transparent;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 10% 8px 10%;
    width: 100%;
    &:hover {
      background: rgba(112, 112, 112, 1);
      color: white;
      transition: all 0.2s ease-in;
      i {
        background: #fff;
      }
    }
  }
  .column {
    &-center {
      text-align: center;
    }
    &-border-rigth {
      display: flex;
      border-right: 1px solid rgba(237, 237, 237, 1);
    }
    &-flex {
      display: flex;
    }
    &-margin-rigth {
      margin-right: 10px;
    }
  }
  .columns {
    &-space {
      justify-content: space-evenly;
    }
    &-wrap{
      flex-wrap: wrap;
    }
  }
  .b-radio.radio .control-label {
    font-weight: bold;
  }
  #datePicker {
    #datePickRigth{
      .vdpFloating.vdpPositionLeft {
    left: inherit;
    right: 0px;
}
    }
    .vdpComponent.vdpWithInput > input {
      padding-right: 0px;
    }
    input {
      padding: 0px;
      width: 100%;
      border: 0px;
      outline: inherit;
    }

    .column {
      &-display-flex {
        display: flex;
      }
    }
    .field {
      display: flex;
      flex-direction: column;
      text-align-last: center;
    }
    .icon-mks {
      margin: 0 0 0 15px;
    }
      .vdpFloating{
        left: 0!important;
        right: 0;
        top: -1580%;
      }
    .vdpInnerWrap{
      border-radius: 20px;
      background: rgba(68, 75, 87, 1);
      position: fixed;
      transform: translate3d(0,0,0);
      will-change: transform;
    }
    .vdpHeader{
      background: rgba(68, 75, 87, 1);
          border-radius: 20px;
    }
    .selectable{
      color: white;
    }
    .outOfRange{
      color: rgba(112, 112, 112, 1)
    }
    .vdpHeadCellContent{
      color: white;
    font-weight: bold;
    }
    .vdpPeriodControl>button{
          color: white;
    font-weight: bold;
    }
.vdpArrowPrev:after {
    border-right-color: white;
}
.vdpArrowNext:after {
    border-left-color: white;
}
.vdpCell.selected .vdpCellContent {
    color: black;
    background: white;
    &:hover{
       color: black;
    background: white;
    }
}
  }
 .vdpCell.selectable:hover .vdpCellContent {
    color: rgba(68, 75, 87, 1);
    background: rgba(255, 255, 255, 1);
}
tr{
  background: #444b57 !important;
  box-shadow: none !important;
}
.vdpRow{
background: #444b57;
box-shadow: none;
}
.vdpPositionReady {
  z-index: 5 !important;
}
}
</style>
