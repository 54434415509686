<template>
  <b-modal :active.sync="changedVendor" class="" id="modalChnageVendor">
    <div class="columns title-bar">
      <div class="column">
        <span style="display: flex; justify-content: center;">Cambiar a Otro Restaurante del Grupo</span>
      </div>
      <button class="btn-close" @click="setClose()"><i class="fa fa-times"></i></button>
    </div>
    <div class="content-pass-modal">
      <div class="content-pass-modal-title">
        <label>Actualmente estás en:&nbsp;</label>
        <strong>{{ vendor.displayName }}</strong>
      </div>
      <hr>
      <div class="content-pass-modal-subtitle">
        <label>Selecciona el restaurante que deseas ver: </label>
      </div>
      <div class="content-pass-modal-items">
        <div v-for="(sede, i) in groupVendor" :key="'group#' + i" class="content-pass-modal-items-item" :class="{'img-hover': id == sede.id}" @mouseover="id = sede.id" @mouseleave="id = 0" @click="setVendorGroup(sede.urlPrecompro)" :title="'Cambiar a ' + sede.displayName">
          <img :src="getImage(sede)" class="content-pass-modal-items-item-img">
          <div class="content-pass-modal-items-item-description">{{ sede.displayName }}<span v-if="sede.sede != null">({{ sede.sede }})</span></div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import authTypes from '@/store/types/auth';
export default {
  data () {
    return {
      id: 0
    };
  },
  methods: {
    setVendorGroup (url) {
      localStorage.setItem('vendorUrl', url);
      this.$store.commit(authTypes.mutations.setShowChangedVendor, false);
      window.location.reload();
    },
    setClose () {
      this.$store.commit(authTypes.mutations.setShowChangedVendor, false);
    },
    getImage (item) {
      let image = 'https://precompro.atlassian.net/s/1kwsvv/b/8/b94db0d830c3249a9511ba1bd633fffb/_/jira-logo-scaled.png';
      if (item.image) {
        image = item.image;
      } else if (item.imageBlack) {
        image = item.imageBlack;
      } else if (item.imageColor) {
        image = item.imageColor;
      } else if (item.logo) {
        image = item.logo;
      }
      return image;
    }
  }
};
</script>
<style lang="scss">
#modalChnageVendor {
  .modal-content {
    height: unset;
  }
  .img-hover {
    background-color: #ada8a8;
    opacity: 0.3;
    font-weight: bold;
  }
  .btn-close {
    float: right;
    border: unset;
    color: white;
    cursor: pointer;
  }
  .title-bar {
    background: #444B57;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px !important;
    border-radius:10px 10px 0 0;
  }
  .content-pass-modal {
    padding: 10px;
    background: #fff;
    border-radius: 0 0 10px 10px;
    overflow-y: scroll;
    height: 450px;
    &-title, &-subtitle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &-items {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 50px;
      &-item {
        height: 200px;
        width: 200px;
        border: 1px solid lightgray;
        border-radius: 9px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        &-img {
          height: calc(100% - 50px);
          width: calc(100% - 25px);
        }
        &-description {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    button {
      padding: 10px 20px;
      border: none;
      background: #444B57;
      color: #fff;
      font-weight: bold;
      border-radius: 50px;
      margin: 5px 20px 2px 0;
      position:relative;
      i {
        position: relative;
        top: auto;
        left: auto;
        margin-right: 10px;
        font-size: 15px;
      }
    }
  }
  @media screen and (max-width: 1030px) {
    .modal-content {
      max-width: 860px!important;
    }
    .content-pass-modal-items-item {
      height: 180px;
      width: 180px;
    }
  }
  @media screen and (max-width: 930px) {
    .modal-content {
      max-width: 760px!important;
    }
    .content-pass-modal-items-item {
      height: 160px;
      width: 160px;
      &-description {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 830px) {
    .modal-content {
      height: unset!important;;
      max-width: 560px!important;
    }
    .content-pass-modal-items-item {
      height: 130px;
      width: 130px;
      &-description {
        font-size: 12px;
      }
    }
  }
   @media screen and (max-width: 630px) {
    .modal-content {
      height: unset!important;;
      max-width: 360px!important;
    }
    .content-pass-modal-items-item {
      height: 100px;
      width: 100px;
      &-description {
        font-size: 12px;
      }
    }
  }
}
</style>
