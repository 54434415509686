import namespace from '@/helpers/namespace';

export default namespace('section', {
  getters: [
    'sections'
  ],
  actions: [
    'sections'
  ],
  mutations: [
    'setSections'
  ]
});
