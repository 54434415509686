<template>
  <div>
    <Sidenav v-if="vendorParams" @change-full="toggle"></Sidenav>
    <div id="newMetrics">
      <HeaderActions
        @sectionId="sectionId = $event"
        :sectionId="sectionId"
      />
      <div class="general-metrics-layer">
        <div class="general-metrics-layer__container">
          <SideBarInfo
            :dataReservations="dataReservation"
            :totalPeopleEfective="getTotalsByRange(byRange, 'people')"
            :totalReservationsEfective="getTotalsByRange(byRange, 'reservations')"
            :totalPeople="dataReservation.people"
            :totalReservations="dataReservation.reservas"
            :type="type"
            :sectionId="sectionId"
            :totalReservationGeneral="totalReservationGeneral"
            :totalPeopleGeneral="totalPeopleGeneral"
            :totalReservationPrePurchaseGeneral="totalReservationPrePurchaseGeneral"
            :totalPeoplePrepurchaseGeneral="totalPeoplePrepurchaseGeneral"
          />
          <!--Desde  -->
          <GeneralCharts
            :sectionId="sectionId"
            :peoplePerDay="byDayD"
            :dataConventionsPerDay="sectionId === 4 ? dataReservation.byDay : byReferrerD"
            :peoplePerGroup="byRangeD"
            :dataConventionsPerGroup="byRange"
            :peoplePerTime="sectionId === 3 ? timeStateN3 : (sectionId === 4 ? timeStateN : timeStateN2)"
            :typeByDay="typeByDay"
            :typeByRange="typeByRange"
            @getColorByName="getColorByName"
            @changeFilter="changeFilter"
            :walkingData="{
              reservations: dataReservationWalkIn,
              people: dataPeopleWalkIn,
              dataReservationYate: dataReservationYate,
              dataPeopleYate: dataPeopleYate,
              dataReservationD: dataReservationD,
              dataPeopleD: dataPeopleD
            }"
            :generalData="byAllDataD"
            :temporalDataOldGraphs="{
              dataReservationRange,
              byMonthD,
              notEffectiveD,
              byHour,
              typeNotEffective,
              byIsCancelledD,
              byNotShowD,
              byAllReservationsD,
              byAllReservations,
              byHourD,
              byHourDGeneral,
              byLastHourD,
              byReservationS,
              byCountry,
              byCountryReservations,
              byGuestD,
              byClientTypeDGeneral,
              metricReservations,
              globalD,
              byRequirementsD,
              byMonth,
              byEffectiveNotEffectiveD,
              byAllTotalReservationsD,
              byAllTotalReservationsNotEffectiveD
            }"
          />

        </div>
      </div>
    </div>
  </div>
</template>
  <!-- <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword> -->
  <!-- <TempPassword></TempPassword> -->
<script>

import Sidenav from '@/components/_shared/Sidenav';
import HeaderActions from '@/components/MetricsV2/HeaderActions';
import SideBarInfo from '@/components/MetricsV2/SideBarInfo';
import GeneralCharts from '@/components/MetricsV2/GeneralCharts';
import { mapGetters } from 'vuex';
import globalTypes from '@/store/types/global';
import metricsTypes from '@/store/types/metrics';
import _ from 'lodash';

export default {
  props: {
  },
  name: 'metricIndex',
  components: {
    Sidenav,
    HeaderActions,
    SideBarInfo,
    GeneralCharts
  },
  data () {
    return {
      sectionId: 1,
      isHidden: true,
      verticalReservation: false,
      load: false,
      purchase: false,
      type: 'reservation',
      decimals: 1,
      byMonthD: {
        columns: ['mes', 'Reservas'],
        rows: []
      },
      byHourD: {
        columns: ['hour', 'Reservas'],
        rows: []
      },
      byHourDGeneral: {
        columns: ['hour', 'Reservas', 'Precompras'],
        rows: []
      },
      byClientTypeDGeneral: {
        columns: ['dia', 'Reservas', 'Precompras'],
        rows: []
      },
      byMediaTypeDGeneral: {
        columns: ['dia', 'Reservas', 'Precompras'],
        rows: []
      },
      byDayD: {
        name: '',
        data: []
      },
      byClientTypeD: {
        name: '',
        data: []
      },
      byLastHourD: {
        name: '',
        data: []
      },
      byMediaTypeD: {
        name: '',
        data: []
      },
      byReferrerD: {},
      notEffectiveD: {
        columns: ['name', 'value'],
        rows: []
      },
      byGuestD: {
        columns: ['name', 'value'],
        rows: []
      },
      byRequirementsD: {
        columns: ['name', 'value'],
        rows: []
      },
      byEffectiveNotEffectiveD: {
        columns: ['name', 'value'],
        rows: []
      },
      byExperienceD: {
        columns: ['name', 'value'],
        rows: []
      },
      byAllReservationsD: {
        columns: ['name', 'value'],
        rows: []
      },
      byAllTotalReservationsD: {
        columns: ['name', 'value'],
        rows: []
      },
      byAllTotalReservationsNotEffectiveD: {
        columns: ['name', 'value'],
        rows: []
      },
      byRangeD: {
        name: '',
        data: []
      },
      timeStateN: [{
        name: 'Reservas',
        data: {}
      },
      {
        name: 'Walk-in',
        data: {}
      },
      {
        name: 'Precompras',
        data: {}
      }],
      timeStateN2: [{
        name: 'Reservas',
        data: {}
      }],
      timeStateN3: [{
        name: 'Walk-in',
        data: {}
      }],
      byReservationS: [{
        name: 'Total reservas finalizadas por el usuario',
        data: {}
      },
      {
        name: 'Total reservas finalizadas por el sistema',
        data: {}
      }],
      byDayReservations: null,
      byDayReservationsClient: null,
      byDayReservationsMedia: null,
      byDayPeopleClient: null,
      byDayPeopleMedia: null,
      dataReservationD: null,
      dataPeopleD: null,
      byDayPeople: null,
      isCancelled: null,
      experiencesTotal: null,
      experiencesEffective: null,
      notShow: null,
      byIsCancelledD: null,
      byNotShowD: null,
      byAllDataD: null,
      dataReservationWalkIn: null,
      dataPeopleWalkIn: null,
      dataPeopleYate: null,
      dataReservationYate: null,
      byReferrerWithColors: [],
      byAllReservations: [],
      typeByDay: 1,
      typeByRange: 1,
      typeByTransaction: 1,
      typeNotEffective: 1,
      typeExperience: 1,
      primaryColor: '#00ADC6',
      totalReservationPhoneDay: 0,
      totalReservationWebDay: 0,
      totalPeoplePhoneDay: 0,
      totalPeopleWebDay: 0,
      globalD: [],
      pseD: [],
      cardD: [],
      totalReservationGeneral: 0,
      totalPeopleGeneral: 0,
      totalReservationPrePurchaseGeneral: 0,
      totalPeoplePrepurchaseGeneral: 0
    };
  },
  computed: {
    ...mapGetters({
      dataReservation: [metricsTypes.getters.dataReservation],
      dataReservationRange: [metricsTypes.getters.dataReservationRange],
      byMonth: [metricsTypes.getters.byMonth],
      byHour: [metricsTypes.getters.byHour],
      byCountry: [metricsTypes.getters.byCountry],
      byCountryPrecompras: [metricsTypes.getters.byCountryPrecompras],
      byCountryReservations: [metricsTypes.getters.byCountryReservations],
      byCountryWalkIn: [metricsTypes.getters.byCountryWalkIn],
      byHourLastHour: [metricsTypes.getters.byHourLastHour],
      byClientType: [metricsTypes.getters.byClientType],
      byExperience: [metricsTypes.getters.byExperience],
      byExperienceEffective: [metricsTypes.getters.byExperienceEffective],
      byMediaType: [metricsTypes.getters.byMediaType],
      byDay: [metricsTypes.getters.byDay],
      byRange: [metricsTypes.getters.byRange],
      dateFrom: [metricsTypes.getters.dateFrom],
      dateTo: [metricsTypes.getters.dateTo],
      year: [metricsTypes.getters.year],
      notEffective: [metricsTypes.getters.notEffective],
      timeState: [metricsTypes.getters.timeState],
      global: [metricsTypes.getters.global],
      pse: [metricsTypes.getters.pse],
      card: [metricsTypes.getters.card],
      metricReservations: [metricsTypes.getters.metricReservations],
      byGuest: [metricsTypes.getters.byGuest],
      byRequirements: [metricsTypes.getters.byRequirements],
      byEffectiveNotEffective: [metricsTypes.getters.byEffectiveNotEffective],
      byAllTotalReservations: [metricsTypes.getters.byAllTotalReservations]
    })
  },
  watch: {
    dataReservationRange () {
      const context = this;
      if (this.dataReservationRange) {
        this.getNewDataGraphics();
        if (this.byDay) {
          if (this.sectionId === 3) {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataReservationYate) {
                this.byDayReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataPeopleYate) {
                this.byDayPeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.byRange) {
          if (this.sectionId === 3) {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataReservationYate) {
                this.byRangeReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataPeopleYate) {
                this.byRangePeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        setTimeout(() => {
          if (this.validateExperiencieTypeGraph()) {
            this.experiencesTotal = this.dataReservation.byExperience && this.dataReservation.byExperience.map(function (response) {
              const aux = {};
              aux.name = response.name;
              aux.value = response.countExperience;
              aux.color = context.getColorByName(aux.name);
              return aux;
            });
            this.experiencesEffective = this.dataReservation.byExperienceEffective && this.dataReservation.byExperienceEffective.map(function (response) {
              const aux = {};
              aux.name = response.name;
              aux.value = response.countExperience;
              aux.color = context.getColorByName(aux.name);
              return aux;
            });
            this.byExperienceD.rows = this.experiencesTotal;
          }
        }, 200);
        if (this.notEffective) {
          if (this.sectionId !== 3) {
            if (this.dataReservationRange.notEffective[0].length > 0) {
              this.isCancelled = this.dataReservationRange.notEffective[0].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.isCancelled = null;
            }
            if (this.dataReservationRange.notEffective[1].length > 0) {
              this.notShow = this.dataReservationRange.notEffective[1].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.notShow = [];
            }
            this.notEffectiveD.rows = this.isCancelled;
            if (this.dataReservationRange.notEffective[0] && this.dataReservationRange.notEffective[1]) {
              this.byIsCancelledD = this.dataReservationRange.notEffective[0].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
              this.byNotShowD = this.dataReservationRange.notEffective[1].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: this.getColorByName(name)
                };
              });
            }
          }
        }
        this.byReferrerWithColors = (this.dataReservation.byReferrer !== null) ? this.dataReservation.byReferrer.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          return {
            ...data,
            name: name,
            color: context.getColorByName(name)
          };
        }) : null;
        this.setDataClientType();
        this.setDataMediaType();
        this.byAllReservations = (this.dataReservation.byReferrer !== null) ? this.byReferrerWithColors.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          const reservations = data.reservations;
          return {
            ...data,
            value: reservations,
            name: name,
            color: context.getColorByName(name)
          };
        }) : [];
        if (this.sectionId === 2) {
          if (this.global != null) {
            this.globalD = context.getNewArrayTransactions(this.global);
          }
        }
        // Se asigna los valores a los objectos de las gráficas
        this.byAllReservationsD.rows = this.byAllReservations;
        // Se asigna los valores a los objectos de las gráficas
        this.byClientTypeD = this.byDayReservationsClient;
        this.byMediaTypeD = this.byDayReservationsMedia;
        this.byMonthD.rows = this.byMonth;
        this.byGuestD.rows = this.byGuest;
        this.byReferrerD = this.byReferrerWithColors;
        this.byDayD = this.byDayReservations;
        this.byRangeD = this.byRangeReservations;
        this.dataReservationD = this.dataReservationRange.reservas;
        this.getDataHours();
        this.getDataHoursLastHour();
        if (this.sectionId === 3) {
          this.dataReservationWalkIn = this.dataReservationRange.walkInReservations;
          this.dataPeopleWalkIn = this.dataReservationRange.walkInPeople;
          const yate = this.byDayD.find(d => d.name === 'yate');
          if (typeof yate !== 'undefined') {
            this.dataReservationYate = this.dataReservationRange.yateRes;
            this.dataPeopleYate = this.dataReservationRange.yatePeople;
          }
        }
        if (this.sectionId !== 2) {
          this.getReservationsCompleted(this.dateFrom, this.dateTo);
          this.getTimeReservations(null, null);
        }
        this.dataPeopleD = this.dataReservationRange.people;
        this.byAllDataD = this.dataReservationRange;
      }
    },
    year () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 5 });
      const context = this;
      if (this.dateFrom != null && this.dateTo != null) {
        if (this.dateFrom <= this.dateTo) {
          this.$store.dispatch({
            type: 'metrics:getMetricReservations',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          });
          this.$store.dispatch({
            type: 'metrics:getDataReservationRange',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          })
            .then(res => {
              this.getReservationsCompleted(this.dateFrom, this.dateTo);
              this.getTimeReservations(this.dateFrom, this.dateTo);
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            })
            .catch(e => {
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
              this.$buefy.notification.open({
                message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
                type: 'is-danger'
              });
            });
        } else {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> La Fecha de inicio no debe ser menor a la fecha tope de busquedada',
            type: 'is-danger'
          });
        }
      } else if (this.dateFrom == null && this.dateTo == null) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 5 });
        this.getReservationsCompleted(null, null);
        this.getTimeReservations(null, null);
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        });
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 5 });
        this.$store
          .dispatch({
            type: 'metrics:getDataReservation',
            data: { vendorId: this.$store.state.idVendor, type: this.sectionId, year: this.year }
          }).then(res => {
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          });
        this.getNewDataGraphics();
        if (this.byDay) {
          if (this.sectionId === 3) {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataReservationYate) {
                this.byDayReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataPeopleYate) {
                this.byDayPeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.byRange) {
          if (this.sectionId === 3) {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataReservationYate) {
                this.byRangeReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataPeopleYate) {
                this.byRangePeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        setTimeout(() => {
          if (this.validateExperiencieTypeGraph()) {
            this.experiencesTotal = this.dataReservation.byExperience && this.dataReservation.byExperience.map(function (response) {
              const aux = {};
              aux.name = response.name;
              aux.value = response.countExperience;
              aux.color = context.getColorByName(aux.name);
              return aux;
            });
            this.experiencesEffective = this.dataReservation.byExperienceEffective && this.dataReservation.byExperienceEffective.map(function (response) {
              const aux = {};
              aux.name = response.name;
              aux.value = response.countExperience;
              aux.color = context.getColorByName(aux.name);
              return aux;
            });
            this.byExperienceD.rows = this.experiencesTotal;
          }
        }, 200);
        if (this.notEffective) {
          if (this.sectionId !== 3) {
            if (this.dataReservation.notEffective[0].length > 0) {
              this.isCancelled = this.dataReservation.notEffective[0].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.isCancelled = null;
            }
            if (this.dataReservation.notEffective[1].length > 0) {
              this.notShow = this.dataReservation.notEffective[1].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.notShow = [];
            }
            this.notEffectiveD.rows = this.isCancelled;
            if (this.dataReservation.notEffective[0] && this.dataReservation.notEffective[1]) {
              this.byIsCancelledD = this.dataReservation.notEffective[0].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: context.getColorByName(name)
                };
              });
              this.byNotShowD = this.dataReservation.notEffective[1].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: context.getColorByName(name)
                };
              });
            }
          }
        }
        this.byReferrerWithColors = this.dataReservation.byReferrer && this.dataReservation.byReferrer.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          return {
            ...data,
            name: name,
            color: context.getColorByName(name)
          };
        });
        this.setDataClientType();
        this.setDataMediaType();
        this.byAllReservations = this.byReferrerWithColors && this.byReferrerWithColors.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          const reservations = data.reservations;
          return {
            ...data,
            value: reservations,
            name: name,
            color: context.getColorByName(name)
          };
        });
        if (this.sectionId === 2) {
          if (this.global != null) {
            this.globalD = context.getNewArrayTransactions(this.global);
          }
        }
        // Se asigna los valores a los objectos de las gráficas
        this.byClientTypeD = this.byDayReservationsClient;
        this.byMediaTypeD = this.byDayReservationsMedia;
        this.byAllReservationsD.rows = this.byAllReservations || [];
        this.byMonthD.rows = this.byMonth;
        this.byGuestD.rows = this.byGuest;
        this.byReferrerD = this.byReferrerWithColors;
        this.byDayD = this.byDayReservations;
        this.byRangeD = this.byRangeReservations;
        this.dataReservationD = this.dataReservation.reservas;
        if (this.sectionId === 3) {
          this.dataReservationWalkIn = this.dataReservationRange.walkInReservations;
          this.dataPeopleWalkIn = this.dataReservationRange.walkInPeople;
          const yate = this.byDayD.find(d => d.name === 'yate');
          if (typeof yate !== 'undefined') {
            this.dataReservationYate = this.dataReservationRange.yateRes;
            this.dataPeopleYate = this.dataReservationRange.yatePeople;
          }
        }
        this.dataPeopleD = this.dataReservation.people;
        this.byAllDataD = this.dataReservation;
        this.getDataHours();
        this.getDataHoursLastHour();
      }
    },
    dateFrom () {
      const context = this;
      if (this.dateFrom != null && this.dateTo != null) {
        if (this.dateFrom <= this.dateTo) {
          this.$store.dispatch({
            type: 'metrics:getMetricReservations',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          });
          this.$store.dispatch({
            type: 'metrics:getDataReservationRange',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          })
            .then(res => {
              this.getReservationsCompleted(this.dateFrom, this.dateTo);
              this.getTimeReservations(this.dateFrom, this.dateTo);
            })
            .catch(e => {
              this.$buefy.notification.open({
                message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
                type: 'is-danger'
              });
            });
        } else {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> La Fecha de inicio no debe ser menor a la fecha tope de busquedada',
            type: 'is-danger'
          });
        }
      } else if (this.dateFrom == null && this.dateTo == null) {
        this.getReservationsCompleted(null, null);
        this.getTimeReservations(null, null);
        this.$store
          .dispatch({
            type: 'metrics:getDataReservation',
            data: { vendorId: this.$store.state.idVendor, type: this.sectionId, year: this.year }
          });
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        });
        this.getNewDataGraphics();
        if (this.byDay) {
          if (this.sectionId === 3) {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataReservationYate) {
                this.byDayReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataPeopleYate) {
                this.byDayPeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.byRange) {
          if (this.sectionId === 3) {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataReservationYate) {
                this.byRangeReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataPeopleYate) {
                this.byRangePeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.notEffective) {
          if (this.sectionId !== 3) {
            if (this.dataReservation.notEffective[0].length > 0) {
              this.isCancelled = this.dataReservation.notEffective[0].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.isCancelled = null;
            }
            if (this.dataReservation.notEffective[1].length > 0) {
              this.notShow = this.dataReservation.notEffective[1].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.notShow = [];
            }
            this.notEffectiveD.rows = this.isCancelled;
            if (this.dataReservation.notEffective[0] && this.dataReservation.notEffective[1]) {
              this.byIsCancelledD = this.dataReservation.notEffective[0].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: context.getColorByName(name)
                };
              });
              this.byNotShowD = this.dataReservation.notEffective[1].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: context.getColorByName(name)
                };
              });
            }
          }
        }
        this.byReferrerWithColors = this.dataReservation.byReferrer && this.dataReservation.byReferrer.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          return {
            ...data,
            name: name,
            color: context.getColorByName(name)
          };
        });
        this.setDataClientType();
        this.setDataMediaType();
        this.byAllReservations = this.byReferrerWithColors && this.byReferrerWithColors.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          const reservations = data.reservations;
          return {
            ...data,
            value: reservations,
            name: name,
            color: context.getColorByName(name)
          };
        });
        if (this.sectionId === 2) {
          if (this.global != null) {
            this.globalD = context.getNewArrayTransactions(this.global);
          }
        }
        // Se asigna los valores a los objectos de las gráficas
        this.byClientTypeD = this.byDayReservationsClient;
        this.byMediaTypeD = this.byDayReservationsMedia;
        this.byAllReservationsD.rows = this.byAllReservations || [];
        this.byMonthD.rows = this.byMonth;
        this.byGuestD.rows = this.byGuest;
        this.byReferrerD = this.byReferrerWithColors;
        this.byDayD = this.byDayReservations;
        this.byRangeD = this.byRangeReservations;
        this.byRequirementsD = this.byRequirements;
        this.dataReservationD = this.dataReservation.reservas;
        if (this.sectionId === 3) {
          this.dataReservationWalkIn = this.dataReservationRange.walkInReservations;
          this.dataPeopleWalkIn = this.dataReservationRange.walkInPeople;
          const yate = this.byDayD.find(d => d.name === 'yate');
          if (typeof yate !== 'undefined') {
            this.dataReservationYate = this.dataReservationRange.yateRes;
            this.dataPeopleYate = this.dataReservationRange.yatePeople;
          }
        }
        this.dataPeopleD = this.dataReservation.people;
        this.byAllDataD = this.dataReservation;
        this.getDataHours();
        this.getDataHoursLastHour();
      }
    },
    dateTo () {
      const context = this;
      if (this.dateFrom !== null && this.dateTo !== null) {
        if (this.dateFrom <= this.dateTo) {
          this.$store.dispatch({
            type: 'metrics:getMetricReservations',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          });
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
          this.$store.dispatch({
            type: 'metrics:getDataReservationRange',
            data: {
              vendorId: this.$store.state.idVendor,
              dateTo: this.dateTo,
              dateFrom: this.dateFrom,
              type: this.sectionId,
              year: this.year
            }
          })
            .then(res => {
              this.getReservationsCompleted(this.dateFrom, this.dateTo);
              this.getTimeReservations(this.dateFrom, this.dateTo);
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            })
            .catch(e => {
              this.$buefy.notification.open({
                message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
                type: 'is-danger'
              });
            });
        } else {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> La Fecha de fin no debe ser menor a la fecha inicio de busquedada',
            type: 'is-danger'
          });
        }
      } else if (this.dateFrom == null && this.dateTo == null) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 5 });
        this.getReservationsCompleted(null, null);
        this.getTimeReservations(null, null);
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        });
        this.$store
          .dispatch({
            type: 'metrics:getDataReservation',
            data: { vendorId: this.$store.state.idVendor, type: this.sectionId, year: this.year }
          });
        this.getNewDataGraphics();
        if (this.byDay) {
          if (this.sectionId === 3) {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataReservationYate) {
                this.byDayReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byDayReservations = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservation.byDay.length; index++) {
              const walk = this.dataReservation.byDay[index];
              if (walk.dataPeopleYate) {
                this.byDayPeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byDayPeople = this.dataReservation.byDay.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.byRange) {
          if (this.sectionId === 3) {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataReservationYate) {
                this.byRangeReservations.push({ name: 'yate', data: walk.dataReservationYate, color: '#000099' });
              }
            }
          } else {
            this.byRangeReservations = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataReservation;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
          if (this.sectionId === 3) {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
            for (let index = 0; index < this.dataReservationRange.byRange.length; index++) {
              const walk = this.dataReservationRange.byRange[index];
              if (walk.dataPeopleYate) {
                this.byRangePeople.push({ name: 'yate', data: walk.dataPeopleYate, color: '#000099' });
              }
            }
          } else {
            this.byRangePeople = this.dataReservationRange.byRange.map(function (response) {
              const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
              const aux = {};
              aux.name = name;
              aux.data = response.dataPeople;
              aux.color = context.getColorByName(name);
              return aux;
            });
          }
        }
        if (this.notEffective) {
          if (this.sectionId !== 3) {
            if (this.dataReservation.notEffective[0].length > 0) {
              this.isCancelled = this.dataReservation.notEffective[0].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = name;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.isCancelled = null;
            }
            if (this.dataReservation.notEffective[1].length > 0) {
              this.notShow = this.dataReservation.notEffective[1].map(function (response) {
                const name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                const aux = {};
                aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
                aux.value = response.reservations;
                aux.people = response.people;
                aux.color = context.getColorByName(name);
                return aux;
              });
            } else {
              this.notShow = [];
            }
            this.notEffectiveD.rows = this.isCancelled;
            if (this.dataReservation.notEffective[0] && this.dataReservation.notEffective[1]) {
              this.byIsCancelledD = this.dataReservation.notEffective[0].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: context.getColorByName(name)
                };
              });
              this.byNotShowD = this.dataReservation.notEffective[1].map((data) => {
                const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
                return {
                  ...data,
                  color: context.getColorByName(name)
                };
              });
            }
          }
        }
        this.byReferrerWithColors = this.dataReservation.byReferrer && this.dataReservation.byReferrer.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          return {
            ...data,
            name: name,
            color: context.getColorByName(name)
          };
        });
        this.setDataClientType();
        this.setDataMediaType();
        this.byAllReservations = this.byReferrerWithColors && this.byReferrerWithColors.map((data) => {
          const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
          const reservations = data.reservations;
          return {
            ...data,
            value: reservations,
            name: name,
            color: context.getColorByName(name)
          };
        });
        if (this.sectionId === 2) {
          if (this.global != null) {
            this.globalD = context.getNewArrayTransactions(this.global);
          }
        }
        // Se asigna los valores a los objectos de las gráficas
        this.byClientTypeD = this.byDayReservationsClient;
        this.byMediaTypeD = this.byDayReservationsMedia;
        this.byAllReservationsD.rows = this.byAllReservations;
        this.byMonthD.rows = this.byMonth;
        this.byGuestD.rows = this.byGuest;
        this.byReferrerD = this.byReferrerWithColors;
        this.byDayD = this.byDayReservations;
        this.byRangeD = this.byRangeReservations;
        this.byRequirementsD = this.byRequirements;
        this.dataReservationD = this.dataReservation.reservas;
        if (this.sectionId === 3) {
          this.dataReservationWalkIn = this.dataReservationRange.walkInReservations;
          this.dataPeopleWalkIn = this.dataReservationRange.walkInPeople;
          const yate = this.byDayD.find(d => d.name === 'yate');
          if (typeof yate !== 'undefined') {
            this.dataReservationYate = this.dataReservationRange.yateRes;
            this.dataPeopleYate = this.dataReservationRange.yatePeople;
          }
        }
        this.dataPeopleD = this.dataReservation.people;
        this.byAllDataD = this.dataReservation;
        this.getDataHours();
        this.getDataHoursLastHour();
      }
    },
    sectionId () {
      this.clearDate();
    },
    typeByDay () {
      if (parseInt(this.typeByDay) === 1) {
        this.byDayD = this.byDayReservations;
      }
      if (parseInt(this.typeByDay) === 2) {
        this.byDayD = this.byDayPeople;
      }
    },
    typeByRange () {
      if (parseInt(this.typeByRange) === 1) {
        this.byRangeD = this.byRangeReservations;
      }
      if (parseInt(this.typeByRange) === 2) {
        this.byRangeD = this.byRangePeople;
      }
    },
    typeByTransaction () {
      if (parseInt(this.typeByTransaction) === 1) {
        this.globalD = this.getNewArrayTransactions(this.global);
      }
      if (parseInt(this.typeByTransaction) === 2) {
        this.globalD = this.getNewArrayTransactions(this.pse);
      }
      if (parseInt(this.typeByTransaction) === 3) {
        this.globalD = this.getNewArrayTransactions(this.card);
      }
    },
    typeNotEffective () {
      if (parseInt(this.typeNotEffective) === 1) {
        this.notEffectiveD.rows = this.isCancelled;
      }
      if (parseInt(this.typeNotEffective) === 2) {
        this.notEffectiveD.rows = this.notShow;
      }
    },
    typeExperience () {
      if (parseInt(this.typeExperience) === 1) {
        this.byExperienceD.rows = this.experiencesTotal;
      }
      if (parseInt(this.typeExperience) === 2) {
        this.byExperienceD.rows = this.experiencesEffective;
      }
    }
  },
  mounted () {
    this.$store.dispatch({
      type: 'auth:refreshUser',
      data: {
        userId: this.user.id
      }
    });

    if (this.dataReservation.prePurchase) {
      this.purchase = true;
    }
    this.byMonthD.rows = this.byMonth;
    this.byGuestD.rows = this.byGuest;
    this.getDataHours();
    this.getDataHoursLastHour();
    const context = this;
    this.setDataClientType();
    this.setDataMediaType();
    this.getNewDataGraphics();
    if (this.byDay) {
      this.byDayReservations = this.dataReservation.byDay.map(function (response) {
        const aux = {};
        const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.name = name;
        aux.data = response.dataReservation;
        aux.color = context.getColorByName(name);
        return aux;
      });
      this.byDayPeople = this.dataReservation.byDay.map(function (response) {
        const aux = {};
        const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.data = response.dataPeople;
        aux.color = context.getColorByName(name);
        return aux;
      });
    }
    if (this.byRange) {
      this.byRangeReservations = this.dataReservation.byRange.map(function (response) {
        const aux = {};
        const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.data = response.dataReservation;
        aux.color = context.getColorByName(name);
        return aux;
      });
      this.byRangePeople = this.dataReservation.byRange.map(function (response) {
        const aux = {};
        const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
        aux.data = response.dataPeople;
        aux.color = context.getColorByName(name);
        return aux;
      });
    }
    setTimeout(() => {
      if (this.validateExperiencieTypeGraph()) {
        this.experiencesTotal = this.dataReservation.byExperience && this.dataReservation.byExperience.map(function (response) {
          const aux = {};
          aux.name = response.name;
          aux.value = response.countExperience;
          aux.color = context.getColorByName(aux.name);
          return aux;
        });
        this.experiencesEffective = this.dataReservation.byExperienceEffective && this.dataReservation.byExperienceEffective.map(function (response) {
          const aux = {};
          aux.name = response.name;
          aux.value = response.countExperience;
          aux.color = context.getColorByName(aux.name);
          return aux;
        });
        this.byExperienceD.rows = this.experiencesTotal;
      }
    }, 200);
    if (this.sectionId !== 3) {
      if (this.notEffective) {
        this.isCancelled = this.dataReservation.notEffective[0].map(function (response) {
          const aux = {};
          aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
          aux.value = response.reservations;
          aux.people = response.people;
          aux.color = context.getColorByName(aux.name);
          return aux;
        });
        this.notShow = this.dataReservation.notEffective[1].map(function (response) {
          const aux = {};
          aux.name = (response.referrer === 'whitelabel') ? 'página web' : (response.referrer === 'dashboard') ? 'telefono' : response.referrer;
          aux.value = response.reservations;
          aux.people = response.people;
          aux.color = context.getColorByName(aux.name);
          return aux;
        });
        this.notEffectiveD.rows = this.isCancelled;

        if (this.dataReservation.notEffective[0] && this.dataReservation.notEffective[1]) {
          this.byIsCancelledD = this.dataReservation.notEffective[0].map((data) => {
            const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
            return {
              ...data,
              color: context.getColorByName(name)
            };
          });
          this.byNotShowD = this.dataReservation.notEffective[1].map((data) => {
            const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
            return {
              ...data,
              color: context.getColorByName(name)
            };
          });
        }
      }
    }
    if (this.sectionId !== 2) {
      this.getReservationsCompleted(this.dateFrom, this.dateTo);
      this.getTimeReservations(null, null);
    }
    this.byReferrerWithColors = this.dataReservation.byReferrer && this.dataReservation.byReferrer.map((data) => {
      const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
      return {
        ...data,
        name: name,
        color: context.getColorByName(name)
      };
    });
    this.byAllReservations = this.byReferrerWithColors && this.byReferrerWithColors.map((data) => {
      const name = (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer;
      const reservations = data.reservations;
      return {
        ...data,
        value: reservations,
        name: name,
        color: context.getColorByName(name)
      };
    });
    if (this.global != null) {
      this.globalD = context.getNewArrayTransactions(this.global);
    }
    this.byAllReservationsD.rows = this.byAllReservations;
    this.byDayD = this.byDayReservations;
    this.byClientTypeD = this.byDayReservationsClient;
    this.byMediaTypeD = this.byDayReservationsMedia;
    this.byReferrerD = this.byReferrerWithColors;
    this.byRangeD = this.byRangeReservations;
    this.dataReservationD = this.dataReservation.reservas;
    this.dataPeopleD = this.dataReservation.people;
    this.byAllDataD = this.dataReservation;
    this.getDataHours();
    this.getDataHoursLastHour();
  },
  methods: {
    clearDate () {
      const max = parseInt(this.$moment().format('YYYY'));
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.$store.commit(metricsTypes.mutations.setYear, max);
      this.$store.commit(metricsTypes.mutations.setFilterTypeDaySection, null);
      this.$store.commit(metricsTypes.mutations.setDateFrom, null);
      this.$store.commit(metricsTypes.mutations.setDateTo, null);
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      if (this.sectionId !== 4) {
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            year: this.year,
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            dateTo: this.dateTo,
            dateFrom: this.dateFrom
          }
        });
      }
      console.log('entraaaa 213123');
      this.$store
        .dispatch({
          type: 'metrics:getDataReservation',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year,
            dateTo: this.dateTo,
            dateFrom: this.dateFrom
          }
        })
        .then(() => {
          this.typeByDay = 1;
          this.typeByRange = 1;
          this.typeNotEffective = 1;

          if (this.sectionId === 1) {
            this.colors = ['#00ADC6', '#82888E'];
            this.colors2 = ['#59575D', '#59C4D5', '#CF257D', '#3C5193', '#0AD561', '#3C9368', '#CAD300'];
            this.type = 'reservation';
            this.primaryColor = '#00ADC6';
          }
          if (this.sectionId === 2) {
            this.colors = ['#CAD300', '#E9ED98'];
            this.colors2 = ['#59575D', '#59C4D5', '#CF257D', '#3C5193', '#0AD561', '#3C9368', '#CAD300'];
            this.type = 'prePurchase';
            this.primaryColor = '#CAD300';
          }
          if (this.sectionId === 3) {
            this.byDayD.columns = ['dia', 'WalkIn'];
            this.byRangeD.columns = ['Rangos', 'WalkIn'];
            this.colors = ['#9453CB', '#D3B9EA'];
            this.colors2 = ['#9453CB', '#D3B9EA'];
            this.type = 'Walk-in';
            this.primaryColor = '#9453CB';
          }
          if (this.sectionId === 4) {
            this.byDayD.columns = ['dia', 'WalkIn'];
            this.byRangeD.columns = ['Rangos', 'WalkIn'];
            this.colors = ['#9453CB', '#D3B9EA'];
            this.colors2 = ['#00ADC6', '#9452CB', '#CAD300'];
            this.type = 'General';
            this.primaryColor = '#9453CB';
          }
          if (this.sectionId === 4) {
            this.getTotalsForGeneralView();
          } else {
          }
        }).finally(() => {
          if (this.sectionId !== 4) {
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          }
        });
      if (this.sectionId !== 2) {
        this.getReservationsCompleted(this.dateFrom, this.dateTo);
        this.getTimeReservations(null, null);
      }
    },
    getNewDataGraphics () {
      setTimeout(() => {
        const context = this;
        if (this.dataReservation.byRequirements) {
          this.byRequirementsD = {
            columns: ['name', 'value'],
            rows: []
          };
          this.byRequirementsD.rows = this.dataReservation.byRequirements.map(function (response) {
            const name = (response.name === 'whitelabel') ? 'página web' : (response.name === 'dashboard') ? 'telefono' : response.name;
            const aux = {};
            aux.name = name;
            aux.value = response.value;
            aux.iconURL = response.iconURL;
            aux.color = context.getColorByName(name);
            return aux;
          });
        }
        if (this.dataReservation.effectiveNotEffective) {
          this.byEffectiveNotEffectiveD = {
            columns: ['name', 'value'],
            rows: []
          };
          if (this.dataReservation.effectiveNotEffective && this.byEffectiveNotEffective.reservationsEffective && this.byEffectiveNotEffective.reservationsNotEffective) {
            this.byEffectiveNotEffectiveD.rows = [
              {
                name: 'Efectivas',
                value: this.byEffectiveNotEffective.reservationsEffective,
                color: '#12A2DF'
              },
              {
                name: 'No efectivas',
                value: this.byEffectiveNotEffective.reservationsNotEffective,
                color: '#58d68d'
              }
            ];
          }
        }
        if (this.dataReservation.allReservations) {
          this.byAllTotalReservationsD = {
            columns: ['name', 'value'],
            rows: []
          };
          if (this.byAllTotalReservations.allReservations) {
            this.byAllTotalReservationsD.rows = [
              {
                name: 'Reservas',
                total: this.byAllTotalReservations.allReservations.reservations.total,
                effective: this.byAllTotalReservations.allReservations.reservations.effective,
                color: '#00ADC6'
              },
              {
                name: 'Precompras',
                total: this.byAllTotalReservations.allReservations.prepurchase.total,
                effective: this.byAllTotalReservations.allReservations.prepurchase.effective,
                color: '#BDC30E'
              },
              {
                name: 'Walk In',
                total: this.byAllTotalReservations.allReservations.walkin.total,
                effective: this.byAllTotalReservations.allReservations.walkin.effective,
                color: '#8A51B4'
              }
            ];
          }
          this.byAllTotalReservationsNotEffectiveD = {
            columns: ['name', 'value'],
            rows: []
          };
          if (this.byAllTotalReservations.summary) {
            this.byAllTotalReservationsNotEffectiveD.rows = [
              {
                name: 'Reservas',
                cancelled: this.byAllTotalReservations.summary.cancelled.reservation,
                arrived: this.byAllTotalReservations.summary.arrived.reservation,
                color: '#00ADC6'
              },
              {
                name: 'Precompras',
                cancelled: this.byAllTotalReservations.summary.cancelled.prepurchase,
                arrived: this.byAllTotalReservations.summary.arrived.prepurchase,
                color: '#BDC30E'
              },
              {
                name: 'Walk In',
                cancelled: this.byAllTotalReservations.summary.cancelled.walkin,
                arrived: this.byAllTotalReservations.summary.arrived.walkin,
                color: '#8A51B4'
              }
            ];
          }
        }
      }, 300);
    },
    changeFilter (graph, value) {
      if (graph === 'perDay') {
        this.typeByDay = value;
      }
      if (graph === 'perGroup') {
        this.typeByRange = value;
      }
      if (graph === 'perNotEffective') {
        this.typeNotEffective = value;
      }
      if (graph === 'transaction') {
        this.typeByTransaction = value;
      }
    },
    getTotalsForGeneralView () {
      const promises = [];
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      for (let pos = 1; pos <= 2; pos++) {
        const promise = this.$store.dispatch({
          type: 'metrics:getDataReservation',
          data: {
            vendorId: this.$store.state.idVendor,
            type: pos,
            year: this.year,
            dateTo: this.dateTo,
            dateFrom: this.dateFrom,
            onlyForSpecificData: true
          }
        }).then((resp) => {
          if (resp.data) {
            if (pos === 1) {
              this.totalReservationGeneral = resp.data.reservas;
              this.totalPeopleGeneral = resp.data.people;
            }
            if (pos === 2) {
              this.totalReservationPrePurchaseGeneral = resp.data.reservas;
              this.totalPeoplePrepurchaseGeneral = resp.data.people;
            }
          }
          /* this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false }); */
        }).catch(() => {
        });
        promises.push(promise);
      }
      Promise.all(promises).then(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
    getHeightReqRing (rows) {
      if (rows.length < 10) {
        return '300px';
      }
      return '450px';
    },
    getTypeText () {
      switch (this.sectionId) {
        case 1:
          return 'reservas';
        case 2:
          return 'precompras';
        case 3:
          return 'walk-In';
        case 4:
          return 'Reservas - Precompras - Walk-In';
        default:
          break;
      }
    },
    getTotalClientType (byClientTypeD, type = null) {
      if (!Array.isArray(byClientTypeD)) {
        return;
      }
      if (byClientTypeD === null) {
        return 0;
      }
      const clientTypeNew = byClientTypeD.map(client => {
        let totalRes = 0;
        totalRes += (client.data.LUN) !== undefined ? client.data.LUN : 0;
        totalRes += (client.data.MAR) !== undefined ? client.data.MAR : 0;
        totalRes += (client.data['MIÉ']) !== undefined ? client.data['MIÉ'] : 0;
        totalRes += (client.data.JUE) !== undefined ? client.data.JUE : 0;
        totalRes += (client.data.VIE) !== undefined ? client.data.VIE : 0;
        totalRes += (client.data['SÁB']) !== undefined ? client.data['SÁB'] : 0;
        totalRes += (client.data.DOM) !== undefined ? client.data.DOM : 0;
        return {
          name: client.name,
          totalRes: totalRes
        };
      });
      if (type != null) {
        return clientTypeNew.find(ctn => ctn.name === type).totalRes;
      }
      if (clientTypeNew.length === 0) {
        return 0;
      }
      return clientTypeNew.reduce((a, b) => {
        return { totalRes: a.totalRes + b.totalRes };
      }).totalRes;
    },
    getTotalsMediaType (medias, type = null) {
      if (medias === null) {
        return 0;
      }
      const mediasNew = medias.map(media => {
        let totalRes = 0;
        const totalPeople = 0;
        totalRes += (media.dataReservation.LUN) !== undefined ? media.dataReservation.LUN : 0;
        totalRes += (media.dataReservation.MAR) !== undefined ? media.dataReservation.MAR : 0;
        totalRes += (media.dataReservation['MIÉ']) !== undefined ? media.dataReservation['MIÉ'] : 0;
        totalRes += (media.dataReservation.JUE) !== undefined ? media.dataReservation.JUE : 0;
        totalRes += (media.dataReservation.VIE) !== undefined ? media.dataReservation.VIE : 0;
        totalRes += (media.dataReservation['SÁB']) !== undefined ? media.dataReservation['SÁB'] : 0;
        totalRes += (media.dataReservation.DOM) !== undefined ? media.dataReservation.DOM : 0;
        // totalPeople += (media['dataPeople']['LUN']) !== undefined ? parseInt(media['dataPeople']['LUN']) : 0;
        // totalPeople += (media['dataPeople']['MAR']) !== undefined ? parseInt(media['dataPeople']['MAR']) : 0;
        // totalPeople += (media['dataPeople']['MIÉ']) !== undefined ? parseInt(media['dataPeople']['MIÉ']) : 0;
        // totalPeople += (media['dataPeople']['JUE']) !== undefined ? parseInt(media['dataPeople']['JUE']) : 0;
        // totalPeople += (media['dataPeople']['VIE']) !== undefined ? parseInt(media['dataPeople']['VIE']) : 0;
        // totalPeople += (media['dataPeople']['SÁB']) !== undefined ? parseInt(media['dataPeople']['SÁB']) : 0;
        // totalPeople += (media['dataPeople']['DOM']) !== undefined ? parseInt(media['dataPeople']['DOM']) : 0;
        return {
          name: media.name,
          totalRes: totalRes,
          totalPeople: totalPeople
        };
      });
      if (type === null) {
        return mediasNew;
      }
      if (mediasNew.length === 0) {
        return 0;
      }
      return mediasNew.reduce((a, b) => {
        return { totalRes: a.totalRes + b.totalRes };
      }).totalRes;
    },
    getTotalsByRange (ranges, type = null) {
      if (ranges === null) {
        return 0;
      }
      const nameMapping = {
        whitelabel: 'página web',
        dashboard: 'telefono'
      };
      const ageGroups = ['0-5', '6-12', '+12'];
      const newRanges = ranges.map((range) => {
        const name = nameMapping[range.name] || range.name;
        const sum = ageGroups.reduce((acc, group) => acc + (range?.dataReservation?.[group] || 0), 0);
        const sumPeople = ageGroups.reduce((acc, group) => acc + (parseInt(range?.dataPeople?.[group], 10) || 0), 0);
        return {
          name: range.name,
          reservations: sum,
          people: sumPeople,
          color: this.getColorByName(name)
        };
      });
      if (type === null) {
        return newRanges;
      }
      if (newRanges.length === 0) {
        return 0;
      }
      if (type === 'reservations') {
        return newRanges.reduce((a, b) => {
          return { reservations: a.reservations + b.reservations };
        }).reservations;
      }
      return newRanges.reduce((a, b) => {
        return { people: a.people + b.people };
      }).people;
    },
    openMenuM2 () {
      // eslint-disable-next-line
      classie.add(document.getElementById('DownloadXLS'), 'show-menu-messager')
    },
    closeMenuM2 () {
      const els = document.getElementsByClassName('show-menu-messager');
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-messager');
        }
      }
    },
    validateClientTypeGraph () {
      if ((this.sectionId === 1 || this.sectionId === 2) && this.params.activeSelectorClient === 1) {
        return true;
      }
      return false;
    },
    validateExperiencieTypeGraph () {
      if (this.params && (this.sectionId === 1) && this.params.activeExperiencies === 1) {
        return true;
      }
      return false;
    },
    percent (value1, value2) {
      value1 = isNaN(value1) || value1 === null ? 0 : parseInt(value1);
      value2 = isNaN(value2) || value2 === null ? 0 : parseInt(value2);

      return Math.trunc((value1 * 100) / (value1 + value2));
    },
    getTotalsGenerals (data, type) {
      if (type === 1) {
        return (parseInt(data.resevationReservations) + parseInt(data.walkinReservations) + parseInt(data.prePurchaseReservations));
      }
      return (parseInt(data.resevationPeople) + parseInt(data.walkinPeople) + parseInt(data.prePurchasePeople));
    },
    getTimeReservations (from, to) {
      if (!from && !to) {
        this.$store
          .dispatch({
            type: 'metrics:getDataReservationTime',
            data: { vendorId: this.idVendor, year: this.year }
          })
          .then(() => {
            const timeCont = _.find(this.timeState, { name: 'Reservas' });
            const timeCont2 = _.find(this.timeState, { name: 'Precompras' });
            const timeCont3 = _.find(this.timeState, { name: 'Walk-in' });
            if (
              (timeCont?.data?.length > 0) ||
              (timeCont2?.data?.length > 0) ||
              (timeCont3?.data?.length > 0)
            ) {
              for (var i = 1; i <= 16; i++) {
                if (timeCont.data['' + i] !== undefined) {
                  if (i !== 16) {
                    this.timeStateN[0].data['' + i] = timeCont.data['' + i];
                  } else {
                    this.timeStateN[0].data['' + i + '+'] = timeCont.data['' + i];
                  }
                } else {
                  if (i !== 16) {
                    this.timeStateN[0].data['' + i] = 0;
                  } else {
                    this.timeStateN[0].data['' + i + '+'] = 0;
                  }
                }
                if (timeCont2.data['' + i] !== undefined) {
                  if (i !== 16) {
                    this.timeStateN[2].data['' + i] = timeCont2.data['' + i];
                  } else {
                    this.timeStateN[2].data['' + i + '+'] = timeCont2.data['' + i];
                  }
                } else {
                  if (i !== 16) {
                    this.timeStateN[2].data['' + i] = 0;
                  } else {
                    this.timeStateN[2].data['' + i + '+'] = 0;
                  }
                }
                if (timeCont3.data['' + i] !== undefined) {
                  if (i !== 16) {
                    this.timeStateN[1].data['' + i] = timeCont3.data['' + i];
                  } else {
                    this.timeStateN[1].data['' + i + '+'] = timeCont3.data['' + i];
                  }
                } else {
                  if (i !== 16) {
                    this.timeStateN[1].data['' + i] = 0;
                  } else {
                    this.timeStateN[1].data['' + i + '+'] = 0;
                  }
                }
              }
            } else {
              this.timeStateN = [];
            }
            if (this.sectionId === 1) {
              this.getDataTimeReservations(this.timeStateN);
            }
            if (this.sectionId === 3) {
              this.getDataTimeReservationsWalkIn(this.timeStateN);
            }
          });
      }
      if (from && to) {
        this.$store
          .dispatch({
            type: 'metrics:getDataReservationTime',
            data: { vendorId: this.idVendor, dateFrom: this.dateFrom, dateTo: this.dateTo, year: this.year }
          })
          .then(() => {
            const timeCont = _.find(this.timeState, { name: 'Reservas' });
            const timeCont2 = _.find(this.timeState, { name: 'Precompras' });
            const timeCont3 = _.find(this.timeState, { name: 'Walk-in' });
            if (
              (timeCont?.data?.length > 0) ||
              (timeCont2?.data?.length > 0) ||
              (timeCont3?.data?.length > 0)
            ) {
              for (var i = 1; i <= 16; i++) {
                if (timeCont.data['' + i] !== undefined) {
                  if (i !== 16) {
                    this.timeStateN[0].data['' + i] = timeCont.data['' + i];
                  } else {
                    this.timeStateN[0].data['' + i + '+'] = timeCont.data['' + i];
                  }
                } else {
                  if (i !== 16) {
                    this.timeStateN[0].data['' + i] = 0;
                  } else {
                    this.timeStateN[0].data['' + i + '+'] = 0;
                  }
                }
                if (timeCont2.data['' + i] !== undefined) {
                  if (i !== 16) {
                    this.timeStateN[2].data['' + i] = timeCont2.data['' + i];
                  } else {
                    this.timeStateN[2].data['' + i + '+'] = timeCont2.data['' + i];
                  }
                } else {
                  if (i !== 16) {
                    this.timeStateN[2].data['' + i] = 0;
                  } else {
                    this.timeStateN[2].data['' + i + '+'] = 0;
                  }
                }
                if (timeCont3.data['' + i] !== undefined) {
                  if (i !== 16) {
                    this.timeStateN[1].data['' + i] = timeCont3.data['' + i];
                  } else {
                    this.timeStateN[1].data['' + i + '+'] = timeCont3.data['' + i];
                  }
                } else {
                  if (i !== 16) {
                    this.timeStateN[1].data['' + i] = 0;
                  } else {
                    this.timeStateN[1].data['' + i + '+'] = 0;
                  }
                }
              }
            } else {
              this.timeStateN = [];
            }
          });
      }
    },
    getReservationsCompleted (from, to) {
      this.byReservationS[0].data = {};
      this.byReservationS[1].data = {};

      let getDataR = { vendorId: this.idVendor, year: this.year };
      if (from && to) {
        getDataR = { vendorId: this.idVendor, dateFrom: this.dateFrom, dateTo: this.dateTo, year: this.year };
      }
      this.$store
        .dispatch({
          type: 'metrics:getDataReservationCompleted',
          data: getDataR
        })
        .then(res => {
          const data = res.data.metricData;
          for (const [key, value] of Object.entries(data)) {
            this.byReservationS[0].data[key] = value.user;
            this.byReservationS[1].data[key] = value.system;
          }
        });
    },
    getNewArrayTransactions (array) {
      const grupos = [
        { name: 'Promedio de Transacciones', data: {}, color: '#3CBA9F' },
        { name: 'Transaccion Mas Baja', data: {}, color: '#C45850' },
        { name: 'Transaccion Mas Alta', data: {}, color: '#E8C3B9' }
      ];
      if (array == null || !Array.isArray(array) || array.length <= 0) {
        return [];
      }

      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        grupos[0].data = { ...grupos[0].data, [element.mes]: parseFloat(element['Promedio de Transacciones']) };
        grupos[1].data = { ...grupos[1].data, [element.mes]: element['Transaccion Mas Baja'] };
        grupos[2].data = { ...grupos[2].data, [element.mes]: element['Transaccion Mas Alta'] };
      }
      return grupos;
    },
    getDataHours () {
      if (this.byHour != null) {
        const byHourClone = [...this.byHour];
        byHourClone.forEach(res => {
          if (res.hora.toString().length === 1) {
            res.hour = `0${res.hora}:00`;
            return;
          }
          res.hour = `${res.hora}:00`;
        });
        if (this.sectionId === 4) {
          this.byHourDGeneral.rows = byHourClone;
        }
        if (this.sectionId === 2) {
          this.byHourD.columns = ['hour', 'Precompras'];
          this.byHourD.rows = byHourClone;
          return;
        }
        this.byHourD.columns = ['hour', 'Reservas'];
        this.byHourD.rows = byHourClone;
      }
    },
    getDataHoursLastHour () {
      if ((this.byHourLastHour != null && this.byHourLastHour.length > 0)) {
        const byHourClone = [...this.byHourLastHour];
        byHourClone.forEach(res => {
          if (res.hora.toString().length === 1) {
            res.name = `0${res.hora}:00`;
            res.data = res.Reservas;
            return;
          }
          res.data = res.Reservas;
          res.name = `${res.hora}:00`;
        });
        const lastHour = byHourClone.pop();
        this.byLastHourD = [{
          name: 'Ultimo Movimiento', data: { [lastHour.name]: lastHour.data }
        }];
      }
    },
    getLastFromDay (byHourClone) {
      const finalArray = [];
      const days = [
        { name: 'DOM', pos: 0 },
        { name: 'LUN', pos: 1 },
        { name: 'MAR', pos: 2 },
        { name: 'MIÉ', pos: 3 },
        { name: 'JUE', pos: 4 },
        { name: 'VIE', pos: 5 },
        { name: 'SÁB', pos: 6 }
      ];
      days.forEach((day) => {
        const filtered = byHourClone.filter(hourLast => hourLast.dia === day.name);
        if (filtered.length > 0) {
          const last = filtered.pop();
          finalArray.push({ name: `${last.dia}-${last.name}`, data: { [`${last.dia}-${last.name}`]: last.data } });
        }
      });
      this.byLastHourD = finalArray;
    },
    getDataTimeReservations (data) {
      const newData = [...data];
      newData.splice(2, 1);
      newData.splice(1, 1);
      this.timeStateN2 = [...newData];
    },
    getDataTimeReservationsWalkIn (data) {
      const newData = [...data];
      newData.splice(0, 1);
      newData.splice(1, 1);
      this.timeStateN3 = [...newData];
    },
    setDataClientType () {
      if (this.byClientType) {
        this.byDayReservationsClient = this.dataReservation.byClientType.map(function (response) {
          const aux = {};
          const name = response.name;
          aux.name = name;
          aux.data = response.dataReservation;
          return aux;
        });
        this.byDayPeopleClient = this.dataReservation.byClientType.map(function (response) {
          const aux = {};
          const name = response.name;
          aux.name = name;
          aux.data = response.dataPeople;
          return aux;
        });
        if (this.sectionId === 4) {
          this.byDayReservationsClient.splice(1, 1);
          this.byClientTypeDGeneral = this.byDayReservationsClient;
        }
      }
    },
    setDataMediaType () {
      if (this.byMediaType) {
        this.byDayReservationsMedia = this.dataReservation.byMediaType.map(function (response) {
          const aux = {};
          const name = response.name;
          aux.name = name;
          aux.data = response.dataReservation;
          return aux;
        });
        this.byDayPeopleMedia = this.dataReservation.byMediaType.map(function (response) {
          const aux = {};
          const name = response.name;
          aux.name = name;
          aux.data = response.dataPeople;
          return aux;
        });
        if (this.sectionId === 4) {
          this.byDayReservationsMedia.splice(1, 1);
          this.byMediaTypeDGeneral = this.byDayReservationsMedia;
        }
      }
    },
    checkAnyPositiveValue (obj) {
      if (typeof obj === 'object' && obj !== null) {
        return Object.values(obj).some(value => this.checkAnyPositiveValue(value));
      }
      return typeof obj === 'number' && obj > 0;
    }
  }
};
</script>
<style lang="scss">
  @use "@/assets/styles/metrics/_index";
</style>
