<template>
  <div id="pixel-form">
    <div class="columns is-desktop style-columns">
      <div class="column is-6">
        <b-field
          :message="errors.first('pixel')"
          :type="{'is-danger': errors.has('pixel')}"
          label="Pixel"
        >
          <textarea
            class="textCelebrationArea"
            name="pixel"
            v-model="pixel"
            v-validate="'required'"
          ></textarea>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Ejemplo">
          <textarea class="textCelebrationArea" disabled v-model="example"></textarea>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop style-columns">
      <div class="column is-4">
        <b-field>
          <b-button
            :loading="sending"
            @click="saveFeatures"
            icon-left="content-save"
            type="is-primary"
          >
            <span>Guardar</span>
          </b-button>
          <b-button
            :loading="sending"
            @click="deleteIntegration"
            icon-left="delete"
            style="margin-left:0.5rem;"
            type="is-danger"
            v-if="id !== null"
          >
            <span>Borrar</span>
          </b-button>
          <b-field>
            <b-button
              :loading="sending"
              @click="cleanUp"
              style="margin-left:0.5rem;"
              type="is-primary"
            >
              <span>Volver</span>
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from '@/store/types/global';
export default {
  props: ['restaurant', 'integration', 'dataIntegrations'],
  data () {
    return {
      sending: false,
      pixel: '',
      example: '',
      type: '',
      id: null,
      exampleFacebook: `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '203291761252085');
    fbq('track', 'PageView');
document.write("<img height='1' width='1' style='display: none' src='https://www.facebook.com/tr?id=203291761252085&ev=PageView&noscript=1/>");
`,
      exampleGoogle: `(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-TGLG98F');

document.write("<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-TGLG98F' height = '0' width = '0' style = 'display:none;visibility:hidden' ></iframe >");`
    };
  },
  mounted () {
    this.type = (this.integration.name === 'facebook') ? 'facebookPixel' : 'googlePixel';
    this.example = (this.integration.name === 'facebook') ? this.exampleFacebook : this.exampleGoogle;
    const integration = this.dataIntegrations.find(inte => inte.type === this.type);
    if (integration) {
      this.pixel = integration.key;
      this.id = integration.id;
    }
  },
  methods: {
    cleanUp () {
      this.$emit('cleanUpIntegration');
    },
    saveFeatures () {
      this.sending = true;
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      try {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('saveUpdateIntegration', { key: this.pixel, type: this.type, id: this.id });
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            return;
          }
          this.sending = false;
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      } catch (error) {
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }
    },
    deleteIntegration () {
      try {
        this.$buefy.dialog.confirm({
          title: 'Borrar Integración.',
          message: '¿Esta Seguro de que desea borrar la integración?',
          confirmText: 'Si, borrar',
          cancelText: 'No, cancelar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.sending = true;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
            this.$emit('deleteIntegration', this.id);
          },
          onCancel: () => {
            this.sending = false;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          }
        });
      } catch (error) {
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }
    }
  }
};
</script>

<style lang="scss">
#pixel-form {
  .style-columns {
    display: flex;
    gap: 1rem;
    margin-top: 60px;
    padding: 10px 10px;
  }
  .textCelebrationArea {
    border: 2px solid #bbbbbba3;
    border-radius: 5px;
    font-size: 15px;
    height: 300px;
    width: -webkit-fill-available;
    resize: none;
  }
}
</style>
